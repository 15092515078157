import { createSelector } from 'reselect'

import { DEFAULT_CATEGORY } from 'src/service-design/sd-plan/constants'
import { CustomTaskAttrs } from 'src/service-design/sd-plan/document/types'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getCustomTasksFilter,
  filterItems,
  FilterFn,
} from 'src/service-design/sd-plan/selectors/filters'
import * as CustomTask from 'src/service-design/shared/models/custom-task'

export const matchesCategory = (
  task: CustomTask.CustomTask,
  { category }: { category?: string },
): boolean => !category || task.category === category
export const matchesStartLocationId = (
  task: CustomTask.CustomTask,
  { startLocationId }: { startLocationId?: string },
): boolean => !startLocationId || task.startLocation.id === startLocationId
export const matchesEndLocationId = (
  task: CustomTask.CustomTask,
  { endLocationId }: { endLocationId?: string },
): boolean => !endLocationId || task.endLocation.id === endLocationId
export const matchesRequiresDriver = (
  task: CustomTask.CustomTask,
  { onlyDriverTasks }: { onlyDriverTasks?: boolean },
): boolean => !onlyDriverTasks || task.requiresDriver

const CUSTOM_TASK_FILTERS: FilterFn<
  CustomTask.CustomTask,
  {
    category?: string
    startLocationId?: string
    endLocationId?: string
    onlyDriverTasks?: boolean
  }
>[] = [
  matchesCategory,
  matchesStartLocationId,
  matchesEndLocationId,
  matchesRequiresDriver,
]

export const getRawCustomTasks = (state: any): CustomTaskAttrs[] =>
  getCollection(state, 'service-design', 'customtasks')

export const getRawCustomTasksMap = createSelector(
  getRawCustomTasks,
  tasks => new Map(tasks.map(t => [t.id, t])),
)

export const getFilteredCustomTasks = createSelector(
  getCustomTasksFilter,
  CustomTask.values,
  (filter, tasks: CustomTask.CustomTask[]) =>
    filterItems(filter, tasks, CUSTOM_TASK_FILTERS),
)

export const getOrderedCategoryOptions = createSelector(
  CustomTask.values,
  tasks =>
    [...new Set([DEFAULT_CATEGORY, ...tasks.map(t => t.category)])].map(c => ({
      key: c,
      value: c,
      text: c,
    })),
)
