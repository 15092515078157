import qs from 'qs'

// We deliberately do not parse arrays so that dealing with them is explicit
// in code that needs to; all param values are `string` and not `string|string[]`
export const parseParams = (params: string) =>
  qs.parse(params, {
    allowDots: true,
    ignoreQueryPrefix: true,
    parseArrays: false,
  })

// ... but when we *give* qs an array, we want it comma-formatted, please
export const formatParams = (params: any) =>
  qs.stringify(params, { allowDots: true, arrayFormat: 'comma' })
