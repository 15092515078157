import { uniq } from 'lodash'
import { createSelector } from 'reselect'

import i18n from 'src/i18n'
import {
  getCorridors,
  getLocations,
} from 'src/service-design/sd-plan/selectors/scenario'
import { SECONDS_PER_WEEK } from 'src/service-design/shared/constants'
import { Corridor } from 'src/service-design/shared/models/corridor/model'
import { Location } from 'src/service-design/shared/models/location'
import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import { EpochTime } from 'src/service-design/shared/utils/dates'
import {
  SimpleWarning,
  WarningDefinition,
  WarningTypeToken,
} from 'src/service-design/shared/warnings'

const isSameDirection = (leadingLeg: OffsetLeg, followingLeg: OffsetLeg) =>
  leadingLeg.startLeg.forward === followingLeg.startLeg.forward

const offsetForCycle0 = (startLeg: StartLeg.StartLeg): number =>
  /**
   * TODO: This is the sort of thing that our ValueObjects ought to help us
   * with. Can we better represent this function using our value objects
   * or are there other ways of implementing the conflict detection
   * algorithm which take advantage of our existing API?
   */
  startLeg.departsLocal.toSeconds() -
  startLeg.departsLocal.asCyclicTime().toSeconds()

const followingDepartureEarlierThanLeadingDeparture = (
  leadingLeg: OffsetLeg,
  followingLeg: OffsetLeg,
) =>
  leadingLeg.departsLocal.toSeconds() >
  followingLeg.departsLocal.toSeconds() - followingLeg.startLeg.headwaySecs
const followingArrivalEarlierThanLeadingArrival = (
  leadingLeg: OffsetLeg,
  followingLeg: OffsetLeg,
) =>
  leadingLeg.arrivesLocal.toSeconds() >
  followingLeg.arrivesLocal.toSeconds() - followingLeg.startLeg.headwaySecs
const followingArrivalEarlierThanLeadingDeparture = (
  leadingLeg: OffsetLeg,
  followingLeg: OffsetLeg,
) => {
  const trainLengthExceeds =
    leadingLeg.startLeg.length > leadingLeg.startLeg.dest.maxTrainLength
  const leadingDetachEndOrDeparture = leadingLeg.startLeg.terminating
    ? leadingLeg.detachEndLocal
    : leadingLeg.nextDepartsLocal
  return (
    trainLengthExceeds &&
    leadingDetachEndOrDeparture >
      followingLeg.arrivesLocal.toSeconds() - followingLeg.startLeg.headwaySecs
  )
}

const headwayCheck = (leadingLeg: OffsetLeg, followingLeg: OffsetLeg) =>
  isSameDirection(leadingLeg, followingLeg) &&
  (followingDepartureEarlierThanLeadingDeparture(leadingLeg, followingLeg) ||
    followingArrivalEarlierThanLeadingArrival(leadingLeg, followingLeg) ||
    followingArrivalEarlierThanLeadingDeparture(leadingLeg, followingLeg))

const opposingTrainsCheck = (
  leadingLeg: OffsetLeg,
  followingLeg: OffsetLeg,
) => {
  if (isSameDirection(leadingLeg, followingLeg)) {
    return false
  }

  let leadingClear = leadingLeg.arrivesLocal.toSeconds()
  if (leadingLeg.startLeg.length > leadingLeg.startLeg.dest.maxTrainLength) {
    leadingClear = leadingLeg.startLeg.terminating
      ? leadingLeg.detachEndLocal
      : leadingLeg.nextDepartsLocal
  }
  return (
    followingLeg.departsLocal.toSeconds() <
    leadingClear + leadingLeg.startLeg.clearanceSecs
  )
}

export const getHeadwayConflicts = createSelector(
  StartLeg.values,
  getCorridors,
  (
    startLegs: StartLeg.StartLeg[],
    corridors: Map<string, Corridor>,
  ): SimpleWarning[] => {
    const headwayWarnings: {
      [offsetLegId: string]: {
        leg: StartLeg.StartLeg
        others: StartLeg.StartLeg[]
      }
    } = {}

    corridors.forEach(corridor => {
      /**
       * Warnings are determined by doing pair-wise checks of the form
       * (leadingLeg, followingLeg) where leadingLeg has always departed earlier
       * than following leg.
       *
       * Note that due to week wrapping a leg that departs later in the week can
       * conflict with one that departs very early in the week. To deal with this
       * We take a copy of each leg and shift it so that it departs within
       * 'week 0' and then repeat everything again in 'week 1'.
       */
      const offsetLegs = startLegs
        .filter(leg => leg.corridor === corridor)
        .map(
          startLeg =>
            new OffsetLeg({ startLeg, offset: -offsetForCycle0(startLeg) }),
        )
        .sort((l1, l2) =>
          EpochTime.sortComparator(l1.departsLocal, l2.departsLocal),
        )

      const repeated = [
        ...offsetLegs,
        ...offsetLegs.map(offsetLeg => offsetLeg.getNextWeek()),
      ]

      /**
       * Since we've just repeated the same legs twice every legs is guaranteed
       * to have been in the first half of the list.
       */
      for (let i = 0; i < repeated.length / 2; i += 1) {
        const leadingLeg = repeated[i]

        // Along consider followingLegs that depart after this leadingLeg.
        for (let j = i + 1; j < repeated.length; j += 1) {
          const followingLeg = repeated[j]
          if (
            followingLeg.id !== leadingLeg.id &&
            headwayCheck(leadingLeg, followingLeg)
          ) {
            headwayWarnings[followingLeg.id] = headwayWarnings[
              followingLeg.id
            ] || { leg: followingLeg.startLeg, others: [] }
            headwayWarnings[followingLeg.id].others.push(leadingLeg.startLeg)
          }
        }
      }
    })

    return Object.values(headwayWarnings).map(w => ({
      entityId: w.leg.id,
      message: i18n.t(
        'service-design::{{train}} is too close to train {{others}}',
        {
          train: w.leg.start.name,
          others: uniq(w.others)
            .map(o => o.start.name)
            .join(', '),
        },
      ),
    }))
  },
)

export const getOpposingTrainsOnCorridorConflicts = createSelector(
  StartLeg.values,
  getCorridors,
  (
    startLegs: StartLeg.StartLeg[],
    corridors: Map<string, Corridor>,
  ): SimpleWarning[] => {
    const opposingTrainsWarnings: {
      [offsetLegId: string]: {
        leg: StartLeg.StartLeg
        other: StartLeg.StartLeg
      }
    } = {}

    corridors.forEach(corridor => {
      /**
       * Warnings are determined by doing pair-wise checks of the form
       * (leadingLeg, followingLeg) where leadingLeg has always departed earlier
       * than following leg.
       *
       * Note that due to week wrapping a leg that departs later in the week can
       * conflict with one that departs very early in the week. To deal with this
       * We take a copy of each leg and shift it so that it departs within
       * 'week 0' and then repeat everything again in 'week 1'.
       */
      const offsetLegs = startLegs
        .filter(leg => leg.corridor === corridor)
        .map(
          startLeg =>
            new OffsetLeg({ startLeg, offset: -offsetForCycle0(startLeg) }),
        )
        .sort((l1, l2) =>
          EpochTime.sortComparator(l1.departsLocal, l2.departsLocal),
        )

      const repeated = [
        ...offsetLegs,
        ...offsetLegs.map(offsetLeg => offsetLeg.getNextWeek()),
      ]

      /**
       * Since we've just repeated the same legs twice every legs is guaranteed
       * to have been in the first half of the list.
       */
      for (let i = 0; i < repeated.length / 2; i += 1) {
        const leadingLeg = repeated[i]

        // Along consider followingLegs that depart after this leadingLeg.
        for (let j = i + 1; j < repeated.length; j += 1) {
          const followingLeg = repeated[j]
          if (followingLeg.id !== leadingLeg.id) {
            if (opposingTrainsCheck(leadingLeg, followingLeg)) {
              opposingTrainsWarnings[followingLeg.id] = {
                leg: followingLeg.startLeg,
                other: leadingLeg.startLeg,
              }

              opposingTrainsWarnings[leadingLeg.id] = {
                leg: leadingLeg.startLeg,
                other: followingLeg.startLeg,
              }
            }
          }
        }
      }
    })

    return Object.values(opposingTrainsWarnings).map(w => ({
      entityId: w.leg.id,
      message: i18n.t(
        'service-design::Train {{trainA}} opposes {{trainB}} on {{corridor}}',
        {
          trainA: w.leg.start.name,
          trainB: w.other.start.name,
          corridor: w.leg.corridor.name,
        },
      ),
    }))
  },
)

const type2sign = {
  originating: +1,
  arrival: +1,
  departure: -1,
  terminating: -1,
}

export class LocationEvent {
  constructor(
    public timestamp: number,
    public type: 'originating' | 'arrival' | 'departure' | 'terminating',
    public leg: StartLeg.StartLeg,
  ) {}

  get sign() {
    return type2sign[this.type]
  }

  static buildEvents(
    startLegs: StartLeg.StartLeg[],
    locId: string,
  ): LocationEvent[] {
    const departingLegs = startLegs.filter(leg => leg.origin.id === locId)
    const arrivingLegs = startLegs.filter(leg => leg.dest.id === locId)

    /**
     * TODO: this code looks very suspect. It appears to assume that every
     * startLeg.departsLocal is going to be in 'week 0' which is not true.
     * departsLocal is completely unbounded and this is likely to have a pretty
     * big impact on the way these calculations play out.
     */
    const departingOffsetLegs = [
      ...departingLegs.map(startLeg => new OffsetLeg({ startLeg, offset: 0 })),
      ...departingLegs.map(
        startLeg => new OffsetLeg({ startLeg, offset: SECONDS_PER_WEEK }),
      ),
    ]

    const arrivingOffsetLegs = [
      ...arrivingLegs.map(startLeg => new OffsetLeg({ startLeg, offset: 0 })),
      ...arrivingLegs.map(
        startLeg => new OffsetLeg({ startLeg, offset: SECONDS_PER_WEEK }),
      ),
    ]

    const departingEvents = departingOffsetLegs.reduce<LocationEvent[]>(
      (coll, offsetLeg: OffsetLeg): LocationEvent[] => {
        coll.push(
          new LocationEvent(
            offsetLeg.departsLocal.toSeconds() +
              offsetLeg.startLeg.clearanceSecs,
            'departure',
            offsetLeg.startLeg,
          ),
        )
        if (offsetLeg.startLeg.originating) {
          coll.push(
            new LocationEvent(
              offsetLeg.attachStartLocal,
              'originating',
              offsetLeg.startLeg,
            ),
          )
        }
        return coll
      },
      [],
    )

    const arrivingEvents = arrivingOffsetLegs.reduce<LocationEvent[]>(
      (coll, offsetLeg: OffsetLeg): LocationEvent[] => {
        coll.push(
          new LocationEvent(
            offsetLeg.arrivesLocal.toSeconds(),
            'arrival',
            offsetLeg.startLeg,
          ),
        )
        if (offsetLeg.startLeg.terminating) {
          coll.push(
            new LocationEvent(
              offsetLeg.detachEndLocal,
              'terminating',
              offsetLeg.startLeg,
            ),
          )
        }
        return coll
      },
      [],
    )

    return this.sort([...departingEvents, ...arrivingEvents])
  }

  static sort(locationEvents: LocationEvent[]): LocationEvent[] {
    return locationEvents.sort((a, b) => {
      if (a.timestamp === b.timestamp) {
        if (a.sign === b.sign) {
          return a.leg.id.localeCompare(b.leg.id)
        }
        // When events are happening at the same time
        // we want arrivals to happen prior to departures
        // (see BOSS-1975) otherwise the location balance
        // can get confused the think the trains are dwelling
        // at the location all week.
        return b.sign - a.sign
      }
      return a.timestamp - b.timestamp
    })
  }
}

export const getLocationConflictWarnings = createSelector(
  getLocations,
  StartLeg.values,
  (
    locations: Map<string, Location>,
    startlegs: StartLeg.StartLeg[],
  ): SimpleWarning<{ startTime: number; endTime: number }>[] => {
    const locTypeWarnings: {
      location: Location
      locationEvents: LocationEvent[]
    }[] = []

    for (const [locId, loc] of locations) {
      const locationEvents = LocationEvent.buildEvents(startlegs, locId)

      let minOccupancy = 0
      let occupancyCount = 0

      locationEvents.forEach(event => {
        occupancyCount += event.sign
        minOccupancy = Math.min(occupancyCount, minOccupancy)
      })
      occupancyCount = minOccupancy * -1

      let accumEvents: LocationEvent[] = []

      locationEvents.forEach(event => {
        occupancyCount += event.sign

        if (occupancyCount > loc.maxOccupyingTrains) {
          accumEvents.push(event)
        } else if (
          accumEvents.length &&
          occupancyCount <= loc.maxOccupyingTrains
        ) {
          accumEvents.push(event)

          const isDuplicate = locTypeWarnings.find(
            warning =>
              warning.location.id === locId &&
              warning.locationEvents.every(locationEvent =>
                accumEvents
                  .map(accumEvent => accumEvent.leg.id)
                  .includes(locationEvent.leg.id),
              ),
          )

          if (!isDuplicate) {
            locTypeWarnings.push({ location: loc, locationEvents: accumEvents })
          }

          accumEvents = []
        }
      })
    }

    return locTypeWarnings.map(w => ({
      // TODO: ideally we'd attach this warning to all the train starts
      entityId: w.location.id,
      message: i18n.t(
        'service-design::Location: {{location.code}} has too many trains: [{{others}}]',
        {
          location: w.location,
          others: uniq(
            w.locationEvents.map(event => event.leg.start.name),
          ).join(', '),
        },
      ),
      startTime: w.locationEvents[0].timestamp,
      endTime: w.locationEvents[w.locationEvents.length - 1].timestamp,
    }))
  },
)

export const HeadwayToken = 'service-design::Insufficient Headway Between Trains' as WarningTypeToken<
  'service-design::Insufficient Headway Between Trains'
>

export const OpposingTrainsOnCorridorToken = 'service-design::Opposing Trains on Corridor' as WarningTypeToken<
  'service-design::Opposing Trains on Corridor'
>
export const TrainCapacityExceededOnLocationToken = 'service-design::Train Capacity Exceeded on Location' as WarningTypeToken<
  'service-design::Train Capacity Exceeded on Location',
  { startTime: number; endTime: number }
>

export const warningDefinitions = [
  new WarningDefinition(StartLeg.StartLeg, HeadwayToken, getHeadwayConflicts),
  new WarningDefinition(
    StartLeg.StartLeg,
    OpposingTrainsOnCorridorToken,
    getOpposingTrainsOnCorridorConflicts,
  ),
  new WarningDefinition(
    Location,
    TrainCapacityExceededOnLocationToken,
    getLocationConflictWarnings,
  ),
] as const
