import { createStructuredSelector } from 'reselect'

import { ScenarioDocument } from 'src/service-design/scenario/document/types'
import { ServiceDesignDocument } from 'src/service-design/sd-plan/document/types'
import { IPreferences } from 'src/service-design/shared/users/reducers/preferences'

// NOTE: These selectors shouldn't really be here but by putting them here
// we're able to simplify calls to getDocumentGraphFactory which puts
// us in a better place to depreciate it.

const EMPTY = {}

export const getUserPreferences = (state: any): IPreferences =>
  state.userPreferences

export const getFatigueScores = (state: any): Record<string, number> =>
  state.fatigue ? state.fatigue.shiftlines : EMPTY

export const getServiceDesignSingletons = (state: any) =>
  getServiceDesignData(state).singletons

export const getScenarioData = (state: any): ScenarioDocument =>
  state.documents.hasOwnProperty('scenario') && state.documents.scenario.data

export const getServiceDesignData = (state: any): ServiceDesignDocument =>
  state.documents.hasOwnProperty('service-design') &&
  state.documents['service-design'].data

export const getServiceDesignName = (state: any): ServiceDesignDocument =>
  state.documents['service-design'] &&
  state.documents['service-design'].meta.name

export const getRevisionData = createStructuredSelector({
  scenario: getScenarioData,
  'service-design': getServiceDesignData,
})
