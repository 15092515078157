import classnames from 'classnames'
import React from 'react'

import styled from 'styled-components'

import { CircleClose } from 'src/service-design/shared/icons'

const StyledCollapseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  z-index: 1;
  padding: 0;
  cursor: pointer;
  outline: none;

  svg {
    height: 30px;
    transform-origin: 50% 50%;
    transition-duration: 0.2s;

    &.collapsed {
      transform: rotate(135deg);
    }
  }
`

interface CollapseButtonProps {
  className?: string
  collapsed: boolean
  onClick: () => void
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  className,
  collapsed,
  onClick,
}) => (
  <StyledCollapseButton className={className} onClick={onClick}>
    <CircleClose title="Open/Close" className={classnames({ collapsed })} />
  </StyledCollapseButton>
)

export { CollapseButton }
export default CollapseButton
