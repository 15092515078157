import { createSelector } from 'reselect'

import {
  CrewPoolAttrs,
  ShiftAttrs,
} from 'src/service-design/sd-plan/document/types'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getCrewFilter,
  filterItems,
  FilterFn,
} from 'src/service-design/sd-plan/selectors/filters'
import * as CrewPool from 'src/service-design/shared/models/crew-pool'
import { CrewPoolRepo } from 'src/service-design/shared/models/crew-pool/repo'
import { Shift } from 'src/service-design/shared/models/shift'

export const matchesLocation = (
  pool: CrewPool.CrewPool,
  { locId }: { locId?: string },
): boolean => !locId || pool.locId === locId

const CREW_POOL_FILTERS: FilterFn<CrewPool.CrewPool, { locId?: string }>[] = [
  matchesLocation,
]

export const getRawCrewPools = (state: any): CrewPoolAttrs[] =>
  getCollection(state, 'service-design', 'crewpools')
export const getRawShifts = (state: any): ShiftAttrs[] =>
  getCollection(state, 'service-design', 'shifts')

export const getCrewPools = CrewPool.values

export const getCrewPoolTotal = createSelector(
  CrewPool.selector,
  repo => repo.values.length,
)

export const getFatigueUpdating = (state: any): boolean =>
  state.fatigue.dirty || state.fatigue.updating

export const getFilteredCrewPools = createSelector(
  getCrewFilter,
  CrewPool.selector,
  (filter, repo: CrewPoolRepo): CrewPool.CrewPool[] =>
    filterItems(filter, repo.values, CREW_POOL_FILTERS),
)

export const getShiftById = createSelector(
  CrewPool.selector,
  (_: any, { shiftId }: { shiftId: string }) => shiftId,
  (repo: CrewPoolRepo, id: string): Shift => {
    const shifts = repo.values.flatMap(x => x.shifts)
    return shifts.find(y => id === y.id)
  },
)
