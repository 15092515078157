import { readAsArrayBuffer } from 'promise-file-reader'

import xlsx from 'xlsx'

import i18n from 'src/i18n'
import {
  revisionsReceive,
  documentPost,
} from 'src/service-design/shared/document/actions/index'
import { postDocumentLoadCheckOnly } from 'src/service-design/shared/document/actions/post-document-load'
import { DocumentSpec } from 'src/service-design/shared/document/types'
import { Schema } from 'src/service-design/shared/exporter/schema'
import {
  WorkbookDefinition,
  isCollectionDefinition,
  isSingletonDefinition,
} from 'src/service-design/shared/exporter/worksheet'

export const MAX_NUM_SPREADSHEET_COLUMNS = 50

export class ImportError extends Error {
  constructor(
    public errors: (
      | {
          id: string
          type: string
          message: string
        }
      | string
    )[],
  ) {
    super('The file could not be imported') // TODO: i18n. Revisit
  }
}

interface ErrorSource {
  sourceMissingName: string
  sourceMissingId: string
  collections: { [collection: string]: string[] }
  message: string
}

interface ErrorMessage {
  [errorSource: string]: ErrorSource
}

export class FixableError extends Error {
  constructor(public errors: ErrorMessage) {
    super()
  }
}

export class TooManySheetColumnsWarning {
  constructor(public sheet: string) {}

  get stack() {
    return i18n.t(
      'common::In the file uploaded, the sheet {{ sheet }} has a large number of columns. All columns after column {{ max_num }} were ignored. Note that these may be blank and they should be deleted.',
      {
        sheets: this.sheet,
        max_num: MAX_NUM_SPREADSHEET_COLUMNS,
      },
    )
  }
}

const headersFromXlsx = (xlsxSheet: xlsx.WorkSheet) => {
  const range = xlsx.utils.decode_range(xlsxSheet['!ref'])
  const endColIdx = range.e.c + 1

  return [...Array(endColIdx).keys()].map(colIdx => {
    const cell = xlsxSheet[xlsx.utils.encode_cell({ r: 0, c: colIdx })]
    return cell ? cell.v : ''
  })
}

export const checkSpreadsheetFields = (
  xlsxSheet: xlsx.WorkSheet,
  schema: Schema,
  sheetName: string,
) => {
  const cols = headersFromXlsx(xlsxSheet)
  const errors = schema.validateHeaders(cols)
  if (errors.length > 0) {
    throw new ImportError(
      errors.map(error => ({
        id: `${sheetName}-${error.fieldDef.header}`,
        type: 'Missing column',
        message: `Column ${error.fieldDef.header} is missing from the sheet ${sheetName}`, // TODO: i18n
      })),
    )
  }
}

export const parseWorkbook = (
  workbook: xlsx.WorkBook,
  workSheets: WorkbookDefinition,
) =>
  workSheets.sheets.reduce<{
    data: {
      [key: string]: {}[] | {}
      singletons: { [key: string]: {} }
    }
    warnings: TooManySheetColumnsWarning[]
  }>(
    (acc, worksheetDef) => {
      const { sheet: sheetName, schema, exclude } = worksheetDef
      const sheet = workbook.Sheets[sheetName]

      if (!exclude && !sheet) {
        throw new ImportError([`Missing '${sheetName}' sheet`])
      }

      if (
        (isCollectionDefinition(worksheetDef) ||
          isSingletonDefinition(worksheetDef)) &&
        sheet
      ) {
        const sheetRangeRef = sheet['!ref']
        const sheetRange = xlsx.utils.decode_range(sheetRangeRef)

        if (sheetRange.e.c > MAX_NUM_SPREADSHEET_COLUMNS) {
          acc.warnings.push(new TooManySheetColumnsWarning(sheetName))
        }

        const limitedSheetRange = {
          ...sheetRange,
          e: {
            ...sheetRange.e,
            c: Math.min(sheetRange.e.c, MAX_NUM_SPREADSHEET_COLUMNS),
          },
        }

        const rows = xlsx.utils.sheet_to_json(sheet, {
          raw: true,
          range: limitedSheetRange,
        })

        if (rows.length > 0) {
          checkSpreadsheetFields(sheet, schema, sheetName)
        }
        const processed = rows.map(row => schema.load(row))

        if (isCollectionDefinition(worksheetDef)) {
          acc.data[worksheetDef.collection] = processed
        } else {
          // eslint-disable-next-line prefer-destructuring
          acc.data.singletons[worksheetDef.singleton] = processed[0]
        }
        return acc
      }

      if (isCollectionDefinition(worksheetDef)) {
        acc.data[worksheetDef.collection] = []
      } else if (isSingletonDefinition(worksheetDef)) {
        acc.data.singletons[worksheetDef.singleton] = {}
      }

      return acc
    },
    { data: { singletons: {} }, warnings: [] },
  )

const validateDocuments = async ({
  documentSpec,
  dispatch,
}: {
  documentSpec: DocumentSpec<string>
  dispatch: any
}) => {
  const { unfixable, fixed } = await dispatch(
    postDocumentLoadCheckOnly({ documentSpec }),
  )

  if (unfixable.length > 0) {
    throw new ImportError(unfixable)
  }

  if (Object.keys(fixed).length > 0) {
    throw new FixableError(fixed)
  }
}

export const importDocumentFromFile = ({
  file,
  documentSpec,
  type,
  workbookDefinition,
}: {
  file: File
  documentSpec: DocumentSpec<string>
  type: string
  workbookDefinition: WorkbookDefinition
}) => async (dispatch: any) => {
  try {
    const array = await readAsArrayBuffer(file)
    const workbook = xlsx.read(array, { type: 'array', cellDates: true })
    const { data: workbookData, warnings } = parseWorkbook(
      workbook,
      workbookDefinition,
    )

    // validate the shape of the imported workbook
    // TODO the *schema* validation that is done in this call is *already performed* by parseWorkbook
    const bookErrors = workbookDefinition.validate(workbookData)
    if (bookErrors.length) {
      throw new ImportError(bookErrors)
    }

    // TODO vvv once validation doesn't require unnecessary collections from the blank document, remove this vvv
    const data = { ...documentSpec.blank, ...workbookData }
    await dispatch(revisionsReceive([{ data, meta: { type } }]))

    // validate / repair the generated document
    await validateDocuments({ documentSpec, dispatch })
    return { warnings }
  } catch (e) {
    if (e instanceof ImportError || e instanceof FixableError) {
      throw e
    } else {
      console.error(e)
      throw new ImportError([
        {
          id: null,
          type: 'File read error',
          message: e.message,
        },
      ])
    }
  }
}

export const saveImportedDocument = ({
  fileName,
  type,
  parentId,
  currentVersion,
}: {
  fileName: string
  type: string
  parentId: number
  currentVersion: string
}) => async (dispatch: any, getState: any) => {
  const { documents } = getState()
  let resp
  try {
    resp = await documentPost(
      fileName,
      type,
      documents[type].data,
      parentId,
      currentVersion,
    )
  } catch (e) {
    throw new ImportError([
      {
        id: null,
        type: e.data.code,
        message: 'There was an unexpected error while saving the document',
      },
    ])
  }

  const { id } = resp.data
  return id
}
