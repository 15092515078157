import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { DriverAssignment } from './model'

export interface IDriverAssignmentRepo extends IRepo<DriverAssignment> {
  byTrainId(id: string): DriverAssignment[]
  byShiftId(id: string): DriverAssignment[]
}

export class DriverAssignmentRepo implements IDriverAssignmentRepo {
  values: DriverAssignment[]
  idMap: { [key: string]: DriverAssignment }
  shiftIdMap: Indexer<DriverAssignment>
  trainIdMap: Indexer<DriverAssignment>

  constructor(values: DriverAssignment[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.trainIdMap = new Indexer(values, x => x.trainId)
    this.shiftIdMap = new Indexer(values, x => x.shiftId)
  }

  byId(id: string): DriverAssignment {
    return this.idMap[id]
  }

  byTrainId(id: string): DriverAssignment[] {
    return this.trainIdMap.lookup(id)
  }

  byShiftId(id: string): DriverAssignment[] {
    return this.shiftIdMap.lookup(id)
  }
}
