import { Mapper } from 'src/service-design/shared/models/mapper'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'

interface Attrs {
  id: string
  startId: string
  templateLegId: string
}

interface Rels {
  start: TrainStart
  templateLeg: TemplateLeg
  startLeg: StartLeg
}

class WorkingLocoLock extends Mapper {
  /**
   * A WorkingLocoLock is an engine lock that specifies that
   * the working locos on a StartLeg should not be removed.
   *
   * Related models:
   * - TrainStart
   * - StartLeg
   * - TemplateLeg
   * - LocoClass
   *
   * @param {string} id
   * @param {string} startId - id of the TrainStart
   * @param {string} templateLegId - id of the TemplateLeg
   */
  constructor({ id, startId, templateLegId }: Attrs) {
    super()
    this.id = id
    this.startId = startId
    this.templateLegId = templateLegId
  }

  setRels({ start, templateLeg, startLeg }: Rels) {
    this.start = start
    this.templateLeg = templateLeg
    this.startLeg = startLeg
  }
}

interface WorkingLocoLock extends Attrs, Rels {}
export { WorkingLocoLock }
