import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { corridorFactory } from './factory'
import { Corridor } from './model'
import { ICorridorRepo, CorridorRepo } from './repo'

const corridorRepo = defaultMemoize(
  (input: FactoryInput) => new CorridorRepo(corridorFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  corridorRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type CorridorRepoReg = {
  corridorRepo: FromDocumentFactory<FactoryInput, ICorridorRepo>
}

export function setup<R extends CorridorRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('corridorRepo', corridorRepo)

  relationships.addConstraints('corridors', {
    unique: [['loc1Id', 'loc2Id']],
    rels: [
      {
        collection: 'accessgroups',
        foreign: 'accessGroupId',
        name: 'accessGroup',
      },
      {
        collection: 'locations',
        foreign: 'loc1Id',
        name: 'loc1',
      },
      {
        collection: 'locations',
        foreign: 'loc2Id',
        name: 'loc2',
      },
    ],
  })
}

export { Corridor }
