import { groupBy } from 'lodash'

type CollectionTypes = { [collection: string]: { id: string } }

type CollectionType<T extends CollectionTypes, K extends keyof T> = T[K]

type CollectionAttr<
  T extends CollectionTypes,
  K extends keyof T
> = keyof CollectionType<T, K>

type UniqueConstraint<
  T extends CollectionTypes,
  C extends keyof T
> = CollectionAttr<T, C>[]

type Rel<S extends CollectionTypes, C extends keyof S, T extends keyof S> = {
  // Used primarily for document validation but also for graph traversal in various places in the code.
  collection: T
  foreign: CollectionAttr<S, C> | CollectionAttr<S, C>[]
  name?: string

  // Only used by a modal to confirm deletes
  allowCascadeDelete?: boolean
}

type RelationshipsType<S extends CollectionTypes> = {
  [C in keyof S]: {
    unique?: UniqueConstraint<S, C>[]
    rels?: Rel<S, C, keyof S>[]
  }
}

type DuplicateError<T extends CollectionTypes, K extends keyof T> = {
  row: CollectionType<T, K>
  constraint: CollectionAttr<T, K>[]
}

export class Relationships<
  U extends CollectionTypes = CollectionTypes,
  T extends U = U
> {
  config: RelationshipsType<T>

  constructor(config = {}) {
    this.config = config as RelationshipsType<T>
  }

  get collections() {
    return Object.keys(this.config) as (keyof RelationshipsType<T>)[]
  }

  addConstraints<K extends keyof U>(
    collection: K,
    rels: RelationshipsType<T>[K],
  ) {
    this.config[collection] = rels as any
  }

  getRels<K extends keyof RelationshipsType<T>>(
    collection: K,
  ): RelationshipsType<T>[K]['rels'] {
    const config = this.config[collection]
    return config && config.rels ? config.rels : []
  }

  getUnique<K extends keyof RelationshipsType<T>>(
    collection: K,
  ): RelationshipsType<T>[K]['unique'] {
    const config = this.config[collection]
    const unique = config && config.unique ? config.unique : []
    return [...unique, ['id']]
  }

  validateConstraint<K extends keyof RelationshipsType<T>>(
    collection: K,
    rows: CollectionType<T, K>[],
  ): DuplicateError<T, K>[] {
    const constraintGroups: [
      CollectionAttr<T, K>[],
      { [constraintValue: string]: any[] },
    ][] = this.getUnique(
      collection,
    ).map((constraint: UniqueConstraint<T, K>) => [
      constraint,
      groupBy(rows, (row: CollectionType<T, K>): string =>
        JSON.stringify(constraint.map(c => row[c])),
      ),
    ])

    return constraintGroups.flatMap(constraintGroup => {
      const [constraint, group] = constraintGroup

      return Object.values(group).flatMap(dupRows => {
        if (dupRows.length <= 1) {
          return []
        }
        return dupRows.map(row => ({ row, constraint }))
      })
    })
  }
}
