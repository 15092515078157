import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  accessGroupId: string
  lococlassId: string
  canLead: boolean
}

interface Rels {
  accessGroup: AccessGroup
  lococlass: Lococlass
}

class LococlassAccessRight extends Mapper {
  /**
   * A LococlassAccessRight is used to grant a loco class access to an
   * AccessGroup.
   *
   * Related models:
   * - `AccessGroup`;
   * - `Lococlass`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} accessGroupId - The id of the AccessGroup
   * @param {string} lococlassId - The id of the Lococlass
   * @param {boolean} canLead - If true, the Lococlass can operate as a lead
   *  loco within this area of the network.
   */
  constructor({ id, accessGroupId, lococlassId, canLead }: Attrs) {
    super()
    this.id = id
    this.accessGroupId = accessGroupId
    this.lococlassId = lococlassId
    this.canLead = canLead
  }

  setRels({ accessGroup, lococlass }: Rels) {
    this.accessGroup = accessGroup
    this.lococlass = lococlass
  }
}

interface LococlassAccessRight extends Attrs, Rels {}
export { LococlassAccessRight }
