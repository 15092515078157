import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { CrewPool } from './model'

export class MissingRosterHead extends InstanceRepair {
  static collectionName = 'crewpools'

  static type = 'service-design::Missing Roster Head'

  static message = 'service-design::{{entity.name}} requires a roster head.'

  static check(pool: CrewPool) {
    return Boolean(pool.quantity && !pool.rosterHead)
  }
}

export const getCrewPoolRepairs = getRepairFactory([MissingRosterHead])
