import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { TrainTemplate } from './model'

export class InvalidLegs extends InstanceRepair {
  static collectionName = 'traintemplates'

  static type = 'service-design::Invalid Train'

  static message =
    'service-design::Train ({{entity.id}}) has invalid leg numbers.'

  static check(template: TrainTemplate) {
    const nums = [...new Set(template.legs.map(l => l.legNum))]
    return (
      nums.length !== template.legs.length ||
      nums[0] !== 1 ||
      nums[nums.length - 1] !== nums.length
    )
  }
}

export const getTrainTemplateRepairs = getRepairFactory([InvalidLegs])
