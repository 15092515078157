import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { WagonCargoCompatibility } from './model'

export const wagonCargoCompatibilityFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.wagoncargocompatibilities.map(
      data => new WagonCargoCompatibility(data),
    ),
  setRelsFromSpec((t: WagonCargoCompatibility, state: any) => ({
    wagon: registry.provide('wagonRepo')(state).byId(t.wagonId),
    cargoType: registry.provide('cargoTypeRepo')(state).byId(t.cargoTypeId),
  })),
)
