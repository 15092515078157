// TODO: This file should be moved over with the yard graph implementation
// as the two are so intimately related.
import { createSelector } from 'reselect'

import { getSelectedResourceProfiles } from 'src/service-design/sd-plan/selectors/yards'
import * as constants from 'src/service-design/shared/constants'
import { SECONDS_PER_MINUTE } from 'src/service-design/shared/constants'
import { Location } from 'src/service-design/shared/models/location'
import { LocationSummary } from 'src/service-design/shared/models/location-summaries'
import { ResourceProfile } from 'src/service-design/shared/models/resource-summaries/types'
import { getLocationSummaries } from 'src/service-design/shared/resource-summaries'
import { intersectionInclusive } from 'src/service-design/shared/utils/dates'

const YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION = 20 * SECONDS_PER_MINUTE

interface IResourceSegment {
  startTimeLocalNormalized: number
  endTimeLocalNormalized: number
}

const adjustedEndTime = (resourceProfile: IResourceSegment): number => {
  if (
    resourceProfile.endTimeLocalNormalized <
    resourceProfile.startTimeLocalNormalized
  ) {
    return resourceProfile.endTimeLocalNormalized + constants.SECONDS_PER_WEEK
  }
  return resourceProfile.endTimeLocalNormalized
}

export const adjustedTimes = (
  l1: IResourceSegment,
  l2: IResourceSegment,
): [[number, number], [number, number]] => {
  const startL1: number =
    l1.startTimeLocalNormalized - YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  const startL2: number =
    l2.startTimeLocalNormalized - YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  const endL1: number =
    adjustedEndTime(l1) + YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  const endL2: number =
    adjustedEndTime(l2) + YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  return [
    [startL1, endL1],
    [startL2, endL2],
  ]
}

export function pack<T extends IResourceSegment>(
  annotateProfiles: T[],
): ({
  yPosition: number
} & T)[] {
  const sorted = annotateProfiles.sort(
    (a, b) => a.startTimeLocalNormalized - b.startTimeLocalNormalized,
  )

  const rows = []
  for (const lw of sorted) {
    let inserted = false
    for (const row of rows) {
      const first = row[0] && {
        startTimeLocalNormalized:
          row[0].startTimeLocalNormalized + constants.SECONDS_PER_WEEK,
        endTimeLocalNormalized:
          row[0].endTimeLocalNormalized + constants.SECONDS_PER_WEEK,
      }
      const last = row.slice(-1)[0]
      if (
        !last ||
        (!intersectionInclusive(...adjustedTimes(lw, last)) &&
          first &&
          !intersectionInclusive(...adjustedTimes(lw, first)))
      ) {
        row.push(lw)
        inserted = true
        break
      }
    }
    if (!inserted) {
      rows.push([lw])
    }
  }

  return rows.flatMap((row, idx) =>
    row.map((lw: T) => ({
      yPosition: idx,
      ...lw,
    })),
  )
}

export interface IActivity {
  id: string
  kind: string
  startTimeLocal: number
  endTimeLocal: number
}

export interface ResourceProfileLayout {
  yPosition: number
  resourceProfile: ResourceProfile
  startTimeLocalNormalized: number
  endTimeLocalNormalized: number
  activities: IActivity[]
}

export interface IYard {
  location: Location
  resourceProfiles: ResourceProfileLayout[]
  locationSummary: LocationSummary
}

export const getYardGraphData = createSelector(
  getSelectedResourceProfiles,
  getLocationSummaries,
  (resourceProfilesByLocation, locationSummaries): IYard[] =>
    resourceProfilesByLocation.map(({ location, resourceProfiles }) => {
      const locationSummary = locationSummaries.find(
        x => x.location === location,
      )
      return {
        location,
        resourceProfiles: pack(
          resourceProfiles
            // ignore ShiftResourceProfile which doesn't have a target,
            // which results in the gantt not being shown for it
            .filter(r => r.target)
            .map(r => ({
              resourceProfile: r,
              startTimeLocalNormalized: r.startTimeLocalNormalized,
              endTimeLocalNormalized: r.endTimeLocalNormalized,
              activities: r.activities
                .flatMap(a => {
                  const [startTimeLocal, endTimeLocal] =
                    a.startTimeLocal > a.endTimeLocal
                      ? [a.endTimeLocal, a.startTimeLocal]
                      : [a.startTimeLocal, a.endTimeLocal]
                  const result = [
                    {
                      id: a.id,
                      kind: a.kind,
                      startTimeLocal,
                      endTimeLocal,
                    },
                  ]

                  // Insert some fake 'arrival' / 'departure' activities
                  // so that we can see trains on the yard chart that pass through
                  // even if they don't stop for activities
                  if (a.kind === 'post-arrival') {
                    result.push({
                      id: `${a.id}-arrival`,
                      kind: 'arrival',
                      startTimeLocal:
                        a.startTimeLocal -
                        YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION,
                      endTimeLocal: a.startTimeLocal,
                    })
                  }

                  if (a.kind === 'pre-departure') {
                    result.push({
                      id: `${a.id}-departure`,
                      kind: 'departure',
                      startTimeLocal: a.endTimeLocal,
                      endTimeLocal:
                        a.endTimeLocal + YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION,
                    })
                  }

                  return result
                })
                // Don't bother drawing activities that aren't going to be
                // visible anyway.
                .filter(a => a.startTimeLocal !== a.endTimeLocal),
            })),
        ),
        locationSummary,
      }
    }),
)
