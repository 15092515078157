import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'
import { DriverAssignment } from 'src/service-design/shared/models/shift-assignment'

export class InvalidDriverAssignment extends InstanceRepair {
  static collectionName = 'driverassignments'

  static type = 'service-design::Invalid driver assignment'

  static message =
    'service-design::Could not find task associated with assignment {{entity.id}}.'

  static check(assignment: DriverAssignment) {
    return !assignment.task
  }
}

export const getDriverAssignmentRepairs = getRepairFactory([
  InvalidDriverAssignment,
])
