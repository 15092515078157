import classnames from 'classnames'
import React from 'react'
import styled, { StyledComponentPropsWithRef } from 'styled-components'

import { ReactComponent as ArrowSE_ } from './ArrowSE.svg'
import { ReactComponent as ArrowSW_ } from './ArrowSW.svg'
import { ReactComponent as Branch_ } from './Branch.svg'
import { ReactComponent as Car_ } from './Car.svg'
import { ReactComponent as CircleClose_ } from './CircleClose.svg'
import { ReactComponent as CircleRemove_ } from './CircleRemove.svg'
import { ReactComponent as Hammer_ } from './Hammer.svg'
import { ReactComponent as MapPinAlternate_ } from './MapPinAlternate.svg'
import { ReactComponent as SwapVertically_ } from './SwapVertically.svg'
import { ReactComponent as Tags_ } from './Tags.svg'
import { ReactComponent as Train_ } from './Train.svg'
import { ReactComponent as TrainStart_ } from './TrainStart.svg'
import { ReactComponent as TrainTemplate_ } from './TrainTemplate.svg'
import { ReactComponent as Wagon_ } from './Wagon.svg'
import { ReactComponent as ZoomIn_ } from './ZoomIn.svg'
import { ReactComponent as ZoomOut_ } from './ZoomOut.svg'

const StyledIcon = styled.svg.attrs(({ className }) => ({
  className: classnames('svg-icon', className),
}))`
  height: 1.2em;
  width: 1.2em;
  display: inline-block;
  overflow: visible;

  > * {
    fill: currentColor;
  }
`

type SVGProps = React.SVGProps<SVGSVGElement> & {
  title?: string
}

const styledIconFactory = (
  Icon: React.FunctionComponent<SVGProps>,
): React.FC<SVGProps & StyledComponentPropsWithRef<'svg'>> => props => (
  <StyledIcon as={Icon} {...props} />
)

export const ArrowSE = styledIconFactory(ArrowSE_)
export const ArrowSW = styledIconFactory(ArrowSW_)
export const Branch = styledIconFactory(Branch_)
export const Car = styledIconFactory(Car_)
export const CircleClose = styledIconFactory(CircleClose_)
export const CircleRemove = styledIconFactory(CircleRemove_)
export const Hammer = styledIconFactory(Hammer_)
export const MapPinAlternate = styledIconFactory(MapPinAlternate_)
export const SwapVertically = styledIconFactory(SwapVertically_)
export const Tags = styledIconFactory(Tags_)
export const Train = styledIconFactory(Train_)
export const TrainStart = styledIconFactory(TrainStart_)
export const TrainTemplate = styledIconFactory(TrainTemplate_)
export const Wagon = styledIconFactory(Wagon_)
export const ZoomIn = styledIconFactory(ZoomIn_)
export const ZoomOut = styledIconFactory(ZoomOut_)
