import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { TrainChangeoverLock } from '.'

export const trainChangeoverLockFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.trainchangeoverlocks.map(
      data => new TrainChangeoverLock(data),
    ),
  setRelsFromSpec((l: TrainChangeoverLock, state: any) => ({
    changeover: registry
      .provide('trainChangeoverRepo')(state)
      .byId(l.changeoverId),
  })),
)
