import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { lococlassAccessRightFactory } from './factory'
import { ILococlassAccessRightRepo, LococlassAccessRightRepo } from './repo'

const lococlassAccessRightRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LococlassAccessRightRepo(lococlassAccessRightFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  lococlassAccessRightRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type LococlassAccessRightRepoReg = {
  lococlassAccessRightRepo: FromDocumentFactory<
    FactoryInput,
    ILococlassAccessRightRepo
  >
}

export function setup<R extends LococlassAccessRightRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('lococlassAccessRightRepo', lococlassAccessRightRepo)

  relationships.addConstraints('lococlassaccessrights', {
    unique: [['lococlassId', 'accessGroupId']],
    rels: [
      {
        collection: 'lococlasses',
        foreign: 'lococlassId',
        name: 'lococlass',
        allowCascadeDelete: true,
      },
      {
        collection: 'accessgroups',
        foreign: 'accessGroupId',
        name: 'accessGroup',
      },
    ],
  })
}

export { LococlassAccessRight } from './model'
