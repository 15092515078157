import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { TrainGraphLocation } from './model'

export const trainGraphLocationFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.traingraphlocations.map(
      data => new TrainGraphLocation(data),
    ),
  setRelsFromSpec((l: TrainGraphLocation, state: any) => ({
    traingraph: registry.provide('trainGraphRepo')(state).byId(l.trainGraphId),
    location: registry.provide('locationRepo')(state).byId(l.locationId),
  })),
)
