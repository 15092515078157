import { sumBy } from 'lodash'

import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Service } from 'src/service-design/shared/models/service'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { LoadTask } from 'src/service-design/shared/models/task/load'

export class Unloading extends LoadTask {
  /**
   * Unloading captures the task of removing Services from wagons. Each
   * Unloading task is associated with a specific cargo type as generally
   * different cargo types are unloaded in different parts of a yard at
   * different rates.
   */
  static kind = 'unloading'

  static timeOffsetAllowed = true

  static build(
    startLeg: StartLeg,
    cargoType: CargoType,
    services: Service[] = [],
  ) {
    const offset = startLeg.getTaskTimeOffset(this.kind)
    const start = startLeg.detachEndLocal + offset
    return new this({
      origin: startLeg.dest,
      destination: startLeg.dest,
      startTimeLocal: start,
      endTimeLocal: start + sumBy(services, x => x.unloadingSecs),
      requiresDriver: services.some(x => x.requiresUnloadingDriver),
      requiresRO: services.some(x => x.requiresUnloadingRO),
      startLeg,
      cargoType,
      services,
    })
  }

  get resourceDict() {
    return this.wagonDict
  }

  get preResourceDict() {
    return this.wagonDict
  }

  get startResourceDict() {
    return this.wagonDict
  }

  get endResourceDict() {
    return new Map([...this.wagonDict.keys()].map(w => [w, 0]))
  }

  getSplitWorkTasks(forDriver: boolean): LoadingWorkTask[] {
    if (this.split) {
      return [
        new LoadingWorkTask({
          task: this,
          requiresDriver: forDriver,
          requiresAssignment: forDriver ? this.requiresDriver : this.requiresRO,
          startTimeLocal: this.startTimeLocal,
          endTimeLocal: Math.min(this.endTimeLocal, this.split.timeLocal),
          index: 0,
        }),
        new LoadingWorkTask({
          task: this,
          requiresDriver: forDriver,
          requiresAssignment: forDriver ? this.requiresDriver : this.requiresRO,
          startTimeLocal: this.split.timeLocal,
          endTimeLocal: Math.max(this.endTimeLocal, this.split.timeLocal),
          index: 1,
        }),
      ]
    }
    return [
      new LoadingWorkTask({
        task: this,
        requiresDriver: forDriver,
        requiresAssignment: forDriver ? this.requiresDriver : this.requiresRO,
        startTimeLocal: this.startTimeLocal,
        endTimeLocal: this.endTimeLocal,
        index: 0,
      }),
    ]
  }
}
