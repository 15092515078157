import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TemplateLeg } from './model'

export interface ITemplateLegRepo extends IRepo<TemplateLeg> {
  byTemplateId(templateId: string): TemplateLeg[]
  byCorridorId(corridorId: string): TemplateLeg[]
}

export class TemplateLegRepo implements ITemplateLegRepo {
  values: TemplateLeg[]
  idMap: { [key: string]: TemplateLeg }
  templateIdMap: Indexer<TemplateLeg>
  corridorIdMap: Indexer<TemplateLeg>

  constructor(values: TemplateLeg[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.templateIdMap = new Indexer(values, x => x.templateId)
    this.corridorIdMap = new Indexer(values, x => x.corridorId)
  }

  byId(id: string): TemplateLeg {
    return this.idMap[id]
  }

  byTemplateId(templateId: string): TemplateLeg[] {
    return this.templateIdMap.lookup(templateId)
  }

  byCorridorId(corridorId: string): TemplateLeg[] {
    return this.corridorIdMap.lookup(corridorId)
  }
}
