import React from 'react'

import { ENVIRONMENT } from 'src/core/constants'
import { flags } from 'src/service-design/shared/flags'
import { BuildInfo } from 'src/service-design/shared/ui/components/BuildInfo'
import { LanguageChange } from 'src/service-design/shared/ui/components/LanguageChange'

import navisSvg from './NavisRail.svg'
import StyledNav from './styles'

interface NavBarProps {
  document?: React.ReactNode
  finderUrl: string
  user?: React.ReactNode
  rhsContent?: React.ReactNode
  buildInfoBackends?: { name: string }[]
}

export const NavBar: React.FC<NavBarProps> = ({
  document = null,
  finderUrl,
  user = null,
  rhsContent = null,
  buildInfoBackends = [],
}) => (
  <StyledNav className="nav-bar">
    <div className="crumbs">
      <a href={`/finder${finderUrl}`}>
        <img className="boss-logo" src={navisSvg} alt="logo" />
      </a>
      {ENVIRONMENT !== 'prod' && (
        <div className="environment">{`[${ENVIRONMENT}]`}</div>
      )}
      {document ? (
        <div data-testid="navbar__document-name">{document}</div>
      ) : null}
    </div>
    <div className="group">
      <BuildInfo backends={buildInfoBackends} />
      {rhsContent}
      {flags.languageChange && <LanguageChange />}
      <div className="nav-actions">{user}</div>
    </div>
  </StyledNav>
)
