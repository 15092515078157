import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LococlassAccessRight } from './model'

export interface ILococlassAccessRightRepo extends IRepo<LococlassAccessRight> {
  byAccessGroupId(id: string): LococlassAccessRight[]
  byLococlassId(id: string): LococlassAccessRight[]
}

export class LococlassAccessRightRepo implements ILococlassAccessRightRepo {
  values: LococlassAccessRight[]
  idMap: { [key: string]: LococlassAccessRight }
  accessGroupIdMap: Indexer<LococlassAccessRight>
  lococlassIdMap: Indexer<LococlassAccessRight>

  constructor(values: LococlassAccessRight[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.accessGroupIdMap = new Indexer(values, x => x.accessGroupId)
    this.lococlassIdMap = new Indexer(values, x => x.lococlassId)
  }

  byId(id: string): LococlassAccessRight {
    return this.idMap[id]
  }

  byAccessGroupId(accessGroupId: string): LococlassAccessRight[] {
    return this.accessGroupIdMap.lookup(accessGroupId)
  }

  byLococlassId(lococlassId: string): LococlassAccessRight[] {
    return this.lococlassIdMap.lookup(lococlassId)
  }
}
