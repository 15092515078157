import { Attach } from './attach'
import { AttachAdvance } from './attach-advance'
import { Task } from './base'
import { Detach } from './detach'
import { DetachDelay } from './detach-delay'
import { Dwell } from './dwell'
import { LegTask } from './leg'
import { LoadTask } from './load'
import { Loading } from './loading'
import { LVTask } from './lv'
import { PostArrival } from './post-arrival'
import { PreDeparture } from './pre-departure'
import { Provisioning } from './provisioning'
import { Transport } from './transport'
import { Unloading } from './unloading'

export { Attach }
export { AttachAdvance }
export { Detach }
export { DetachDelay }
export { Dwell }
export { LegTask }
export { LoadTask }
export { Loading }
export { LVTask }
export { PostArrival }
export { PreDeparture }
export { Provisioning }
export { Task }
export { Transport }
export { Unloading }

const PRE_LEG = [Attach, AttachAdvance, PreDeparture]

const POST_LEG = [PostArrival, DetachDelay, Detach, Provisioning, Dwell]

export const LOCATION_ORDERED_ACTIVITIES = [...POST_LEG, ...PRE_LEG]

export const LEG_ORDERED_ACTIVITIES = [...PRE_LEG, ...POST_LEG]
