import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { CustomTaskAssignment } from './model'

export const customTaskAssignmentFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.customtaskassignments.map(
      data => new CustomTaskAssignment(data),
    ),
  setRelsFromSpec((customTaskAssignment: CustomTaskAssignment, state: any) => ({
    customTask: registry
      .provide('customTaskRepo')(state)
      .byId(customTaskAssignment.customTaskId),
    shift: registry
      .provide('shiftRepo')(state)
      .byId(customTaskAssignment.shiftId),
  })),
)
