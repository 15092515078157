import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LocalAssignment } from './model'

export interface ILocalAssignmentRepo extends IRepo<LocalAssignment> {
  byStartId(id: string): LocalAssignment[]
  byShiftId(id: string): LocalAssignment[]
  byTemplateLegId(id: string): LocalAssignment[]
  byStartLegId(id: string): LocalAssignment[]
}

export class LocalAssignmentRepo implements ILocalAssignmentRepo {
  values: LocalAssignment[]
  idMap: { [key: string]: LocalAssignment }
  shiftIdMap: Indexer<LocalAssignment>
  startIdMap: Indexer<LocalAssignment>
  startLegIdMap: Indexer<LocalAssignment>
  templateLegIdMap: Indexer<LocalAssignment>

  constructor(values: LocalAssignment[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.shiftIdMap = new Indexer(values, x => x.shiftId)
    this.startIdMap = new Indexer(values, x => x.startId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
  }

  byId(id: string): LocalAssignment {
    return this.idMap[id]
  }

  byStartId(id: string): LocalAssignment[] {
    return this.startIdMap.lookup(id)
  }

  byTemplateLegId(id: string): LocalAssignment[] {
    return this.templateLegIdMap.lookup(id)
  }

  byStartLegId(id: string): LocalAssignment[] {
    return this.startLegIdMap.lookup(id)
  }

  byShiftId(id: string): LocalAssignment[] {
    return this.shiftIdMap.lookup(id)
  }
}
