import { Location } from 'src/service-design/shared/models/location'
import { Shift } from 'src/service-design/shared/models/shift'
import { Task } from 'src/service-design/shared/models/task/base'

import { Duration } from 'src/service-design/shared/utils/dates/duration'

export class LVTask extends Task {
  shift: Shift
  LV: any
  requiresTravel: boolean
  requiresDriver: boolean

  actingAsDriver = false

  static entityName = 'LV'
  static kind = 'lv-movement'

  static timeOffsetAllowed = false

  /**
   * An LVTask is an instance of an LVTrip within a Shift.
   *
   * Related models:
   * - Shift.
   *
   * @param shift {Shift}: The shift which owns the task
   * @param LV {LV}: The LV object.
   */
  constructor({
    shift,
    LV,
    ...taskArgs
  }: {
    shift: Shift
    LV: any
    origin: Location
    destination: Location
    startTimeLocal: number
    endTimeLocal: number
  }) {
    super(taskArgs)
    this.shift = shift
    this.LV = LV
    this.requiresTravel = true
    this.requiresDriver = false
  }

  get shiftId() {
    return this.shift.id
  }

  get resources() {
    return [this.LV]
  }

  get resourceDict() {
    return new Map([[this.LV, 1]])
  }

  get id() {
    return `${this.shiftId}-${this.startTimeLocal}`
  }

  get name() {
    return `LV - ${this.origin.code} to ${this.destination.code}`
  }

  /**
   * @deprecated
   */
  get minDuration() {
    return this.lvTrip && this.lvTrip.durationSecs
  }

  get minDurationVO() {
    return this.lvTrip && this.lvTrip.durationSecsVO
  }

  /**
   * @deprecated
   */
  get slackDuration() {
    return this.slackDurationVO.toSeconds()
  }

  get slackDurationVO() {
    return this.minDurationVO
      ? this.durationVO.subtract(this.minDurationVO)
      : Duration.nil
  }

  get lvTrip() {
    return this.origin.lvTripTo(this.destination)
  }

  get entityName() {
    return (this.constructor as any).entityName
  }
}
