import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { StartLegTaskOffset } from './model'

export const startLegTaskOffsetFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.startlegtaskoffsets.map(
      ({ offset, ...data }) =>
        new StartLegTaskOffset({
          offset: Duration.fromSeconds(offset),
          ...data,
        }),
    ),
  setRelsFromSpec((startLegTaskOffset: StartLegTaskOffset, state: any) => ({
    start: registry
      .provide('trainStartRepo')(state)
      .byId(startLegTaskOffset.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(startLegTaskOffset.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(
        `${startLegTaskOffset.startId}:${startLegTaskOffset.templateLegId}`,
      ),
  })),
)
