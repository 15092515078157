import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { ResourceAvailability } from './model'

export interface IResourceAvailabilityRepo extends IRepo<ResourceAvailability> {
  byLococlassId(id: string): ResourceAvailability
  byWagonId(id: string): ResourceAvailability
}

export class ResourceAvailabilityRepo implements IResourceAvailabilityRepo {
  values: ResourceAvailability[]
  idMap: { [key: string]: ResourceAvailability }
  lococlassIdMap: { [key: string]: ResourceAvailability }
  wagonIdMap: { [key: string]: ResourceAvailability }

  constructor(values: ResourceAvailability[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.lococlassIdMap = Object.fromEntries(
      values.map(v => [v.lococlassId, v]),
    )
    this.wagonIdMap = Object.fromEntries(values.map(v => [v.wagonId, v]))
  }

  byId(id: string): ResourceAvailability {
    return this.idMap[id]
  }

  byLococlassId(lococlassId: string): ResourceAvailability {
    return this.lococlassIdMap[lococlassId]
  }

  byWagonId(wagonId: string): ResourceAvailability {
    return this.wagonIdMap[wagonId]
  }
}
