import { Lococlass } from 'src/service-design/shared/models/lococlass'
import {
  ResourceProfile,
  IResource,
} from 'src/service-design/shared/models/resource-summaries/types'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import {
  LOCATION_ORDERED_ACTIVITIES,
  Task,
  LegTask,
} from 'src/service-design/shared/models/task'
import { Wagon } from 'src/service-design/shared/models/wagon'
import { normalize } from 'src/service-design/shared/utils/dates'
import { sumCounts } from 'src/service-design/shared/utils/math'

export class Event {
  constructor(public task: Task, public type: string, public profile: any) {}

  get taskKind() {
    return this.task && this.task.kind
  }

  get target() {
    return this.profile && this.profile.target
  }

  get name() {
    return `${this.taskKind} (${this.type})`
  }
}

export class StartOfWeek extends Event {
  static desc = 'start of week'

  constructor() {
    super(null, 'start', null)
  }

  get name() {
    return (this.constructor as any).desc
  }
}

export class EndOfWeek extends Event {
  static desc = 'end of week'

  constructor() {
    super(null, 'end', null)
  }

  get name() {
    return (this.constructor as any).desc
  }
}

interface Attrs {
  arrivingLeg?: StartLeg
  departingLeg?: StartLeg
}

export class DwellResourceProfile implements ResourceProfile {
  arrivingLeg?: StartLeg
  departingLeg?: StartLeg
  _activities: LegTask<StartLeg>[]

  /**
   * DwellResourceProfile tracks how the resources associated with a TrainStart
   * changes it sits at a Location between to StartLegs.
   *
   * When a TrainStart arrives at a Location it moves through PostArrival,
   * Detach, etc and each of these tasks could potentially release resources
   * (eg Lococlasses, Wagons) at the location.
   *
   * This information is used to conjunction other ResourceProfiles to determine
   * total resource usage over the cyclic week.
   *
   * @param arrivingLeg {StartLeg|null}: The leg prior to the dwell, or null
   *   for a originating TrainStart.
   * @param departingLeg {StartLeg|null}: The leg following the dwell, or null
   *   for a terminating TrainStart.
   */
  constructor({ arrivingLeg, departingLeg }: Attrs) {
    this.arrivingLeg = arrivingLeg
    this.departingLeg = departingLeg
  }

  get id() {
    return `${this.arrivingLeg?.id}::${this.departingLeg?.id}`
  }

  get businessGroupId() {
    return this.arrivingLeg
      ? this.arrivingLeg.template.businessGroupId
      : this.departingLeg.template.businessGroupId
  }

  get resources(): (Lococlass | Wagon)[] {
    const resources = new Set<Lococlass | Wagon>()
    if (this.arrivingLeg) {
      this.arrivingLeg.resources.forEach(r => resources.add(r))
    }
    if (this.departingLeg) {
      this.departingLeg.resources.forEach(r => resources.add(r))
    }

    return [...resources]
  }

  diffResources(prev: Task, activity: LegTask<StartLeg>): [any, number][] {
    return this.resources.map(resourceType => {
      const quantity = prev.resourceDict.get(resourceType) || 0
      const difference =
        quantity - (activity.resourceDict.get(resourceType) || 0)
      return [resourceType, difference]
    })
  }

  get deltas(): {
    offset: number
    delta: Map<IResource, number>
    event: Event
  }[] {
    // assumes first task uses resources of arriving leg, doesnt check delta on arrival
    // assumes last task uses resources of departing leg, doesnt check delta on departure
    const [head, ...rest] = this.activities
    const _deltas: {
      offset: number
      delta: Map<IResource, number>
      event: Event
    }[] = []
    if (!this.arrivingLeg) {
      const initialDelta = new Map(
        [...head.resourceDict.entries()].map(([resourceType, quantity]) => [
          resourceType,
          -quantity,
        ]),
      )
      _deltas.push({
        offset: head.startTimeLocalNormalized,
        delta: initialDelta,
        event: new Event(head, 'start', this),
      })
    }

    let prevActivity = head
    for (const activity of rest) {
      const delta = new Map(this.diffResources(prevActivity, activity))
      _deltas.push({
        offset: activity.startTimeLocalNormalized,
        delta,
        event: new Event(activity, 'start', this),
      })

      prevActivity = activity
    }

    // FIXME: This seems like a sneaky hack to just deal with loco provisioning?
    if (!this.departingLeg) {
      // offset needs to fall within week boundary
      _deltas.push({
        offset: normalize(prevActivity.endTimeLocalNormalized),
        delta: prevActivity.resourceDict,
        event: new Event(prevActivity, 'end', this),
      })
    }
    return _deltas
  }

  get target() {
    return this.arrivingLeg ? this.arrivingLeg.start : this.departingLeg.start
  }

  get location() {
    return this.arrivingLeg ? this.arrivingLeg.dest : this.departingLeg.origin
  }

  get startTimeLocal() {
    return this.activities[0].startTimeLocal
  }

  get startTimeLocalNormalized() {
    return this.activities[0].startTimeLocalNormalized
  }

  get endTimeLocal() {
    return this.activities[this.activities.length - 1].endTimeLocal
  }

  get endTimeLocalNormalized() {
    return this.activities[this.activities.length - 1].endTimeLocalNormalized
  }

  get activities() {
    if (!this._activities) {
      this._activities = LOCATION_ORDERED_ACTIVITIES.map(Model => {
        const leg = Model.arrivingTask ? this.arrivingLeg : this.departingLeg
        return leg && leg.getTask(Model.kind)
      }).filter(x => x)
    }
    return this._activities
  }

  get totalWorkingSecs() {
    return this.activities.reduce<Map<IResource, number>>(
      (total, activity) => sumCounts(total, activity.totalWorkingSecs),
      new Map(this.resources.map(r => [r, 0])),
    )
  }

  get totalHauledSecs() {
    return this.activities.reduce<Map<IResource, number>>(
      (total, activity) => sumCounts(total, activity.totalHauledSecs),
      new Map(this.resources.map(r => [r, 0])),
    )
  }
}
