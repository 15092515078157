import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { RemoteRest } from './model'

export class RemoteRestInvalidDuration extends InstanceRepair {
  static collectionName = 'remoterests'

  static type = 'service-design::Remote Rest duration invalid'

  static message =
    'service-design::Remote Rest has invalid duration {{entity.duration}}'

  static check(remoteRest: RemoteRest) {
    return remoteRest.duration < 0
  }
}

export class RemoteRestPoolMismatch extends InstanceRepair {
  static collectionName = 'remoterests'

  static type = 'service-design::Remote Rest CrewPool mismatch'

  static message =
    'service-design::Shifts and their connecting Remote Rest have mismatched CrewPools'

  static check(remoteRest: RemoteRest) {
    return (
      remoteRest.poolId !== remoteRest.startShift.poolId ||
      remoteRest.poolId !== remoteRest.nextShift.poolId
    )
  }
}

export const getRemoteRestRepairs = getRepairFactory([
  RemoteRestInvalidDuration,
  RemoteRestPoolMismatch,
])
