import { createSelector } from 'reselect'

import * as constants from 'src/service-design/sd-plan/constants'
import { flags } from 'src/service-design/sd-plan/flags'
import * as CustomTask from 'src/service-design/shared/models/custom-task'
import { DriverTask } from 'src/service-design/shared/models/driver-task'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Shift } from 'src/service-design/shared/models/shift'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import { LegTask } from 'src/service-design/shared/models/task'
import * as TrainStart from 'src/service-design/shared/models/train-start'

export const getUnfilteredLocalTasks = createSelector(
  StartLeg.values,
  startLegs => {
    if (!flags.crewing) {
      return []
    }
    return startLegs.flatMap(leg => leg.localTasks)
  },
)

export const getLocalTasks = createSelector(getUnfilteredLocalTasks, tasks =>
  tasks.filter(task => task.requiresAssignment),
)

export const getUnfilteredLoadingWorkTasks = createSelector(
  StartLeg.values,
  legs => legs.flatMap(l => l.loadingWorkTasks),
)

export const getLoadingWorkTasks = createSelector(
  getUnfilteredLoadingWorkTasks,
  tasks => tasks.filter(task => task.requiresAssignment),
)

export const getTasks = createSelector(
  TrainStart.values,
  getLocalTasks,
  CustomTask.values,
  getLoadingWorkTasks,
  (_: any, { trainId }: { trainId: string }) => trainId,
  (trains, localTasks, customTasks, loadingWorkTasks, trainIdFormValue) => {
    const trainIdSelected = (
      task: LegTask<any> | DriverTask | CustomTask.CustomTask | LoadingWorkTask,
    ) => {
      if (trainIdFormValue === constants.ALL_TRAINS) {
        return true
      }
      if (task instanceof CustomTask.CustomTask) {
        return false
      }
      return task.startId === trainIdFormValue
    }

    const drivingTasks = trains.flatMap(t => t.driverTasks)

    const tasks = [
      ...localTasks,
      ...drivingTasks,
      ...customTasks,
      ...loadingWorkTasks,
    ].filter(task => trainIdSelected(task))

    return Shift.sortShiftAssignments(
      tasks,
      'startTimeLocalNormalized',
      'endTimeLocalNormalized',
    )
  },
)
export const getUnAssignedTasks = createSelector(getTasks, tasks =>
  tasks.filter(x => !x.isAssigned),
)
