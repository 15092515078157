import React from 'react'
import { Button } from 'semantic-ui-react'

import i18n from 'src/i18n'

type Props = {
  submitting?: boolean
  pristine?: boolean
  ignorePristine?: boolean
  submitText?: string
  formTarget?: string
  submitDisabled?: boolean
  dismiss?: () => void
}

export const FormSubmit: React.FC<Props> = ({
  submitting = false,
  pristine = false,
  ignorePristine = false,
  submitText = 'Save',
  formTarget = null,
  submitDisabled = false,
  dismiss = null,
}) => (
  <>
    {dismiss && (
      <Button content={i18n.t('Cancel')} onClick={dismiss} type="button" />
    )}
    <Button
      form={formTarget}
      content={i18n.t(submitText)}
      primary
      type="submit"
      loading={submitting}
      disabled={submitDisabled || (pristine && !ignorePristine) || submitting}
    />
  </>
)
