import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { AccessGroup } from './model'

export interface IAccessGroupRepo extends IRepo<AccessGroup> {}

export class AccessGroupRepo implements IAccessGroupRepo {
  values: AccessGroup[]
  idMap: { [key: string]: AccessGroup }

  constructor(values: AccessGroup[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
  }

  byId(id: string): AccessGroup {
    return this.idMap[id]
  }
}
