import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { LoadTable } from 'src/service-design/shared/models/load-table'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { TransitTimes } from 'src/service-design/shared/models/transit-times'
import { Duration } from 'src/service-design/shared/utils/dates'

class Corridor extends Mapper {
  static defaults = {}

  id: string
  accessGroupId: string
  accessBaseCost: number
  accessPerTonneCost: number
  axleLoadLimit: number
  distance: number
  loc1Id: string
  loc2Id: string
  locoHaulCost: number
  maxLength: number
  maxLocosPerTrain: number
  maxPasses: number
  _minHeadway: Duration
  accessGroup: AccessGroup
  loc1: Location
  loc2: Location
  loadtables: LoadTable[]
  transittimes: TransitTimes[]

  /**
   * A Corridor represents track between two locations in the rail network.
   *
   * Related models:
   * - `AccessGroup`;
   * - `Location`;
   * - `TransitTime`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} accessGroupId - The AccessGroup Id for the corridor.
   * @param {number} accessBaseCost - Flag fall cost associated with a train
   *  entering this corridor.
   * @param {number} accessPerTonneCost - Cost ($/t) associated with using
   *  this corridor.
   * @param {number} axleLoadLimit - Axle load limit.
   * @param {number} distance - Length of the corridor in kilometers.
   * @param {number} loc1Id - The id of the location at one end.
   * @param {number} loc2Id - The id of the locaiton at the other end.
   * @param {number} locoHaulCost - A swiss engine parameter used to discourage
   *  the hauling of locos on this corridor.
   * @param {number} maxLength - The maximum allowable length of a train moving
   *  over this corridor.
   * @param {number} maxLocosPerTrain - The maximum number of locos per train on
   *  this corridor. Used by engines to restrict loco movement around the
   *  network.
   * @param {number} maxPasses - The number of times a train can pass another
   *  train on this corridor while still avoiding collision. Roughly equal to
   *  the number of sidings on the corridor.
   * @param {Duration} minHeadway - The time (s) buffer any train traveling on
   *  this corridor should allow to avoid collision.
   */
  constructor({
    id,
    accessGroupId,
    accessBaseCost,
    accessPerTonneCost,
    axleLoadLimit,
    distance,
    loc1Id,
    loc2Id,
    locoHaulCost,
    maxLength,
    maxLocosPerTrain,
    maxPasses,
    minHeadway,
  }: {
    id: string
    accessGroupId: string
    accessBaseCost: number
    accessPerTonneCost: number
    axleLoadLimit: number
    distance: number
    loc1Id: string
    loc2Id: string
    locoHaulCost: number
    maxLength: number
    maxLocosPerTrain: number
    maxPasses: number
    minHeadway: Duration
  }) {
    super()
    this.id = id
    this.accessGroupId = accessGroupId
    this.accessBaseCost = accessBaseCost
    this.accessPerTonneCost = accessPerTonneCost
    this.axleLoadLimit = axleLoadLimit
    this.distance = distance
    this.loc1Id = loc1Id
    this.loc2Id = loc2Id
    this.locoHaulCost = locoHaulCost
    this.maxLength = maxLength
    this.maxLocosPerTrain = maxLocosPerTrain
    this.maxPasses = maxPasses
    this._minHeadway = minHeadway
  }

  setRels({
    accessGroup,
    loc1,
    loc2,
    loadtables = [],
    transittimes = [],
  }: {
    accessGroup: AccessGroup
    loc1: Location
    loc2: Location
    loadtables: LoadTable[]
    transittimes: TransitTimes[]
  }) {
    this.accessGroup = accessGroup
    this.loadtables = loadtables
    this.loc1 = loc1
    this.loc2 = loc2
    this.transittimes = transittimes
  }

  get name(): string {
    return `${this.loc1.code} - ${this.loc2.code}`
  }

  transitTime(trainTypeId: string, forward: boolean): number | null {
    const transitTimeObj = this.transittimes.find(
      t => t.trainTypeId === trainTypeId,
    )
    if (transitTimeObj) {
      return forward
        ? transitTimeObj.forwardTransitTime
        : transitTimeObj.reverseTransitTime
    }
    return null
  }

  get minHeadway(): number {
    return this._minHeadway.toSeconds()
  }
}

export { Corridor }
