import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LocationCargoCompatibility } from './model'

export const locationCargoCompatibilityFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.locationcargocompatibilities.map(
      data => new LocationCargoCompatibility(data),
    ),
  setRelsFromSpec(
    (locationCargoCompatibility: LocationCargoCompatibility, state: any) => ({
      location: registry
        .provide('locationRepo')(state)
        .byId(locationCargoCompatibility.locationId),
      cargoType: registry
        .provide('cargoTypeRepo')(state)
        .byId(locationCargoCompatibility.cargoTypeId),
    }),
  ),
)
