import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { locationCargoCompatibilityFactory } from './factory'
import {
  ILocationCargoCompatibilityRepo,
  LocationCargoCompatibilityRepo,
} from './repo'

const locationCargoCompatibilityRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LocationCargoCompatibilityRepo(
      locationCargoCompatibilityFactory(input),
    ),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  locationCargoCompatibilityRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type LocationCargoCompatibilityRepoReg = {
  locationCargoCompatibilityRepo: FromDocumentFactory<
    FactoryInput,
    ILocationCargoCompatibilityRepo
  >
}

export function setup<R extends LocationCargoCompatibilityRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind(
    'locationCargoCompatibilityRepo',
    locationCargoCompatibilityRepo,
  )

  relationships.addConstraints('locationcargocompatibilities', {
    unique: [['locationId', 'cargoTypeId']],
    rels: [
      {
        collection: 'locations',
        foreign: 'locationId',
        name: 'location',
      },
      {
        collection: 'cargotypes',
        foreign: 'cargoTypeId',
        name: 'cargoType',
      },
    ],
  })
}

export { LocationCargoCompatibility } from './model'
