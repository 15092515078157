import { createSelector } from 'reselect'

import * as LocoEvent from 'src/service-design/shared/models/loco-event'
import * as LocoClass from 'src/service-design/shared/models/lococlass'

export const getForeignLocoClasses = createSelector(
  LocoEvent.values,
  allLocoEvents =>
    new Set(allLocoEvents.filter(x => x.isForeign).map(x => x.lococlass)),
)

export const getLocoClassesWithForeign = createSelector(
  LocoClass.values,
  getForeignLocoClasses,
  (locoClasses, foreignLocoClasses) => [...locoClasses, ...foreignLocoClasses],
)
