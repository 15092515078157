import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { CustomTaskAssignment } from './model'

export interface ICustomTaskAssignmentRepo extends IRepo<CustomTaskAssignment> {
  byCustomTaskId(id: string): CustomTaskAssignment
  byShiftId(id: string): CustomTaskAssignment[]
}

export class CustomTaskAssignmentRepo implements ICustomTaskAssignmentRepo {
  values: CustomTaskAssignment[]
  idMap: { [key: string]: CustomTaskAssignment }
  customTaskIdMap: { [key: string]: CustomTaskAssignment }
  shiftIdMap: Indexer<CustomTaskAssignment>

  constructor(values: CustomTaskAssignment[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.customTaskIdMap = Object.fromEntries(
      values.map(v => [v.customTaskId, v]),
    )
    this.shiftIdMap = new Indexer(values, x => x.shiftId)
  }

  byId(id: string): CustomTaskAssignment {
    return this.idMap[id]
  }

  byCustomTaskId(customTaskId: string): CustomTaskAssignment {
    return this.customTaskIdMap[customTaskId]
  }

  byShiftId(shiftId: string): CustomTaskAssignment[] {
    return this.shiftIdMap.lookup(shiftId)
  }
}
