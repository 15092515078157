import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Corridor } from './model'

export interface ICorridorRepo extends IRepo<Corridor> {
  byAccessGroupId(id: string): Corridor[]
  byLoc1Id(id: string): Corridor[]
  byLoc2Id(id: string): Corridor[]
}

export class CorridorRepo implements ICorridorRepo {
  values: Corridor[]
  idMap: { [key: string]: Corridor }
  accessGroupIdMap: Indexer<Corridor>
  loc1IdMap: Indexer<Corridor>
  loc2IdMap: Indexer<Corridor>

  constructor(values: Corridor[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.accessGroupIdMap = new Indexer(values, x => x.accessGroupId)
    this.loc1IdMap = new Indexer(values, x => x.loc1Id)
    this.loc2IdMap = new Indexer(values, x => x.loc2Id)
  }

  byId(id: string): Corridor {
    return this.idMap[id]
  }

  byAccessGroupId(accessGroupId: string): Corridor[] {
    return this.accessGroupIdMap.lookup(accessGroupId)
  }

  byLoc1Id(loc1Id: string): Corridor[] {
    return this.loc1IdMap.lookup(loc1Id)
  }

  byLoc2Id(loc2Id: string): Corridor[] {
    return this.loc2IdMap.lookup(loc2Id)
  }
}
