// TODO: rename this file. it's not should have something to do with deferable calcs
import { createSelector } from 'reselect'

import { IDeferableCalcs } from 'src/service-design/sd-plan/reducers/deferable-calcs'
import { getUserPreferences } from 'src/service-design/sd-plan/selectors/document-graph'

import { buildWarningRepo, Warning } from 'src/service-design/shared/warnings'

export const getDeferableCalcs = (state: any): IDeferableCalcs =>
  state.ui.deferableCalcs

// Deliberately not exported! Use getWarningRepo
const getAsyncWarnings = createSelector(
  getDeferableCalcs,
  calcs => calcs.warnings,
)

export const getAsyncResourceSummaries = createSelector(
  getDeferableCalcs,
  calcs => calcs.resourceSummaries,
)

export const getWarningRepo = createSelector(
  getAsyncWarnings,
  getUserPreferences,
  (warnings: Warning[], { warningsBlacklist }) =>
    buildWarningRepo(
      warnings.filter(warning => !warningsBlacklist.includes(warning.type)),
    ),
)
