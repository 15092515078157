import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { WorkingLocoLock } from './model'

export const workingLocoLockFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.workinglocolocks.map(data => new WorkingLocoLock(data)),
  setRelsFromSpec((t: WorkingLocoLock, state: any) => ({
    start: registry.provide('trainStartRepo')(state).byId(t.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(t.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${t.startId}:${t.templateLegId}`),
  })),
)
