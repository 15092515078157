import { createSelector } from 'reselect'

import { graphFocusSchema } from 'src/service-design/sd-plan/components/focus/FocusGraph/focus-config'
import { getLocations } from 'src/service-design/sd-plan/selectors/scenario'
import * as CustomTask from 'src/service-design/shared/models/custom-task'
import * as Shift from 'src/service-design/shared/models/shift'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import { generateLocationWork } from 'src/service-design/shared/resource-summaries'

export const getSelectedYardIds = createSelector(
  graphFocusSchema.getFocus,
  (focus: { yardId?: string }) => focus.yardId?.split(',') || [],
)

export const getSelectedYards = createSelector(
  getSelectedYardIds,
  getLocations,
  (selectedIds, yards) => selectedIds.map((id: string) => yards.get(id)),
)

export const getSelectedResourceProfiles = createSelector(
  getSelectedYards,
  TrainStart.values,
  Shift.values,
  CustomTask.values,
  generateLocationWork,
)
