import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { RosterLine } from './model'

export const rosterLineFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.rosterlines.map(data => new RosterLine(data)),
  setRelsFromSpec((rosterLine: RosterLine, state: any) => ({
    next: registry.provide('rosterLineRepo')(state).byId(rosterLine.nextId),
    prev: registry.provide('rosterLineRepo')(state).byNextId(rosterLine.id),
    parent: registry.provide('crewPoolRepo')(state).byId(rosterLine.crewPoolId),
    shiftLines: registry
      .provide('shiftLineRepo')(state)
      .byRosterLineId(rosterLine.id),
    RDOs: registry.provide('rdoRepo')(state).byLineId(rosterLine.id),
  })),
)
