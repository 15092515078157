import * as constants from 'src/service-design/shared/constants'
import { BusinessGroup } from 'src/service-design/shared/models/business-group'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Service } from 'src/service-design/shared/models/service'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainBlock } from 'src/service-design/shared/models/train-block'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { TrainType } from 'src/service-design/shared/models/train-type'
import {
  Delta,
  TimeOfDay,
  EpochTime,
} from 'src/service-design/shared/utils/dates'

interface Attrs {
  id: string
  name: string
  businessGroupId: string
  typeId: string
  ecpBraking: boolean
  minLeadLocos: number
  maxHauledLocos: number
  lock: boolean
}

interface Rels {
  type: TrainType
  businessGroup: BusinessGroup
  legs: TemplateLeg[]
  starts: TrainStart[]
}

class TrainTemplate extends Mapper {
  entityName = 'TrainTemplate'

  static defaults = {
    ecpBraking: false,
    minLeadLocos: 1,
    maxHauledLocos: 99,
    lock: false,
  }

  /**
   * A TrainTemplate is a set metadata and route described as a sequence of
   * TemplateLegs. A TrainStart is a decision to run a TrainTemplate on a
   * specific day of week.
   *
   * Each TemplateLeg describes a journey over a Corridor and has an
   * `departsDelta` and an `arrivesDelta`. When a TrainStart is turned created
   * for a given day of week we can fully determine its route and timing. ie
   *
   * departure_time_of_start_leg(n) =
   *   trainStart.dayOfWeek * SECONDS_PER_WEEK +
   *   sum(l.departsDelta + l.arrivesDelta for l in trainTemplate.legs[:n])
   *
   * arrives_time_of_start_leg(n) =
   *   departure_time_of_start_leg(n) + trainTemplate.legs[n].arrivesDelta
   *
   * Note the there is no restriction that the first `departsDelta` be less than
   * a day. This is because rail operators do not necessarily want to couple the
   * intrepretation of the day of week of a TrainStart to the departure day of
   * the TrainStart. Example: a train may leave Tues 23:00 and arrive at its
   * destination Wed 23:00, and it's entirely reasonable that they might want to
   * call this a Wednesday train.
   *
   * CLIENT INSIGHT:
   *
   * TasRail's train names have the form XYY (eg 134) where X denotes a day of
   * week (base 0) and YY represents the template name.
   *
   * PN's train names have the form XYYY (eg 1MP7) where X denotes a day of week
   * (base 1) and YYY represents the template name.
   *
   * Related models:
   * - TrainStart;
   * - Corridor;
   * - TemplateLeg;
   * - StartLeg.
   *
   * @param {string} id: The entity id
   * @param {string} name: The template name
   * @param {string} businessGroupId: The id of the template's BusinessGroup
   * @param {string} typeId: The id of the template's TrainType
   * @param {boolean} ecpBraking: Whether the template requires ECP Braking
   * @param {number} minLeadLocos: The number of lead locos required to pull a
   *   TrainTemplate. Typically this will either be 1 or 2. Examples where a rail
   *   operator may require two lead locos:
   *   - The template travels a massive distance and to lead locos are need
   *     for redundancy incase of breakdown;
   *   - The template terminates at a place where locos can't turn, in this case
   *     two locos will be attached back to back to the front of the train.
   * @param {number} maxHauledLocos: Used to prescribe the max number of hauled
   *   locos that can be attach to an TrainStart (of this template) at anyone
   *   time. There's not a strong bunsiness case for this, the feature was
   *   original implemented for PN.
   * @param {boolean} lock: Whether or not the template is locked from an engine
   *   persceptive. NOTE: Ideally we'd remodel this and get the lock of the
   *   template itself.
   */

  constructor({
    id,
    name,
    businessGroupId,
    typeId,
    ecpBraking,
    minLeadLocos,
    maxHauledLocos,
    lock,
  }: Attrs) {
    super()
    this.id = id
    this.name = name
    this.businessGroupId = businessGroupId
    this.typeId = typeId
    this.ecpBraking = ecpBraking
    this.minLeadLocos = minLeadLocos
    this.maxHauledLocos = maxHauledLocos
    this.lock = lock
  }

  setRels({ type, businessGroup, legs = [], starts }: Rels) {
    this.type = type
    this.businessGroup = businessGroup
    this.legs = legs.sort((l1, l2) => l1.legNum - l2.legNum)
    this.starts = starts
  }

  get departsTimeOfDay(): TimeOfDay {
    return TimeOfDay.fromEpochTime(this.departsLocal)
  }

  get departsLocal(): EpochTime {
    return this.legs[0].departsLocal
  }

  get daysOfWeek(): number[] {
    return this.starts.map(start => start.departsDayOfWeek)
  }

  // TODO: duped between TrainStart and TrainTemplate
  legOffsetLocal(leg: TemplateLeg): Delta {
    const legIndex = this.legs.indexOf(leg)
    if (legIndex === -1) {
      throw new Error('why would you do that')
    }

    return this.legs
      .slice(0, legIndex)
      .reduce<Delta>(
        (acc, l) => acc.add(l.departsDelta).add(l.arrivesDelta),
        Delta.nil,
      )
  }

  // FIXME: temporary until starts are properly exposed in the UI
  get start(): TrainStart {
    return this.starts.length ? this.starts[0] : null
  }

  get origin(): Location {
    return this.legs[0].origin as Location
  }

  get destination(): Location {
    return this.legs[this.legs.length - 1].dest as Location
  }

  get services(): Service[] {
    return [
      ...new Set(
        this.starts.flatMap(start => start.legs.flatMap(leg => leg.services)),
      ),
    ]
  }

  get blocks(): TrainBlock[] {
    return this.legs.flatMap(l => l.arrivingTrainBlocks)
  }

  get lockTypes(): Set<string> {
    const result = new Set(this.starts.flatMap(x => [...x.lockTypes.values()]))
    if (this.lock) {
      result.add(constants.LOCK_TRAIN)
    }
    return result
  }
}

interface TrainTemplate extends Attrs, Rels {}

export { TrainTemplate }
