import { Event } from 'src/service-design/shared/models/dwell-resource-profile'
import { Location } from 'src/service-design/shared/models/location'
import { ResourceProfile } from 'src/service-design/shared/models/resource-summaries/types'
import { LVTask } from 'src/service-design/shared/models/task'
import { normalize } from 'src/service-design/shared/utils/dates'

export class ShiftResourceProfile implements ResourceProfile {
  id: string
  lvTasks: LVTask[]
  location: Location
  LV: any

  /**
   * A ShiftResourceProfile is used to track how the balance
   * of LVs is effected by the the set of LVTasks within
   * a Shift. ResourceProfiles are used by ResourceSummary
   * to compute the required size for a Resource fleet.
   *
   * Related models:
   * - `Shift`;
   * - `LVTask`;
   * - `ResourceProfile`;
   * - `Location`.
   *
   * @constructor
   * @param {LVTask[]} lvTasks - LV tasks with origin/dest at the location
   * @param {Location} location - a Location
   * @param LV - LV specification between two locations from scenario
   */

  constructor({
    id,
    lvTasks,
    location,
    LV,
  }: {
    id: string
    lvTasks: LVTask[]
    location: Location
    LV: any
  }) {
    this.id = id
    this.location = location
    this.lvTasks = lvTasks.filter(
      x => x.origin === location || x.dest === location,
    )
    this.LV = LV
  }

  get resources() {
    return this.lvTasks.length ? [this.LV] : []
  }

  get deltas(): {
    offset: number
    delta: Map<any, number>
    event: Event
  }[] {
    return this.lvTasks.map(lvTask => {
      const isArrival = lvTask.dest === this.location
      const { offset, type, sign } = isArrival
        ? {
            offset: normalize(lvTask.endTimeLocalNormalized),
            type: 'end',
            sign: 1,
          }
        : {
            offset: lvTask.startTimeLocalNormalized,
            type: 'start',
            sign: -1,
          }
      return {
        offset,
        delta: new Map([[this.LV, sign]]),
        event: new Event(lvTask, type, this),
      }
    })
  }
}
