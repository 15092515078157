import { uniq, groupBy } from 'lodash'
import { createSelector } from 'reselect'

import { Section } from 'src/service-design/sd-plan/components/modals/TrainTemplate/path-selector'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import * as Corridor from 'src/service-design/shared/models/corridor'
import { values as corridorValues } from 'src/service-design/shared/models/corridor'
import {
  Location,
  selector as locationSelector,
} from 'src/service-design/shared/models/location'
import { sortByName } from 'src/service-design/shared/utils/arrays/index'

export const getRawBusinessGroups = (state: any) =>
  getCollection(state, 'scenario', 'businessgroups')
export const getRawCargoTypes = (state: any) =>
  getCollection(state, 'scenario', 'cargotypes')
export const getRawCorridors = (state: any) =>
  getCollection(state, 'scenario', 'corridors')
export const getRawLoadTables = (state: any) =>
  getCollection(state, 'scenario', 'loadtables')
export const getRawLocations = (state: any) =>
  getCollection(state, 'scenario', 'locations')
export const getRawTrainTypes = (state: any) =>
  getCollection(state, 'scenario', 'traintypes')
export const getRawTimezones = (state: any) =>
  getCollection(state, 'scenario', 'timezones')
export const getRawTransitTimes = (state: any) =>
  getCollection(state, 'scenario', 'transittimes')
export const getRawWagons = (state: any) =>
  getCollection(state, 'scenario', 'wagons')

export const getTrainTypes = createSelector(
  getRawTrainTypes,
  trainTypes => new Map(trainTypes.map(s => [s.id, s])),
)

export const getYardLocations = createSelector(getRawLocations, locations =>
  locations.filter(location => location.yard),
)

export const getBusinessGroups = createSelector(
  [getRawBusinessGroups],
  businessGroups => new Map(businessGroups.map(b => [b.id, b])),
)

export const getTimezones = createSelector(
  getRawTimezones,
  timezones => new Map(timezones.map(t => [t.id, t])),
)

export const getLocations = createSelector(
  locationSelector,
  repo => new Map(Object.entries(repo.idMap)),
)

export const getCorridors = createSelector(
  Corridor.selector,
  repo => new Map(Object.entries(repo.idMap)),
)

export interface IDirectedCorridor {
  name: string
  start: Location
  end: Location
  corridorId: string
  forward: boolean
}

export const getDirectedCorridors = createSelector(
  Corridor.values,
  (corridors: Corridor.Corridor[]): IDirectedCorridor[] =>
    sortByName(
      corridors.reduce<IDirectedCorridor[]>(
        (acc, c) => [
          ...acc,
          {
            name: `${c.loc1.code} - ${c.loc2.code}`,
            start: c.loc1,
            end: c.loc2,
            corridorId: c.id,
            forward: true,
          },
          {
            name: `${c.loc2.code} - ${c.loc1.code}`,
            start: c.loc2,
            end: c.loc1,
            corridorId: c.id,
            forward: false,
          },
        ],
        [],
      ),
    ),
)

export const getFilteredDirectedCorridors = (
  state: any,
  startId: string = null,
  endId: string = null,
) =>
  getDirectedCorridors(state).filter(
    c => (!startId || c.start.id === startId) && (!endId || c.end.id === endId),
  )

export const getConnectedLocations = createSelector(
  corridorValues,
  (corridors): Location[] =>
    uniq(corridors.flatMap(c => [c.loc1, c.loc2])).sort(),
)

export const getCorridorByStartId = createSelector(
  getDirectedCorridors,
  (corridors: IDirectedCorridor[]) => groupBy(corridors, corr => corr.start.id),
)

export const getAdjacentLocations = (originId: string, visited: string[]) =>
  createSelector(
    locationSelector,
    getCorridorByStartId,
    (locationRepo, corridorByStartId) => {
      const validCorridors = (
        startId: string,
        currentNodes: string[],
      ): IDirectedCorridor[] =>
        corridorByStartId[startId].filter(
          corr =>
            !currentNodes.includes(corr.end.id) &&
            !visited.includes(corr.end.id),
        )

      const pathData = ({
        corridorId,
        forward,
        end,
      }: IDirectedCorridor): Section => ({
        end: end.code,
        endId: end.id,
        outOfNetwork: end.outOfNetwork,
        forward,
        corridorId,
      })

      const paths: Section[][] = []

      const addNext = (path: Section[]) => {
        const last = path.slice(-1)[0]
        if (!last.outOfNetwork) {
          const currentNodes = path.map(x => x.endId)
          const corridors = validCorridors(
            currentNodes.slice(-1)[0],
            currentNodes,
          )

          if (corridors.length === 1) {
            const corridor = corridors[0]
            const next = [...path, pathData(corridor)]
            paths.push(next)
            addNext(next)
          }
        }
      }

      const origin = locationRepo.byId(originId)
      const initial =
        visited.length === 1 || !origin.outOfNetwork
          ? validCorridors(originId, []).map(corridor => [pathData(corridor)])
          : []

      for (const path of initial) {
        paths.push(path)
        addNext(path)
      }

      return paths
    },
  )
