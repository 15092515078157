import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { EmptyWagonEvent } from './model'

export interface IEmptyWagonEventRepo extends IRepo<EmptyWagonEvent> {
  byStartId(id: string): EmptyWagonEvent[]
  byTemplateLegId(id: string): EmptyWagonEvent[]
  byStartLegId(id: string): EmptyWagonEvent[]
  byWagonId(id: string): EmptyWagonEvent[]
}

export class EmptyWagonEventRepo implements IEmptyWagonEventRepo {
  values: EmptyWagonEvent[]
  idMap: { [key: string]: EmptyWagonEvent }
  startIdMap: Indexer<EmptyWagonEvent>
  templateLegIdMap: Indexer<EmptyWagonEvent>
  startLegIdMap: Indexer<EmptyWagonEvent>
  wagonIdMap: Indexer<EmptyWagonEvent>

  constructor(values: EmptyWagonEvent[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
    this.wagonIdMap = new Indexer(values, x => x.wagonId)
  }

  byId(id: string): EmptyWagonEvent {
    return this.idMap[id]
  }

  byStartId(startId: string): EmptyWagonEvent[] {
    return this.startIdMap.lookup(startId)
  }

  byTemplateLegId(templateLegId: string): EmptyWagonEvent[] {
    return this.templateLegIdMap.lookup(templateLegId)
  }

  byStartLegId(startLegId: string): EmptyWagonEvent[] {
    return this.startLegIdMap.lookup(startLegId)
  }

  byWagonId(wagonId: string): EmptyWagonEvent[] {
    return this.wagonIdMap.lookup(wagonId)
  }
}
