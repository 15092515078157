import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { yardBlockFactory } from './factory'
import { IYardBlockRepo, YardBlockRepo } from './repo'

export const yardBlockRepo = defaultMemoize(
  (input: FactoryInput) => new YardBlockRepo(yardBlockFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  yardBlockRepo(input),
)
export const values = (state: RootState) => selector(state).values

export function setup<R extends YardBlockRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('yardBlockRepo', yardBlockRepo)

  relationships.addConstraints('yardblocks', {
    rels: [
      {
        collection: 'locations',
        foreign: 'originId',
        name: 'origin',
      },
      {
        collection: 'locations',
        foreign: 'destinationId',
        name: 'destination',
      },
    ],
  })
}

export type YardBlockRepoReg = {
  yardBlockRepo: FromDocumentFactory<FactoryInput, IYardBlockRepo>
}
export { YardBlock } from './model'
