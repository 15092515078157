import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { RDO } from './model'

export interface IRDORepo extends IRepo<RDO> {
  byLineId(id: string): RDO[]
}

export class RDORepo implements IRDORepo {
  values: RDO[]
  idMap: { [key: string]: RDO }
  lineIdMap: Indexer<RDO>

  constructor(values: RDO[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.lineIdMap = new Indexer(values, x => x.lineId)
  }

  byId(id: string): RDO {
    return this.idMap[id]
  }

  byLineId(lineId: string): RDO[] {
    return this.lineIdMap.lookup(lineId)
  }
}
