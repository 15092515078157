import { createAction, ActionType } from 'typesafe-actions'

import { getAuthToken } from 'src/core/auth'
import i18n from 'src/i18n'
import api from 'src/service-design/shared/api'
import * as constants from 'src/service-design/shared/constants'
import { modalShow } from 'src/service-design/shared/forms/actions/modals'

const STAY_LOGGED_IN_INTERVAL = 60 * 1000

interface IUser {
  name: string
}
interface ICurrentUserPayload {
  name: string
}

export const currentUserReceive = createAction(
  'sd/CURRENT_USER/RECEIVE',
  (user: IUser): ICurrentUserPayload => user,
)()

export const CurrentUserActions = {
  currentUserReceive,
} as const
export type CurrentUserActionTypes = ActionType<typeof CurrentUserActions>

export const currentUserLoad = () => (dispatch: any) =>
  api
    .get({
      url: '/current-user',
    })
    .then(({ data }: { data: IUser }) => dispatch(currentUserReceive(data)))
    .catch(e => {
      let message = e.toString()
      if (e.status && e.data && e.data.message) {
        message = `${i18n.t('Status Code')} ${e.status}: ${e.data.message}`
      }

      return dispatch(
        modalShow(constants.MODAL_ERROR, {
          header: 'There was an error loading the current user.',
          message,
        }),
      )
    })

// HACK: Keycloak Security Proxy sessions time out after 30 mins of inactivity
// so this action calls the ping url every minute.

export const stayLoggedIn = () => {
  setInterval(async () => {
    const headers: { Authorization?: string } = {}
    const token = await getAuthToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    fetch('/session-keep-alive', {
      method: 'GET',
      headers,
    })
  }, STAY_LOGGED_IN_INTERVAL)
}
