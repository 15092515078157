import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class Detach<T extends IStartLeg> extends LegTask<T> {
  /**
   * Detach is a LegTask that occurs immediately after a TrainStart
   * arrives at a location and comes to a complete stop (ie PostArrival has
   * completed). During this task shunting work is done to decouple Services
   * and Wagons from the TrainStart.
   */

  static arrivingTask = true

  static kind = 'detach'

  static requiresRailOperator = true

  static timeOffsetAllowed = true

  static build(startLeg: StartLeg) {
    return new this({
      origin: startLeg.dest,
      destination: startLeg.dest,
      startTimeLocal: startLeg.detachStartLocal,
      endTimeLocal: startLeg.detachEndLocal,
      startLeg,
    })
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(
      this.duration,
      this.startLeg.consist.workingDict,
      this.startLeg.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.startLeg.consist.hauledDict)
  }
}
