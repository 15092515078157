import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { rosterLineFactory } from './factory'
import { getRosterLineRepairs } from './repairs'
import { IRosterLineRepo, RosterLineRepo } from './repo'

const rosterLineRepo = defaultMemoize(
  (input: FactoryInput) => new RosterLineRepo(rosterLineFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  rosterLineRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type RosterLineRepoReg = {
  rosterLineRepo: FromDocumentFactory<FactoryInput, IRosterLineRepo>
}

export function setup<R extends RosterLineRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('rosterLineRepo', rosterLineRepo)

  relationships.addConstraints('rosterlines', {
    unique: [['nextId']],
    rels: [
      {
        collection: 'crewpools',
        foreign: 'parentId',
        name: 'parent',
      },
      {
        collection: 'rosterlines',
        foreign: 'nextId',
        name: 'next',
      },
    ],
  })

  repairs.register(createSelector(values, getRosterLineRepairs))
}

export { RosterLine } from './model'
