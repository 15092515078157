import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class AttachAdvance<T extends IStartLeg> extends LegTask<T> {
  /**
   * AttachAdvance captures any time between Attach and PreDeparture.
   *
   * It is sometimes useful to preform Attach in advance of PreDeparture.
   *
   * Related:
   * - AttachAdvance
   * - StartTaskLegOffset
   */
  static arrivingTask = false

  static kind = 'attach advance'

  static requiresRailOperator = false

  static timeOffsetAllowed = false

  static build(startLeg: IStartLeg) {
    return startLeg.attachEndLocal !== startLeg.preDepartStartLocal
      ? new this({
          origin: startLeg.origin,
          destination: startLeg.origin,
          startTimeLocal: startLeg.attachEndLocal,
          endTimeLocal: startLeg.preDepartStartLocal,
          startLeg,
        })
      : null
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get workingLocosDict() {
    return this.startLeg.consist.workingDict
  }

  get hauledLocosDict() {
    return this.startLeg.consist.hauledDict
  }

  get wagonDict() {
    return this.startLeg.wagonDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts<IResource>(
      this.duration,
      this.workingLocosDict,
      this.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.hauledLocosDict)
  }
}
