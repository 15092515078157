import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { rdoFactory } from './factory'
import { IRDORepo, RDORepo } from './repo'

const rdoRepo = defaultMemoize(
  (input: FactoryInput) => new RDORepo(rdoFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  rdoRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type RDORepoReg = {
  rdoRepo: FromDocumentFactory<FactoryInput, IRDORepo>
}

export function setup<R extends RDORepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('rdoRepo', rdoRepo)

  relationships.addConstraints('rdos', {
    unique: [['lineId', 'day']],
    rels: [
      {
        collection: 'rosterlines',
        foreign: 'lineId',
        name: 'line',
      },
    ],
  })
}

export { RDO } from './model'
