import { createSelector } from 'reselect'

import { getBusinessGroups } from 'src/service-design/sd-plan/selectors/scenario'
import { ResourceSummary } from 'src/service-design/shared/models/resource-summaries'
import { getLococlassSummaries } from 'src/service-design/shared/resource-summaries'

type KeysOfAttrType<T, K> = {
  [P in keyof T]: T[P] extends K ? P : never
}[keyof T]

// TODO: only for the kcs reports page... Can we delete this?
// UPDATE: When the worksheet gets moved up to client, this can go up to client/kcs too
export const getFleetPlan = createSelector(
  getLococlassSummaries,
  getBusinessGroups,
  (locoSummaries, businessgroups) => {
    const data = []

    const extraRows: {
      name: string
      key: KeysOfAttrType<ResourceSummary, number>
    }[] = [
      {
        name: 'Fleet Total',
        key: 'fleetTotal',
      },
      { name: 'Idle', key: 'rosteredIdle' },
      {
        name: 'Hauled',
        key: 'rosteredHauled',
      },
      { name: 'Spare', key: 'spare' },
    ]

    const makeRowFactory = (
      name: string,
      category: string,
      getQuantity: (summary: ResourceSummary) => number,
    ) => (summary: ResourceSummary) => ({
      category,
      utilizationType: name,
      resource: summary.resource,
      quantity: getQuantity(summary) || 0,
    })

    for (const row of extraRows) {
      const makeRow = makeRowFactory(
        row.name,
        'Other',
        (summ: ResourceSummary): number => summ[row.key],
      )
      data.push(...locoSummaries.map(makeRow))
    }

    let unavailReason = new Set()
    for (const group of businessgroups.values()) {
      const makeRow = makeRowFactory(group.name, 'Rostered', summ =>
        summ.bgRostered.get(group.id),
      )
      data.push(...locoSummaries.map(makeRow))

      unavailReason = new Set(
        [].concat(
          ...locoSummaries.map(summ =>
            summ.unavailabilities.map(unavailability => unavailability.reason),
          ),
        ),
      )
    }

    for (const reason of unavailReason) {
      for (const summary of locoSummaries) {
        const row = {
          category: 'Unavailable',
          resource: summary.resource,
          utilizationType: reason,
          quantity: 0,
        }
        for (const unavail of summary.unavailabilities) {
          if (unavail.reason === reason) {
            row.quantity = unavail.quantity
          }
        }
        data.push(row)
      }
    }
    return data
  },
)
