import { Mapper } from 'src/service-design/shared/models/mapper'
import { Service } from 'src/service-design/shared/models/service'

interface Attrs {
  id: string
  serviceId: string
}

interface Rels {
  service: Service
}

class ServiceLock extends Mapper {
  constructor({ id, serviceId }: Attrs) {
    super()
    this.id = id
    this.serviceId = serviceId
  }

  setRels({ service }: Rels) {
    this.service = service
  }
}

interface ServiceLock extends Attrs, Rels {}
export { ServiceLock }
