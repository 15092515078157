import i18n from 'src/i18n'

import { SECONDS_PER_WEEK } from 'src/service-design/shared/constants'
import * as CompoundShift from 'src/service-design/shared/models/compound-shift'
import * as RosterLine from 'src/service-design/shared/models/roster-line'
import * as Shift from 'src/service-design/shared/models/shift'
import {
  END_OF_WEEK_EPOCH_TIME,
  EpochTime,
  intersectionExclusive,
  WEEK_DURATION,
} from 'src/service-design/shared/utils/dates'
import { SimpleWarning } from 'src/service-design/shared/warnings'

// TODO
//
// There is a sortBy function in 'src/service-design/shared/utils/arrays/index.ts'
// that should be adapted to a python like interface that accepts a key function
// and then this function can die.
// ie
// sortBy(conflicts, c => [c.endTimeLocalVO.toSeconds(), c.startTimeLocalVO.toSeconds()] as const)
function sort<
  T extends { startTimeLocalVO: EpochTime; endTimeLocalVO: EpochTime }
>(tasks: T[]): T[] {
  return tasks.sort((a, b) => {
    if (a.startTimeLocalVO.equals(b.startTimeLocalVO)) {
      return a.endTimeLocalVO.toSeconds() - b.endTimeLocalVO.toSeconds()
    }
    return a.startTimeLocalVO.toSeconds() - b.startTimeLocalVO.toSeconds()
  })
}

interface TemporalData {
  startTime: number
  endTime: number
}

const createConflictWarning = (
  message: string,
  line: RosterLine.RosterLine,
  startTime: EpochTime,
  endTime: EpochTime,
) => ({
  message,
  entityId: line.id,
  startTime: startTime.toSeconds(),
  endTime: endTime.toSeconds(),
})

interface IShiftConflictWarning {
  entityId: string
  message: string
  startTime: EpochTime
  endTime: EpochTime
  shift1: Shift.Shift
  shift2: Shift.Shift
}

// check the previous line's shifts for overlaps at the start of this line, ensuring that:
// - the time for the conflict is shifted back to cover the start of the line
// - the entityId is the *second* line's ID
export const checkPrevShiftConflict = (
  line: RosterLine.RosterLine,
): IShiftConflictWarning[] =>
  checkShiftConflict(line)
    .filter(w => w.endTime.toSeconds() > SECONDS_PER_WEEK)
    .map(w => ({
      ...w,
      startTime: w.startTime.makeEarlier(WEEK_DURATION),
      endTime: w.endTime.makeEarlier(WEEK_DURATION),
      entityId: w.shift2.line.id,
    }))

export const checkShiftConflict = (
  line: RosterLine.RosterLine,
  addShift: Shift.Shift = null,
): IShiftConflictWarning[] => {
  const theseShifts: {
    shift: Shift.Shift
    startTimeLocalVO: EpochTime
    endTimeLocalVO: EpochTime
  }[] = sort(
    [...(addShift ? [addShift] : []), ...line.shifts].map(shift => ({
      shift,
      startTimeLocalVO: shift.startTimeLocalVO,
      endTimeLocalVO: shift.endTimeLocalVO.makeLater(line.minHomeRestSecsVO),
    })),
  )
  const nextShifts = sort(
    line.next.shifts.map(shift => ({
      shift,
      startTimeLocalVO: shift.startTimeLocalVO.makeLater(WEEK_DURATION),
      endTimeLocalVO: shift.endTimeLocalVO
        .makeLater(WEEK_DURATION)
        .makeLater(line.minHomeRestSecsVO),
    })),
  )

  const allShifts = [...theseShifts, ...nextShifts]
  const warnings: IShiftConflictWarning[] = []
  theseShifts.forEach((shift1, index) => {
    const laterShifts = allShifts.slice(index + 1)
    for (const shift2 of laterShifts) {
      if (
        !shift1.shift.endAtRemoteRest ||
        shift1.shift.endAtRemoteRest !== shift2.shift.startAtRemoteRest
      ) {
        if (
          intersectionExclusive(
            [
              shift1.startTimeLocalVO.toSeconds(),
              shift1.endTimeLocalVO.toSeconds(),
            ],
            [
              shift2.startTimeLocalVO.toSeconds(),
              shift2.endTimeLocalVO.toSeconds(),
            ],
          )
        ) {
          const context = {
            shift1: shift1.shift,
            shift2: shift2.shift,
            line,
            pool: shift1.shift.pool,
          }
          const message = i18n.t(
            'service-design::Shift {{shift2.name}} is too soon after {{shift1.name}} on {{pool.name}} roster line {{line.num}}',
            context,
          )
          warnings.push({
            message,
            entityId: line.id,
            startTime: shift1.startTimeLocalVO,
            endTime: shift2.endTimeLocalVO.makeEarlier(line.minHomeRestSecsVO),
            ...context,
          })
        } else if (
          shift2.startTimeLocalVO.isStrictlyLater(shift1.endTimeLocalVO)
        ) {
          break
        }
      }
    }
  })
  return addShift
    ? warnings.filter(w => [w.shift1, w.shift2].indexOf(addShift) > -1)
    : warnings
}

export const checkRDOConflict = (
  line: RosterLine.RosterLine,
  addShift: Shift.Shift | CompoundShift.CompoundShift = null,
) => {
  const warnings: SimpleWarning<TemporalData>[] = []
  const rdos = [
    ...line.prev.RDOs.map(r => ({
      id: r.id,
      startTimeLocalVO: r.startTimeLocalVO.makeEarlier(WEEK_DURATION),
      endTimeLocalVO: r.endTimeLocalVO.makeEarlier(WEEK_DURATION),
    })),
    ...line.RDOs,
    ...line.next.RDOs.map(r => ({
      id: r.id,
      startTimeLocalVO: r.startTimeLocalVO.makeLater(WEEK_DURATION),
      endTimeLocalVO: r.endTimeLocalVO.makeLater(WEEK_DURATION),
    })),
  ]

  const shifts = sort(addShift ? [addShift] : line.shifts)

  for (const rdo of rdos) {
    for (const shift of shifts) {
      if (
        intersectionExclusive(
          [
            shift.startTimeLocalVO.toSeconds(),
            shift.endTimeLocalVO.toSeconds(),
          ],
          [rdo.startTimeLocalVO.toSeconds(), rdo.endTimeLocalVO.toSeconds()],
        )
      ) {
        const message = i18n.t(
          'service-design::Shift {{shift.name}} overlaps with an RDO on {{shift.pool.name}} roster line {{line.num}}',
          { line, shift },
        )
        warnings.push(
          createConflictWarning(
            message,
            line,
            shift.startTimeLocalVO,
            shift.endTimeLocalVO,
          ),
        )
      } else if (shift.startTimeLocalVO.isStrictlyLater(rdo.endTimeLocalVO)) {
        break
      }
    }
  }
  return warnings
}

export const checkReliefLineConflict = (
  line: RosterLine.RosterLine,
  addShift: Shift.Shift | CompoundShift.CompoundShift = null,
) => {
  const warnings: SimpleWarning<TemporalData>[] = []
  const shifts = addShift ? [addShift] : line.shifts

  const message =
    'service-design::Sign-on is too soon after relief on {{line.crewPool.name}} roster line {{line.num}}'

  // shifts from the previous line that overlap into this line are conflicts
  if (line.prev.relief) {
    warnings.push(
      ...shifts
        .filter(
          previousShift =>
            previousShift.startTimeLocalVO.toSeconds() <
            line.reliefBufferDurationVO.toSeconds(),
        )
        .map(s =>
          createConflictWarning(
            i18n.t(message, { line: line.prev }),
            line.prev,
            s.startTimeLocalVO,
            s.endTimeLocalVO,
          ),
        ),
    )
  }

  // all shifts on this line are conflicts
  if (line.relief) {
    warnings.push(
      ...shifts.map(s =>
        createConflictWarning(
          i18n.t(message, { line }),
          line,
          s.startTimeLocalVO,
          s.endTimeLocalVO,
        ),
      ),
    )
  }

  if (line.next.relief) {
    warnings.push(
      ...shifts
        .filter(x => x.endTimeLocalVO.isStrictlyLater(END_OF_WEEK_EPOCH_TIME))
        .map(s =>
          createConflictWarning(
            i18n.t(message, { line: line.next }),
            line.next,
            s.startTimeLocalVO,
            s.endTimeLocalVO,
          ),
        ),
    )
  }
  return warnings
}
