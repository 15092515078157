import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { BusinessGroup } from './model'

export interface IBusinessGroupRepo extends IRepo<BusinessGroup> {}

export class BusinessGroupRepo implements IBusinessGroupRepo {
  values: BusinessGroup[]
  idMap: { [key: string]: BusinessGroup }

  constructor(values: BusinessGroup[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
  }

  byId(id: string): BusinessGroup {
    return this.idMap[id]
  }
}
