import { LocationCargoCompatibility } from 'src/service-design/shared/models/location-cargo-compatibility'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Wagon } from 'src/service-design/shared/models/wagon'
import { WagonCargoCompatibility } from 'src/service-design/shared/models/wagon-cargo-compatibility'

interface Attrs {
  id: string
  name: string
  wagonId: string
  volumeUnit: string
  usesTonnage: boolean
}

interface Rels {
  wagon: Wagon
  compatibilities: LocationCargoCompatibility[]
  wagoncompatibilities: WagonCargoCompatibility[]
}

class CargoType extends Mapper {
  _volumeUnit: string

  /**
   * A CargoType represents a type of cargo that is moved on Wagons by rail
   * operator on their network.
   *
   * Different cargo types have different units of measurement associated
   * with them. For example:
   * - Coal might be measured in tonnes;
   * - Intermodal is likely to be measured in TEU
   * (https://en.wikipedia.org/wiki/Twenty-foot_equivalent_unit)
   *
   * Related models:
   * - `Wagon`;
   * - `WagonCargoCompatibility`;
   * - `LocationCargoCompatibility`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the access group.
   * @param {string} wagonId - The id for the default wagon associated with this
   *  CargoType.
   * @param {string|null} volumeUnit - Good examples 'TEU', 'Logs'. null if and
   *  only if `useTonnage` is `true`.
   * @param {boolean} usesTonnage - If true the volume is measured in tonnes.
   **/
  constructor({ id, name, wagonId, volumeUnit, usesTonnage }: Attrs) {
    super()
    this.id = id
    this.name = name
    this.wagonId = wagonId
    this._volumeUnit = volumeUnit
    this.usesTonnage = usesTonnage
  }

  setRels({ wagon, compatibilities = [], wagoncompatibilities = [] }: Rels) {
    this.wagon = wagon
    this.compatibilities = compatibilities
    this.wagoncompatibilities = wagoncompatibilities
  }

  get volumeUnit() {
    return this.usesTonnage ? 't' : this._volumeUnit
  }

  get wagonVolume() {
    const compatibility = this.wagon.compatibilities.find(
      compat => compat.cargoTypeId === this.id,
    )
    return compatibility ? compatibility.volume : 0
  }

  numberOfWagons(volume: number) {
    return this.wagonVolume ? Math.ceil(volume / this.wagonVolume) : null
  }

  length(volume: number) {
    const numWagons = this.numberOfWagons(volume)
    return numWagons ? numWagons * this.wagon.length : 0
  }
}

interface CargoType extends Attrs, Rels {}
export { CargoType }
