import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { CyclicTime } from 'src/service-design/shared/utils/dates'

import { Service } from './model'

export const serviceFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.services.map(
      ({ deliveryCutOffLocal, dueLocal, ...data }) =>
        new Service({
          deliveryCutOffLocal: CyclicTime.fromSeconds(deliveryCutOffLocal),
          dueLocal: CyclicTime.fromSeconds(dueLocal),
          ...data,
        }),
    ),
  setRelsFromSpec((service: Service, state: any) => ({
    origin: registry.provide('locationRepo')(state).byId(service.originId),
    destination: registry
      .provide('locationRepo')(state)
      .byId(service.destinationId),
    cargoType: registry
      .provide('cargoTypeRepo')(state)
      .byId(service.cargoTypeId),
    events: registry.provide('serviceEventRepo')(state).byServiceId(service.id),

    wagonset: registry.provide('wagonSetRepo')(state).byServiceId(service.id),
    serviceLock: registry
      .provide('serviceLockRepo')(state)
      .byServiceId(service.id),
  })),
)
