import { Task } from 'src/service-design/shared/models/task/base'

export class Transport extends Task {
  /**
   * The Transport task is used the Service class to account for time spent
   * moving on a TrainStart. It doesn't uses resources since the wagons are
   * associated with the Service and the crew with the TrainStart.
   */
  static kind = 'transport'
  resourceDict = new Map()
}
