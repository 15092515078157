import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { RosterLine } from './model'

export interface IRosterLineRepo extends IRepo<RosterLine> {
  byNextId(id: string): RosterLine
  byParentId(id: string): RosterLine[]
}

export class RosterLineRepo implements IRosterLineRepo {
  values: RosterLine[]
  idMap: { [key: string]: RosterLine }
  parentIdMap: Indexer<RosterLine>
  nextIdMap: { [key: string]: RosterLine }

  constructor(values: RosterLine[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.nextIdMap = Object.fromEntries(values.map(v => [v.nextId, v]))
    this.parentIdMap = new Indexer(values, x => x.parentId)
  }

  byId(id: string): RosterLine {
    return this.idMap[id]
  }

  byNextId(nextId: string): RosterLine {
    return this.nextIdMap[nextId]
  }

  byParentId(parentId: string): RosterLine[] {
    return this.parentIdMap.lookup(parentId)
  }
}
