import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { WagonAccessRight } from './model'

export const wagonAccessRightFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.wagonaccessrights.map(data => new WagonAccessRight(data)),
  setRelsFromSpec((t: WagonAccessRight, state: any) => ({
    accessGroup: registry
      .provide('accessGroupRepo')(state)
      .byId(t.accessGroupId),
    wagon: registry.provide('wagonRepo')(state).byId(t.wagonId),
  })),
)
