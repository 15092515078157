import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { YardBlockAssignment } from './model'

export const yardBlockAssignmentFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.yardblockassignments.map(
      data => new YardBlockAssignment(data),
    ),
  setRelsFromSpec((t: YardBlockAssignment, state: any) => ({
    yardBlock: registry.provide('yardBlockRepo')(state).byId(t.yardBlockId),
    trainBlock: registry.provide('trainBlockRepo')(state).byId(t.trainBlockId),
    departingLeg: registry
      .provide('templateLegRepo')(state)
      .byId(t.departingLegId),
  })),
)
