import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class Attach<T extends IStartLeg> extends LegTask<T> {
  /**
   * Attach is a LegTask where shunting takes place prior PreDeparture. During
   * this task shunting work is done to attach Services and Wagons to the
   * TrainStart.
   */
  static arrivingTask = false

  static kind = 'attach'

  static requiresRailOperator = true

  static timeOffsetAllowed = true

  static build<S extends IStartLeg>(startLeg: S) {
    return new this({
      origin: startLeg.origin,
      destination: startLeg.origin,
      startTimeLocal: startLeg.attachStartLocal,
      endTimeLocal: startLeg.attachEndLocal,
      startLeg,
    })
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(
      this.duration,
      this.startLeg.consist.workingDict,
      this.startLeg.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.startLeg.consist.hauledDict)
  }
}
