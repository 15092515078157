import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { TrainTemplate } from './model'

export const trainTemplateFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.traintemplates.map(data => new TrainTemplate(data)),
  setRelsFromSpec((l: TrainTemplate, state: any) => ({
    businessGroup: registry
      .provide('businessGroupRepo')(state)
      .byId(l.businessGroupId),
    type: registry.provide('trainTypeRepo')(state).byId(l.typeId),
    legs: registry.provide('templateLegRepo')(state).byTemplateId(l.id),
    starts: registry.provide('trainStartRepo')(state).byTemplateId(l.id),
  })),
)
