import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { WorkingLocoLock } from './model'

export interface IWorkingLocoLockRepo extends IRepo<WorkingLocoLock> {
  byStartId(id: string): WorkingLocoLock[]
  byTemplateLegId(id: string): WorkingLocoLock[]
  byStartLegId(id: string): WorkingLocoLock
}

export class WorkingLocoLockRepo implements IWorkingLocoLockRepo {
  values: WorkingLocoLock[]
  idMap: { [key: string]: WorkingLocoLock }
  startIdMap: Indexer<WorkingLocoLock>
  templateLegIdMap: Indexer<WorkingLocoLock>
  startLegIdMap: { [key: string]: WorkingLocoLock }

  constructor(values: WorkingLocoLock[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = Object.fromEntries(
      values.map(v => [`${v.startId}:${v.templateLegId}`, v]),
    )
  }

  byId(id: string): WorkingLocoLock {
    return this.idMap[id]
  }

  byStartId(id: string): WorkingLocoLock[] {
    return this.startIdMap.lookup(id)
  }

  byTemplateLegId(id: string): WorkingLocoLock[] {
    return this.templateLegIdMap.lookup(id)
  }

  byStartLegId(id: string) {
    return this.startLegIdMap[id]
  }
}
