import { Mapper } from 'src/service-design/shared/models/mapper'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { YardBlock } from 'src/service-design/shared/models/yard-block'
import { YardBlockAssignment } from 'src/service-design/shared/models/yard-block-assignment'

interface Attrs {
  id: string
  name: string
  templateLegId: string
}

interface Rels {
  templateLeg: TemplateLeg
  assignments: YardBlockAssignment[]
}

class TrainBlock extends Mapper {
  _yardBlocks?: YardBlock[]
  _name: string

  /**
   * A TrainBlock describes a set of YardBlocks which have a common destination
   * and are delivered by the same TrainTemplate.
   *
   * DOMAIN MIS-MATCH:
   *
   * In reality a KCS YardBlock may travel over multiple TrainBlocks to get to
   * its desination, otherwise known as its set-out location. At the time of
   * implementation KCS did not have the data necessary to support this
   * modelling.
   *
   * @param {string} id: The entity id
   * @param {string} name: The name
   * @param {string} templateLegId: Since a template may go through the same
   *   Location multiple times we use this TemplateLeg to describe the
   *   destination of the TrainBlock.
   *
   */
  constructor({ id, name, templateLegId, ...rels }: Attrs) {
    super()
    this.id = id
    this._name = name
    this.templateLegId = templateLegId
  }

  get name() {
    return `${this.template.name} / ${this.shortName}`
  }

  get shortName() {
    return this._name
  }

  setRels({ templateLeg, assignments = [] }: Rels) {
    this.templateLeg = templateLeg
    this.assignments = assignments
  }

  get dest() {
    return this.templateLeg.dest
  }

  get template() {
    return this.templateLeg.template
  }

  get yardBlocks() {
    if (!this._yardBlocks) {
      this._yardBlocks = this.assignments.map(a => a.yardBlock)
    }

    return this._yardBlocks
  }

  get departingLegs() {
    const legs = []
    let prev = this.templateLeg
    while (prev) {
      legs.push(prev)
      ;({ prev } = prev)
    }
    legs.reverse()
    return legs
  }
}

interface TrainBlock extends Attrs, Rels {}

export { TrainBlock }
