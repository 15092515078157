import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { DriverAssignment } from './model'

export const driverAssignmentFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.driverassignments.map(data => new DriverAssignment(data)),
  setRelsFromSpec((driverAssignment: DriverAssignment, state: any) => ({
    shift: registry.provide('shiftRepo')(state).byId(driverAssignment.shiftId),
    train: registry
      .provide('trainStartRepo')(state)
      .byId(driverAssignment.startId),
    startLocationChangeover: registry
      .provide('locationChangeoverRepo')(state)
      .byId(driverAssignment.startLocationChangeoverId),
    endLocationChangeover: registry
      .provide('locationChangeoverRepo')(state)
      .byId(driverAssignment.endLocationChangeoverId),

    startTrainChangeover: registry
      .provide('trainChangeoverRepo')(state)
      .byId(driverAssignment.startTrainChangeoverId),
    endTrainChangeover: registry
      .provide('trainChangeoverRepo')(state)
      .byId(driverAssignment.endTrainChangeoverId),
  })),
)
