import { push } from 'connected-react-router'
import { Location } from 'history'
import { isEqual } from 'lodash'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Middleware } from 'redux'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { createAction, ActionType, isActionOf } from 'typesafe-actions'

import {
  formatParams,
  parseParams,
} from 'src/service-design/sd-plan/components/focus/params'

export const getLocation = (state: any): Location => state.router.location

export type FocusAttrs = { [name: string]: string }
type Focus = { [attr: string]: string }
type FocusValidator = (state: any, id: string) => Focus

// the validation and expansion of focus attrs always results in new objects
// being created each time we check, so use a deep equality memoizer to
// ensure we don't thrash when the contents don't change
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export class FocusParamSchema {
  constructor(
    public readonly namespace: string,
    public registered: { [attr: string]: FocusValidator } = {},
  ) {
    this.getFocus = this.getFocus.bind(this)
    this.getNamespaceParams = this.getNamespaceParams.bind(this)
  }

  register(attr: string, selector: FocusValidator): void {
    if (attr in this.registered) {
      throw new Error(`attr ${attr} already registered`)
    }
    this.registered[attr] = selector
  }

  getNamespaceParams = createSelector(
    getLocation,
    location => parseParams(location.search)[this.namespace] || {},
  )

  // filter the supplied focus attr(s) to ensure they're even usable as focus
  getUsableFocusAttrs = createDeepEqualSelector(
    state => this.getNamespaceParams(state),
    (namespace): [string, string][] => {
      const validAttrs = Object.keys(namespace).filter(
        attr => this.registered[attr],
      )
      return validAttrs.map(attr => [attr, namespace[attr]])
    },
  )

  // now validate the focus attrs, potentially annotating them with additional focus attrs
  getFocus = createDeepEqualSelector(
    state =>
      this.getUsableFocusAttrs(state).map(([attr, value]) =>
        this.registered[attr](state, value),
      ),
    results => Object.assign({}, ...results),
  )

  setFocusAction(attrs: FocusAttrs) {
    for (const attr of Object.keys(attrs)) {
      if (!(attr in this.registered)) {
        throw new Error(`${attr} is not a registered param`)
      }
    }
    return setFocusAction({ namespace: this.namespace, attrs })
  }

  setFocus = (attr: string, value: string, context: FocusAttrs) => {
    if (!(attr in this.registered)) {
      throw new Error(`${attr} is not a registered param`)
    }
    return { [attr]: value, ...context }
  }

  mergeQueryParams(
    location: { search: string; pathname: string },
    newFocus: { [x: string]: string },
    url?: string,
  ) {
    return mergeQueryParams(location, newFocus, this.namespace, url)
  }
}

const mergeQueryParams = (
  location: { search: string; pathname: string },
  newFocus: { [x: string]: string },
  namespace: string,
  url?: string,
) => {
  const params = {
    ...parseParams(location.search),
    [namespace]: newFocus,
  }
  const sParams = formatParams(params)
  if (sParams) {
    return `${url || location.pathname}?${sParams}`
  }
  return `${url || location.pathname}`
}

// This action is not explicitly reduced - it exists to explicitly
// identify history push actions that are actually focus manipulation
export const setFocusAction = createAction('SET_FOCUS')<{
  namespace: string
  attrs: FocusAttrs
}>()

export type SetFocusActionType = ActionType<typeof setFocusAction>

export type FocusContextType = {
  schema: FocusParamSchema
}

export interface IClearFocus {
  (): any
}

export interface ISetFocus {
  (attr: string, value: string, focusContext?: FocusAttrs): any
}

export type UseFocusType = {
  focus: FocusAttrs
  setFocus: ISetFocus
  clearFocus: IClearFocus
  schema: FocusParamSchema
}

export type UseFocusControlsType = {
  setFocus: ISetFocus
  clearFocus: IClearFocus
  schema: FocusParamSchema
}

type Props = {
  schema: FocusParamSchema
  context: React.Context<FocusContextType>
  children: any
}

export const FocusProvider: React.FC<Props> = ({
  schema,
  context,
  children,
}: {
  schema: FocusParamSchema
  context: React.Context<FocusContextType>
  children: any
}) => {
  const value = React.useMemo(() => ({ schema }), [schema])
  return <context.Provider value={value}>{children}</context.Provider>
}

export const useFocus = (
  Context: React.Context<FocusContextType>,
): UseFocusType => {
  const { schema } = useContext(Context)
  const focus = useSelector(schema.getFocus)

  // this is an action that must be dispatched by the user of setFocus
  // (it is not automatically dispatched so that it may be batched with other actions)
  const setFocus = React.useCallback(
    (attr: string, value: string, focusContext?: FocusAttrs) => {
      const newFocus = schema.setFocus(attr, value, focusContext)
      return schema.setFocusAction(newFocus)
    },
    [schema],
  )

  const clearFocus = React.useCallback(() => schema.setFocusAction({}), [
    schema,
  ])

  return React.useMemo(() => ({ focus, setFocus, clearFocus, schema }), [
    clearFocus,
    focus,
    schema,
    setFocus,
  ])
}

// Split out so that this hook doesn't update when focus changes
// TODO this needs to be a factory per context so it can memo correctly if it
// is invoked with different contexts
export const useFocusControls = (
  Context: React.Context<FocusContextType>,
): UseFocusControlsType => {
  const { schema } = useContext(Context)

  // this is an action that must be dispatched by the user of setFocus
  // (it is not automatically dispatched so that it may be batched with other actions)
  const setFocus = React.useCallback(
    (attr: string, value: string, focusContext?: FocusAttrs) => {
      const newFocus = schema.setFocus(attr, value, focusContext)
      return schema.setFocusAction(newFocus)
    },
    [schema],
  )

  const clearFocus = React.useCallback(() => schema.setFocusAction({}), [
    schema,
  ])

  return React.useMemo(() => ({ setFocus, clearFocus, schema }), [
    clearFocus,
    schema,
    setFocus,
  ])
}

export const focusMiddleware: Middleware = ({ dispatch }) => next => action => {
  // Handle setFocusAction as a connected-react-router push action
  if (isActionOf(setFocusAction, action)) {
    const { attrs, namespace } = action.payload
    dispatch(push(mergeQueryParams(window.location, attrs, namespace)))
  }

  // other things care about setFocusAction, so propagate it
  return next(action)
}
