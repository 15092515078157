import { LocationCargoCompatibility } from 'src/service-design/shared/models/location-cargo-compatibility'
import { LVTrip } from 'src/service-design/shared/models/lv-trip'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Timezone } from 'src/service-design/shared/models/timezone'
import { Duration } from 'src/service-design/shared/utils/dates'

class Location extends Mapper {
  id: string
  timezoneId: string
  name: string
  code: string
  attachDetachCost: number
  _dwellAllowanceSecs: Duration
  maxOccupyingTrains: number
  maxTrainLength: number
  _postArrivalSecs: Duration
  _preDepartureSecs: Duration
  _provisionSecs: Duration
  _serviceAttachSecs: Duration
  _serviceDetachSecs: Duration
  outOfNetwork: boolean
  yard: boolean
  canTurnLocos: boolean
  canChangeLocos: boolean
  allowRemoteRest: boolean
  remoteRestCost: number

  timezone: Timezone
  locationcargocompatibility: LocationCargoCompatibility[]
  lvTrips: LVTrip[]

  /**
   * A Location in the rail operators network.
   *
   * Related models:
   * - `Corridor`;
   * - `LVTrip`;
   * - `Timezone`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the location.
   * @param {string} code - An abbreviation for the location.
   * @param {boolean} yard - A yard is a location where trains can be
   *  'modified'. This includes attaching / detaching of:
   *  - locos
   *  - wagons
   *  - demands
   * Typicaly, yards will have considerable more infrastructure to support
   * shunting. A non-yard could be nothing more than a marker on a stretch of
   * track.
   *
   * CAUTION: This flag was retro fitted into the tool and doesn't sit super
   * with some of the features within the tool. At a glance, a number of
   * parameters below seem to only make sense when applied to yards.

   * CAUTION: Be aware however that some trains do originate and terminate at
   * non-yards and in the tool this effective overrides this flags for the
   * purposes of that train.
   * @param {string} timezoneId - The timezone associated with the location.
   * @param {number} attachDetachCost - A penalty used by swiss to discourage
   *  the attachment/detachment of locos from train moving through this
   *  location.
   * @param {number} dwellAllowanceSecs - Used by pluto along (with corridor
   *  transist times) to generate candidate train schedules.
   * @param {number} maxOccupyingTrains - Used for conflict detection. How many
   *  train can be dwelling at a location before we say they are in conflict.
   *  Very loosely, you can think of this number representing the size of the
   *  siding at this location.
   * @param {number} maxTrainLength - Used for conflict detection. If a train
   *  exceeds this length then it can't fit 'within' the location without its
   *  tail hanging out on the incoming corridor.
   * @param {number} postArrivalSecs - The time (s) a train's post-arrival takes
   *  at this location. Loosely, the amount of time from the moment a enters a
   *  locations 'Yard Limit' to it coming to a complete stop.
   * @param {number} preDepartureSecs - The time (s) a train's pre-departure
   *  takes at this location. Very loosely, the amount of it takes a stationary
   *  train at this location to hit the 'Yard Limit'.
   * @param {number} serviceAttachSecs - The time (s) it takes perform a shunt
   *  which attaches a demand (aka Service) to a a train at this location.
   * @param {number} serviceDetachSecs - The time (s) it takes perform a shunt
   *  which detaches a demand (aka Service) to a a train at this location.
   * @param {boolean} outOfNetwork - If true, time resources (in particular
   *  Lococlasses) spend at this location are completely unaccounted for.
   *
   *  This hack was introduced with ForeignRailroads. Typically there will be a
   *  one to one mapping between the number of outOfNetwork locations in the
   *  rail operator's network and the ForeignRailroads. You can think of each
   *  foreign railroad having their network collapsed to a single point
   *  represented by this  outOfNetwork location. Since ForeignRailroads can use
   *  locos but we have no visibility how the usage is unaccounted.
   * @param {boolean} canTurnLocos - Used by pluto to determine how many locos
   *  need assigned to trains. Trains terminating at places that can't turn
   *  locos will require at least two.
   * @param {boolean} canChangeLocos - Can a train going through this location
   *  change locos here? Should only be true for yards.
   * @param {boolean} allowRemoteRest - Can a crew RemoteRest at this location?
   * @param {number} remoteRestCost - The one-off cost of a remote rest at this
   *  location.
   */
  constructor({
    id,
    name,
    code,
    yard,
    timezoneId,
    attachDetachCost,
    dwellAllowanceSecs,
    maxOccupyingTrains,
    maxTrainLength,
    postArrivalSecs,
    preDepartureSecs,
    provisionSecs,
    serviceAttachSecs,
    serviceDetachSecs,
    outOfNetwork,
    canTurnLocos,
    canChangeLocos,
    allowRemoteRest,
    remoteRestCost,
  }: {
    id: string
    timezoneId: string
    name: string
    code: string
    attachDetachCost: number
    dwellAllowanceSecs: Duration
    maxOccupyingTrains: number
    maxTrainLength: number
    postArrivalSecs: Duration
    preDepartureSecs: Duration
    provisionSecs: Duration
    serviceAttachSecs: Duration
    serviceDetachSecs: Duration
    outOfNetwork: boolean
    yard: boolean
    canTurnLocos: boolean
    canChangeLocos: boolean
    allowRemoteRest: boolean
    remoteRestCost: number
  }) {
    super()
    this.id = id
    this.name = name
    this.code = code
    this.yard = yard
    this.timezoneId = timezoneId
    this.attachDetachCost = attachDetachCost
    this._dwellAllowanceSecs = dwellAllowanceSecs
    this.maxOccupyingTrains = maxOccupyingTrains
    this.maxTrainLength = maxTrainLength
    this._postArrivalSecs = postArrivalSecs
    this._preDepartureSecs = preDepartureSecs
    this._provisionSecs = provisionSecs
    this._serviceAttachSecs = serviceAttachSecs
    this._serviceDetachSecs = serviceDetachSecs
    this.outOfNetwork = outOfNetwork
    this.canTurnLocos = canTurnLocos
    this.canChangeLocos = canChangeLocos
    this.allowRemoteRest = allowRemoteRest
    this.remoteRestCost = remoteRestCost
  }

  setRels({
    timezone,
    locationcargocompatibility = [],
    lvTrips1 = [],
    lvTrips2 = [],
  }: {
    timezone: Timezone
    locationcargocompatibility: LocationCargoCompatibility[]
    lvTrips1: LVTrip[]
    lvTrips2: LVTrip[]
  }) {
    this.timezone = timezone
    this.locationcargocompatibility = locationcargocompatibility
    this.lvTrips = [...lvTrips1, ...lvTrips2]
  }

  lvTripTo(destination: Location) {
    if (destination.id === this.id) {
      return undefined
    }

    return this.lvTrips.find(
      lvTrip => lvTrip.loc1 === destination || lvTrip.loc2 === destination,
    )
  }

  // DateTime valueObject Adapters VVV

  get dwellAllowanceSecs(): number {
    return this._dwellAllowanceSecs.toSeconds()
  }

  get postArrivalSecs(): number {
    return this._postArrivalSecs.toSeconds()
  }

  get preDepartureSecs(): number {
    return this._preDepartureSecs.toSeconds()
  }

  get provisionSecs(): number {
    return this._provisionSecs.toSeconds()
  }

  get serviceAttachSecs(): number {
    return this._serviceAttachSecs.toSeconds()
  }

  get serviceDetachSecs(): number {
    return this._serviceDetachSecs.toSeconds()
  }

  // DateTime valueObject Adapters ^^^
}

export { Location }
