export const downloadCSV = (fileData: {
  filename: string
  mime: string
  contents: BlobPart
}) => {
  const blob = new Blob(
    [fileData.contents], // eslint-disable-line no-undef
    { type: fileData.mime },
  )
  const url = URL.createObjectURL(blob) // eslint-disable-line no-undef
  const link = document.createElement('a')
  if (typeof link.download === 'string') {
    document.body.appendChild(link)
    link.download = fileData.filename
    link.href = url
    link.click()
    document.body.removeChild(link)
  }
}

export function entries<T extends {}, P extends keyof T>(obj: T): [P, T[P]][] {
  return Object.entries(obj) as [P, T[P]][]
}
