import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  name: string
  bgColor: string
}

class ForeignRailroad extends Mapper {
  /**
   * A ForeignRailroad represents another rail operator connected to our rail
   * network who we can shared resources with. Also see Lococlass.
   *
   * NOTE: Typically, if you have a ForeignRailroad you will also have a
   * Location which is marked out of network. Usually, this will represent the
   * source of Foreign Lococlasses within the network.
   *
   * Related models:
   * - `Location`;
   * - `Lococlass`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the foreign railroad.
   * @param {string} bgColor - A color associated with the foreign railroad use
   *  for display purposes only.
   */
  constructor({ id, name, bgColor }: Attrs) {
    super()
    this.id = id
    this.name = name
    this.bgColor = bgColor
  }
}

interface ForeignRailroad extends Attrs {}
export { ForeignRailroad }
