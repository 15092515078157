import { AccessGroupRepoReg } from 'src/service-design/shared/models/access-group'
import { BusinessGroupRepoReg } from 'src/service-design/shared/models/business-group'
import { CargoTypeRepoReg } from 'src/service-design/shared/models/cargo-type'
import { LocationChangeoverLockRepoReg } from 'src/service-design/shared/models/changeover-lock/location'
import { TrainChangeoverLockRepoReg } from 'src/service-design/shared/models/changeover-lock/train'
import { LocationChangeoverRepoReg } from 'src/service-design/shared/models/changeover/location'
import { TrainChangeoverRepoReg } from 'src/service-design/shared/models/changeover/train'
import { CompoundShiftRepoReg } from 'src/service-design/shared/models/compound-shift'
import { CorridorRepoReg } from 'src/service-design/shared/models/corridor'
import { CrewPoolRepoReg } from 'src/service-design/shared/models/crew-pool'
import { CrewTypeRepoReg } from 'src/service-design/shared/models/crew-type'
import { CustomTaskRepoReg } from 'src/service-design/shared/models/custom-task'
import { EmptyWagonEventRepoReg } from 'src/service-design/shared/models/empty-wagon-event'
import { ForeignRailroadRepoReg } from 'src/service-design/shared/models/foreign-railroad'
import { RosterHeadRepoReg } from 'src/service-design/shared/models/linked-list-head/roster'
import { LoadCategoryRepoReg } from 'src/service-design/shared/models/load-category'
import { LoadTableRepoReg } from 'src/service-design/shared/models/load-table'
import { LoadingWorkSplitRepoReg } from 'src/service-design/shared/models/loading-work-split'
import { LocationRepoReg } from 'src/service-design/shared/models/location'
import { LocationCargoCompatibilityRepoReg } from 'src/service-design/shared/models/location-cargo-compatibility'
import { LocoEventRepoReg } from 'src/service-design/shared/models/loco-event'
import { LococlassRepoReg } from 'src/service-design/shared/models/lococlass'
import { LococlassAccessRightRepoReg } from 'src/service-design/shared/models/lococlass-access-right'
import { LVTripRepoReg } from 'src/service-design/shared/models/lv-trip'
import { RDORepoReg } from 'src/service-design/shared/models/rdo'
import { RemoteRestRepoReg } from 'src/service-design/shared/models/remote-rest'
import { ResourceAvailabilityRepoReg } from 'src/service-design/shared/models/resource-availability'
import { ResourceUnavailabilityRepoReg } from 'src/service-design/shared/models/resource-unavailability'
import { RosterLineRepoReg } from 'src/service-design/shared/models/roster-line'
import { RouteKnowledgeRepoReg } from 'src/service-design/shared/models/route-knowledge'
import { ServiceRepoReg } from 'src/service-design/shared/models/service'
import { ServiceEventRepoReg } from 'src/service-design/shared/models/service-event'
import { ServiceLockRepoReg } from 'src/service-design/shared/models/service-lock'
import { ShiftRepoReg } from 'src/service-design/shared/models/shift'
import { CustomTaskAssignmentRepoReg } from 'src/service-design/shared/models/shift-assignment/custom-task'
import { DriverAssignmentRepoReg } from 'src/service-design/shared/models/shift-assignment/driver'
import { LoadingAssignmentRepoReg } from 'src/service-design/shared/models/shift-assignment/loading'
import { LocalAssignmentRepoReg } from 'src/service-design/shared/models/shift-assignment/local'
import { UncrewedLegTaskRepoReg } from 'src/service-design/shared/models/shift-assignment/uncrewed-leg-task'
import { ShiftLineRepoReg } from 'src/service-design/shared/models/shift-line'
import { StartLegRepoReg } from 'src/service-design/shared/models/start-leg'
import { StartLegTaskOffsetRepoReg } from 'src/service-design/shared/models/start-leg-task-offset'
import { TemplateLegRepoReg } from 'src/service-design/shared/models/template-leg'
import { TimezoneRepoReg } from 'src/service-design/shared/models/timezone'
import { TrainBlockRepoReg } from 'src/service-design/shared/models/train-block'
import { TrainGraphRepoReg } from 'src/service-design/shared/models/train-graph'
import { TrainGraphLocationRepoReg } from 'src/service-design/shared/models/train-graph-location'
import { TrainStartRepoReg } from 'src/service-design/shared/models/train-start'
import { TrainTemplateRepoReg } from 'src/service-design/shared/models/train-template'
import { TrainTypeRepoReg } from 'src/service-design/shared/models/train-type'
import { TransitTimesRepoReg } from 'src/service-design/shared/models/transit-times'
import { WagonRepoReg } from 'src/service-design/shared/models/wagon'
import { WagonAccessRightRepoReg } from 'src/service-design/shared/models/wagon-access-right'
import { WagonAllocationRepoReg } from 'src/service-design/shared/models/wagon-allocation'
import { WagonCargoCompatibilityRepoReg } from 'src/service-design/shared/models/wagon-cargo-compatibility'
import { WagonSetRepoReg } from 'src/service-design/shared/models/wagon-set'
import { WorkingLocoLockRepoReg } from 'src/service-design/shared/models/working-loco-lock'
import { YardBlockRepoReg } from 'src/service-design/shared/models/yard-block'
import { YardBlockAssignmentRepoReg } from 'src/service-design/shared/models/yard-block-assignment'

import { Registry } from './Registry'

export interface IRegistry
  extends AccessGroupRepoReg,
    BusinessGroupRepoReg,
    CargoTypeRepoReg,
    CompoundShiftRepoReg,
    CorridorRepoReg,
    CrewPoolRepoReg,
    CrewTypeRepoReg,
    CustomTaskAssignmentRepoReg,
    CustomTaskRepoReg,
    DriverAssignmentRepoReg,
    EmptyWagonEventRepoReg,
    ForeignRailroadRepoReg,
    LoadCategoryRepoReg,
    LoadingAssignmentRepoReg,
    LoadingWorkSplitRepoReg,
    LoadTableRepoReg,
    LocalAssignmentRepoReg,
    LocationCargoCompatibilityRepoReg,
    LocationChangeoverLockRepoReg,
    LocationChangeoverRepoReg,
    LocationRepoReg,
    LococlassAccessRightRepoReg,
    LococlassRepoReg,
    LocoEventRepoReg,
    LVTripRepoReg,
    RDORepoReg,
    RemoteRestRepoReg,
    ResourceAvailabilityRepoReg,
    ResourceUnavailabilityRepoReg,
    RosterHeadRepoReg,
    RosterLineRepoReg,
    RouteKnowledgeRepoReg,
    ServiceEventRepoReg,
    ServiceLockRepoReg,
    ServiceRepoReg,
    ShiftLineRepoReg,
    ShiftRepoReg,
    StartLegRepoReg,
    StartLegTaskOffsetRepoReg,
    TemplateLegRepoReg,
    TimezoneRepoReg,
    TrainBlockRepoReg,
    TrainChangeoverLockRepoReg,
    TrainChangeoverRepoReg,
    TrainGraphLocationRepoReg,
    TrainGraphRepoReg,
    TrainStartRepoReg,
    TrainTemplateRepoReg,
    TrainTypeRepoReg,
    TransitTimesRepoReg,
    UncrewedLegTaskRepoReg,
    WagonAccessRightRepoReg,
    WagonAllocationRepoReg,
    WagonCargoCompatibilityRepoReg,
    WagonRepoReg,
    WagonSetRepoReg,
    WorkingLocoLockRepoReg,
    YardBlockAssignmentRepoReg,
    YardBlockRepoReg {}

export const registry = new Registry<IRegistry>()
