import { createSelector } from 'reselect'

import * as constants from 'src/service-design/sd-plan/constants'
import { BatchState } from 'src/service-design/sd-plan/reducers/batch'
import { TonnageEqualityTypes } from 'src/service-design/sd-plan/reducers/filters'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getTrainFilter,
  filterItems,
  oneOf,
  FilterFn,
} from 'src/service-design/sd-plan/selectors/filters'
import { getWarningRepo } from 'src/service-design/sd-plan/selectors/warnings'
import * as Service from 'src/service-design/shared/models/service'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import * as TrainTemplate from 'src/service-design/shared/models/train-template'
import { WarningRepo } from 'src/service-design/shared/warnings'

import {
  getRawTrainStarts,
  getRawTrainTemplates,
  getRawTemplateLegs,
  getRawStartLegs,
} from './raw'

export {
  getRawTemplateLegs,
  getRawTrainStarts,
  getRawTrainTemplates,
  getRawStartLegs,
}

export const getRawResourceAvailabilities = (state: any) =>
  getCollection(state, 'service-design', 'resourceavailabilities')
export const getRawResourceUnavailabilities = (state: any) =>
  getCollection(state, 'service-design', 'resourceunavailabilities')

export const getRawTrainBlocks = (state: any) =>
  getCollection(state, 'service-design', 'trainblocks')

export const getBatchState = (state: any): BatchState => state.batch
export const getBatchIds = createSelector(
  getBatchState,
  ({ ids }): string[] => ids,
)

export const TONNAGE_EQUALITY_BEHAVIOUR = {
  LIGHTEST_LEG_GREATER_THAN: (
    train: TrainStart.TrainStart,
    tonnage: number,
  ): boolean => train.lightestLeg && train.lightestLeg.tonnage > tonnage,
  LIGHTEST_LEG_LESS_THAN: (
    train: TrainStart.TrainStart,
    tonnage: number,
  ): boolean => !train.lightestLeg || train.lightestLeg.tonnage <= tonnage,
  HEAVIEST_LEG_GREATER_THAN: (
    train: TrainStart.TrainStart,
    tonnage: number,
  ): boolean => train.heaviestLeg && train.heaviestLeg.tonnage > tonnage,
  HEAVIEST_LEG_LESS_THAN: (
    train: TrainStart.TrainStart,
    tonnage: number,
  ): boolean => !train.heaviestLeg || train.heaviestLeg.tonnage <= tonnage,
}

export const matchesTrainTypeId = (
  train: TrainTemplate.TrainTemplate,
  { typeId }: { typeId?: string },
): boolean => !typeId || train.typeId === typeId

export const matchesBusinessGroupId = (
  train: TrainTemplate.TrainTemplate,
  { businessGroupId }: { businessGroupId?: string },
): boolean => !businessGroupId || train.businessGroupId === businessGroupId
export const matchesOneOfWarningTypes = (
  warningRepo: WarningRepo<any, any>,
) => (
  train: TrainTemplate.TrainTemplate,
  { warningTypes }: { warningTypes?: string },
): boolean =>
  oneOf(
    new Set(
      warningRepo.byEntity(TrainTemplate.TrainTemplate, train).map(w => w.type),
    ),
    warningTypes,
  )
export const matchesOneOfLockTypes = (
  train: TrainTemplate.TrainTemplate,
  { lockTypes }: { lockTypes?: string },
): boolean => oneOf(train.lockTypes, lockTypes)
export const checksTonnageEquality = (
  train: TrainStart.TrainStart,
  {
    tonnageEquality,
    tonnage,
  }: {
    tonnageEquality?: TonnageEqualityTypes
    tonnage?: number
  },
) =>
  !tonnageEquality ||
  TONNAGE_EQUALITY_BEHAVIOUR[tonnageEquality](train, tonnage)

export function matchTemplateCheckTonnageEquality(
  template: TrainTemplate.TrainTemplate,
  filterOptions: {
    tonnageEquality?: TonnageEqualityTypes
    tonnage?: number
  },
) {
  return (
    !filterOptions.tonnageEquality ||
    template.starts.some((start: TrainStart.TrainStart): boolean =>
      checksTonnageEquality(start, filterOptions),
    )
  )
}

const trainTemplateFilter: (
  warningRepo: WarningRepo<any, any>,
) => FilterFn<
  TrainTemplate.TrainTemplate,
  {
    typeId?: string
    businessGroupId?: string
    warningTypes?: string
    lockTypes?: string
    tonnageEquality?: TonnageEqualityTypes
    tonnage?: number
  }
>[] = (warningRepo: WarningRepo<any, any>) => [
  matchesTrainTypeId,
  matchesBusinessGroupId,
  matchesOneOfWarningTypes(warningRepo),
  matchesOneOfLockTypes,
  matchTemplateCheckTonnageEquality,
]

export const getTrainTotal = createSelector(
  TrainStart.values,
  scheduled => scheduled.length,
)

export const getTrainTemplateTotal = createSelector(
  TrainTemplate.values,
  templates => templates.length,
)

export const getTrainLegs = createSelector(StartLeg.values, allLegs => {
  const trainLegs = allLegs.reduce<Map<string, StartLeg.StartLeg[]>>(
    (acc, leg) => {
      if (!acc.has(leg.startId)) {
        acc.set(leg.startId, [])
      }
      acc.get(leg.startId).push(leg)
      return acc
    },
    new Map(),
  )
  trainLegs.forEach(legs =>
    legs.sort((leg1, leg2) => leg1.legNum - leg2.legNum),
  )
  return trainLegs
})

export const getScheduledTrainOptions = createSelector(
  TrainStart.values,
  trains =>
    [
      {
        key: constants.ALL_TRAINS,
        text: constants.ALL_TRAINS,
        value: constants.ALL_TRAINS,
      },
    ].concat(
      trains.map(t => ({
        key: t.id,
        text: t.name,
        value: t.id,
      })),
    ),
)

export const getTrainStartsByID = (state: any) =>
  new Map(Object.entries(TrainStart.selector(state).idMap))

export const getFilteredTrainTemplates = createSelector(
  getTrainFilter,
  TrainTemplate.values,
  getWarningRepo,
  (filterOptions, templates, warningRepo): TrainTemplate.TrainTemplate[] =>
    filterItems(filterOptions, templates, trainTemplateFilter(warningRepo)),
)

export const getFilteredTrainStarts = createSelector(
  getFilteredTrainTemplates,
  templates => templates.flatMap(t => t.starts),
)

export const getTrainTemplatesBatch = createSelector(
  TrainTemplate.values,
  getBatchIds,
  (templates, batchIds) =>
    templates.filter(template => batchIds.includes(template.id)),
)

export const getServicesBatch = createSelector(
  Service.values,
  getBatchIds,
  (services, batchIds) =>
    services.filter(service => batchIds.includes(service.id)),
)

export const getStartLegsByBatch = createSelector(
  getBatchIds,
  StartLeg.values,
  (batchIds, legs) => legs.filter(leg => batchIds.includes(leg.templateId)),
)

export const getStartLegsMap = createSelector(
  getRawStartLegs,
  startlegs =>
    new Map(
      startlegs.map(({ id, startId, templateLegId }) => [
        id,
        { startId, templateLegId },
      ]),
    ),
)
