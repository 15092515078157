import { Mapper } from 'src/service-design/shared/models/mapper'
import { Duration } from 'src/service-design/shared/utils/dates'

class Timezone extends Mapper {
  id: string
  name: string
  abbreviation: string
  _offset: Duration

  /**
   * WARNING: You should probably just act as if this class does not exist.
   * Arguably it should be ripped from the code base as it. It was ported from
   * the old PN LocoRoster but since so many features have been added an removed
   * since then (for a clients who don't care about timezones) the functionality
   * around timezones is likely to be completely busted. Any code that claims
   * to use timezones or UTC is likely to be wrong or at the very least naive in
   * its implementation.
   **/
  constructor({
    id,
    name,
    abbreviation,
    offset,
  }: {
    id: string
    name: string
    abbreviation: string
    offset: Duration
  }) {
    super()
    this.id = id
    this.name = name
    this.abbreviation = abbreviation
    this._offset = offset
  }

  // DateTime valueObject Adapters VVV

  get offset(): number {
    return this._offset.toSeconds()
  }

  // DateTime valueObject Adapters ^^^
}

export { Timezone }
