import { createSelector, Selector } from 'reselect'

import {
  getScenarioData,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'
import * as Location from 'src/service-design/shared/models/location'
import { LocationSummary } from 'src/service-design/shared/models/location-summaries'
import { ResourceSummary } from 'src/service-design/shared/models/resource-summaries'
import {
  IResource,
  IResourceProfiler,
  ResourceProfile,
} from 'src/service-design/shared/models/resource-summaries/types'
import * as Service from 'src/service-design/shared/models/service'
import * as Shift from 'src/service-design/shared/models/shift'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import * as Wagon from 'src/service-design/shared/models/wagon'
import { getLocoClassesWithForeign } from 'src/service-design/shared/selectors'

export const generateLocationWork = (
  locations: Location.Location[],
  ...resourceProfiler: IResourceProfiler[][]
): {
  location: Location.Location
  resourceProfiles: ResourceProfile[]
}[] =>
  locations.map(location => ({
    location,
    resourceProfiles: resourceProfiler
      .flat()
      .flatMap((c: IResourceProfiler) => c.getResourceProfiles(location)),
  }))

export const getLocationSummaries = createSelector(
  Location.selector,
  TrainStart.values,
  Shift.values,
  (locations, trains, shifts) =>
    LocationSummary.buildCollection(
      new Map(Object.entries(locations.idMap)),
      generateLocationWork([...locations.values], trains, shifts),
    ),
)

export const getLVs = createSelector(getScenarioData, scenario => [
  scenario.singletons.lv,
])

interface AsyncResourceSummary {
  id: string
  fleetTotal: number
  rosteredTotal: number
  unavailable: number
  spare: number
  horsepowerHours: number
}

export const getResources = createSelector(
  Wagon.values,
  getLocoClassesWithForeign,
  getLVs,
  (...args) => args.flat(),
)

export const getResourceConsumers = createSelector(
  StartLeg.values,
  Service.values,
  Shift.values,
  (...args) => args.flat(),
)

export const getResourceSummaries = createSelector(
  getResources,
  getLocationSummaries,
  getResourceConsumers,
  ResourceSummary.buildCollection,
)

const resourceSummaryFactory = (
  resourceSelector: Selector<RootState, IResource[]>,
) =>
  createSelector(
    resourceSelector,
    getResourceSummaries,
    (resources: IResource[], resourceSummaries: ResourceSummary[]) =>
      resources.map(resource =>
        resourceSummaries.find(
          resourceSummary => resourceSummary.resource === resource,
        ),
      ),
  )

export const getLococlassSummaries = resourceSummaryFactory(
  getLocoClassesWithForeign,
)

export const getWagonSummaries = resourceSummaryFactory(Wagon.values)

export const getLVSummaries = resourceSummaryFactory(getLVs)

export const getCalcAsyncResourceSummaries = createSelector(
  getResourceSummaries,
  (summaries): AsyncResourceSummary[] =>
    summaries.map(r => ({
      id: r.id,
      fleetTotal: r.fleetTotal,
      rosteredTotal: r.rosteredTotal,
      spare: r.spare,
      horsepowerHours: r.horsepowerHours,
      unavailable: r.unavailable,
    })),
)
