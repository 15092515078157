import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Wagon } from './model'

export interface IWagonRepo extends IRepo<Wagon> {}

export class WagonRepo implements IWagonRepo {
  values: Wagon[]
  idMap: { [key: string]: Wagon }

  constructor(values: Wagon[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
  }

  byId(id: string): Wagon {
    return this.idMap[id]
  }
}
