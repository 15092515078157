import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { UncrewedLegTask } from './model'

export const uncrewedLegTaskFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.uncrewedlegtasks.map(data => new UncrewedLegTask(data)),
  setRelsFromSpec((uncrewedLegTask: UncrewedLegTask, state: any) => ({
    start: registry
      .provide('trainStartRepo')(state)
      .byId(uncrewedLegTask.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(uncrewedLegTask.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${uncrewedLegTask.startId}:${uncrewedLegTask.templateLegId}`),
  })),
)
