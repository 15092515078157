import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { ResourceUnavailability } from './model'

export interface IResourceUnavailabilityRepo
  extends IRepo<ResourceUnavailability> {
  byAvailabilityId(id: string): ResourceUnavailability[]
}

export class ResourceUnavailabilityRepo implements IResourceUnavailabilityRepo {
  values: ResourceUnavailability[]
  idMap: { [key: string]: ResourceUnavailability }
  availabilityIdMap: Indexer<ResourceUnavailability>

  constructor(values: ResourceUnavailability[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.availabilityIdMap = new Indexer(values, x => x.availabilityId)
  }

  byId(id: string): ResourceUnavailability {
    return this.idMap[id]
  }

  byAvailabilityId(availabilityId: string): ResourceUnavailability[] {
    return this.availabilityIdMap.lookup(availabilityId)
  }
}
