import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { Wagon } from './model'

export const wagonFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.wagons.map(data => new Wagon(data)),
  setRelsFromSpec((t: Wagon, state: any) => ({
    compatibilities: registry
      .provide('wagonCargoCompatibilityRepo')(state)
      .byWagonId(t.id),
    accessRights: registry
      .provide('wagonAccessRightRepo')(state)
      .byWagonId(t.id),
    availability: registry
      .provide('resourceAvailabilityRepo')(state)
      .byWagonId(t.id),
  })),
)
