import { set } from 'lodash'

import { toLocalValue } from 'src/service-design/shared/utils/flags'

// TODO: Train templates flag is now a standard feature.
// The flag and all code controlled by the flag should be excised together.

const FEATURE_FLAG_NAMES = [
  ['languageChange', 'REACT_APP_LANG_CHANGE'],
  ['defaultLanguage', 'REACT_APP_LANG_DEFAULT'],
  ['languageOptions.en', 'REACT_APP_LANG_EN'],
  ['languageOptions.en-AU', 'REACT_APP_LANG_EN_AU'],
  ['languageOptions.es-MX', 'REACT_APP_LANG_ES_MX'],
  ['scheduleImporter', 'REACT_APP_SCHEDULE_IMPORTING'],
  ['blocks', 'REACT_APP_BLOCKS'],
  ['crewing', 'REACT_APP_CREWING'],
  ['auditLog', 'REACT_APP_AUDIT_LOG'],
  ['freightServices', 'REACT_APP_FREIGHT_SERVICES'],
  ['dummyApiEndpoints', 'REACT_APP_DUMMY_API_ENDPOINTS'],
  ['remoteRests', 'REACT_APP_REMOTE_RESTS'],
  ['returnEmpties', 'REACT_APP_RETURN_EMPTIES'],
]

const envFlags = {}
for (const [key, envKey] of FEATURE_FLAG_NAMES) {
  const val = process.env[envKey]
  if (val !== undefined) {
    if (val === '0' || val === '1') {
      set(envFlags, key, val === '1')
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Value ${val} is not valid for env ${envKey}`)
    }
  }
}

const targetEnvFlag = process.env.REACT_APP_TARGET_ENV

const defaultFlags = {
  languageChange: true,
  defaultLanguage: false,
  languageOptions: {
    en: false,
    'en-AU': true,
    'es-MX': false,
  },
  auditLog: true,
  dummyApiEndpoints: process.env.NODE_ENV === 'development',
  remoteRests: false,

  scheduleImporter: targetEnvFlag === 'KCS',
  freightServices: targetEnvFlag === 'TR',
  blocks: targetEnvFlag === 'KCS',
  crewing: targetEnvFlag === 'TR',
  fleetPlanExport: targetEnvFlag === 'KCS',

  // Following are service-design flags dependent on targetEnv:
  trainGraph: targetEnvFlag === 'TR',
  wagonFleet: targetEnvFlag === 'TR',
  // TODO: Consider removing after service design entrypoints are finished
  emptyWagons: targetEnvFlag === 'TR',
  extraTonnage: targetEnvFlag === 'KCS',
  returnEmpties: false,
} as const

const flags = {
  ...defaultFlags,
  ...envFlags, // Apply overrides if set
} as const

const engineAliases = {
  ceto: process.env.REACT_APP_CETO_ALIAS || 'ceto',
  pallas: process.env.REACT_APP_PALLAS_ALIAS || 'pallas',
  swiss: process.env.REACT_APP_SWISS_ALIAS || 'swiss',
  pluto: process.env.REACT_APP_PLUTO_ALIAS || 'pluto',
  sedna: process.env.REACT_APP_SEDNA_ALIAS || 'sedna',
} as const

const engineFlags = {
  engineSwiss: true,
  engineCeto: targetEnvFlag === 'TR',
  engineSedna: targetEnvFlag === 'TR',
  enginePallas: targetEnvFlag === 'TR',
  enginePluto: targetEnvFlag === 'TR',
  engineSwissReview: targetEnvFlag === 'KCS',
} as const

const serviceDesignFlags = {
  conflicts: targetEnvFlag === 'TR',
  engineAliases,
  ...engineFlags,
  ...flags,
} as const

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  Object.keys(serviceDesignFlags).forEach(flag => {
    const localValue = localStorage.getItem(flag)
    if (['true', 'false'].includes(localValue)) {
      // @ts-ignore mutate the flags in local development
      serviceDesignFlags[flag] = toLocalValue(localValue as 'true' | 'false')
    }
  })
}

export { flags, serviceDesignFlags }
