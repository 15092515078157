import { push } from 'connected-react-router'

export interface QueryParams {
  [key: string]: string
}

export const locationTo = (
  { pathname, search }: { pathname: string; search: string },
  {
    pathname: newPathname = undefined,
    params: newParams = {},
  }: { pathname?: string; params?: QueryParams },
  mergeParams: boolean,
) => {
  let updatedParams: QueryParams
  if (mergeParams) {
    const params = new URLSearchParams(search)
    const oldParams = [...params.entries()].reduce<QueryParams>(
      (acc, [key, value]) => {
        acc[key] = value
        return acc
      },
      {} as QueryParams,
    )
    updatedParams = { ...oldParams, ...newParams }
  } else {
    updatedParams = newParams
  }

  const updated = Object.keys(updatedParams).reduce<URLSearchParams>(
    (acc, key) => {
      acc.set(key, updatedParams[key])
      return acc
    },
    new URLSearchParams(),
  )

  const updatedSearch = updated.toString()
  const updatedLocation = {
    pathname: newPathname === undefined ? pathname : newPathname,
    ...(updatedSearch.length ? { search: `?${updatedSearch}` } : {}),
  }
  return updatedLocation
}

export const locationSet = (
  to: { pathname?: string; params?: QueryParams },
  mergeParams: boolean = true,
) => (dispatch: any, getState: any) => {
  dispatch(push(locationTo(getState().router.location, to, mergeParams)))
}
