import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Lococlass } from './model'

export interface ILococlassRepo extends IRepo<Lococlass> {
  byLoadCategory(id: string): Lococlass[]
}

export class LococlassRepo implements ILococlassRepo {
  values: Lococlass[]
  idMap: { [key: string]: Lococlass }
  loadCategoryMap: Indexer<Lococlass>

  constructor(values: Lococlass[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.loadCategoryMap = new Indexer(values, x => x.loadCategory)
  }

  byId(id: string): Lococlass {
    return this.idMap[id]
  }

  byLoadCategory(loadCategory: string): Lococlass[] {
    return this.loadCategoryMap.lookup(loadCategory)
  }
}
