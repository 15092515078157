import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { Corridor } from 'src/service-design/shared/models/corridor'
import { LoadCategory } from 'src/service-design/shared/models/load-category'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { TrainTemplate } from 'src/service-design/shared/models/train-template'
import { TrainType } from 'src/service-design/shared/models/train-type'
import {
  toUtc,
  normalize,
  Delta,
  EpochTime,
} from 'src/service-design/shared/utils/dates'

export abstract class BaseLeg extends Mapper {
  /**
   * This mixin has limited value and we should consider just rolling
   * its behaviour back into the StartLeg / TemplateLeg
   */
  static entityName = 'Leg'

  entityName = BaseLeg.entityName

  abstract get template(): TrainTemplate
  abstract get corridor(): Corridor
  abstract get forward(): boolean
  abstract get arrivesDelta(): Delta
  abstract get arrivesLocal(): EpochTime
  abstract get departsLocal(): EpochTime
  abstract get prev(): any
  abstract get next(): any

  get trainType(): TrainType {
    return this.template.type
  }

  get businessGroupId(): string {
    return this.template.businessGroupId
  }

  get accessGroup(): AccessGroup {
    return this.corridor.accessGroup
  }

  get origin(): Location {
    return this.forward ? this.corridor.loc1 : this.corridor.loc2
  }

  get dest(): Location {
    return this.forward ? this.corridor.loc2 : this.corridor.loc1
  }

  get originating(): boolean {
    return !this.prev
  }

  get terminating(): boolean {
    return !this.next
  }

  loadCategoryPower(category: LoadCategory): number {
    const entry = this.corridor.loadtables.find(
      l => l.trainType === this.trainType && l.loadCategoryObj === category,
    )
    const type = this.forward ? 'forwardTonnage' : 'reverseTonnage'
    return entry ? entry[type] : null
  }

  get departsLocalNormalized(): number {
    return normalize(this.departsLocal.toSeconds())
  }

  get arrivesLocalNormalized(): number {
    return normalize(this.arrivesLocal.toSeconds())
  }

  get departsUtc(): number {
    return toUtc(this.departsLocal.toSeconds(), this.origin.timezone.offset)
  }

  get arrivesUtc(): number {
    return toUtc(this.arrivesLocal.toSeconds(), this.dest.timezone.offset)
  }

  get transitTime(): number {
    return this.arrivesDelta.toSeconds()
  }

  get headwaySecs(): number {
    return this.template.type.headwaySecs
  }

  get clearanceSecs(): number {
    return this.template.type.clearanceSecs
  }
}
