import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { CyclicTime } from 'src/service-design/shared/utils/dates'

import { Shift } from './model'

export const shiftFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.shifts.map(
      ({ signOnLocal, signOffLocal, ...data }) =>
        new Shift({
          signOnLocal: CyclicTime.fromSeconds(signOnLocal),
          signOffLocal: CyclicTime.fromSeconds(signOffLocal),
          ...data,
        }),
    ),
  setRelsFromSpec((shift: Shift, state: any) => ({
    pool: registry.provide('crewPoolRepo')(state).byId(shift.poolId),
    driverAssignments: registry
      .provide('driverAssignmentRepo')(state)
      .byShiftId(shift.id),
    localAssignments: registry
      .provide('localAssignmentRepo')(state)
      .byShiftId(shift.id),
    customTaskAssignments: registry
      .provide('customTaskAssignmentRepo')(state)
      .byShiftId(shift.id),
    loadingAssignments: registry
      .provide('loadingAssignmentRepo')(state)
      .byShiftId(shift.id),
    shiftLine: registry.provide('shiftLineRepo')(state).byShiftId(shift.id),
    // Shifts start at the end of a remote rest, and end at the start of a remote rest
    startAtRemoteRest: registry
      .provide('remoteRestRepo')(state)
      .byNextShiftId(shift.id),
    endAtRemoteRest: registry
      .provide('remoteRestRepo')(state)
      .byStartShiftId(shift.id),
    compoundShift: registry
      .provide('compoundShiftRepo')(state)
      .byShiftId(shift.id),
    singletons: state.collectionData.singletons,
  })),
)
