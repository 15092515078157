import { createSelector } from 'reselect'

import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import { getBatchIds } from 'src/service-design/sd-plan/selectors/trains'
import * as TrainStart from 'src/service-design/shared/models/train-start'

export const getRawLocoEvents = (state: any) =>
  getCollection(state, 'service-design', 'locoevents')

export const getScheduledLocoEventsByBatch = createSelector(
  getBatchIds,
  TrainStart.values,
  (batchIds, trainStarts) =>
    trainStarts
      .filter(train => batchIds.includes(train.templateId))
      .flatMap(trainStart => trainStart.legs.flatMap(leg => leg.locoevents)),
)
