import { groupBy, sum } from 'lodash'

import * as constants from 'src/service-design/shared/constants'
import {
  StartOfWeek,
  EndOfWeek,
  Event,
} from 'src/service-design/shared/models/dwell-resource-profile'
import { Location } from 'src/service-design/shared/models/location'
import {
  ResourceProfile,
  IResource,
} from 'src/service-design/shared/models/resource-summaries/types'
import { sumCounts, minCounts } from 'src/service-design/shared/utils/math'

type BalanceMap = Map<IResource, number>
type BalanceDefintion = {
  localOffset: number
  balance: BalanceMap
  delta: Map<IResource, number>
  event: Event
}

export type ResourceBalance = {
  resource: IResource
  balances: { localTime: number; quantity: number }[]
}

export class LocationSummary {
  location: Location
  resourceProfiles: ResourceProfile[]
  _relativeBalances: BalanceDefintion[]
  _balances: BalanceDefintion[] = null
  _finalBalances: {
    localOffset: number
    balance: BalanceMap
  }[] = null

  _imbalances: BalanceMap = null

  /**
   * A LocationSummary describes how resources sit idle in a specific Location.
   * Given a Location and its ResourceProfiles an instance of this class
   * utilizes the profile deltas to determine how resources come and go which
   * can used to determine resource idle time and underpin the YardChart.
   *
   * @param location {Location}: The location the LocationSummary is for
   * @param resourceProfiles {ResourceProfile[]}: The resource profiles for the
   *  location.
   */
  constructor({
    location,
    resourceProfiles,
  }: {
    location: Location
    resourceProfiles: ResourceProfile[]
  }) {
    this.location = location
    this.resourceProfiles = resourceProfiles
  }

  get locationId(): string {
    return this.location.id
  }

  get outOfNetwork(): boolean {
    return this.location.outOfNetwork
  }

  get min(): number {
    return Math.min(...this.balanceValues)
  }

  get max(): number {
    return Math.max(...this.balanceValues)
  }

  get resourceSecs(): Map<IResource, number> {
    const balPairs = this.finalBalances
      .slice(0, -1)
      .map((bal, idx) => [bal, this.finalBalances[idx + 1]])
    return balPairs
      .map(
        pair =>
          new Map(
            [...pair[0].balance.keys()].map(resource => [
              resource,
              (pair[1].localOffset - pair[0].localOffset) *
                pair[0].balance.get(resource),
            ]),
          ),
      )
      .reduce((tot, secsMap) => sumCounts(tot, secsMap))
  }

  static buildCollection(
    locations: Map<string, Location>,
    resourceProfiles: {
      location: Location
      resourceProfiles: ResourceProfile[]
    }[],
  ): LocationSummary[] {
    return [...locations.values()].map(location =>
      LocationSummary.build(
        location,
        resourceProfiles.find(l => l.location.id === location.id),
      ),
    )
  }

  static build(
    location: Location,
    { resourceProfiles }: { resourceProfiles: ResourceProfile[] },
  ): LocationSummary {
    return new LocationSummary({
      location,
      resourceProfiles,
    })
  }

  get resources(): IResource[] {
    return [
      ...new Set<IResource>(
        this.resourceProfiles.flatMap(item => item.resources),
      ).values(),
    ]
  }

  get allDeltas(): {
    offset: number
    delta: Map<IResource, number>
    event: Event
  }[] {
    return this.resourceProfiles
      .flatMap(lw => lw.deltas)
      .sort((d1, d2) => {
        if (d1.offset !== d2.offset) {
          return d1.offset - d2.offset
        }
        // If things happen at the same time, make sure incoming resources come
        // first so that summing deltas in order has no instantaneous peaks
        return sum([...d2.delta.values()]) - sum([...d1.delta.values()])
      })
  }

  get relativeBalances(): BalanceDefintion[] {
    if (!this._relativeBalances) {
      const { imbalances, relativeBalances } = this.computeRelativeBalances()
      this._imbalances = imbalances
      this._relativeBalances = relativeBalances
    }
    return this._relativeBalances
  }

  get balanceValues(): number[] {
    return this.finalBalances.flatMap(b => [...b.balance.values()])
  }

  get resourceBalances(): ResourceBalance[] {
    return this.resources.map(l => ({
      resource: l,
      balances: this.finalBalances.map(b => ({
        localTime: b.localOffset,
        quantity: b.balance.get(l),
      })),
    }))
  }

  get balances(): BalanceDefintion[] {
    if (!this._balances) {
      this._balances = this.computeBalances()
    }
    return this._balances
  }

  get finalBalances() {
    // When two events occur at precisely the same time we don't want to see little
    // 'blips' on the yard chart. For example: the release of a loco at the exact time a loco
    // is acquired should result in a tick up and down, but instead we just show no change.
    if (!this._finalBalances) {
      // [BOSS-932]: We don't want the start of week to ever
      // be stripped out or it can end up looking like there's
      // a resource imbalance. Note: We don't have to worry about
      // the end of week because anything happening at SECONDS_PER_WEEK
      // should be normalised to 0 (ie the start of week).
      const [startOfWeek, ...rest] = this.balances.map(
        ({ localOffset, balance }) => ({
          localOffset,
          balance,
        }),
      )
      const grouped = groupBy(rest, x => x.localOffset)
      const stripped = Object.values(grouped)
        .map(balances => balances.slice(-1)[0])
        .sort((b1, b2) => b1.localOffset - b2.localOffset)
      this._finalBalances = [startOfWeek, ...stripped]
    }
    return this._finalBalances
  }

  get imbalances() {
    if (!this._relativeBalances) {
      const { imbalances, relativeBalances } = this.computeRelativeBalances()
      this._imbalances = imbalances
      this._relativeBalances = relativeBalances
    }
    return this._imbalances
  }

  computeRelativeBalances(): {
    relativeBalances: BalanceDefintion[]
    imbalances: BalanceMap
  } {
    const relativeBalances = []

    let prevBalance = {
      localOffset: 0,
      balance: new Map(this.resources.map(l => [l, 0])),
      delta: new Map(this.resources.map(l => [l, 0])),
      event: new StartOfWeek(),
    }

    relativeBalances.push(prevBalance)
    this.allDeltas.forEach(({ offset, delta, event }) => {
      prevBalance = {
        localOffset: offset,
        balance: sumCounts(prevBalance.balance, delta),
        delta,
        event,
      }
      relativeBalances.push(prevBalance)
    })

    const imbalances = prevBalance.balance

    relativeBalances.push({
      localOffset: constants.SECONDS_PER_WEEK,
      balance: new Map(imbalances),
      delta: new Map(this.resources.map(l => [l, 0])),
      event: new EndOfWeek(),
    })

    return {
      relativeBalances,
      imbalances: new Map([...imbalances.entries()].filter(e => e[1] !== 0)),
    }
  }

  computeBalances() {
    const lows = this.relativeBalances.reduce<Map<IResource, number>>(
      (rv, relative) => minCounts<IResource>(rv, relative.balance),
      new Map(),
    )

    // We want to normalize the demand graphs so the short fall (if any)
    // is represented by a negative balance at the location.
    // In all other cases, include the case where there is a postive
    // imbalance at week end we want to push the graph up so that its
    // lowest  point is zero.
    const adjustment = new Map(
      [...lows.entries()].map(([loco, low]) => [
        loco,
        this.imbalances.get(loco) < 0 ? this.imbalances.get(loco) - low : -low,
      ]),
    )

    return this.relativeBalances.map(relative => ({
      ...relative,
      balance: sumCounts(relative.balance, adjustment),
    }))
  }

  get resourceEvents() {
    return this.balances.flatMap(bal => {
      const { localOffset, event, delta } = bal
      return Array.from(bal.balance.entries()).map(([resource, val]) => ({
        localOffset,
        event,
        resource,
        delta: delta.get(resource),
        balance: val,
        locationSummary: this,
      }))
    })
  }
}
