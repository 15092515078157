import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { trainChangeoverFactory } from './factory'
import { ITrainChangeoverRepo, TrainChangeoverRepo } from './repo'

const trainChangeoverRepo = defaultMemoize(
  (input: FactoryInput) =>
    new TrainChangeoverRepo(trainChangeoverFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  trainChangeoverRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type TrainChangeoverRepoReg = {
  trainChangeoverRepo: FromDocumentFactory<FactoryInput, ITrainChangeoverRepo>
}

export function setup<R extends TrainChangeoverRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('trainChangeoverRepo', trainChangeoverRepo)

  relationships.addConstraints('trainchangeovers', {
    unique: [
      ['templateLegAId', 'startAId', 'atDeparture'],
      ['templateLegBId', 'startBId', 'atDeparture'],
    ],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startAId',
        name: 'startA',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegAId',
        name: 'templateLegA',
      },
      {
        collection: 'trainstarts',
        foreign: 'startBId',
        name: 'startB',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegBId',
        name: 'templateLegB',
      },
    ],
  })
}

export { TrainChangeover } from './model'
