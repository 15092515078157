import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { wagonAllocationFactory } from './factory'
import { IWagonAllocationRepo, WagonAllocationRepo } from './repo'

const wagonAllocationRepo = defaultMemoize(
  (input: FactoryInput) =>
    new WagonAllocationRepo(wagonAllocationFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  wagonAllocationRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type WagonAllocationRepoReg = {
  wagonAllocationRepo: FromDocumentFactory<FactoryInput, IWagonAllocationRepo>
}

export function setup<R extends WagonAllocationRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('wagonAllocationRepo', wagonAllocationRepo)

  relationships.addConstraints('wagonallocations', {
    unique: [['wagonsetId', 'wagonId']],
    rels: [
      {
        collection: 'wagonsets',
        foreign: 'wagonsetId',
        name: 'wagonset',
      },
      {
        collection: 'wagons',
        foreign: 'wagonId',
        name: 'wagon',
      },
    ],
  })
}

export { WagonAllocation } from './model'
