import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LoadingAssignment } from './model'

export interface ILoadingAssignmentRepo extends IRepo<LoadingAssignment> {
  byStartId(id: string): LoadingAssignment[]
  byShiftId(id: string): LoadingAssignment[]
  byTemplateLegId(id: string): LoadingAssignment[]
  byStartLegId(id: string): LoadingAssignment[]
  byCargoTypeId(id: string): LoadingAssignment[]
}

export class LoadingAssignmentRepo implements ILoadingAssignmentRepo {
  values: LoadingAssignment[]
  idMap: { [key: string]: LoadingAssignment }
  shiftIdMap: Indexer<LoadingAssignment>
  startIdMap: Indexer<LoadingAssignment>
  startLegIdMap: Indexer<LoadingAssignment>
  templateLegIdMap: Indexer<LoadingAssignment>
  cargoTypeIdMap: Indexer<LoadingAssignment>

  constructor(values: LoadingAssignment[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.shiftIdMap = new Indexer(values, x => x.shiftId)
    this.startIdMap = new Indexer(values, x => x.startId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.cargoTypeIdMap = new Indexer(values, x => x.cargoTypeId)
  }

  byId(id: string): LoadingAssignment {
    return this.idMap[id]
  }

  byStartId(id: string): LoadingAssignment[] {
    return this.startIdMap.lookup(id)
  }

  byTemplateLegId(id: string): LoadingAssignment[] {
    return this.templateLegIdMap.lookup(id)
  }

  byStartLegId(id: string): LoadingAssignment[] {
    return this.startLegIdMap.lookup(id)
  }

  byShiftId(id: string): LoadingAssignment[] {
    return this.shiftIdMap.lookup(id)
  }

  byCargoTypeId(id: string): LoadingAssignment[] {
    return this.cargoTypeIdMap.lookup(id)
  }
}
