import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { ServiceLock } from './model'

export const serviceLockFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.servicelocks.map(data => new ServiceLock(data)),
  setRelsFromSpec((serviceLock: ServiceLock, state: any) => ({
    service: registry.provide('serviceRepo')(state).byId(serviceLock.serviceId),
  })),
)
