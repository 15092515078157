import { createSelector } from 'reselect'

import { ServiceAttrs } from 'src/service-design/sd-plan/document/types'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getServiceFilter,
  filterItems,
  FilterFn,
} from 'src/service-design/sd-plan/selectors/filters'
import * as Service from 'src/service-design/shared/models/service'

export const matchesCustomerName = (
  service: Service.Service,
  { customer }: { customer?: string },
): boolean => !customer || service.customer === customer
export const matchesCargoType = (
  service: Service.Service,
  { cargoTypeId }: { cargoTypeId?: string },
): boolean => !cargoTypeId || service.cargoTypeId === cargoTypeId
export const matchesOrigin = (
  service: Service.Service,
  { originId }: { originId?: string },
): boolean => !originId || service.originId === originId
export const matchesDestination = (
  service: Service.Service,
  { destinationId }: { destinationId?: string },
): boolean => !destinationId || service.destinationId === destinationId

const SERVICE_FILTERS: FilterFn<
  Service.Service,
  {
    customer?: string
    cargoTypeId?: string
    originId?: string
    destinationId?: string
  }
>[] = [matchesCustomerName, matchesCargoType, matchesOrigin, matchesDestination]

export const getRawServices = (state: any): ServiceAttrs[] =>
  getCollection(state, 'service-design', 'services')

export const getServiceTotal = createSelector(
  getRawServices,
  services => services.length,
)

export const getServiceRouteName = (serviceId: string) =>
  createSelector(getRawServices, services => {
    const { name } = services.find(x => x.id === serviceId)
    return `all route assignments for ${name}`
  })

export const getAssignment = (
  state: any,
  serviceId: string,
  assignmentNum: number,
) =>
  Service.selector(state)
    .byId(serviceId)
    .assignments.find(a => a.assignmentNum === assignmentNum)

export const getFilteredServices = createSelector(
  getServiceFilter,
  Service.values,
  (filter, services): Service.Service[] =>
    filterItems(filter, services, SERVICE_FILTERS),
)
