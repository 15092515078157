import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { foreignRailroadFactory } from './factory'
import { IForeignRailroadRepo, ForeignRailroadRepo } from './repo'

const foreignRailroadRepo = defaultMemoize(
  (input: FactoryInput) =>
    new ForeignRailroadRepo(foreignRailroadFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  foreignRailroadRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type ForeignRailroadRepoReg = {
  foreignRailroadRepo: FromDocumentFactory<FactoryInput, IForeignRailroadRepo>
}

export function setup<R extends ForeignRailroadRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('foreignRailroadRepo', foreignRailroadRepo)
}

export { ForeignRailroad } from './model'
