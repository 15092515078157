import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { ShiftLine } from './model'

export interface IShiftLineRepo extends IRepo<ShiftLine> {
  byShiftId(id: string): ShiftLine
  byRosterLineId(id: string): ShiftLine[]
}

export class ShiftLineRepo implements IShiftLineRepo {
  values: ShiftLine[]
  idMap: { [key: string]: ShiftLine }
  shiftIdMap: { [key: string]: ShiftLine }
  rosterLineIdMap: Indexer<ShiftLine>

  constructor(values: ShiftLine[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.shiftIdMap = Object.fromEntries(values.map(v => [v.shiftId, v]))
    this.rosterLineIdMap = new Indexer(values, x => x.rosterLineId)
  }

  byId(id: string): ShiftLine {
    return this.idMap[id]
  }

  byShiftId(id: string): ShiftLine {
    return this.shiftIdMap[id]
  }

  byRosterLineId(id: string): ShiftLine[] {
    return this.rosterLineIdMap.lookup(id)
  }
}
