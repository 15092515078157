import React from 'react'
import { Label } from 'semantic-ui-react'
import './index.css'

const defaultLabelColor = 'rgba(0,0,0,.6)'

interface ColoredLabelProps {
  entity?: {
    bgColor?: string
    name: string
  }
  icon?: string
  onRemove?: (e: React.SyntheticEvent) => void
}

export const ColoredLabel: React.FC<ColoredLabelProps> = ({
  entity = null,
  ...props
}) => (
  <Label
    className="label-entity"
    style={{
      background: entity ? entity.bgColor : '',
      color: entity ? defaultLabelColor : 'red',
    }}
    {...(!entity ? { icon: 'broken chain' } : { content: entity.name })}
    {...props}
  />
)
