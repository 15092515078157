import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Duration } from 'src/service-design/shared/utils/dates'

class LVTrip extends Mapper {
  id: string
  loc1Id: string
  loc2Id: string
  distanceKMs: number
  _durationSecs: Duration
  loc1: Location
  loc2: Location

  /**
   * LV trips are used by crew to reposition them independently of trains.
   * An LVTrip is a journey that can be taken between two locations within an LV.
   *
   * An LV is short for light vechile. A light vechile is an automobile (avoiding
   * the term car here as this can be confused with wagons in certain regions).
   *
   * Related models:
   * - `Location`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} loc1Id - The id of the location at one end of the trip.
   * @param {string} loc2Id - The id of the location at the other end of the
   *  trip.
   * @param {string} distanceKMs - The distance (km) of the trip.
   * @param {number} durationSecs - The duration (s) of the trip.
   **/
  constructor({
    id,
    loc1Id,
    loc2Id,
    distanceKMs,
    durationSecs,
  }: {
    id: string
    loc1Id: string
    loc2Id: string
    distanceKMs: number
    durationSecs: Duration
  }) {
    super()
    this.id = id
    this.loc1Id = loc1Id
    this.loc2Id = loc2Id
    this.distanceKMs = distanceKMs
    this._durationSecs = durationSecs
  }

  setRels({ loc1, loc2 }: { loc1: Location; loc2: Location }) {
    this.loc1 = loc1
    this.loc2 = loc2
  }

  /**
   * @deprecated
   */
  get durationSecs(): number {
    return this._durationSecs.toSeconds()
  }

  get durationSecsVO(): Duration {
    return this._durationSecs
  }
}

export { LVTrip }
