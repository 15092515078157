export class Mapper {
  /**
   * A base class for our core models. Ripe for culling as it adds no real
   * value.
   */
  static construct(obj: object) {
    const mapper = new (this as any)(obj)
    mapper.setRels(obj)
    return mapper
  }

  setRels(obj: object) {
    Object.assign(this, obj)
  }

  applyDefaults() {
    for (const key of Object.keys(this)) {
      // @ts-ignore really need to kill this function
      if (this[key] === undefined) {
        // @ts-ignore really need to kill this function
        this[key] = (this.constructor as any).defaults[key]
      }
    }
  }
}
