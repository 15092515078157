import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { shiftFactory } from './factory'
import { IShiftRepo, ShiftRepo } from './repo'

const shiftRepo = defaultMemoize(
  (input: FactoryInput) => new ShiftRepo(shiftFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  shiftRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type ShiftRepoReg = {
  shiftRepo: FromDocumentFactory<FactoryInput, IShiftRepo>
}

export function setup<R extends ShiftRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('shiftRepo', shiftRepo)

  relationships.addConstraints('shifts', {
    rels: [
      {
        collection: 'crewpools',
        foreign: 'poolId',
        name: 'pool',
      },
    ],
  })
}

export { Shift } from './model'
