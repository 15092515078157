import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { Location } from './model'

export const locationFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.locations.map(
      ({
        dwellAllowanceSecs,
        postArrivalSecs,
        preDepartureSecs,
        provisionSecs,
        serviceAttachSecs,
        serviceDetachSecs,
        ...data
      }) =>
        new Location({
          dwellAllowanceSecs: Duration.fromSeconds(dwellAllowanceSecs),
          postArrivalSecs: Duration.fromSeconds(postArrivalSecs),
          preDepartureSecs: Duration.fromSeconds(preDepartureSecs),
          provisionSecs: Duration.fromSeconds(provisionSecs),
          serviceAttachSecs: Duration.fromSeconds(serviceAttachSecs),
          serviceDetachSecs: Duration.fromSeconds(serviceDetachSecs),
          ...data,
        }),
    ),
  setRelsFromSpec((location: Location, state: any) => ({
    timezone: registry.provide('timezoneRepo')(state).byId(location.timezoneId),
    locationcargocompatibility: registry
      .provide('locationCargoCompatibilityRepo')(state)
      .byLocationId(location.id),
    lvTrips1: registry.provide('lvTripRepo')(state).byLoc1Id(location.id),
    lvTrips2: registry.provide('lvTripRepo')(state).byLoc2Id(location.id),
  })),
)
