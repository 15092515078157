import { Corridor } from 'src/service-design/shared/models/corridor'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { TrainType } from 'src/service-design/shared/models/train-type'
import { Duration } from 'src/service-design/shared/utils/dates'

class TransitTimes extends Mapper {
  id: string
  corridorId: string
  trainTypeId: string
  _forwardDuration: Duration
  _reverseDuration: Duration
  _extraForwardDuration: Duration
  _extraReverseDuration: Duration
  corridor: Corridor
  trainType: TrainType

  /**
   * A TransitTimes describes how long it will take a train of a particular
   * type to traverse a corridor.
   *
   * Related models:
   * - `Corridor`;
   * - `TrainType`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} corridorId - The id of the Corridor.
   * @param {string} trainTypeId - The id of the TrainType.
   * @param {number} forwardDuration -  The normal duration (s) of train from
   * corridor.loc1 to corridor.loc2.
   * @param {number} reverseDuration -  The normal duration (s) of train from
   * corridor.loc2 to corridor.loc1.
   * @param {number} extraForwardDuration - An extra duration (s) to be applied
   * to the corridor on top of the forwardDuration. The main use case for this
   * would be speed restrictions.
   * @param {number} extraReverseDuration - An extra duration (s) to be applied
   * to the corridor on top of the reverseDuration. The main use case for this
   * would be speed restrictions.
   */

  constructor({
    id,
    corridorId,
    trainTypeId,
    forwardDuration,
    reverseDuration,
    extraForwardDuration,
    extraReverseDuration,
  }: {
    id: string
    corridorId: string
    trainTypeId: string
    forwardDuration: Duration
    reverseDuration: Duration
    extraForwardDuration: Duration
    extraReverseDuration: Duration
  }) {
    super()
    this.id = id
    this.corridorId = corridorId
    this.trainTypeId = trainTypeId
    this._forwardDuration = forwardDuration
    this._reverseDuration = reverseDuration
    this._extraForwardDuration = extraForwardDuration
    this._extraReverseDuration = extraReverseDuration
  }

  setRels({
    corridor,
    trainType,
  }: {
    corridor: Corridor
    trainType: TrainType
  }) {
    this.corridor = corridor
    this.trainType = trainType
  }

  get forwardTransitTime(): number {
    return this.forwardDuration + this.extraForwardDuration
  }

  get reverseTransitTime(): number {
    return this.reverseDuration + this.extraReverseDuration
  }

  // DateTime valueObject Adapters VVV

  get forwardDuration(): number {
    return this._forwardDuration.toSeconds()
  }

  get reverseDuration(): number {
    return this._reverseDuration.toSeconds()
  }

  get extraForwardDuration(): number {
    return this._extraForwardDuration.toSeconds()
  }

  get extraReverseDuration(): number {
    return this._extraReverseDuration.toSeconds()
  }

  // DateTime valueObject Adapters ^^^
}

export { TransitTimes }
