import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LoadingWorkSplit } from './model'

export interface ILoadingWorkSplitRepo extends IRepo<LoadingWorkSplit> {
  byStartId(id: string): LoadingWorkSplit[]
  byTemplateLegId(id: string): LoadingWorkSplit[]
  byStartLegId(id: string): LoadingWorkSplit[]
}

export class LoadingWorkSplitRepo implements ILoadingWorkSplitRepo {
  values: LoadingWorkSplit[]
  idMap: { [key: string]: LoadingWorkSplit }
  templateLegIdMap: Indexer<LoadingWorkSplit>
  startIdMap: Indexer<LoadingWorkSplit>
  startLegIdMap: Indexer<LoadingWorkSplit>

  constructor(values: LoadingWorkSplit[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
  }

  byId(id: string): LoadingWorkSplit {
    return this.idMap[id]
  }

  byStartId(startId: string): LoadingWorkSplit[] {
    return this.startIdMap.lookup(startId)
  }

  byTemplateLegId(templateLegId: string): LoadingWorkSplit[] {
    return this.templateLegIdMap.lookup(templateLegId)
  }

  byStartLegId(startLegId: string): LoadingWorkSplit[] {
    return this.startLegIdMap.lookup(startLegId)
  }
}
