import {
  ShiftAssignment,
  Attrs as BaseAttrs,
  Rels as BaseRels,
} from 'src/service-design/shared/models/shift-assignment/base'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'

interface Attrs {
  startId: string
  templateLegId: string
  kind: 'attach' | 'detach' | 'pre-departure' | 'loco-provisioning'
}

interface Rels {
  start: TrainStart
  startLeg: StartLeg
  templateLeg: TemplateLeg
}

class LocalAssignment extends ShiftAssignment {
  static collection = 'localassignments'

  /**
   * A LocalAssignment represents an assignment of a LegTask to a Shift.
   *
   *
   * TERMINOLOGY: Within the UI 'Attach', 'Detach' and 'Pre-Departure' are
   * refered to as 'Yard Tasks'. This terminology was arrived at very late.
   * Previously, we had been referring to this work as 'Local Tasks'. The term
   * Local Task was born out of our work with PN where they refer to work that
   * ROs have to do within a yard as Local Work. There is a very strong argument
   * for renaming LocalAssignment => YardAssignment however we'd would have to
   * dance around the fact the LoadingAssignments also occur within yards...
   * Ideally we could increase the alignment between Loading and Yard tasks
   * and use common UI and code to refer to them both however (at least at the
   * time of writing this) that would be a non-trivial amount of work.
   *
   * Related models:
   * - `CrewPool`;
   * - `Shift`;
   * - `RDO`;
   * - `RosterHead`;
   * - `RosterLine`;
   * - `Location`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} typeId - The id of the CrewType this pool represents.
   **/
  // TODO: Rather than trying to reuse the base attrs and rels
  // we should just be explicit here and list everything that is
  // required
  constructor({ startId, templateLegId, kind, ...rest }: Attrs & BaseAttrs) {
    super(rest)
    this.startId = startId
    this.templateLegId = templateLegId
    this.kind = kind
  }

  setRels({ start, startLeg, templateLeg, ...rest }: Rels & BaseRels) {
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
    super.setRels(rest)
  }

  get name() {
    return `${this.start.name} - ${this.task.origin.code} ${this.kind}`
  }

  get task() {
    return this.startLeg.getTask(this.kind)
  }

  get taskId() {
    return this.task.id
  }

  get startLocation() {
    return this.task.origin
  }

  get endLocation() {
    return this.task.destination
  }
}

interface LocalAssignment extends Attrs, Rels {}
export { LocalAssignment }
