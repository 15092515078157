import { capitalize } from 'lodash'

import i18n from 'src/i18n'
import { Location } from 'src/service-design/shared/models/location'
import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { Task } from 'src/service-design/shared/models/task/base'

export abstract class LegTask<T extends IStartLeg> extends Task {
  // TODO this attribute lies, it's not always a startleg
  startLeg: T
  requiresDriver: boolean
  requiresTravel: boolean

  /**
   * A LegTask is a Task that is associated with a StartLeg. A LegTask
   * may need to be crewed by a Driver or a Rail Operatior.
   *
   * Related models:
   * - Task.
   *
   * @param {StartLeg |  Offset} startLeg: The leg the task is associated with.
   * @param rest: Refer to Task.
   */
  constructor({
    startLeg,
    ...rest
  }: {
    startLeg: T
    origin: Location
    destination: Location
    startTimeLocal: number
    endTimeLocal: number
    offsetLegs?: OffsetLeg[]
  }) {
    super(rest)
    this.startLeg = startLeg
    this.requiresDriver = false
    this.requiresTravel = false
  }

  static generatedId(kind: string, startId: string, templateLegId: string) {
    return `${kind}-${startId}-${templateLegId}`
  }

  get id() {
    return LegTask.generatedId(this.kind, this.startId, this.templateLegId)
  }

  get requiresRailOperator() {
    if (!(this.constructor as any).requiresRailOperator) {
      return false
    }
    return !this.startLeg.uncrewedLegTasks.find(no => no.kind === this.kind)
  }

  get startId() {
    return this.startLeg.startId
  }

  get templateLegId() {
    return this.startLeg.templateLegId
  }

  get name() {
    return `${capitalize(i18n.t(`service-design::${this.kind}`))} - ${
      this.startLeg.name
    }`
  }

  get requiresAssignment() {
    return this.requiresRailOperator && this.duration > 0
  }

  get isAssigned() {
    return Boolean(this.assignment)
  }

  get assignment() {
    return this.startLeg.localAssignments.find(
      la =>
        (this.constructor as any).generatedId(
          la.kind,
          la.startId,
          la.templateLegId,
        ) === this.id,
    )
  }

  get start() {
    return this.startLeg.start
  }

  get timeOffset() {
    return this.startLeg.getTaskTimeOffset(this.kind)
  }
}
