import { groupBy } from 'lodash'

export class Indexer<T> {
  index: { [key: string]: T[] }
  private default: T[] = []

  constructor(values: T[], keyFunc: (x: T) => string) {
    this.index = groupBy(values, keyFunc)
  }

  lookup(key: string): T[] {
    let result = this.index[key]
    if (!result) {
      result = this.default
    }
    return result
  }
}
