import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { RouteKnowledge } from './model'

export const routeKnowledgeFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.routeknowledge.map(data => new RouteKnowledge(data)),
  setRelsFromSpec((routeKnowledge: RouteKnowledge, state: any) => ({
    corridor: registry
      .provide('corridorRepo')(state)
      .byId(routeKnowledge.corridorId),
    crewType: registry
      .provide('crewTypeRepo')(state)
      .byId(routeKnowledge.crewTypeId),
  })),
)
