import { ChangeoverLock } from 'src/service-design/shared/models/changeover-lock/model'
import {
  Changeover,
  Timing,
} from 'src/service-design/shared/models/changeover/base'
import { Location } from 'src/service-design/shared/models/location'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Duration } from 'src/service-design/shared/utils/dates'

class TrainChangeover extends Changeover {
  startAId: string
  templateLegAId: string
  startBId: string
  templateLegBId: string
  startA: TrainStart
  startLegA: StartLeg
  templateLegA: TemplateLeg
  startB: TrainStart
  startLegB: StartLeg
  templateLegB: TemplateLeg

  _timing: Timing

  /**
   * A TrainChangeover represents a Changeover between two drivers on different
   * TrainStarts, say A and B. See Changeover.
   *
   * In order to conduct a changeover both TrainStarts need to be stationary within
   * a location. Roughly, the window for the changeover is:
   *  [
   *    max(postArrivalA.startTimeLocal, postArrivalB.startTimeLocal),
   *    min(preDepartureA.endTimeLocal, preDepartureB.endTimeLocal)
   *  ]
   *
   * If `atDeparture` is true the changeover will take place as late as
   * possible, if false it will happen as early as possible.
   *
   * SIDE NOTE: A two LocationChangeover can be used to model any given
   * TrainChangeover however it will always take twice the time. That said,
   * a TrainChangeover is a slightly stronger condition in that it binds the
   * TrainStarts together and forces them to meet.
   *
   * Related models:
   * - `Changeover`;
   * - `DriverTask`;
   * - `TemplateLeg`;
   * - `TrainStart`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} startAId - The id of TrainStart A.
   * @param {string} templateLegAId - The id of TemplateLeg A.
   * @param {string} startBId - The id of TrainStart B.
   * @param {string} templateLegBId - The id of TemplateLeg B.
   * @param {boolean} atDeparture - See Changeover.
   **/
  constructor({
    id,
    startAId,
    templateLegAId,
    startBId,
    templateLegBId,
    atDeparture,
    timeOffset,
  }: {
    id: string
    startAId: string
    templateLegAId: string
    startBId: string
    templateLegBId: string
    atDeparture: boolean
    timeOffset: Duration
  }) {
    super({ id, atDeparture, timeOffset })
    this.startAId = startAId
    this.templateLegAId = templateLegAId
    this.startBId = startBId
    this.templateLegBId = templateLegBId
  }

  setRels({
    startA,
    startLegA,
    templateLegA,
    startB,
    startLegB,
    templateLegB,
    changeoverLock,
    singletons,
  }: {
    startA: TrainStart
    startLegA: StartLeg
    templateLegA: TemplateLeg
    startB: TrainStart
    startLegB: StartLeg
    templateLegB: TemplateLeg
    changeoverLock: ChangeoverLock<TrainChangeover>
    singletons: any
  }) {
    this.startA = startA
    this.startLegA = startLegA
    this.templateLegA = templateLegA
    this.startB = startB
    this.startLegB = startLegB
    this.templateLegB = templateLegB
    super.setRels({ changeoverLock, singletons })
  }

  legFor(start: TrainStart): StartLeg | null {
    if (this.startA === start) {
      return this.startLegA
    }
    if (this.startB === start) {
      return this.startLegB
    }
    return null
  }

  otherTrain(startId: string): TrainStart {
    if (startId === this.startAId) {
      return this.startB
    }
    return this.startA
  }

  get name(): string {
    return `${this.startA.name} / ${this.startB.name} - Crew change on ${
      this.atDeparture ? 'departure' : 'arrival'
    } `
  }

  get location(): Location {
    return this.startLegA[this.atDeparture ? 'origin' : 'dest']
  }

  protected get timing() {
    if (!this._timing) {
      this._timing = Changeover.timingsFromLegs(
        this.legs,
        this.atDeparture,
        this.singletons.crew.changeoverSecs,
      )
    }
    return this._timing
  }

  get dwellWindow(): { start: number; end: number } {
    return Changeover.dwellWindow(this.legs, this.atDeparture)
  }

  get legs(): StartLeg[] {
    return [this.startLegA, this.startLegB]
  }
}

export { TrainChangeover }
