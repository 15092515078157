import { groupBy } from 'lodash'

import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainChangeover } from './model'

export interface ITrainChangeoverRepo extends IRepo<TrainChangeover> {
  byStartAId(id: string): TrainChangeover[]
  byStartBId(id: string): TrainChangeover[]
  byTemplateLegAId(id: string): TrainChangeover[]
  byTemplateLegBId(id: string): TrainChangeover[]
  byStartLegAId(id: string): TrainChangeover[]
  byStartLegBId(id: string): TrainChangeover[]
}

const EMPTY: TrainChangeover[] = []

export class TrainChangeoverRepo implements ITrainChangeoverRepo {
  values: TrainChangeover[]
  idMap: { [key: string]: TrainChangeover }
  startAIdMap: { [key: string]: TrainChangeover[] }
  startBIdMap: { [key: string]: TrainChangeover[] }
  templateLegAIdMap: { [key: string]: TrainChangeover[] }
  templateLegBIdMap: { [key: string]: TrainChangeover[] }
  startLegAIdMap: { [key: string]: TrainChangeover[] }
  startLegBIdMap: { [key: string]: TrainChangeover[] }

  constructor(values: TrainChangeover[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startAIdMap = groupBy(values, x => x.startAId)
    this.startBIdMap = groupBy(values, x => x.startBId)
    this.templateLegAIdMap = groupBy(values, x => x.templateLegAId)
    this.templateLegBIdMap = groupBy(values, x => x.templateLegBId)
    this.startLegAIdMap = groupBy(
      values,
      ({ startAId, templateLegAId }) => `${startAId}:${templateLegAId}`,
    )
    this.startLegBIdMap = groupBy(
      values,
      ({ startBId, templateLegBId }) => `${startBId}:${templateLegBId}`,
    )
  }

  byId(id: string): TrainChangeover {
    return this.idMap[id]
  }

  byStartAId(startAId: string): TrainChangeover[] {
    return this.startAIdMap[startAId] || EMPTY
  }

  byStartBId(startBId: string): TrainChangeover[] {
    return this.startBIdMap[startBId] || EMPTY
  }

  byTemplateLegAId(templateLegAId: string): TrainChangeover[] {
    return this.templateLegAIdMap[templateLegAId] || EMPTY
  }

  byTemplateLegBId(templateLegBId: string): TrainChangeover[] {
    return this.templateLegBIdMap[templateLegBId] || EMPTY
  }

  byStartLegAId(startLegAId: string): TrainChangeover[] {
    return this.startLegAIdMap[startLegAId] || EMPTY
  }

  byStartLegBId(startLegBId: string): TrainChangeover[] {
    return this.startLegBIdMap[startLegBId] || EMPTY
  }
}
