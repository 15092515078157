import React from 'react'
import styled from 'styled-components'

import { FormErrorLabel } from 'src/core/ui/forms/FormErrorLabel'

const StyledLabel = styled.label`
  height: 2em;
`

type Props = {
  htmlFor: string
  label?: string
  touched?: boolean
  error?: string
  warning?: string
  className?: string
}

export const FormLabel: React.FC<Props> = ({
  htmlFor,
  label,
  touched = false,
  error = null,
  warning = null,
  className = 'form-field-label',
}: Props) => (
  // eslint-disable-next-line jsx-a11y/label-has-for
  <StyledLabel htmlFor={htmlFor} className={className}>
    {label}
    {touched && (
      <FormErrorLabel
        error={error}
        warning={warning}
        pointing={label ? 'left' : 'below'}
      />
    )}
  </StyledLabel>
)
