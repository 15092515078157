import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { LoadingWorkSplit } from './model'

export const loadingWorkSplitFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.loadingworksplits.map(
      ({ primaryTaskDuration, ...data }) =>
        new LoadingWorkSplit({
          primaryTaskDuration: Duration.fromSeconds(primaryTaskDuration),
          ...data,
        }),
    ),
  setRelsFromSpec((loadingWorkSplit: LoadingWorkSplit, state: any) => ({
    start: registry
      .provide('trainStartRepo')(state)
      .byId(loadingWorkSplit.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(loadingWorkSplit.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${loadingWorkSplit.startId}:${loadingWorkSplit.templateLegId}`),
  })),
)
