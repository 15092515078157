import React from 'react'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { Menu } from 'semantic-ui-react'

interface ContextMenuCompProps {
  trigger: React.ReactNode
  rows: React.ReactElement[]
  header?: React.ReactNode
  id: string
  onShow?: (event: React.SyntheticEvent) => void
  onHide?: (event: React.SyntheticEvent) => void
}

const ContextMenuComp: React.FC<ContextMenuCompProps> = ({
  trigger,
  header = null,
  rows,
  id,
  onShow = () => {},
  onHide = () => {},
}) => (
  <>
    <ContextMenuTrigger id={id} holdToDisplay={-1}>
      {trigger}
    </ContextMenuTrigger>
    <Menu id={id} vertical as={ContextMenu} onShow={onShow} onHide={onHide}>
      {header && <Menu.Item header>{header}</Menu.Item>}
      {rows.map(row => (
        <MenuItem key={row.key} disabled={row.props.disabled}>
          {row}
        </MenuItem>
      ))}
    </Menu>
  </>
)

type WrappedContextMenuType = React.NamedExoticComponent<
  ContextMenuCompProps
> & { Item?: typeof Menu.Item }
const WrappedContextMenu: WrappedContextMenuType = React.memo(ContextMenuComp)
WrappedContextMenu.Item = Menu.Item

export { ContextMenuComp, WrappedContextMenu as ContextMenu }
