import { createSelector } from 'reselect'

import { getServiceDesignData } from 'src/service-design/sd-plan/selectors/document-graph'
import { getScenarioData } from 'src/service-design/shared/document-factory/factory-input'

export {
  getDocuments,
  getDocument,
  getCollection,
} from 'src/service-design/shared/document/selectors'

export const getPlanId = (state: any): number =>
  state.documents['service-design'].meta.id

export const getParentId = (state: any): number =>
  state.documents.scenario && state.documents.scenario.meta.id

export const getStrippedDocumentData = createSelector(
  getScenarioData,
  getServiceDesignData,
  (scenarioData, serviceDesignData) => ({
    scenario: { data: scenarioData },
    'service-design': { data: serviceDesignData },
  }),
)
