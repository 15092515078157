import { connect } from 'react-redux'

import { ConnectedLink as ConnectedLinkComp } from './component'

const mapStateToProps = (state: any) => ({
  currentLocation: state.router.location,
})

const mapDispatchToProps = () => ({})

export const ConnectedLink = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedLinkComp)
