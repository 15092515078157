import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { RouteKnowledge } from './model'

export interface IRouteKnowledgeRepo extends IRepo<RouteKnowledge> {
  byCorridorId(id: string): RouteKnowledge[]
  byCrewTypeId(id: string): RouteKnowledge[]
}

export class RouteKnowledgeRepo implements IRouteKnowledgeRepo {
  values: RouteKnowledge[]
  idMap: { [key: string]: RouteKnowledge }
  crewTypeIdMap: Indexer<RouteKnowledge>
  corridorIdMap: Indexer<RouteKnowledge>

  constructor(values: RouteKnowledge[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.crewTypeIdMap = new Indexer(values, x => x.crewTypeId)
    this.corridorIdMap = new Indexer(values, x => x.corridorId)
  }

  byId(id: string): RouteKnowledge {
    return this.idMap[id]
  }

  byCorridorId(corridorId: string): RouteKnowledge[] {
    return this.corridorIdMap.lookup(corridorId)
  }

  byCrewTypeId(crewTypeId: string): RouteKnowledge[] {
    return this.crewTypeIdMap.lookup(crewTypeId)
  }
}
