import classnames from 'classnames'
import { isNumber, isBoolean, sortBy as lodashSortBy } from 'lodash'
import React from 'react'
// @ts-ignore
import autobind from 'react-autobind'
import { createSelector } from 'reselect'
import { Icon } from 'semantic-ui-react'

import { KEYCODE_ENTER } from 'src/service-design/shared/constants'

import StyledDiv from './styled'

interface RowType {
  id: string
}

interface Cell<TRow extends RowType> {
  displayName: string
  key: string
  cellDisplay?: (row: TRow) => React.ReactNode
  sortBy?: keyof TRow | ((row1: TRow, row2: TRow) => number)
}

export const sortData = <TRow extends RowType>(
  data: TRow[],
  col: Cell<TRow> | null,
  asc: boolean,
) => {
  let result: TRow[]
  const sortBy = col?.sortBy

  if (sortBy instanceof Function) {
    result = data.sort(sortBy)
  } else if (col) {
    const valFunc = sortBy ? (x: TRow) => x[sortBy] : col.cellDisplay

    if (valFunc) {
      result = lodashSortBy(data, valFunc)
      const allNum = data.every(x => {
        const val = valFunc(x)
        if (!isBoolean(val) && !isNumber(val) && typeof val !== 'string') {
          console.error(
            `Programmer Error: To sort column '${col.key}' it will need a sortBy`,
          )
        }

        return isNumber(val) || isBoolean(val)
      })
      if (allNum) {
        result.reverse()
      }
    }
  }

  if (!result) {
    result = [...data]
  }

  if (asc) {
    return result.reverse()
  }
  return result
}

interface CachedSortProps<TRow extends RowType> {
  // TODO: these types really should have a TRow generic arg
  data: TRow[]
  sortColumn: Cell<TRow> | null
  sortAsc: boolean
}

interface CachedSorter<TRow extends RowType> {
  (props: CachedSortProps<TRow>): TRow[]
}

export interface TableProps<TRow extends RowType> {
  className?: string
  cells: Cell<TRow>[]
  data: TRow[]
  activateItem?: (id: string) => void
  active?: string
  RowActions?: React.ComponentType<{ row: TRow }>
  bodyScrolls?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
}

interface State<TRow extends RowType> {
  sortColumn: Cell<TRow> | null
  sortAsc: boolean
}

/**
 * @deprecated - Use GridTable instead
 */
export class Table<TRow extends RowType> extends React.Component<
  TableProps<TRow>,
  State<TRow>
> {
  public cachedSort: CachedSorter<TRow>
  static displayName = 'Table'
  static defaultProps = {
    bodyScrolls: true,
  }

  constructor(props: TableProps<TRow>) {
    super(props)
    autobind(this)

    this.state = {
      sortColumn: null,
      sortAsc: true,
    }
    this.cachedSort = createSelector(
      p => p.data,
      p => p.sortColumn,
      p => p.sortAsc,
      sortData,
    )
  }

  getSortedData(): TRow[] {
    const { data } = this.props
    const { sortColumn, sortAsc } = this.state
    if (sortColumn) {
      return this.cachedSort({
        data,
        sortColumn,
        sortAsc,
      })
    }
    return data
  }

  handleClickSort(col: Cell<TRow>) {
    const { sortColumn, sortAsc } = this.state
    if (sortColumn !== col) {
      this.setState({
        sortColumn: col,
        sortAsc: true,
      })
    } else {
      this.setState({
        sortColumn: col,
        sortAsc: !sortAsc,
      })
    }
  }

  render() {
    const {
      className,
      cells,
      activateItem,
      active,
      RowActions,
      footer,
      header,
      bodyScrolls,
    } = this.props
    const { sortColumn, sortAsc } = this.state

    const sortedData = this.getSortedData()
    return (
      <StyledDiv
        className={classnames('boss-table', className)}
        bodyScrolls={bodyScrolls}
      >
        {header || (
          <div className="header">
            {cells.map(cell => (
              <div
                className={classnames('cell', cell.key)}
                key={cell.key}
                tabIndex={0}
                onClick={() => this.handleClickSort(cell)}
                onKeyUp={e =>
                  e.keyCode === KEYCODE_ENTER && this.handleClickSort(cell)
                }
                role="button"
              >
                <div className={classnames('header-text')} role="row">
                  {cell.displayName.length ? cell.displayName : ''}
                </div>
                <Icon
                  className={cell !== sortColumn ? 'hidden' : ''}
                  size="large"
                  name={
                    cell === sortColumn && sortAsc ? 'angle up' : 'angle down'
                  }
                  color="black"
                />
              </div>
            ))}
            <div className="controls" />
          </div>
        )}

        <div className="body">
          {sortedData.map(item => (
            <Row
              key={item.id}
              item={item}
              active={active}
              activateItem={activateItem}
              cells={cells}
              rowActions={RowActions}
            />
          ))}
        </div>
        {footer}
      </StyledDiv>
    )
  }
}

interface RowProps<TRow extends RowType> {
  item: TRow
  cells: Cell<TRow>[]
  activateItem?: (id: string) => void
  active?: string
  rowActions?: React.ComponentType<{ row: TRow }>
}

const Row = <TRow extends RowType>({
  item,
  active,
  activateItem,
  cells,
  rowActions: RowActions,
}: RowProps<TRow>) => (
  <div
    className={classnames('row', { active: item.id === active })}
    onClick={() => activateItem && activateItem(item.id)}
    onKeyUp={e =>
      e.keyCode === KEYCODE_ENTER && activateItem && activateItem(item.id)
    }
    role="row"
    tabIndex={activateItem ? 0 : undefined}
  >
    {cells.map(cell => (
      <div
        className={classnames('cell', cell.key)}
        key={`${cell.key}-${item.id}`}
      >
        {cell.cellDisplay && cell.cellDisplay(item)}
      </div>
    ))}
    <div className="controls">
      {item.id === active && RowActions && <RowActions row={item} />}
    </div>
  </div>
)
