import {
  SECONDS_PER_HOUR,
  DAYS_IN_WEEK,
} from 'src/service-design/shared/constants'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { RosterLine } from 'src/service-design/shared/models/roster-line'
import {
  Delta,
  Duration,
  EpochTime,
  DAY_DELTA,
} from 'src/service-design/shared/utils/dates'

const DEFAULT_RDO_DURATION_HOURS = 30
const LONG_RDO_DURATION_HOURS = 35

class RDO extends Mapper {
  id: string
  lineId: string
  day: number
  _delta: Delta
  line: RosterLine

  static DEFAULT_DURATION = Duration.fromSeconds(
    DEFAULT_RDO_DURATION_HOURS * SECONDS_PER_HOUR,
  )

  static LONG_DURATION = Duration.fromSeconds(
    LONG_RDO_DURATION_HOURS * SECONDS_PER_HOUR,
  )

  /**
   * An RDO is a Rostered Day Off.
   * A CrewPool's roster consists of weekly Rosterlines and an RDO occupies
   * a day of a Rosterline. See CrewPool.
   *
   * The days of the week start at 0 Sunday and ends at 6 Saturday.
   *
   * An RDO can be part of a contiguous sequence of RDOs in the same
   * Rosterline.
   * An RDO has getters for the start and end of the sequence of RDOs.
   *
   * Related models:
   * - `RDO`;
   * - `Rosterline`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} lineId - The id of the Rosterline.
   * @param {number} day - The day of the week on which the RDO starts as a number.
   * @param {number} delta - The time of day at which the RDO starts in seconds.
   **/

  constructor({
    id,
    lineId,
    day,
    delta,
  }: {
    id: string
    lineId: string
    day: number
    delta: Delta
  }) {
    super()
    this.id = id
    this.lineId = lineId
    this.day = day
    this._delta = delta
  }

  setRels({ line }: { line: RosterLine }) {
    this.line = line
  }

  /**
   * @deprecated
   */
  get duration(): number {
    return this.durationVO.toSeconds()
  }

  get durationVO(): Duration {
    return !this.deltaVO.equals(Delta.nil)
      ? RDO.LONG_DURATION
      : RDO.DEFAULT_DURATION
  }

  get dayInRoster(): number {
    return this.day + DAYS_IN_WEEK * (this.line.num - 1)
  }

  get sequenceStart(): RDO {
    let current: RDO = this
    while (current.yesterdayRdo) {
      current = current.yesterdayRdo
      if (current === this) {
        // Pathological edge case where roster is nothing but RDOs
        break
      }
    }
    return current
  }

  /**
   * @deprecated
   */
  get sequenceStartTimeLocal(): number {
    return this.sequenceStartTimeLocalVO.toSeconds()
  }

  get sequenceStartTimeLocalVO(): EpochTime {
    return this.sequenceStart.startTimeLocalVO
  }

  get sequenceEnd(): RDO {
    let current: RDO = this
    while (current.tomorrowRdo) {
      current = current.tomorrowRdo
      if (current === this) {
        // Pathological edge case where roster is nothing but RDOs
        break
      }
    }
    return current
  }

  /**
   * @deprecated
   */
  get sequenceEndTimeLocal(): number {
    return this.sequenceEndTimeLocalVO.toSeconds()
  }

  get sequenceEndTimeLocalVO(): EpochTime {
    return this.sequenceEnd.endTimeLocalVO
  }

  get isStartOfMultiDay(): boolean {
    const tomorrowRDO =
      this.day === 6
        ? this.line.next.rdoForDay(0)
        : this.line.rdoForDay(this.day + 1)

    return Boolean(!this.yesterdayRdo && tomorrowRDO)
  }

  get yesterdayRdo(): RDO {
    return this.day === 0
      ? this.line.prev.rdoForDay(6)
      : this.line.rdoForDay(this.day - 1)
  }

  get tomorrowRdo(): RDO {
    return this.day === 6
      ? this.line.next.rdoForDay(0)
      : this.line.rdoForDay(this.day + 1)
  }

  /**
   * @deprecated
   */
  get delta(): number {
    return this.deltaVO.toSeconds()
  }

  get deltaVO(): Delta {
    return this.sequenceStart !== this
      ? this.sequenceStart.deltaVO
      : this._delta
  }

  /**
   * @deprecated
   */
  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return EpochTime.epoch
      .makeLater(DAY_DELTA.multiply(this.day))
      .makeLater(this.deltaVO)
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return this.startTimeLocalVO.makeLater(this.durationVO)
  }

  get weekend(): boolean {
    return Boolean(
      (this.day === 6 && this.line.next.rdoForDay(0)) ||
        (this.day === 0 && this.line.prev.rdoForDay(6)),
    )
  }
}

export { RDO }
