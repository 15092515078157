import { sum } from 'lodash'

import { BaseLeg } from 'src/service-design/shared/models/base-leg'
import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { TrainChangeover } from 'src/service-design/shared/models/changeover'
import { LocationChangeover } from 'src/service-design/shared/models/changeover/location'
import { Consist } from 'src/service-design/shared/models/consist'
import { Corridor } from 'src/service-design/shared/models/corridor'
import { EmptyWagonEvent } from 'src/service-design/shared/models/empty-wagon-event'
import { LoadResourceProfile } from 'src/service-design/shared/models/load-resource-profile'
import { LoadingWorkSplit } from 'src/service-design/shared/models/loading-work-split'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Location } from 'src/service-design/shared/models/location'
import { LocoEvent } from 'src/service-design/shared/models/loco-event'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import {
  IResourceProfiler,
  IResourceConsumer,
} from 'src/service-design/shared/models/resource-summaries/types'
import { Service } from 'src/service-design/shared/models/service'
import { ServiceEvent } from 'src/service-design/shared/models/service-event'
import { LoadingAssignment } from 'src/service-design/shared/models/shift-assignment/loading'
import { LocalAssignment } from 'src/service-design/shared/models/shift-assignment/local'
import { UncrewedLegTask } from 'src/service-design/shared/models/shift-assignment/uncrewed-leg-task'
import { StartLegTaskOffset } from 'src/service-design/shared/models/start-leg-task-offset'
import {
  LEG_ORDERED_ACTIVITIES,
  Loading,
  Unloading,
  LegTask,
} from 'src/service-design/shared/models/task'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { TrainTemplate } from 'src/service-design/shared/models/train-template'
import { Wagon } from 'src/service-design/shared/models/wagon'
import { WagonAllocation } from 'src/service-design/shared/models/wagon-allocation'
import { WorkingLocoLock } from 'src/service-design/shared/models/working-loco-lock'
import {
  Delta,
  EpochTime,
  Duration,
} from 'src/service-design/shared/utils/dates'
import { count } from 'src/service-design/shared/utils/math'

import { IStartLeg } from './istartleg'

interface Attrs {
  id: string
  startId: string
  templateId: string
  templateLegId: string
}

interface Rels {
  start: TrainStart
  template: TrainTemplate
  templateLeg: TemplateLeg
  singletons: any // TODO DJH
  emptywagonevents: EmptyWagonEvent[]
  locoevents: LocoEvent[]
  localAssignments: LocalAssignment[]
  serviceevents: ServiceEvent[]
  locationchangeovers: LocationChangeover[]
  workinglocolock: WorkingLocoLock
  loadingAssignments: LoadingAssignment[]
  loadingWorkSplits: LoadingWorkSplit[]
  uncrewedLegTasks: UncrewedLegTask[]
  taskOffsets: StartLegTaskOffset[]
  _consist?: Consist
}

class StartLeg extends BaseLeg
  implements IStartLeg, IResourceProfiler, IResourceConsumer {
  template: TrainTemplate

  _localTasks: LegTask<StartLeg>[]
  _loadTasks: Loading[]
  _unloadTasks: Unloading[]

  // TODO: Type these VVV
  _throughLocoAllocations: LocoEvent[]
  _detachLocoEvents: any[]
  _throughWagonAllocations: WagonAllocation[]
  _wagonallocations: any[]
  _detachWagonEvents: any[]

  /**
   * A StartLeg instance is derived from a TrainStart, TemplateLeg pair who
   * share an association with a TrainTemplate. See TrainTemplate.
   *
   * Note that since StartLeg are derived, other entities that wish to relate
   * to a StartLeg need to do so via a composite foreign key
   * [startId, templateLegId].
   *
   * Related models:
   * - TrainStart;
   * - TrainTemplate;
   * - TemplateLeg.
   *
   * @param {string} id - The entity id.
   * @param {string} startId - The TrainStart id.
   * @param {string} templateId - The Template id.
   * @param {string} templateLegId - The TemplateLegId
   *
   */

  constructor({ id, startId, templateId, templateLegId }: Attrs) {
    super()
    this.id = id
    this.startId = startId
    this.templateId = templateId
    this.templateLegId = templateLegId
  }

  setRels({
    start,
    template,
    templateLeg,
    singletons,
    emptywagonevents = [],
    locoevents = [],
    localAssignments = [],
    serviceevents = [],
    locationchangeovers = [],
    workinglocolock,
    loadingAssignments = [],
    loadingWorkSplits = [],
    uncrewedLegTasks = [],
    taskOffsets = [],
    _consist,
  }: Rels) {
    this.start = start
    this.template = template
    this.templateLeg = templateLeg
    this.singletons = singletons
    this.emptywagonevents = emptywagonevents
    this.locoevents = locoevents
    this.localAssignments = localAssignments
    this.serviceevents = serviceevents
    this.locationchangeovers = locationchangeovers
    this.workinglocolock = workinglocolock
    this.loadingAssignments = loadingAssignments
    this.loadingWorkSplits = loadingWorkSplits
    this.uncrewedLegTasks = uncrewedLegTasks
    this.taskOffsets = taskOffsets
    this._consist = _consist
  }

  static construct(data: Partial<Attrs & Rels>) {
    const startLeg = new StartLeg(data as any)
    startLeg.setRels(data as any)
    return startLeg
  }

  get name(): string {
    return `${this.start.name} leg ${this.legNum}: ${this.origin.code} - ${this.dest.code}`
  }

  get legNum(): number {
    return this.templateLeg.legNum
  }

  get corridorId(): string {
    return this.templateLeg.corridorId
  }

  get corridor(): Corridor {
    return this.templateLeg.corridor
  }

  get departsDelta(): Delta {
    return this.templateLeg.departsDelta
  }

  get arrivesDelta(): Delta {
    return this.templateLeg.arrivesDelta
  }

  get departsLocal(): EpochTime {
    return EpochTime.epoch
      .makeLater(this.templateLeg.departsDelta)
      .makeLater(this.start.legOffsetLocal(this))
  }

  get arrivesLocal(): EpochTime {
    return this.departsLocal.makeLater(this.templateLeg.arrivesDelta)
  }

  get forward(): boolean {
    return this.templateLeg.forward
  }

  get trainchangeovers(): TrainChangeover[] {
    return this.templateLeg.trainChangeoverA
      .filter(x => x.startAId === this.startId)
      .concat(
        this.templateLeg.trainChangeoverB.filter(
          x => x.startBId === this.startId,
        ),
      )
  }

  get next(): StartLeg {
    const result = this.start.legs[this.legNum]
    // HACK: See BOSS-2781, for now, avoid infinite loops by bailing here
    if (result === this) {
      return null
    }
    return result
  }

  get prev(): StartLeg {
    const result = this.start.legs[this.legNum - 2]
    // HACK: See BOSS-2781, for now, avoid infinite loops by bailing here
    if (result === this) {
      return null
    }
    return result
  }

  get originChangeover(): LocationChangeover | TrainChangeover {
    return this.changeovers.find(
      changeover =>
        changeover.atDeparture && this.origin === changeover.location,
    )
  }

  get destChangeover(): LocationChangeover | TrainChangeover {
    return this.changeovers.find(
      changeover =>
        !changeover.atDeparture && this.dest === changeover.location,
    )
  }

  get transitTime(): number {
    return this.arrivesDelta.toSeconds()
  }

  /**
   * @deprecated
   */
  get postArriveStartLocal(): number {
    return this.postArriveStartLocalVO.toSeconds()
  }

  get postArriveStartLocalVO(): EpochTime {
    return this.arrivesLocal
  }

  /**
   * @deprecated
   */
  get postArriveEndLocal(): number {
    return this.postArriveEndLocalVO.toSeconds()
  }

  get postArriveEndLocalVO(): EpochTime {
    return this.postArriveStartLocalVO.makeLater(this.postArrivalDuration)
  }

  get postArrivalDuration(): Duration {
    return Duration.fromSeconds(
      this.detachCount ||
        this.terminating ||
        this.detachingLocos ||
        this.next.attachCount ||
        this.next.attachingLocos
        ? this.dest.postArrivalSecs
        : 0,
    )
  }

  /**
   * @deprecated
   */
  get detachStartLocal(): number {
    return this.detachStartLocalVO.toSeconds()
  }

  get detachStartLocalVO(): EpochTime {
    const offset = this.getTaskTimeOffset('detach')
    return this.postArriveEndLocalVO.makeLater(Duration.fromSeconds(offset))
  }

  /**
   * @deprecated
   */
  get detachEndLocal(): number {
    return this.detachEndLocalVO.toSeconds()
  }

  get detachEndLocalVO(): EpochTime {
    return this.detachStartLocalVO.makeLater(this.detachDurationVO)
  }

  /**
   * @deprecated
   */
  get detachDuration(): number {
    return this.detachDurationVO.toSeconds()
  }

  get detachDurationVO(): Duration {
    return Duration.fromSeconds(this.dest.serviceDetachSecs).multiply(
      this.detachCount,
    )
  }

  get dwellDuration(): Duration {
    return this.templateLeg.dwellDuration
  }

  /**
   * @deprecated
   */
  get attachStartLocal(): number {
    return this.attachStartLocalVO.toSeconds()
  }

  get attachStartLocalVO(): EpochTime {
    return this.attachEndLocalVO.makeEarlier(this.attachDuration)
  }

  /**
   * @deprecated
   */
  get attachEndLocal(): number {
    return this.attachEndLocalVO.toSeconds()
  }

  get attachEndLocalVO(): EpochTime {
    const offset = this.getTaskTimeOffset('attach')
    return this.preDepartStartLocalVO.makeLater(Duration.fromSeconds(offset))
  }

  get attachDuration(): Duration {
    return Duration.fromSeconds(this.origin.serviceAttachSecs).multiply(
      this.attachCount,
    )
  }

  /**
   * @deprecated
   */
  get preDepartStartLocal(): number {
    return this.preDepartStartLocalVO.toSeconds()
  }

  get preDepartStartLocalVO(): EpochTime {
    return this.preDepartEndLocalVO.makeEarlier(this.preDepartureDurationVO)
  }

  /**
   * @deprecated
   */
  get preDepartEndLocal(): number {
    return this.preDepartEndLocalVO.toSeconds()
  }

  get preDepartEndLocalVO(): EpochTime {
    return this.departsLocal
  }

  /**
   * @deprecated
   */
  get preDepartureDuration(): number {
    return this.preDepartureDurationVO.toSeconds()
  }

  get preDepartureDurationVO(): Duration {
    return Duration.fromSeconds(
      this.attachCount ||
        this.originating ||
        this.attachingLocos ||
        this.prev.detachCount ||
        this.prev.detachingLocos
        ? this.origin.preDepartureSecs
        : 0,
    )
  }

  get provisionStartLocal(): EpochTime {
    return this.detachEndLocalVO
  }

  get provisionEndLocal(): EpochTime {
    return this.provisionStartLocal.makeLater(this.provisionSecs)
  }

  get provisionSecs(): Duration {
    return Duration.fromSeconds(this.terminating ? this.dest.provisionSecs : 0)
  }

  get totalWorkingSecs(): Map<any, number> {
    return new Map<Lococlass | Wagon, number>([
      ...count(this.consist.workingLocos, this.transitTime),
      ...new Map(
        this.wagonallocations.map(a => [
          a.wagon,
          a.quantity * this.transitTime,
        ]),
      ),
    ])
  }

  get totalWorkingKms(): Map<Lococlass, number> {
    return count(this.consist.workingLocos, this.corridor.distance)
  }

  get totalHauledSecs(): Map<Lococlass, number> {
    return count(this.consist.hauledLocos, this.transitTime)
  }

  get totalHauledKms(): Map<Lococlass, number> {
    return count(this.consist.hauledLocos, this.corridor.distance)
  }

  get attachServices(): Service[] {
    return this.serviceevents
      .filter(e => e.type === 'attach')
      .map(e => e.service)
  }

  get detachServices(): Service[] {
    return this.serviceevents
      .filter(e => e.type === 'detach')
      .map(e => e.service)
  }

  get services(): Service[] {
    const detached = this.prev ? this.prev.detachServices : []

    const prevServices: Service[] = this.prev
      ? this.prev.services.filter(s => detached.indexOf(s) < 0)
      : []
    return [...prevServices, ...this.attachServices]
  }

  get emptyTonnage(): number {
    return this.wagonallocations.reduce<number>(
      (total, allocation) => total + allocation.tonnage,
      0,
    )
  }

  get serviceTonnage(): number {
    return this.services.reduce<number>(
      (total, service) => total + service.grossTonnage,
      0,
    )
  }

  get blockTonnage(): number {
    return this.templateLeg.blockTonnagePerStart
  }

  get extraTonnage(): number {
    return this.templateLeg.extraTonnagePerStart
  }

  get tonnage(): number {
    return (
      this.serviceTonnage +
      this.emptyTonnage +
      this.extraTonnage +
      this.blockTonnage
    )
  }

  get numBlockWagons(): number {
    return this.templateLeg.numBlockWagons / this.template.starts.length
  }

  get numExtraWagons(): number {
    return this.templateLeg.extraWagons / this.template.starts.length
  }

  get numEmptyWagons(): number {
    return sum([...this.wagonDict.values()])
  }

  get numWagons(): number {
    return this.numEmptyWagons + this.numBlockWagons + this.numExtraWagons
  }

  get serviceLength(): number {
    return this.services.reduce<number>(
      (total, service) => total + service.grossLength,
      0,
    )
  }

  get emptyLength(): number {
    return this.wagonallocations.reduce<number>(
      (total, allocation) => total + allocation.length,
      0,
    )
  }

  get totalWagonLength(): number {
    return this.serviceLength + this.emptyLength
  }

  get totalLocoLength(): number {
    return this.consist.length
  }

  get totalLocoWeight(): number {
    return this.consist.weight
  }

  get blockLength(): number {
    return this.templateLeg.blockLengthPerStart
  }

  get extraLength(): number {
    return this.templateLeg.extraLengthPerStart
  }

  get length(): number {
    return (
      this.totalWagonLength +
      this.totalLocoLength +
      this.blockLength +
      this.extraLength
    )
  }

  get weight(): number {
    return this.tonnage + this.totalLocoWeight
  }

  get attachCount(): number {
    // If crew are required for loading then the demand is put onto wagons as
    //  the train is built. So the attach isn't necessary
    const services = this.attachServices.filter(
      x => x.origin !== this.origin || !x.crewRequiredLoading,
    )
    return services.length + Number(this.attachingEmpties)
  }

  get detachCount(): number {
    // If crew are required for unloading then the demand is put onto wagons as
    //  the train is pulled apart. So the detach isn't necessary
    const services = this.detachServices.filter(
      x => x.destination !== this.dest || !x.crewRequiredUnloading,
    )
    return services.length + Number(this.detachingEmpties)
  }

  get detachingLocos(): boolean {
    return this.detachLocoEvents.length > 0
  }

  get detachingEmpties(): boolean {
    return this.detachWagonEvents.length > 0
  }

  get attachingLocos(): boolean {
    return this.attachLocoEvents.length > 0
  }

  get attachingEmpties(): boolean {
    return this.attachWagonEvents.length > 0
  }

  get minDwellDuration(): number {
    if (this.originating) {
      return 0
    }
    return this.prev.postArrivalDuration
      .add(this.prev.detachDurationVO)
      .add(this.preDepartureDurationVO)
      .add(this.attachDuration)
      .toSeconds()
  }

  getResourceProfiles(location: Location): LoadResourceProfile[] {
    const profiles = []
    if (this.origin === location) {
      profiles.push(...this.loadTasks.map(t => new LoadResourceProfile(t)))
    }

    if (this.dest === location) {
      profiles.push(...this.unloadTasks.map(t => new LoadResourceProfile(t)))
    }
    return profiles
  }

  get loadTasks(): Loading[] {
    if (!this._loadTasks) {
      // Generate a map of cargo types to demands, including potentially empty demands
      // where there's an existing assignment but no longer a demand. The result is we
      // generate loading tasks which may include no demand.
      const assignedCargoTypes: CargoType[] = this.loadingAssignments
        .filter(ass => ass.kind === 'loading')
        .map(ass => ass.cargoType)

      const cargoServiceMap: Map<CargoType, Service[]> = this.attachServices
        .filter(x => x.origin === this.origin && x.isRouted)
        .reduce<Map<CargoType, Service[]>>((acc, service) => {
          const { cargoType } = service
          if (!acc.has(cargoType)) {
            acc.set(cargoType, [])
          }
          acc.get(cargoType).push(service)
          return acc
        }, new Map(assignedCargoTypes.map(cargoType => [cargoType, []])))

      this._loadTasks = [
        ...cargoServiceMap.entries(),
      ].map(([cargoType, services]) => Loading.build(this, cargoType, services))
    }
    return this._loadTasks
  }

  get unloadTasks(): Unloading[] {
    if (!this._unloadTasks) {
      // Generate a map of cargo types to demands, including potentially empty demands
      // where there's an existing assignment but no longer a demand. The result is we
      // generate unloading tasks which may include no demand.
      const assignedCargoTypes: CargoType[] = this.loadingAssignments
        .filter(ass => ass.kind === 'unloading')
        .map(ass => ass.cargoType)

      const cargoServiceMap: Map<CargoType, Service[]> = this.detachServices
        .filter(x => x.destination === this.dest && x.isRouted)
        .reduce<Map<CargoType, Service[]>>((acc, service) => {
          const { cargoType } = service
          if (!acc.has(cargoType)) {
            acc.set(cargoType, [])
          }
          acc.get(cargoType).push(service)
          return acc
        }, new Map(assignedCargoTypes.map(cargoType => [cargoType, []])))

      this._unloadTasks = [
        ...cargoServiceMap.entries(),
      ].map(([cargoType, services]) =>
        Unloading.build(this, cargoType, services),
      )
    }
    return this._unloadTasks
  }

  get loadingWorkTasks(): LoadingWorkTask[] {
    return [...this.loadTasks, ...this.unloadTasks].reduce<LoadingWorkTask[]>(
      (acc, t) => [...acc, ...t.work],
      [],
    )
  }

  getLoadingWorkTask(id: string): LoadingWorkTask {
    return this.loadingWorkTasks.find(t => t.id === id)
  }

  getTaskTimeOffset(kind: string): number {
    const offset = this.taskOffsets.find(o => o.kind === kind)
    if (offset) {
      return offset.offset
    }
    return 0
  }

  get consist(): Consist {
    if (!this._consist) {
      this._consist = new Consist({
        startLeg: this,
        workingLocoLock: this.workinglocolock,
        allocations: this.locoallocations,
      })
    }
    return this._consist
  }

  get throughLocoAllocations(): LocoEvent[] {
    if (!this._throughLocoAllocations) {
      const [prev, detached] = this.prev
        ? [this.prev.locoallocations, this.prev.detachLocoEvents]
        : [[], []]

      const taken = new Set()
      this._throughLocoAllocations = prev.filter(a => {
        const detach = detached.find(
          e =>
            e.lococlassId === a.lococlassId &&
            e.working === a.working &&
            !taken.has(e),
        )
        if (detach) {
          taken.add(detach)
        }
        return !detach
      })
    }
    return this._throughLocoAllocations
  }

  get locoallocations(): LocoEvent[] {
    return [...this.attachLocoEvents, ...this.throughLocoAllocations]
  }

  get attachWorkingLocoEvents(): LocoEvent[] {
    return this.attachLocoEvents.filter(e => e.working)
  }

  get attachHauledLocoEvents(): LocoEvent[] {
    return this.attachLocoEvents.filter(e => !e.working)
  }

  get detachWorkingLocoEvents(): LocoEvent[] {
    return this.detachLocoEvents.filter(e => e.working)
  }

  get detachHauledLocoEvents(): LocoEvent[] {
    return this.detachLocoEvents.filter(e => !e.working)
  }

  get attachLocoEvents(): LocoEvent[] {
    return this.locoevents.filter(x => x.type === 'attach')
  }

  get detachLocoEventPreferences(): LocoEvent[] {
    return this.locoevents.filter(x => x.type === 'detach')
  }

  get detachLocoEvents(): LocoEvent[] {
    if (!this._detachLocoEvents) {
      const allocs = this.locoallocations
      if (!this.next) {
        this._detachLocoEvents = allocs
      } else {
        const taken = new Set()
        this._detachLocoEvents = this.detachLocoEventPreferences
          .map(e => {
            const alloc = allocs.find(
              a =>
                e.lococlassId === a.lococlassId &&
                e.working === a.working &&
                !taken.has(a),
            )
            if (alloc) {
              taken.add(alloc)
              return e
            }
            return null
          })
          .filter(Boolean)
      }
    }
    return this._detachLocoEvents
  }

  get haulageCapacity(): number {
    return this.consist.haulageCapacity
  }

  get powerRating(): number {
    return this.tonnage
      ? (100 * this.consist.haulageCapacity) / this.tonnage
      : 100
  }

  get canDepowerALoco(): boolean {
    return (
      this.powerRating >= 100 &&
      this.consist.workingLocos.some(allocation => {
        const locoHaulageCapacity =
          this.loadCategoryPower(allocation.loadCategoryObj) || 0
        const stillOverpowered =
          this.consist.haulageCapacity - locoHaulageCapacity >= this.tonnage
        return (
          stillOverpowered &&
          (!allocation.canLead(this.accessGroup) ||
            this.consist.numLeadLocos - 1 >= this.template.minLeadLocos)
        )
      })
    )
  }

  get missingLoadTables(): Lococlass[] {
    return this.consist.missingLoadTables
  }

  get throughWagonAllocations(): WagonAllocation[] {
    if (!this._throughWagonAllocations) {
      const [prev, detached] = this.prev
        ? [this.prev.wagonallocations, this.prev.detachWagonEvents]
        : [[], []]

      this._throughWagonAllocations = WagonAllocation.fromEvents(prev, detached)
    }
    return this._throughWagonAllocations
  }

  get wagonallocations(): WagonAllocation[] {
    if (!this._wagonallocations) {
      this._wagonallocations = WagonAllocation.fromEvents([
        ...this.attachWagonEvents,
        ...this.throughWagonAllocations,
      ])
    }
    return this._wagonallocations
  }

  get wagonDict(): Map<any, number> {
    return new Map(
      this.wagonallocations.map(({ wagon, quantity }) => [wagon, quantity]),
    )
  }

  get attachWagonEvents(): EmptyWagonEvent[] {
    return this.emptywagonevents.filter(x => x.type === 'attach')
  }

  get detachWagonEventPreferences(): EmptyWagonEvent[] {
    return this.emptywagonevents.filter(x => x.type === 'detach')
  }

  get detachWagonEvents(): EmptyWagonEvent[] {
    if (!this._detachWagonEvents) {
      const allocs = this.wagonallocations
      if (!this.next) {
        // ie. auto detach everything for now
        // HACK: probably should be converting these to EmptyWagonEvents here
        this._detachWagonEvents = allocs
      } else {
        this._detachWagonEvents = this.detachWagonEventPreferences
          .map(e => {
            const alloc = allocs.find(a => e.wagonId === a.wagonId)
            return EmptyWagonEvent.construct({
              ...e,
              quantity: Math.min(alloc ? alloc.quantity : 0, e.quantity),
            })
          })
          .filter(e => (e as any).quantity) // TODO Clearly this should be tidied when the empty wagon event is typed
      }
    }
    return this._detachWagonEvents
  }

  get allocations(): LocoEvent[] {
    return this.consist.allocations
  }

  get locos(): Lococlass[] {
    return this.consist.locos
  }

  get locoDict(): Map<Lococlass, number> {
    return count(this.locos)
  }

  // Does this belong in warnings?
  get invalidEmptyWagons(): Wagon[] {
    return this.wagons.filter(wagon => !wagon.hasAccess(this.accessGroup))
  }

  get wagons(): Wagon[] {
    return [...new Set(this.wagonallocations.map(item => item.wagon))]
  }

  get throughLocosDict(): Map<Lococlass, number> {
    return this.throughLocoAllocations.reduce<Map<Lococlass, number>>(
      (map, { lococlass }) => map.set(lococlass, (map.get(lococlass) || 0) + 1),
      new Map(),
    )
  }

  get throughWagonDict(): Map<any, number> {
    return new Map(
      this.throughWagonAllocations.map(({ wagon, quantity }) => [
        wagon,
        quantity,
      ]),
    )
  }

  get resources(): (Lococlass | Wagon)[] {
    return [...this.locos, ...this.wagons]
  }

  get resourceDict(): Map<any, number> {
    return new Map([...this.locoDict, ...this.wagonDict])
  }

  get localTasks() {
    if (!this._localTasks) {
      this._localTasks = LEG_ORDERED_ACTIVITIES.map(Model =>
        Model.build(this),
      ).filter(Boolean) as any[]
    }
    return this._localTasks
  }

  getTask(kind: string): LegTask<StartLeg> {
    return this.localTasks.find(task => task.kind === kind)
  }

  get changeovers(): (LocationChangeover | TrainChangeover)[] {
    return [...this.locationchangeovers, ...this.trainchangeovers].sort(
      (a, b) => Number(!a.atDeparture) - Number(!b.atDeparture),
    )
  }
}

interface StartLeg extends Attrs, Rels {}
export { StartLeg }
