import { CrewPool } from 'src/service-design/shared/models/crew-pool'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { RosterLine } from 'src/service-design/shared/models/roster-line'

interface Attrs {
  id: string
  parentId: string
  headId: string
}

interface Rels {
  // TODO: This is pretty hard to type at the moment.
  // We either need to consolidate this with the roster head
  // model or define some more approriate interfaces for ts
  parent: CrewPool
  head: RosterLine
}

class LinkedListHead extends Mapper {
  constructor({ id, parentId, headId }: Attrs) {
    super()
    this.id = id
    this.parentId = parentId
    this.headId = headId
  }

  setRels({ parent, head }: Rels) {
    this.parent = parent
    this.head = head
  }
}

interface LinkedListHead extends Attrs, Rels {}
export { LinkedListHead }
