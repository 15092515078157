import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { emptyWagonEventFactory } from './factory'
import { getEmptyWagonEventRepairs } from './repairs'
import { IEmptyWagonEventRepo, EmptyWagonEventRepo } from './repo'

const emptyWagonEventRepo = defaultMemoize(
  (input: FactoryInput) =>
    new EmptyWagonEventRepo(emptyWagonEventFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  emptyWagonEventRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type EmptyWagonEventRepoReg = {
  emptyWagonEventRepo: FromDocumentFactory<FactoryInput, IEmptyWagonEventRepo>
}

export function setup<R extends EmptyWagonEventRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('emptyWagonEventRepo', emptyWagonEventRepo)

  relationships.addConstraints('emptywagonevents', {
    unique: [['templateLegId', 'startId', 'wagonId', 'type']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
      {
        collection: 'wagons',
        foreign: 'wagonId',
        name: 'wagon',
      },
    ],
  })

  repairs.register(createSelector(values, getEmptyWagonEventRepairs))
}

export { EmptyWagonEvent } from './model'
