import { Corridor } from 'src/service-design/shared/models/corridor'
import { CrewType } from 'src/service-design/shared/models/crew-type'
import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  corridorId: string
  crewTypeId: string
}

interface Rels {
  corridor: Corridor
  crewType: CrewType
}
class RouteKnowledge extends Mapper {
  static defaults = {}

  /**
   * In order to drive a train over a Corridor a drivr requires RouteKnowledge.
   *
   * Related models:
   * - `Corridor`;
   * - `CrewType`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} corridorId - The id of the Corridor.
   * @param {string} crewTypeId - The id of the CrewType.
   **/
  constructor({ id, corridorId, crewTypeId }: Attrs) {
    super()
    this.id = id
    this.corridorId = corridorId
    this.crewTypeId = crewTypeId
  }

  setRels({ corridor, crewType }: Rels) {
    this.corridor = corridor
    this.crewType = crewType
  }
}

interface RouteKnowledge extends Attrs, Rels {}
export { RouteKnowledge }
