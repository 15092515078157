import styled from 'styled-components'

import { ENVIRONMENT } from 'src/core/constants'
import {
  snowwhite,
  greyblue1,
  greyblue2,
  lightgrey2,
  brightGreen,
  yellowBronze,
  pastelRed,
} from 'src/service-design/shared/style-constants'

const ENVIRONMENT_COLOR_MAP = {
  local: brightGreen,
  dev: yellowBronze,
}
const ENVIRONMENT_FALLBACK_COLOR = pastelRed
const ENVIRONMENT_COLOR =
  ENVIRONMENT in ENVIRONMENT_COLOR_MAP
    ? ENVIRONMENT_COLOR_MAP[ENVIRONMENT as keyof typeof ENVIRONMENT_COLOR_MAP]
    : ENVIRONMENT_FALLBACK_COLOR

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.5em;
  min-height: 2.5em;
  font-family: proxima-nova, sans-serif;
  color: ${snowwhite};
  background: ${greyblue1};
  border-top: ${ENVIRONMENT === 'prod'
    ? 'none'
    : `3px solid ${ENVIRONMENT_COLOR}`};

  div.crumbs {
    margin-left: 0.5em;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .environment {
      margin-right: 2em;
    }
  }

  .crumbs img.boss-logo {
    height: 1.6em;
    width: auto;
    margin-right: 2em;
    cursor: pointer;
    transform: translateY(0.1em);
  }

  div.nav-actions {
    display: flex;
    align-items: center;
    background: ${greyblue2};
    padding: 0 5px;
  }

  div.group {
    display: flex;
    justify-content: space-around;
  }

  .build-id {
    padding-right: 1.5em;
    display: flex;
    align-items: center;
    color: ${lightgrey2};
  }

  &&& .dropdown {
    display: flex;
    align-items: center;
  }
`

export default StyledNav
