import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Shift } from 'src/service-design/shared/models/shift'
import { ShiftAssignment } from 'src/service-design/shared/models/shift-assignment/base'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'

interface Attrs {
  startId: string
  templateLegId: string
  kind: 'loading' | 'unloading'
  cargoTypeId: string
  index: 0 | 1
}

interface Rels {
  start: TrainStart
  startLeg: StartLeg
  templateLeg: TemplateLeg
  cargoType: CargoType
}

class LoadingAssignment extends ShiftAssignment {
  static collection = 'loadingassignments'
  _task: LoadingWorkTask
  _actingAsDriver: boolean

  /**
   * A LoadingAssignment assigns a LoadingWorkTask to
   * a Shift.
   *
   * Related models:
   * - `LoadingAssignment`;
   * - `Shift`;
   * - `LoadingWorkTask`;
   * - `CargoType`.
   *
   * @constructor
   * @param {string} startId - The startLeg id of the LoadingWorkTask.
   * @param {string} templateLegId The templateLeg id of the LoadingWorkTask.
   * @param {string} kind - `loading` or `unloading`.
   * @param {string} cargoTypeId - The CargoType id.
   * @param {number} index - the side of a LoadingWorkTask split being assigned.
   */

  constructor({
    startId,
    templateLegId,
    kind,
    cargoTypeId,
    actingAsDriver,
    index,
    ...rest
  }: {
    id: string
    shiftId: string
    actingAsDriver: boolean
  } & Attrs) {
    super(rest)
    this.startId = startId
    this.templateLegId = templateLegId
    this.kind = kind
    this.cargoTypeId = cargoTypeId
    this._actingAsDriver = actingAsDriver
    this.index = index
  }

  setRels({
    start,
    startLeg,
    templateLeg,
    cargoType,
    ...rest
  }: Rels & { shift: Shift }) {
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
    this.cargoType = cargoType
    super.setRels(rest)
  }

  get task() {
    if (!this._task) {
      this._task = this.startLeg.getLoadingWorkTask(
        LoadingWorkTask.generateId(
          this.startId,
          this.templateLegId,
          this.kind,
          this.cargoTypeId,
          this._actingAsDriver,
          this.index,
        ),
      )
    }

    return this._task
  }

  get name() {
    return this.task.name
  }

  get startLocation() {
    return this.task.startLocation
  }

  get endLocation() {
    return this.task.endLocation
  }

  get location() {
    return this.task.startLocation
  }
}

interface LoadingAssignment extends Attrs, Rels {}
export { LoadingAssignment }
