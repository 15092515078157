import { Corridor } from 'src/service-design/shared/models/corridor'
import { LoadCategory } from 'src/service-design/shared/models/load-category'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { TrainType } from 'src/service-design/shared/models/train-type'

interface Attrs {
  id: string
  corridorId: string
  trainTypeId: string
  loadCategory: string
  forwardTonnage: number
  reverseTonnage: number
  comment: string
}

interface Rels {
  corridor: Corridor
  trainType: TrainType
  loadCategoryObj: LoadCategory
}
class LoadTable extends Mapper {
  /**
   * LoadTables describe how much freight Lococlasses of a particular
   * LoadCategory can haul over Corridors. Since Lococlasses behave differently
   * depending on how fast the train they pulling is going the TrainType is also
   * an input to this table.
   *
   * NOTE: This load table modeling assumes that every loco added to a train
   * will increase the total haulage capacity of the train in exactly the same
   * way. In reality, the way Lococlasses are mixed is important, some
   * lococlasses are less compatible than others.
   *
   * Related models:
   * - Corridor;
   * - TrainType;
   * - LoadCategory;
   * - Lococlass.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} corridorId - The id of the Corridor.
   * @param {string} trainTypeId - The id of the TrainType.
   * @param {string} loadCategory - The id of the LoadCategory.
   * @param {number} forwardTonnage - How many tonnes can loco classes in this
   *  load category pull from corridor.loc1 to corridor.loc2 while connected to
   *  this train type.
   * @param {number} reverseTonnage - How many tonnes can loco classes in this
   *  load category pull from corridor.loc2 to corridor.loc1 while connected to
   *  this train type.
   * @param {string} comment - Often rail operators need to do a few
   *  calculations to produce load tables so this is a place where they can
   *  store their working.
   **/
  constructor({
    id,
    corridorId,
    trainTypeId,
    loadCategory,
    forwardTonnage,
    reverseTonnage,
    comment,
  }: Attrs) {
    super()
    this.id = id
    this.corridorId = corridorId
    this.trainTypeId = trainTypeId
    this.loadCategory = loadCategory
    this.forwardTonnage = forwardTonnage
    this.reverseTonnage = reverseTonnage
    this.comment = comment
  }

  setRels({ corridor, trainType, loadCategoryObj }: Rels) {
    this.corridor = corridor
    this.trainType = trainType
    this.loadCategoryObj = loadCategoryObj
  }
}

interface LoadTable extends Attrs, Rels {}
export { LoadTable }
