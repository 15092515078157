import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { EpochTime } from 'src/service-design/shared/utils/dates'

export class BuildLeg {
  /**
   * A BuildLeg represents a series of legs between yard Locations. This is
   * really only used by the 'LegTable' component with the TrainStart view.
   *
   * This is a UI specific model that probably does not really belong in the
   * core of our application.
   *
   **/
  constructor(public legs: StartLeg[]) {}

  get id() {
    return this.firstLeg.id
  }

  get templateId() {
    return this.firstLeg.templateId
  }

  get startId() {
    return this.firstLeg.startId
  }

  get departsLocal(): EpochTime {
    return this.firstLeg.departsLocal
  }

  get departsLocalNormalized() {
    return this.firstLeg.departsLocalNormalized
  }

  get arrivesLocal(): EpochTime {
    return this.lastLeg.arrivesLocal
  }

  get arrivesLocalNormalized() {
    return this.lastLeg.arrivesLocalNormalized
  }

  get consist() {
    return this.firstLeg.consist
  }

  get origin() {
    return this.firstLeg.origin
  }

  get tonnage() {
    return this.firstLeg.tonnage
  }

  get emptyTonnage() {
    return this.firstLeg.emptyTonnage
  }

  get extraLength() {
    return this.firstLeg.extraLength
  }

  get numExtraWagons() {
    return this.firstLeg.numExtraWagons
  }

  get blockTonnage() {
    return this.firstLeg.blockTonnage
  }

  get emptyWagonEvents() {
    return this.firstLeg.emptywagonevents
  }

  get next() {
    return this.legs.slice(-1)[0].next
  }

  get emptyLength() {
    return this.firstLeg.emptyLength
  }

  get blockLength() {
    return this.firstLeg.blockLength
  }

  get numBlockWagons() {
    return this.firstLeg.numBlockWagons
  }

  get numWagons() {
    return this.firstLeg.numWagons
  }

  get extraTonnage() {
    return this.firstLeg.extraTonnage
  }

  get services() {
    return this.firstLeg.services
  }

  get firstLeg() {
    return this.legs[0]
  }

  get lastLeg() {
    return this.legs.slice(-1)[0]
  }

  get length() {
    return this.firstLeg.length
  }

  get weight() {
    return this.firstLeg.weight
  }

  get dest() {
    return this.legs.slice(-1)[0].dest
  }

  get attachLocoEvents() {
    return this.firstLeg.attachLocoEvents
  }

  get detachLocoEvents() {
    return this.lastLeg.detachLocoEvents
  }

  get attachWorkingLocoEvents() {
    return this.firstLeg.attachWorkingLocoEvents
  }

  get attachHauledLocoEvents() {
    return this.firstLeg.attachHauledLocoEvents
  }

  get detachWorkingLocoEvents() {
    return this.lastLeg.detachWorkingLocoEvents
  }

  get detachHauledLocoEvents() {
    return this.lastLeg.detachHauledLocoEvents
  }

  get attachWagonEvents() {
    return this.firstLeg.attachWagonEvents
  }

  get detachWagonEvents() {
    return this.lastLeg.detachWagonEvents
  }

  get throughWagonAllocations() {
    return this.firstLeg.throughWagonAllocations
  }

  get locoallocations() {
    return this.firstLeg.locoallocations
  }

  get wagonallocations() {
    return this.firstLeg.wagonallocations
  }

  get totalLocoLength() {
    return this.firstLeg.totalLocoLength
  }

  get totalLocoWeight() {
    return this.firstLeg.totalLocoWeight
  }

  get lowestHaulageCapacityLeg() {
    return [...this.legs].sort(
      (a, b) => a.consist.haulageCapacity - b.consist.haulageCapacity,
    )[0]
  }

  get hasAllocations() {
    return Boolean(this.locoallocations.length + this.wagonallocations.length)
  }

  get subLegsCount() {
    return this.legs.length - 1
  }

  get hasSubLegs() {
    return this.subLegsCount > 0
  }
}
