import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class Provisioning<T extends StartLeg> extends LegTask<T> {
  /**
   * Provisioning is the task of servicing locomotives. Primarily this involves
   * refueling but other activities might include refilling the sand (used for braking)
   * or cleaning.
   *
   * DOMAIN MISMATCH:
   *
   * Within this tool provisioning of working locos takes place
   * after a TrainStart reaches its destination and is dismantled. This makes
   * little sense as locomotives can be attached / detached mid journey it's
   * possible some locomotives will never be refueled. Additionally, within
   * smaller networks it would be possible for a locomotives to power several
   * TrainStarts before requiring provisioning.
   *
   * Both TasRail and KCS have indicated that this modelling does not meet their
   * needs. This modelling was originally produced PN who have a large network
   * and do not often do loco changes mid-trip.
   *
   */
  static arrivingTask = true

  static kind = 'loco-provisioning'

  static requiresRailOperator = true

  static timeOffsetAllowed = false

  static build(startLeg: StartLeg) {
    return !startLeg.next
      ? new this({
          origin: startLeg.dest,
          destination: startLeg.dest,
          startTimeLocal: startLeg.provisionStartLocal.toSeconds(),
          endTimeLocal: startLeg.provisionEndLocal.toSeconds(),
          startLeg,
        })
      : null
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(this.duration, this.startLeg.consist.workingDict)
  }

  get resourceDict() {
    return this.startLeg.consist.workingDict
  }
}
