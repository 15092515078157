import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { EmptyWagonEvent } from './model'

export class TerminatingWagonDetach extends InstanceRepair {
  static collectionName = 'emptywagonevents'

  static type = 'service-design::Terminating Wagon Detachment'

  static message =
    'service-design::Terminating leg of train {{entity.startLeg.start.name}} has wagon detachs. This is not permitted.'

  static check(event: EmptyWagonEvent) {
    return event.startLeg.terminating && event.type === 'detach'
  }
}

export class OccursAtNonYardLocation extends InstanceRepair {
  static collectionName = 'emptywagonevents'

  static type = 'service-design::Occurs at a non-yard location'

  static message =
    'service-design::Wagons on train {{entity.startLeg.start.name}} attach/detach at {{entity.location.code}} which is not a yard'

  static check(event: EmptyWagonEvent) {
    const originatingAttach =
      event.startLeg.originating && event.type === 'attach'
    const terminatingDetach =
      event.startLeg.terminating && event.type === 'detach'
    return !event.location.yard && !(originatingAttach || terminatingDetach)
  }
}

export const getEmptyWagonEventRepairs = getRepairFactory([
  TerminatingWagonDetach,
  OccursAtNonYardLocation,
])
