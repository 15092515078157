import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { ForeignRailroad } from 'src/service-design/shared/models/foreign-railroad'
import { LoadCategory } from 'src/service-design/shared/models/load-category'
import { LococlassAccessRight } from 'src/service-design/shared/models/lococlass-access-right'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { ResourceAvailability } from 'src/service-design/shared/models/resource-availability'
import { IResource } from 'src/service-design/shared/models/resource-summaries/types'

interface Attrs {
  id: string
  name: string
  loadCategory: string
  axleLoad: number
  ecpBraking: boolean
  bgColor: string
  fixedCost: number
  length: number
  weight: number
  horsepower: number
  workingHourCost: number
  workingHourFuelBurn: number
  workingKmCost: number
  workingKmFuelBurn: number
  comment: string
}

interface Rels {
  // TODO: Lococlasses shouldn't have a reference to the resource availability.
  // If anything, this relationship should go that other way.
  availability: ResourceAvailability
  loadCategoryObj: LoadCategory
  accessrights: LococlassAccessRight[]
}

class Lococlass extends Mapper implements IResource {
  entityName: string
  _owned: Map<ForeignRailroad, Lococlass>
  _availability: ResourceAvailability

  /**
   * A Lococlass describes class of locomotive engine which can be used to power
   * trains that operate within a rail network.
   *
   * Since every Lococlass is different they need to be throughly tested in all
   * parts of a rail network. If a Lococlass has been approved to run in an area
   * of the network it is granted a LococlassAccessRight. Additionaly, testing
   * will determine the pulling characteristic of a Lococlass and these are
   * entered into LoadTables.
   *
   * ForeignRailroads... Foreign Lococlasses... WTF?
   * -----------------------------------------------
   * ForeignRailroads were are very late edition to the tool. Due to this fact
   * and timeline pressure some fairly targeted hacks have been introduced into
   * the tool.
   *
   * The idea here is that KCS shared locos with their neighboring rail
   * operators, eg BNSF, UP. Since cargo can flow over operator boundaries so
   * do trains. For efficiency, operators agree to share their locos but the
   * accounting of this is important.
   *
   * We call a loco 'foreign' when the owner is a ForeignRailroad. When foreign
   * locos are within the boundary of our operator's network they incur
   * horsepower/hour debt. When our operator's locos are within the boundary
   * of a ForeignRailroad they are credited.
   *
   * Unfortunately, our tool is unable to report how much credit or debit the
   * the rail operator is incurring whilst within the ForeignRailroad's network.
   * This is because there is not any information about what is happening to
   * locos once they leave enter the foreign network.
   *
   * For more information also see LocoEvents.
   *
   * Related models:
   * - `ForeignRailroad`;
   * - `LoadCategory`;
   * - `LoadTable`;
   * - `LococlassAccessRight`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the Lococlass.
   * @param {string} loadCategory - The id of the LoadCategory
   * @param {number} axleLoad - The axle load of the Lococlass.
   * @param {boolean} ecpBraking - If true, this Lococlass has ECP braking.
   * @param {string} bgColor - A color associated with this Lococlass for
   *  display.
   * @param {number} fixedCost - The fixed cost ($/w) associated with owning a
   *  loco of this class.
   * @param {number} horsePower - The horse power of a loco in this class.
   * @param {number} length - The length (m) of a loco in this class.
   * @param {number} weight - The weight (t) of a loco in this class.
   * @param {number} workingHourCost - The cost ($/h) associated have a loco of
   *  this class switched on.
   * @param {number} workingHourFuelBurn - The fuel burn (L/h). This cost is
   *  applied when the loco is being used for shunt operations.
   * @param {number} workingKmCost - The cost ($/km) have having of a loco in
   *  this class moving while on.
   * @param {number} workingKmFuelBurn - The fuel burn (L/km). This cost is
   *  applied when the loco is pulling a train.
   * @param {string} comment -
   */
  constructor({
    id,
    name,
    loadCategory,
    axleLoad,
    ecpBraking,
    bgColor,
    fixedCost,
    length,
    weight,
    horsepower,
    workingHourCost,
    workingHourFuelBurn,
    workingKmCost,
    workingKmFuelBurn,
    comment,
  }: Attrs) {
    super()
    this.entityName = 'Lococlass'
    this.id = id
    this.name = name
    this.loadCategory = loadCategory
    this.axleLoad = axleLoad
    this.ecpBraking = ecpBraking
    this.bgColor = bgColor
    this.fixedCost = fixedCost
    this.horsepower = horsepower
    this.length = length
    this.weight = weight
    this.workingHourCost = workingHourCost
    this.workingHourFuelBurn = workingHourFuelBurn
    this.workingKmCost = workingKmCost
    this.workingKmFuelBurn = workingKmFuelBurn
    this.comment = comment
  }

  setRels({ loadCategoryObj, availability, accessrights = [] }: Rels) {
    this._availability = availability
    this.loadCategoryObj = loadCategoryObj
    this.accessrights = accessrights
  }

  getAccessRight(accessGroup: AccessGroup) {
    return (
      this.accessrights.find(right => right.accessGroup === accessGroup) || null
    )
  }

  hasAccess(accessGroup: AccessGroup) {
    return this.getAccessRight(accessGroup) !== null
  }

  canLead(accessGroup: AccessGroup) {
    const right = this.getAccessRight(accessGroup)
    return right ? right.canLead : false
  }

  get availability() {
    if (!this._availability) {
      this._availability = new ResourceAvailability({
        id: null,
        wagonId: null,
        lococlassId: this.id,
        lococlass: this,
        quantity: 0,
      })
    }
    return this._availability
  }

  asOwned(foreignRailroad: ForeignRailroad) {
    if (!foreignRailroad) {
      return this
    }
    if (!this._owned) {
      this._owned = new Map()
    }
    if (!this._owned.has(foreignRailroad)) {
      this._owned.set(
        foreignRailroad,
        new Proxy<Lococlass>(this, {
          get(target, name) {
            switch (name) {
              case 'name':
                return `${target.name} - ${foreignRailroad.name}`
              case 'id':
                return `${target.id}-${foreignRailroad.id}`
              case 'foreignRailroad':
                return foreignRailroad
              case 'availability':
                return null
              default:
                return Reflect.get(target, name)
            }
          },
        }),
      )
    }
    return this._owned.get(foreignRailroad)
  }
}

interface Lococlass extends Attrs, Rels {}
export { Lococlass }
