import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LocoEvent } from './model'

export interface ILocoEventRepo extends IRepo<LocoEvent> {
  byLococlassId(id: string): LocoEvent[]
  byStartId(id: string): LocoEvent[]
  byTemplateLegId(id: string): LocoEvent[]
  byStartLegId(id: string): LocoEvent[]
  byForeignRailroadId(id: string): LocoEvent[]
}

export class LocoEventRepo implements ILocoEventRepo {
  values: LocoEvent[]
  idMap: { [key: string]: LocoEvent }
  lococlassIdMap: Indexer<LocoEvent>
  startIdMap: Indexer<LocoEvent>
  templateLegIdMap: Indexer<LocoEvent>
  startLegIdMap: Indexer<LocoEvent>
  foreignRailroadIdMap: Indexer<LocoEvent>

  constructor(values: LocoEvent[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.lococlassIdMap = new Indexer(values, x => x._lococlassId)
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
    this.foreignRailroadIdMap = new Indexer(values, x => x.foreignRailroadId)
  }

  byId(id: string): LocoEvent {
    return this.idMap[id]
  }

  byLococlassId(lococlassId: string): LocoEvent[] {
    return this.lococlassIdMap.lookup(lococlassId)
  }

  byStartId(startId: string): LocoEvent[] {
    return this.startIdMap.lookup(startId)
  }

  byTemplateLegId(templateLegId: string): LocoEvent[] {
    return this.templateLegIdMap.lookup(templateLegId)
  }

  byStartLegId(startLegId: string): LocoEvent[] {
    return this.startLegIdMap.lookup(startLegId)
  }

  byForeignRailroadId(foreignRailroadId: string): LocoEvent[] {
    return this.foreignRailroadIdMap.lookup(foreignRailroadId)
  }
}
