import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { WagonCargoCompatibility } from './model'

export interface IWagonCargoCompatibilityRepo
  extends IRepo<WagonCargoCompatibility> {
  byCargoTypeId(id: string): WagonCargoCompatibility[]
  byWagonId(id: string): WagonCargoCompatibility[]
}

export class WagonCargoCompatibilityRepo
  implements IWagonCargoCompatibilityRepo {
  values: WagonCargoCompatibility[]
  idMap: { [key: string]: WagonCargoCompatibility }
  cargoTypeIdMap: Indexer<WagonCargoCompatibility>
  wagonIdMap: Indexer<WagonCargoCompatibility>

  constructor(values: WagonCargoCompatibility[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.cargoTypeIdMap = new Indexer(values, x => x.cargoTypeId)
    this.wagonIdMap = new Indexer(values, x => x.wagonId)
  }

  byId(id: string): WagonCargoCompatibility {
    return this.idMap[id]
  }

  byWagonId(id: string): WagonCargoCompatibility[] {
    return this.wagonIdMap.lookup(id)
  }

  byCargoTypeId(id: string): WagonCargoCompatibility[] {
    return this.cargoTypeIdMap.lookup(id)
  }
}
