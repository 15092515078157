import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { Location } from './model'

export class LocoChangedAtNonYardLocation extends InstanceRepair {
  static collectionName = 'locations'

  static type = 'service-design::Loco changes can only occur at a yard location'

  static message =
    'service-design::Location {{entity.name}} has been selected as a loco change location despite not being marked as a yard.'

  static check(location: Location) {
    return location.canChangeLocos && !location.yard
  }
}

export const getLocationRepairs = getRepairFactory([
  LocoChangedAtNonYardLocation,
])
