export function resolveAfterTimeout(timeout: number) {
  return new Promise(resolve => {
    window.setTimeout(() => {
      resolve()
    }, timeout)
  })
}

export async function retryPoller<T>(
  poll: (context: T) => Promise<boolean>,
  failureCheckerFactory: (context: T) => (error: any) => boolean,
  pollFrequency = 1000,
  context: T = {} as T,
) {
  let finished = false
  let failureChecker: (error: any) => boolean = null

  async function doPoll() {
    try {
      const result = await poll(context)
      failureChecker = null
      return result
    } catch (e) {
      if (!failureChecker) {
        failureChecker = failureCheckerFactory(context)
      }
      return failureChecker(e)
    }
  }

  do {
    // NOTE: it's important that timoutPromise is called before pollPromise,
    // as in the tests pollPromise is responsible for calling jest.runAllTimers.
    // Lame that the code has to be restructured a tiny bit to make tests work,
    // but that's how it's gotta be for this example :/ - MD
    const timeoutPromise = resolveAfterTimeout(pollFrequency)
    const pollPromise = doPoll()
    // eslint-disable-next-line no-await-in-loop
    const results = await Promise.all([timeoutPromise, pollPromise])
    finished = results.some(r => r)
  } while (!finished)
}
