import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { serviceLockFactory } from './factory'
import { getServiceLockRepairs } from './repairs'
import { IServiceLockRepo, ServiceLockRepo } from './repo'

const serviceLockRepo = defaultMemoize(
  (input: FactoryInput) => new ServiceLockRepo(serviceLockFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  serviceLockRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type ServiceLockRepoReg = {
  serviceLockRepo: FromDocumentFactory<FactoryInput, IServiceLockRepo>
}

export function setup<R extends ServiceLockRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('serviceLockRepo', serviceLockRepo)

  relationships.addConstraints('servicelocks', {
    unique: [['serviceId']],
    rels: [
      {
        collection: 'services',
        foreign: 'serviceId',
        name: 'service',
      },
    ],
  })

  repairs.register(createSelector(values, getServiceLockRepairs))
}

export { ServiceLock } from './model'
