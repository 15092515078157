import { documentSpec as scenarioDocumentSpec } from 'src/service-design/scenario/document'
import { workSheets as worksheets } from 'src/service-design/sd-plan/worksheets'
import { DocumentSpec } from 'src/service-design/shared/document/types'

import blankDocument from './blank'
import relationships from './relationships'
import repairs from './repairs'
import schema from './schema.json'
import { ServiceDesignDocument } from './types'

export const blank: ServiceDesignDocument = blankDocument

export const documentSpec: DocumentSpec<'service-design', 'scenario'> = {
  kind: 'service-design',
  currentVersion: '1.93',
  displayName: 'Plan',
  schema,
  parent: scenarioDocumentSpec,
  relationships,
  repairs,
  worksheets,
  blank,
}
