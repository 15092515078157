import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LocationChangeover } from './model'

export interface ILocationChangeoverRepo extends IRepo<LocationChangeover> {
  byStartId(id: string): LocationChangeover[]
  byTemplateLegId(id: string): LocationChangeover[]
  byStartLegId(id: string): LocationChangeover[]
}

export class LocationChangeoverRepo implements ILocationChangeoverRepo {
  values: LocationChangeover[]
  idMap: { [key: string]: LocationChangeover }
  startIdMap: Indexer<LocationChangeover>
  templateLegIdMap: Indexer<LocationChangeover>
  startIdtemplateLegIdMap: Indexer<LocationChangeover>

  constructor(values: LocationChangeover[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startIdtemplateLegIdMap = new Indexer(
      values,
      ({ startId, templateLegId }) => `${startId}:${templateLegId}`,
    )
  }

  byId(id: string): LocationChangeover {
    return this.idMap[id]
  }

  byStartId(startId: string): LocationChangeover[] {
    return this.startIdMap.lookup(startId)
  }

  byTemplateLegId(templateLegId: string): LocationChangeover[] {
    return this.templateLegIdMap.lookup(templateLegId)
  }

  byStartLegId(startIdtemplateLegId: string): LocationChangeover[] {
    return this.startIdtemplateLegIdMap.lookup(startIdtemplateLegId)
  }
}
