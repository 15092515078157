import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { customTaskAssignmentFactory } from './factory'
import { ICustomTaskAssignmentRepo, CustomTaskAssignmentRepo } from './repo'

const customTaskAssignmentRepo = defaultMemoize(
  (input: FactoryInput) =>
    new CustomTaskAssignmentRepo(customTaskAssignmentFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  customTaskAssignmentRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type CustomTaskAssignmentRepoReg = {
  customTaskAssignmentRepo: FromDocumentFactory<
    FactoryInput,
    ICustomTaskAssignmentRepo
  >
}

export function setup<R extends CustomTaskAssignmentRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('customTaskAssignmentRepo', customTaskAssignmentRepo)

  relationships.addConstraints('customtaskassignments', {
    unique: [['customTaskId']],
    rels: [
      {
        collection: 'customtasks',
        foreign: 'customTaskId',
        name: 'customTask',
      },
      {
        collection: 'shifts',
        foreign: 'shiftId',
        name: 'shift',
      },
    ],
  })
}

export { CustomTaskAssignment } from './model'
