import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Timezone } from './model'

export interface ITimezoneRepo extends IRepo<Timezone> {}

export class TimezoneRepo implements ITimezoneRepo {
  values: Timezone[]
  idMap: { [key: string]: Timezone }

  constructor(values: Timezone[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
  }

  byId(id: string): Timezone {
    return this.idMap[id]
  }
}
