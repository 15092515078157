import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { CrewType } from './model'

export interface ICrewTypeRepo extends IRepo<CrewType> {
  byLocId(id: string): CrewType[]
}

export class CrewTypeRepo implements ICrewTypeRepo {
  values: CrewType[]
  idMap: { [key: string]: CrewType }
  locIdMap: Indexer<CrewType>

  constructor(values: CrewType[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.locIdMap = new Indexer(values, x => x.locId)
  }

  byId(id: string): CrewType {
    return this.idMap[id]
  }

  byLocId(locId: string): CrewType[] {
    return this.locIdMap.lookup(locId)
  }
}
