import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { LocationChangeover } from './model'

export const locationChangeoverFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.locationchangeovers.map(
      ({ timeOffset, ...data }) =>
        new LocationChangeover({
          timeOffset: Duration.fromSeconds(timeOffset),
          ...data,
        }),
    ),
  setRelsFromSpec((l: LocationChangeover, state: any) => ({
    singletons: state.collectionData.singletons,
    start: registry.provide('trainStartRepo')(state).byId(l.startId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${l.startId}:${l.templateLegId}`),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(l.templateLegId),
    changeoverLock: registry
      .provide('locationChangeoverLockRepo')(state)
      .byChangeoverId(l.id),
  })),
)
