import React from 'react'
import { Label } from 'semantic-ui-react'

export const FormErrorLabel: React.FC<{
  error?: string
  warning?: string
  pointing: true | 'left' | 'right' | 'below'
  floating?: boolean
}> = ({ error, warning, pointing, floating }) => {
  const content = error || warning
  if (!content) {
    return null
  }
  return (
    <Label
      color={error ? 'red' : 'orange'}
      pointing={pointing}
      floating={floating}
    >
      {content}
    </Label>
  )
}
