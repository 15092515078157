import React, { useEffect, useState } from 'react'
import { Popup } from 'semantic-ui-react'

import NavBarButton from 'src/service-design/shared/ui/components/NavBar/NavBarButton'

const BUILD_NAME = process.env.REACT_APP_BUILD_NAME
const BUILD_ID = process.env.REACT_APP_BUILD_ID

export interface BuildInfoBackend {
  name: string
  getVersion?: () => Promise<
    | {
        branch: string
        buildNumber: string
      }
    | {
        error: string
      }
  >
}
interface BuildInfoProps {
  backends: BuildInfoBackend[]
}

type BuildInfoDict = {
  [key: string]: {
    branch: string
    buildNumber: string
  }
}

export const BuildInfo: React.FC<BuildInfoProps> = React.memo(
  ({ backends }) => {
    const [backendInfo, updateBuildInfo] = useState<BuildInfoDict>({})
    const [loading, updateLoading] = useState<boolean>(true)

    useEffect(() => {
      let isMounted = true

      Promise.all(
        backends.map(async backend => {
          const result = await backend.getVersion()
          if ('error' in result) {
            return [
              backend.name,
              {
                branch: 'ERROR',
                buildNumber: result.error,
              },
            ]
          }
          return [
            backend.name,
            {
              branch: result.branch,
              buildNumber: `v.${result.buildNumber}`,
            },
          ]
        }),
      ).then((results: any[]) => {
        if (isMounted) {
          updateLoading(false)
          updateBuildInfo(Object.fromEntries(results))
        }
      })

      return () => {
        isMounted = false
      }
    }, [backends])

    return (
      <Popup
        trigger={
          <NavBarButton>
            {BUILD_NAME} v.{BUILD_ID}
          </NavBarButton>
        }
        on="click"
        wide
      >
        <p>
          Navis Rail {BUILD_NAME} v.{BUILD_ID}
        </p>
        {Object.entries(backendInfo).map(([name, { branch, buildNumber }]) => (
          <p key={name}>
            {loading ? 'loading' : `${name}: ${branch} ${buildNumber}`}
          </p>
        ))}
      </Popup>
    )
  },
)
