import { groupBy } from 'lodash'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import {
  TrainStartAttrs,
  TemplateLegAttrs,
} from 'src/service-design/sd-plan/document/types'
import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { StartLeg } from './model'

export function generateStartLegs(
  trainStarts: TrainStartAttrs[],
  templateLegs: TemplateLegAttrs[],
) {
  const legsByTemplate = groupBy(templateLegs, x => x.templateId)
  return trainStarts.flatMap(({ id: startId, templateId }) =>
    (legsByTemplate[templateId] || []).map(({ id: templateLegId }) => ({
      id: `${startId}:${templateLegId}`,
      startId,
      templateId,
      templateLegId,
    })),
  )
}

export const startLegFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    generateStartLegs(
      collectionData.trainstarts,
      collectionData.templatelegs,
    ).map(args => new StartLeg(args)),
  setRelsFromSpec((l: StartLeg, state: any) => ({
    start: registry.provide('trainStartRepo')(state).byId(l.startId),
    template: registry.provide('trainTemplateRepo')(state).byId(l.templateId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(l.templateLegId),
    singletons: state.collectionData.singletons,
    emptywagonevents: registry
      .provide('emptyWagonEventRepo')(state)
      .byStartLegId(l.id),
    locoevents: registry.provide('locoEventRepo')(state).byStartLegId(l.id),
    localAssignments: registry
      .provide('localAssignmentRepo')(state)
      .byStartLegId(l.id),
    serviceevents: registry
      .provide('serviceEventRepo')(state)
      .byStartLegId(l.id),
    locationchangeovers: registry
      .provide('locationChangeoverRepo')(state)
      .byStartLegId(l.id),
    workinglocolock: registry
      .provide('workingLocoLockRepo')(state)
      .byStartLegId(l.id),
    loadingAssignments: registry
      .provide('loadingAssignmentRepo')(state)
      .byStartLegId(l.id),
    loadingWorkSplits: registry
      .provide('loadingWorkSplitRepo')(state)
      .byStartLegId(l.id),
    uncrewedLegTasks: registry
      .provide('uncrewedLegTaskRepo')(state)
      .byStartLegId(l.id),
    taskOffsets: registry
      .provide('startLegTaskOffsetRepo')(state)
      .byStartLegId(l.id),
  })),
)
