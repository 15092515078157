import * as constants from 'src/service-design/shared/constants'
import { CrewPool } from 'src/service-design/shared/models/crew-pool'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Shift } from 'src/service-design/shared/models/shift'
import {
  durationString,
  snapTo,
  EpochTime,
  Interval,
  Duration,
} from 'src/service-design/shared/utils/dates'

export const restDuration = (
  startShiftSignOffLocal: number,
  nextShiftSignOnLocal: number,
) =>
  snapTo(nextShiftSignOnLocal, startShiftSignOffLocal) - startShiftSignOffLocal

interface Attrs {
  id: string
  poolId: string
  startShiftId: string
  nextShiftId: string
  locationId: string
}

interface Rels {
  pool: CrewPool
  startShift: Shift
  nextShift: Shift
  location: Location
}

class RemoteRest extends Mapper {
  public entityName = 'RemoteRest'

  /**
   * A RemoteRest joins two Shifts in one CrewPool, and represents a rest
   * at a Location between those Shifts.
   *
   * The RemoteRest location will replace the depot as the end location for
   * the first shift, and will replace the depot as the start location
   * for the second shift. This primarily influences LV Trip generation
   * for the shift.
   *
   * N.B. Remote Rests must have duration in the interval [0,3.5 weeks)
   * This is achieved with snapTo which keeps the endTimeLocal within 3.5 week
   * after the startTimeLocal
   *
   * Related models:
   * - `Shift`;
   * - `Location`;
   * - `LVTrip`;
   * - `CrewPool`;
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} poolId - The id of the CrewPool the RemoteRest belongs to.
   * @param {string} startShiftId - The id of the Shift prior to the RemoteRest.
   * @param {string} nextShiftId - The id of the Shift after to the RemoteRest.
   **/
  constructor({ id, poolId, startShiftId, nextShiftId, locationId }: Attrs) {
    super()
    this.id = id
    this.poolId = poolId
    this.startShiftId = startShiftId
    this.nextShiftId = nextShiftId
    this.locationId = locationId
  }

  setRels({ pool, startShift, nextShift, location }: Rels) {
    this.pool = pool
    this.startShift = startShift
    this.nextShift = nextShift
    this.location = location
  }

  get displayName(): string {
    return `Rest at ${this.location.code} between shifts ${this.startShift.name} and ${this.nextShift.name}`
  }

  /**
   * @deprecated
   */
  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return this.startShift.signOffLocalVO
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return EpochTime.fromSeconds(
      snapTo(
        this.nextShift.signOnLocalVO.toSeconds(),
        this.startTimeLocalVO.toSeconds(),
      ),
    )
  }

  /**
   * @deprecated
   */
  get duration(): number {
    return this.durationVO.toSeconds()
  }

  get durationVO(): Duration {
    return Interval.fromEpochTimes(
      this.startTimeLocalVO,
      this.endTimeLocalVO,
    ).duration()
  }

  get durationString(): string {
    return durationString(this.duration)
  }

  /**
   * @deprecated
   */
  get remoteRestMinDurationSecs(): number {
    return this.remoteRestMinDurationSecsVO.toSeconds()
  }

  get remoteRestMinDurationSecsVO(): Duration {
    return this.pool.type.remoteRestMinDurationSecsVO
  }

  /**
   * @deprecated You should use remoteRestMinDurationSecsVO
   */
  get remoteRestMinDurationHours(): number {
    return this.remoteRestMinDurationSecs / constants.SECONDS_PER_HOUR
  }
}

interface RemoteRest extends Attrs, Rels {}
export { RemoteRest }
