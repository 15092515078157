import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { ServiceLock } from './model'

export class InvalidServiceLock extends InstanceRepair {
  static collectionName = 'servicelocks'

  static type = 'service-design::Locked Demand Route Has Unlocked Train'

  static message =
    'service-design::Demand {{entity.service.name}} requires trains [{{trains}}] to be locked'

  static check(serviceLock: ServiceLock) {
    const { service } = serviceLock
    return service.assignments.some(a => !a.start.lock)
  }

  constructor(lock: ServiceLock) {
    const { service } = lock
    super(lock, {
      trains: service.assignments
        .filter(a => !a.start.lock)
        .map(a => a.start.name)
        .join(', '),
    })
  }
}

export const getServiceLockRepairs = getRepairFactory([InvalidServiceLock])
