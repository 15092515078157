import React from 'react'

import { ENVIRONMENT } from 'src/core/constants'
import { LoadingSpinner } from 'src/service-design/shared/ui'

const EntryPointMap = {
  TR: React.lazy(() => import('./clients/tr/EntryPoint')),
  KCS: React.lazy(() => import('./clients/kcs/EntryPoint')),
  NRDemo: React.lazy(() => import('./clients/nrdemo/EntryPoint')),
} as const

if (ENVIRONMENT !== 'prod') {
  document.title = `${document.title} [${ENVIRONMENT}]`
}

function isValidEnv(env: string): env is keyof typeof EntryPointMap {
  return env in EntryPointMap
}

const App: React.FC = () => {
  if (isValidEnv(process.env.REACT_APP_TARGET_ENV)) {
    const EntryPoint = EntryPointMap[process.env.REACT_APP_TARGET_ENV]
    return (
      <React.Suspense fallback={<LoadingSpinner />}>
        <EntryPoint />
      </React.Suspense>
    )
  }
  throw new Error(
    `${process.env.REACT_APP_TARGET_ENV} has no EntryPoint configured`,
  )
}

export default App
