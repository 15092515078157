export interface FromDocumentFactory<D, T> {
  (document: D): T
}

export interface FromDocumentPostProcesser<D, T> {
  (collection: T, document: D): void
}

export function createFactoryFromDocument<D, T>(
  factory: FromDocumentFactory<D, T>,
  postProcessor?: FromDocumentPostProcesser<D, T>,
): FromDocumentFactory<D, T> {
  let cachedDocument: D | null = null
  let cached: T | null = null

  const _factory = (document: D): T => {
    if (document === cachedDocument) {
      return cached
    }

    // memoizing happens here, this allows postFactory to call factories that
    // call this one, in their own postProcessor calls. Order of this caching is
    // also important, by calling the factory first we're preventing the
    // factory from calling itself and getting the previous collection.
    cached = factory(document)
    cachedDocument = document

    if (postProcessor) {
      postProcessor(cached, document)
    }

    return cached
  }
  return _factory
}

// Factory helpers

interface IWithSetRels {
  setRels(rels: { [K: string]: any }): void
}

type SetRelParams<T extends IWithSetRels> = Parameters<T['setRels']>[0]

type RelSpec<T extends IWithSetRels, D> = (
  val: T,
  document: D,
) => {
  [K in keyof SetRelParams<T>]: SetRelParams<T>[K]
}

export const setRelsFromSpec = <T extends IWithSetRels[], D>(
  spec: RelSpec<T[0], D>,
): FromDocumentPostProcesser<D, T> => (collection: T, document: D): void => {
  collection.forEach(val => {
    const rels: SetRelParams<T[0]> = spec(val, document)
    val.setRels(rels)
  })
}
