import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { RosterHead } from './model'

export const rosterHeadFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.rosterheads.map(data => new RosterHead(data)),
  setRelsFromSpec((rosterHead: RosterHead, state: any) => ({
    parent: registry.provide('crewPoolRepo')(state).byId(rosterHead.parentId),
    head: registry.provide('rosterLineRepo')(state).byId(rosterHead.headId),
  })),
)
