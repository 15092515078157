import { decode as base64Decode } from 'base64-arraybuffer'
import { saveAs } from 'file-saver'
import { createAction } from 'typesafe-actions'

import { fetchDocuments } from 'src/service-design/shared/document/actions'
import { Document } from 'src/service-design/shared/document/types'

// this is a hack so we can stub saveAs in cypress testing of file download
window.saveAs = saveAs

export const documentsExportStarted = createAction('DOCUMENTS_EXPORT_STARTED')()
export const documentsExportSuccess = createAction('DOCUMENTS_EXPORT_SUCCESS')()

const downloadExportResult = async (base64Result: string, fileName: string) => {
  const contentType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const b64array = base64Decode(base64Result)
  const blobbed = new Blob([b64array], { type: contentType })
  const fileToSave = new File([blobbed], fileName, { type: contentType })
  window.saveAs(fileToSave)
}

const getFileName = (documents: Document[]): string => {
  const documentType = documents[0].meta.type
  let exportName =
    documents.length === 1 ? documents[0].meta.name : `${documentType}`
  if (exportName.slice(-5) !== '.xlsx') {
    exportName += '.xlsx'
  }
  return exportName
}

export function exportDocumentAction(documentId: number) {
  return exportDocumentsAction([documentId])
}

// TODO this type is just me desperately matching Workerize type
export type ExportWorkerType = {
  exportDocuments: (
    exportType: any, // TODO yep
    documents: Document[],
    parentDocuments: { [key: string]: Document },
  ) => Promise<Promise<string>>
}

// TODO replace me with a proper dependency injection
let exportWorker: ExportWorkerType
export const setExportWorker = (worker: ExportWorkerType) => {
  exportWorker = worker
}

export function exportDocumentsAction(
  documentIds: number[],
  exportTypeOverride: string = null,
) {
  if (!exportWorker) {
    throw new Error(
      "exportWorker has not been set. Did you forget to call 'setExportWorker'?",
    )
  }

  return async (dispatch: any) => {
    dispatch(documentsExportStarted())
    const { documents, parentDocuments } = await fetchDocuments(documentIds)

    const exportType = exportTypeOverride || documents[0].meta.type

    const fileStr = await exportWorker.exportDocuments(
      // @ts-ignore export type is a string not a 'document type'
      exportType,
      documents,
      parentDocuments,
    )
    const fileName = getFileName(documents)
    await downloadExportResult(fileStr, fileName)
    dispatch(documentsExportSuccess())
  }
}
