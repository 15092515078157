import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { CargoType } from './model'

export interface ICargoTypeRepo extends IRepo<CargoType> {
  byWagonId(id: string): CargoType[]
}

export class CargoTypeRepo implements ICargoTypeRepo {
  values: CargoType[]
  idMap: { [key: string]: CargoType }
  wagonIdMap: Indexer<CargoType>

  constructor(values: CargoType[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.wagonIdMap = new Indexer(values, x => x.wagonId)
  }

  byId(id: string): CargoType {
    return this.idMap[id]
  }

  byWagonId(wagonId: string): CargoType[] {
    return this.wagonIdMap.lookup(wagonId)
  }
}
