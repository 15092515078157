import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { locationChangeoverFactory } from './factory'
import { ILocationChangeoverRepo, LocationChangeoverRepo } from './repo'

const locationChangeoverRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LocationChangeoverRepo(locationChangeoverFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  locationChangeoverRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type LocationChangeoverRepoReg = {
  locationChangeoverRepo: FromDocumentFactory<
    FactoryInput,
    ILocationChangeoverRepo
  >
}

export function setup<R extends LocationChangeoverRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('locationChangeoverRepo', locationChangeoverRepo)

  relationships.addConstraints('locationchangeovers', {
    unique: [['templateLegId', 'startId', 'atDeparture']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
    ],
  })
}

export { LocationChangeover } from './model'
