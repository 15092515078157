import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { CargoType } from './model'

export const cargoTypeFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.cargotypes.map(data => new CargoType(data)),
  setRelsFromSpec((cargoType: CargoType, state: any) => ({
    wagon: registry.provide('wagonRepo')(state).byId(cargoType.wagonId),
    compatibilities: registry
      .provide('locationCargoCompatibilityRepo')(state)
      .byCargoTypeId(cargoType.id),
    wagoncompatibilities: registry
      .provide('wagonCargoCompatibilityRepo')(state)
      .byCargoTypeId(cargoType.id),
  })),
)
