import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Delta } from 'src/service-design/shared/utils/dates'

import { RDO } from './model'

export const rdoFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.rdos.map(
      ({ delta, ...data }) =>
        new RDO({ delta: Delta.fromSeconds(delta), ...data }),
    ),
  setRelsFromSpec((rdo: RDO, state: any) => ({
    line: registry.provide('rosterLineRepo')(state).byId(rdo.lineId),
  })),
)
