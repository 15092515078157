import { createSelector } from 'reselect'

import {
  TrainGraphLocationAttrs,
  TrainGraphAttrs,
} from 'src/service-design/scenario/document/types'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  HeadwayToken,
  OpposingTrainsOnCorridorToken,
  TrainCapacityExceededOnLocationToken,
} from 'src/service-design/sd-plan/selectors/conflicts'
import { getLocations } from 'src/service-design/sd-plan/selectors/scenario'
import { getWarningRepo } from 'src/service-design/sd-plan/selectors/warnings'
import { Location } from 'src/service-design/shared/models/location/model'
import * as Service from 'src/service-design/shared/models/service'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import { EpochTime } from 'src/service-design/shared/utils/dates'
import { Warning, WarningRepo } from 'src/service-design/shared/warnings'

export const getRawTrainGraphDefinitions = (state: any): TrainGraphAttrs[] =>
  getCollection(state, 'scenario', 'traingraphs')
export const getRawTrainGraphLocations = (
  state: any,
): TrainGraphLocationAttrs[] =>
  getCollection(state, 'scenario', 'traingraphlocations')

const HEADER_OFFSET = 10
const PADDING = 90

interface YLocation extends Location {
  yPosition: number
}

export type YTick = {
  name: string
  id?: string
  value: number
  header: boolean
}

export type Point = {
  id?: string
  yPosition: number
  time: number
  end?: boolean
}

export type Path = {
  interconnector: boolean
  points: Point[]
}

export type Entity = {
  id: string
  name: string
  className: string
  points: Point[]
  paths: Path[]
  train?: TrainStart.TrainStart
  service?: Service.Service
}

interface ISegment {
  origin: Location
  dest: Location
  departsLocal: EpochTime | number // TODO fixme when Task has VOs
  arrivesLocal: EpochTime | number
}

export class TrainGraph {
  public HEADER_OFFSET: number
  public PADDING: number
  public sortedYLocations: YLocation[]
  public yLocations: Map<string, YLocation>

  constructor(
    public name: string,
    public offset: number,
    yLocations: YLocation[],
  ) {
    this.name = name
    this.offset = offset
    this.HEADER_OFFSET = HEADER_OFFSET
    this.PADDING = PADDING

    this.yLocations = new Map(yLocations.map(l => [l.id, l]))

    this.sortedYLocations = [...yLocations].sort(
      (l1, l2) => l1.yPosition - l2.yPosition,
    )
  }

  static buildCollection(
    definitions: TrainGraphAttrs[],
    trainGraphLocations: TrainGraphLocationAttrs[],
    locations: Map<string, Location>,
  ) {
    let offset = 0
    return definitions.map(definition => {
      // @ts-ignore: class is spread into an object including methods
      const yLocations: YLocation[] = trainGraphLocations
        .filter(l => l.trainGraphId === definition.id)
        .map(l => ({
          yPosition: l.yPosition,
          ...locations.get(l.locationId),
        }))

      const trainGraph = new TrainGraph(definition.name, offset, yLocations)
      offset += trainGraph.height
      return trainGraph
    })
  }

  get yTicks(): YTick[] {
    return [
      {
        name: this.name,
        value: this.offset - this.HEADER_OFFSET,
        header: true,
      },
      ...this.sortedYLocations.map((l, _, locs) => ({
        name: l.code,
        id: l.id,
        value: l.yPosition - locs[0].yPosition + this.offset,
        header: false,
      })),
    ]
  }

  get height() {
    if (this.sortedYLocations.length < 1) {
      return 0
    }

    const first = this.sortedYLocations[0]
    const [last] = this.sortedYLocations.slice(-1)
    return last.yPosition - first.yPosition + this.HEADER_OFFSET + this.PADDING
  }

  containsLocation(location: Location) {
    return this.containsLocationId(location.id)
  }

  containsLocationId(locationId: string) {
    return this.yLocations.has(locationId)
  }

  coversLeg(leg: ISegment) {
    return this.containsLocation(leg.origin) && this.containsLocation(leg.dest)
  }

  coversLegPartially(leg: ISegment) {
    return this.containsLocation(leg.origin) || this.containsLocation(leg.dest)
  }

  yPosition(locationId: string): number {
    const yLocation = this.yLocations.get(locationId)
    if (!yLocation) {
      return NaN
    }
    return (
      yLocation.yPosition - this.sortedYLocations[0].yPosition + this.offset
    )
  }
}

const pointPosition = (trainGraphs: TrainGraph[], location: Location) => {
  const trainGraph = trainGraphs.find(g => g.containsLocation(location))
  // TODO: handle missing train graphs
  return trainGraph ? trainGraph.yPosition(location.id) : 0
}

export const pathFromLocation = (
  trainGraphs: TrainGraph[],
  conflict: { entityId: string; startTime: number; endTime: number },
): Path => {
  const trainGraph = trainGraphs.filter(g =>
    g.containsLocationId(conflict.entityId),
  )[0]
  return {
    interconnector: false,
    points: [
      {
        yPosition: trainGraph.yPosition(conflict.entityId),
        time: conflict.startTime,
      },
      {
        yPosition: trainGraph.yPosition(conflict.entityId),
        time: conflict.endTime,
      },
    ],
  }
}

export const pathFromLegs = (
  trainGraphs: TrainGraph[],
  legsAndTasks: ISegment[],
): Path[] =>
  legsAndTasks.reduce<Path[]>(
    (acc, legOrTask) => {
      const candidates = trainGraphs.filter(g =>
        g.coversLegPartially(legOrTask),
      )
      const trainGraph =
        candidates.find(g => g.coversLeg(legOrTask)) || candidates[0]

      if (!trainGraph) {
        return acc
      }

      const legPoints = [
        {
          yPosition: trainGraph.yPosition(legOrTask.origin.id),
          time:
            legOrTask.departsLocal instanceof EpochTime
              ? legOrTask.departsLocal.toSeconds()
              : legOrTask.departsLocal,
        },
        {
          yPosition: trainGraph.yPosition(legOrTask.dest.id),
          time:
            legOrTask.arrivesLocal instanceof EpochTime
              ? legOrTask.arrivesLocal.toSeconds()
              : legOrTask.arrivesLocal,
        },
      ]

      const [lastPath] = acc.slice(-1)
      const [lastPoint] = lastPath.points.length
        ? lastPath.points.slice(-1)
        : [legPoints[0]]

      // Perhaps the interconnector code should be part of a pipeline
      // instead of directly mutating the leg points right here
      const needsInterconnector = lastPoint.yPosition !== legPoints[0].yPosition

      // the NaNs occur when a location is missing in the trainGraph
      // NaNs comparison is always false so interconnectors would be added
      // without this check
      // interconnectors to/from missing locations in the trainGraph are daft
      const hasNaNs =
        isNaN(lastPoint.yPosition) || isNaN(legPoints[0].yPosition)

      if (needsInterconnector && !hasNaNs) {
        // ensure dwell is rendered to location closest to top of graph
        const needsInterconnectorBeforeDwell =
          lastPoint.yPosition > legPoints[0].yPosition
        if (needsInterconnectorBeforeDwell) {
          const dwellPoint = {
            yPosition: legPoints[0].yPosition,
            time: lastPoint.time,
          }
          legPoints.unshift(dwellPoint)
          acc.push({
            interconnector: true,
            points: [lastPoint, dwellPoint],
          })
        } else {
          const dwellPoint = {
            yPosition: lastPoint.yPosition,
            time: legPoints[0].time,
          }
          lastPath.points.push(dwellPoint)
          acc.push({
            interconnector: true,
            points: [dwellPoint, legPoints[0]],
          })
        }
        acc.push({
          interconnector: false,
          points: legPoints,
        })
      } else {
        lastPath.points.push(...legPoints)
      }

      return acc
    },
    [
      {
        interconnector: false,
        points: [],
      },
    ],
  )

export const renderService = (
  trainGraphs: TrainGraph[],
  service: Service.Service,
): { points: Point[]; paths: Path[] } => {
  const origin = pointPosition(trainGraphs, service.origin)
  const dest = pointPosition(trainGraphs, service.destination)
  const cutOff = {
    id: service.id,
    yPosition: origin,
    time: service.deliveryCutOffLocal,
  }
  const due = {
    id: service.id,
    yPosition: dest,
    time: service.dueLocal,
    end: true, // will be hidden when the services is not selected
  }
  const loaded = service.activities.length
    ? {
        time: service.activities[0].startTimeLocal,
        yPosition: pointPosition(trainGraphs, service.activities[0].origin),
      }
    : {
        time: service.dueLocal,
        yPosition: pointPosition(trainGraphs, service.destination),
      }
  const unloaded = {
    time: service.terminatingLocal,
    yPosition: pointPosition(trainGraphs, service.terminatingLocation),
  }

  return {
    points: [cutOff, due],
    paths: [
      {
        interconnector: true,
        points: [cutOff, loaded],
      },
      ...pathFromLegs(
        trainGraphs,
        service.activities.reduce<ISegment[]>((acc, activity) => {
          if (activity.offsetLegs) {
            acc.push(...activity.offsetLegs)
          } else {
            acc.push(activity)
          }
          return acc
        }, []),
      ),
      {
        interconnector: true,
        points: [unloaded, due],
      },
    ],
  }
}

export const renderTrainPaths = (
  trainGraphs: TrainGraph[],
  train: TrainStart.TrainStart,
) => pathFromLegs(trainGraphs, train.legs)

export const getTrainGraphs = createSelector(
  getRawTrainGraphDefinitions,
  getRawTrainGraphLocations,
  getLocations,
  TrainGraph.buildCollection,
)

export const layoutTrainGraph = (
  trainGraphs: TrainGraph[],
  trainStarts: TrainStart.TrainStart[],
  services: Service.Service[],
): Entity[] =>
  [
    ...services.map(
      (service: Service.Service): Entity => ({
        id: service.id,
        name: service.name,
        className: 'service',
        service,
        ...renderService(trainGraphs, service),
      }),
    ),
    ...trainStarts.map(
      (train: TrainStart.TrainStart): Entity => ({
        className: 'train',
        id: train.id,
        name: train.name,
        train,
        points: [],
        paths: renderTrainPaths(trainGraphs, train),
      }),
    ),
  ].map(stripNaNFromEntity)

export const getTrainGraphYTicks = createSelector(getTrainGraphs, trainGraphs =>
  trainGraphs.reduce<YTick[]>(
    (acc, trainGraph) => [...acc, ...trainGraph.yTicks],
    [],
  ),
)

const layoutTrainGraphConflicts = (
  trainGraphs: TrainGraph[],
  startLegs: StartLeg.StartLeg[],
  warningRepo: WarningRepo<any, any>,
): Entity[] => [
  ...warningRepo.byType(TrainCapacityExceededOnLocationToken).map(
    (conflict): Entity => ({
      id: conflict.entityId,
      name: conflict.message,
      className: 'conflict',
      points: [],
      paths: [pathFromLocation(trainGraphs, conflict)],
    }),
  ),
  ...startLegs.flatMap(startLeg =>
    warningRepo
      .byEntity(StartLeg.StartLeg, startLeg, [
        OpposingTrainsOnCorridorToken,
        HeadwayToken,
      ])
      .map(
        (conflict: Warning): Entity => ({
          id: conflict.id,
          name: conflict.message,
          className: 'conflict',

          points: [],
          paths: pathFromLegs(trainGraphs, [startLeg]),
        }),
      ),
  ),
]

export const getTrainGraphData = createSelector(
  getTrainGraphs,
  TrainStart.values,
  Service.values,
  layoutTrainGraph,
)

export const getTrainGraphConflicts = createSelector(
  getTrainGraphs,
  StartLeg.values,
  getWarningRepo,
  layoutTrainGraphConflicts,
)

// Postprocessing to remove points with NaN locations
// the NaNs occur when a location is missing in the trainGraph
// this bug is [BOSS-3179]
const stripNaNFromEntity = (entity: Entity) => ({
  ...entity,
  points: stripNaN(entity.points),
  paths: entity.paths.map(path => ({
    interconnector: path.interconnector,
    points: stripNaN(path.points),
  })),
})

const stripNaN = (points: Point[]) =>
  points.filter(point => !isNaN(point.yPosition))
