import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { WagonAccessRight } from './model'

export interface IWagonAccessRightRepo extends IRepo<WagonAccessRight> {
  byAccessGroupId(id: string): WagonAccessRight[]
  byWagonId(id: string): WagonAccessRight[]
}

export class WagonAccessRightRepo implements IWagonAccessRightRepo {
  values: WagonAccessRight[]
  idMap: { [key: string]: WagonAccessRight }
  wagonIdMap: Indexer<WagonAccessRight>
  accessGroupIdMap: Indexer<WagonAccessRight>

  constructor(values: WagonAccessRight[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.wagonIdMap = new Indexer(values, x => x.wagonId)
    this.accessGroupIdMap = new Indexer(values, x => x.accessGroupId)
  }

  byId(id: string): WagonAccessRight {
    return this.idMap[id]
  }

  byWagonId(id: string): WagonAccessRight[] {
    return this.wagonIdMap.lookup(id)
  }

  byAccessGroupId(id: string): WagonAccessRight[] {
    return this.accessGroupIdMap.lookup(id)
  }
}
