import React from 'react'
import { Button, ButtonProps } from 'semantic-ui-react'

import { downloadCSV } from 'src/service-design/shared/utils'

interface DownloadButtonProps extends ButtonProps {
  name: string
  getCSVDownload: () => {
    mime: string
    filename: string
    contents: any
  }
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  name,
  getCSVDownload,
  ...props
}) => (
  <Button
    icon="download"
    content={name}
    onClick={() => downloadCSV(getCSVDownload())}
    basic
    {...props}
  />
)
