import { get } from 'lodash'
import { connect } from 'react-redux'

import { AnyAction, Dispatch } from 'redux'

import {
  documentsLoad,
  postDocumentLoadDisplayModalError,
} from 'src/service-design/shared/document/actions'
import { DocumentSpec } from 'src/service-design/shared/document/types'
import { currentUserLoad } from 'src/service-design/shared/users/actions'

import { DocumentLoader as DocumentLoaderComp } from './component'

type ExtraAction =
  | ((documentId: string) => AnyAction)
  | ((documentId: string) => (dispatch: Dispatch<any>) => Promise<void>)

interface Props {
  documentRoot?: string
  documentSpec: DocumentSpec<any>
  extraActions?: ExtraAction[]
}

const mapStateToProps = (state: any, { documentRoot }: Props) => {
  const document = documentRoot && get(state, documentRoot)
  return {
    loaded: state.loading.loaded,
    hasErrors: state.loading.hasErrors,
    loadedId: document && document.meta.id,
  }
}

const mapDispatchToProps = (
  dispatch: any,
  { documentSpec, documentRoot, extraActions = [] as ExtraAction[] }: Props,
) => ({
  load: (id: string) =>
    Promise.all([
      dispatch(documentsLoad(id)),
      ...extraActions.map(a => dispatch(a(id))),
      dispatch(currentUserLoad()),
    ]).then(() =>
      dispatch(
        postDocumentLoadDisplayModalError({
          documentSpec,
          documentRoot,
        }),
      ),
    ),
})

export const DocumentLoader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentLoaderComp)
