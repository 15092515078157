import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LoadTable } from './model'

export interface ILoadTableRepo extends IRepo<LoadTable> {
  byCorridorId(id: string): LoadTable[]
  byTrainTypeId(id: string): LoadTable[]
  byLoadCategory(id: string): LoadTable[]
}

export class LoadTableRepo implements ILoadTableRepo {
  values: LoadTable[]
  idMap: { [key: string]: LoadTable }
  corridorIdMap: Indexer<LoadTable>
  trainTypeIdMap: Indexer<LoadTable>
  loadCategoryMap: Indexer<LoadTable>

  constructor(values: LoadTable[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.corridorIdMap = new Indexer(values, x => x.corridorId)
    this.trainTypeIdMap = new Indexer(values, x => x.trainTypeId)
    this.loadCategoryMap = new Indexer(values, x => x.loadCategory)
  }

  byId(id: string): LoadTable {
    return this.idMap[id]
  }

  byCorridorId(corridorId: string): LoadTable[] {
    return this.corridorIdMap.lookup(corridorId)
  }

  byTrainTypeId(trainTypeId: string): LoadTable[] {
    return this.trainTypeIdMap.lookup(trainTypeId)
  }

  byLoadCategory(loadCategory: string): LoadTable[] {
    return this.loadCategoryMap.lookup(loadCategory)
  }
}
