import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { WagonSet } from './model'

export interface IWagonSetRepo extends IRepo<WagonSet> {
  byServiceId(id: string): WagonSet
}

export class WagonSetRepo implements IWagonSetRepo {
  values: WagonSet[]
  idMap: { [key: string]: WagonSet }
  serviceIdMap: { [key: string]: WagonSet }

  constructor(values: WagonSet[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.serviceIdMap = Object.fromEntries(values.map(v => [v.serviceId, v]))
  }

  byId(id: string): WagonSet {
    return this.idMap[id]
  }

  byServiceId(id: string): WagonSet {
    return this.serviceIdMap[id]
  }
}
