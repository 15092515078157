import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { YardBlockAssignment } from './model'

export class InvalidYardBlockAssignment extends InstanceRepair {
  static collectionName = 'yardblockassignments'

  static type = 'service-design::Invalid yard block assignment'

  static message =
    'service-design::Yard Block Assignment (id={{entity.id}}) has a train block and departing leg which do not belong to the same template.'

  static check({ trainBlock, departingLeg }: YardBlockAssignment) {
    return trainBlock.template !== departingLeg.template
  }
}

export const getYardBlockAssignmentRepairs = getRepairFactory([
  InvalidYardBlockAssignment,
])
