// Assumes each Map has distinct keys
export const mergeMultiplyCounts = <T>(
  multiplier: number,
  ...maps: Map<T, number>[]
) =>
  new Map(
    maps
      .reduce<[T, number][]>((acc, x) => [...acc, ...x], [])
      .map(([k, v]) => [k, v * multiplier]),
  )
