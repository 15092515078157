import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { YardBlock } from './model'

export interface IYardBlockRepo extends IRepo<YardBlock> {
  byOriginId(id: string): YardBlock[]
  byDestinationId(id: string): YardBlock[]
}

export class YardBlockRepo implements IYardBlockRepo {
  values: YardBlock[]
  idMap: { [key: string]: YardBlock }
  originIdMap: Indexer<YardBlock>
  destinationIdMap: Indexer<YardBlock>

  constructor(values: YardBlock[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.originIdMap = new Indexer(values, x => x.originId)
    this.destinationIdMap = new Indexer(values, x => x.destinationId)
  }

  byId(id: string): YardBlock {
    return this.idMap[id]
  }

  byOriginId(id: string): YardBlock[] {
    return this.originIdMap.lookup(id)
  }

  byDestinationId(id: string): YardBlock[] {
    return this.destinationIdMap.lookup(id)
  }
}
