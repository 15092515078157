import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { ServiceLock } from './model'

export interface IServiceLockRepo extends IRepo<ServiceLock> {
  byServiceId(id: string): ServiceLock
}

export class ServiceLockRepo implements IServiceLockRepo {
  values: ServiceLock[]
  idMap: { [key: string]: ServiceLock }
  serviceIdMap: { [key: string]: ServiceLock }

  constructor(values: ServiceLock[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.serviceIdMap = Object.fromEntries(values.map(v => [v.serviceId, v]))
  }

  byId(id: string): ServiceLock {
    return this.idMap[id]
  }

  byServiceId(serviceId: string): ServiceLock {
    return this.serviceIdMap[serviceId]
  }
}
