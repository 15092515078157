import { LocationChangeoverLock } from 'src/service-design/shared/models/changeover-lock/location'
import {
  Changeover,
  Timing,
} from 'src/service-design/shared/models/changeover/base'
import { Location } from 'src/service-design/shared/models/location'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Duration } from 'src/service-design/shared/utils/dates'

class LocationChangeover extends Changeover {
  startId: string
  templateLegId: string
  start: TrainStart
  startLeg: StartLeg
  templateLeg: TemplateLeg
  _timing: Timing

  /**
   * A LocationChangeover represents a Changeover between a driver on a
   * TrainStart and a driver idle at a Location.
   *
   * There are a number of reasons a driver might be idle at the Location:
   * - this is their depot;
   * - they drove a TrainStart to this Location and performed a
   *    LocationChangeover;
   * - they drove an LV (see LVTask) to this Location.
   *
   * Related models:
   * - `Changeover`;
   * - `DriverTask`;
   * - `TemplateLeg`;
   * - `TrainStart`;
   * - `Location`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} startId - The id of the TrainStart.
   * @param {string} templateLegId - The id of the TemplateLeg.
   * @param {boolean} atDeparture - See Changeover.
   **/
  constructor({
    id,
    startId,
    templateLegId,
    atDeparture,
    timeOffset,
  }: {
    id: string
    startId: string
    templateLegId: string
    atDeparture: boolean
    timeOffset: Duration
  }) {
    super({
      id,
      atDeparture,
      timeOffset,
    })
    this.startId = startId
    this.templateLegId = templateLegId
  }

  setRels({
    start,
    startLeg,
    templateLeg,
    singletons,
    changeoverLock,
  }: {
    start: TrainStart
    startLeg: StartLeg
    templateLeg: TemplateLeg
    singletons: any
    changeoverLock: LocationChangeoverLock
  }) {
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
    super.setRels({ singletons, changeoverLock })
  }

  legFor(start: TrainStart): StartLeg | null {
    if (this.start === start) {
      return this.startLeg
    }
    return null
  }

  get name(): string {
    return `${this.startLeg.start.name} - Crew change on ${
      this.atDeparture ? 'departure' : 'arrival'
    }`
  }

  get location(): Location {
    return this.startLeg[this.atDeparture ? 'origin' : 'dest']
  }

  protected get timing() {
    if (!this._timing) {
      this._timing = Changeover.timingsFromLegs(
        this.legs,
        this.atDeparture,
        this.singletons.crew.changeoverSecs,
      )
    }
    return this._timing
  }

  get dwellWindow(): { start: number; end: number } {
    return Changeover.dwellWindow(this.legs, this.atDeparture)
  }

  get legs(): StartLeg[] {
    return [this.startLeg]
  }
}

export { LocationChangeover }
