import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { LocoEvent } from './model'

export class TerminatingLocoDetach extends InstanceRepair {
  static collectionName = 'locoevents'

  static type = 'service-design::Terminating Loco Detachment'

  static message =
    'service-design::Terminating leg {{entity.leg.name}} has loco detachs. This is not permitted.'

  static check(event: LocoEvent) {
    return event.startLeg.terminating && event.type === 'detach'
  }
}

export class CannotChangeLocos extends InstanceRepair {
  static collectionName = 'locoevents'

  static type = 'service-design::Occurs at a non-yard location'

  static message =
    'service-design::Locos on train {{entity.startLeg.start.name}} attach/detach at {{entity.location.code}} which cannot change locos'

  static check(event: LocoEvent) {
    const originatingAttach =
      event.startLeg.originating && event.type === 'attach'
    const terminatingDetach =
      event.startLeg.terminating && event.type === 'detach'
    return (
      !event.location.canChangeLocos && !originatingAttach && !terminatingDetach
    )
  }
}

export const getLocoEventRepairs = getRepairFactory([
  TerminatingLocoDetach,
  CannotChangeLocos,
])
