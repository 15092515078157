import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { RemoteRest } from './model'

export interface IRemoteRestRepo extends IRepo<RemoteRest> {
  byStartShiftId(id: string): RemoteRest
  byNextShiftId(id: string): RemoteRest
  byPoolId(id: string): RemoteRest[]
  byLocationId(id: string): RemoteRest[]
}

export class RemoteRestRepo implements IRemoteRestRepo {
  values: RemoteRest[]
  idMap: { [key: string]: RemoteRest }
  startShiftIdMap: { [key: string]: RemoteRest }
  nextShiftIdMap: { [key: string]: RemoteRest }
  poolIdMap: Indexer<RemoteRest>
  locationIdMap: Indexer<RemoteRest>

  constructor(values: RemoteRest[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startShiftIdMap = Object.fromEntries(
      values.map(v => [v.startShiftId, v]),
    )
    this.nextShiftIdMap = Object.fromEntries(
      values.map(v => [v.nextShiftId, v]),
    )
    this.poolIdMap = new Indexer(values, x => x.poolId)
    this.locationIdMap = new Indexer(values, x => x.locationId)
  }

  byId(id: string): RemoteRest {
    return this.idMap[id]
  }

  byStartShiftId(id: string): RemoteRest {
    return this.startShiftIdMap[id]
  }

  byNextShiftId(id: string): RemoteRest {
    return this.nextShiftIdMap[id]
  }

  byPoolId(id: string): RemoteRest[] {
    return this.poolIdMap.lookup(id)
  }

  byLocationId(id: string): RemoteRest[] {
    return this.locationIdMap.lookup(id)
  }
}
