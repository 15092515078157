import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { BaseLeg } from 'src/service-design/shared/models/base-leg'
import { LocationChangeover } from 'src/service-design/shared/models/changeover/location'
import { TrainChangeover } from 'src/service-design/shared/models/changeover/train'
import { Corridor } from 'src/service-design/shared/models/corridor'
import { EmptyWagonEvent } from 'src/service-design/shared/models/empty-wagon-event'
import { LoadingWorkSplit } from 'src/service-design/shared/models/loading-work-split'
import { Location } from 'src/service-design/shared/models/location'
import { LocoEvent } from 'src/service-design/shared/models/loco-event'
import { ServiceEvent } from 'src/service-design/shared/models/service-event'
import { LoadingAssignment } from 'src/service-design/shared/models/shift-assignment/loading'
import { LocalAssignment } from 'src/service-design/shared/models/shift-assignment/local'
import { TrainBlock } from 'src/service-design/shared/models/train-block'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { TrainTemplate } from 'src/service-design/shared/models/train-template'
import { TrainType } from 'src/service-design/shared/models/train-type'
import { WorkingLocoLock } from 'src/service-design/shared/models/working-loco-lock'
import { YardBlock } from 'src/service-design/shared/models/yard-block'
import { YardBlockAssignment } from 'src/service-design/shared/models/yard-block-assignment'
import {
  Delta,
  EpochTime,
  Interval,
  Duration,
} from 'src/service-design/shared/utils/dates'

class TemplateLeg extends BaseLeg {
  id: string
  templateId: string
  legNum: number
  corridorId: string
  _departsDelta: Delta
  _arrivesDelta: Delta
  forward: boolean
  extraTonnage: number
  extraLength: number
  extraWagons: number

  arrivingTrainBlocks: TrainBlock[]
  corridor: Corridor
  emptywagonevents: EmptyWagonEvent[]
  departingYardBlockAssignments: YardBlockAssignment[]
  loadingAssignments: LoadingAssignment[]
  locoevents: LocoEvent[]
  localAssignments: LocalAssignment[]
  serviceevents: ServiceEvent[]
  singletons: any[] // TODO: DJH
  template: TrainTemplate
  workinglocolocks: WorkingLocoLock[]
  locationchangeovers: LocationChangeover[]
  loadingWorkSplits: LoadingWorkSplit[]
  trainChangeoverA: TrainChangeover[]
  trainChangeoverB: TrainChangeover[]

  /**
   * A TemplateLeg describes portion of a TrainTemplate's route. See
   * TrainTemplate.
   *
   * Related models:
   * - TrainTemplate
   * - Corridor.
   *
   * @param {string} id: The entity id
   * @param {string} templateId: The id of the TrainTemplate
   * @param {number} legNum: The sequence number for the leg (base 1... don't
   *   ask)
   * @param {string} corridorId: The id of the Corridor the leg runs over.
   * @param {Delta} departsDelta: The time of week the leg departs it's origin.
   * @param {number} arrivesDelta: The time of week the leg arrives at it's
   *   destination.
   * @param {boolean} forward: Whether the leg moves in the forward direction
   *   over the corridor.
   *
   * The following variables are pretty hacky an exist primarily so that KCS
   * can model the flow of empty wagons within their network.
   *
   * @param {number} extraTonnage: Adds an additional weekly tonnage that should
   *   be pulled by the TrainStarts.
   * @param {number} extraLength: Adds an additional weekly length that should
   *   be pulled by the TrainStarts.
   * @param {number} extraWagons: Adds an additional weekly wagon count that
   *   should be pulled by the TrainStarts.
   */

  constructor({
    id,
    templateId,
    legNum,
    corridorId,
    departsDelta,
    arrivesDelta,
    forward,
    extraTonnage,
    extraLength,
    extraWagons,
  }: {
    id: string
    templateId: string
    legNum: number
    corridorId: string
    departsDelta: Delta
    arrivesDelta: Delta
    forward: boolean
    extraTonnage: number
    extraLength: number
    extraWagons: number
  }) {
    super()
    this.id = id
    this.templateId = templateId
    this.legNum = legNum
    this.corridorId = corridorId
    this._departsDelta = departsDelta
    this._arrivesDelta = arrivesDelta
    this.forward = forward
    this.extraTonnage = extraTonnage
    this.extraLength = extraLength
    this.extraWagons = extraWagons
  }

  setRels({
    arrivingTrainBlocks = [],
    corridor,
    departingYardBlockAssignments = [],
    emptywagonevents = [],
    locoevents = [],
    localAssignments = [],
    serviceevents = [],
    locationchangeovers = [],
    trainChangeoverA = [],
    trainChangeoverB = [],
    workinglocolocks = [],
    singletons,
    template,
    loadingAssignments = [],
    loadingWorkSplits = [],
  }: {
    arrivingTrainBlocks: TrainBlock[]
    corridor: Corridor
    emptywagonevents: EmptyWagonEvent[]
    departingYardBlockAssignments: YardBlockAssignment[]
    loadingAssignments: LoadingAssignment[]
    locoevents: LocoEvent[]
    localAssignments: LocalAssignment[]
    serviceevents: ServiceEvent[]
    singletons: any[] // TODO: DJH
    template: TrainTemplate
    workinglocolocks: WorkingLocoLock[]
    locationchangeovers: LocationChangeover[]
    loadingWorkSplits: LoadingWorkSplit[]
    trainChangeoverA: TrainChangeover[]
    trainChangeoverB: TrainChangeover[]
  }) {
    this.arrivingTrainBlocks = arrivingTrainBlocks
    this.corridor = corridor
    this.departingYardBlockAssignments = departingYardBlockAssignments
    this.emptywagonevents = emptywagonevents
    this.loadingAssignments = loadingAssignments
    this.locoevents = locoevents
    this.localAssignments = localAssignments
    this.serviceevents = serviceevents
    this.singletons = singletons
    this.template = template
    this.workinglocolocks = workinglocolocks
    this.locationchangeovers = locationchangeovers
    this.loadingWorkSplits = loadingWorkSplits
    this.trainChangeoverA = trainChangeoverA
    this.trainChangeoverB = trainChangeoverB
  }

  static construct(
    data: Partial<
      ConstructorParameters<typeof TemplateLeg>[0] &
        Parameters<TemplateLeg['setRels']>[0]
    >,
  ) {
    const templateLeg = new TemplateLeg(data as any)
    templateLeg.setRels(data as any)
    return templateLeg
  }

  // DateTime valueObject Adapters VVV

  get arrivesDelta(): Delta {
    return this._arrivesDelta
  }

  get departsDelta(): Delta {
    return this._departsDelta
  }

  // DateTime valueObject Adapters ^^^

  // FIXME: temporary
  get train(): TrainStart {
    return this.template.start
  }

  get trainType(): TrainType {
    return this.template.type
  }

  get businessGroupId(): string {
    return this.template.businessGroupId
  }

  get accessGroup(): AccessGroup {
    return this.corridor.accessGroup
  }

  get name(): string {
    return `${this.template.name} leg ${this.legNum}: ${this.origin.name} - ${this.dest.name}`
  }

  get origin(): Location {
    return this.forward ? this.corridor.loc1 : this.corridor.loc2
  }

  get dest(): Location {
    return this.forward ? this.corridor.loc2 : this.corridor.loc1
  }

  get originating(): boolean {
    return !this.prev
  }

  get terminating(): boolean {
    return !this.next
  }

  get next(): TemplateLeg {
    const result = this.template.legs[this.legNum]
    // HACK: See BOSS-2781, for now, avoid infinite loops by bailing here
    if (result === this) {
      return null
    }
    return result
  }

  get prev(): TemplateLeg {
    const result = this.template.legs[this.legNum - 2]
    // HACK: See BOSS-2781, for now, avoid infinite loops by bailing here
    if (result === this) {
      return null
    }
    return result
  }

  get departsLocal(): EpochTime {
    return EpochTime.epoch.makeLater(
      this.template.legOffsetLocal(this).add(this.departsDelta),
    )
  }

  get arrivesLocal(): EpochTime {
    return this.departsLocal.makeLater(this.arrivesDelta)
  }

  get dwellDuration(): Duration {
    if (this.originating) {
      return Duration.nil
    }

    const dwell = Interval.fromEpochTimes(
      this.prev.arrivesLocal,
      this.departsLocal,
    ).duration()
    return dwell
  }

  get attachedYardBlocks(): YardBlock[] {
    return this.departingYardBlockAssignments.map(yba => yba.yardBlock)
  }

  get detachedYardBlocks(): YardBlock[] {
    return this.arrivingTrainBlocks.flatMap(tb => tb.yardBlocks)
  }

  get yardBlocks(): YardBlock[] {
    const detached = this.prev ? this.prev.detachedYardBlocks : []
    const prevBlocks = this.prev
      ? this.prev.yardBlocks.filter(yb => !detached.includes(yb))
      : []
    return [...prevBlocks, ...this.attachedYardBlocks]
  }

  get trainBlocks(): TrainBlock[] {
    return [...new Set(this.yardBlocks.map(yb => yb.trainBlock))].sort(
      (a, b) => a.templateLeg.legNum - b.templateLeg.legNum,
    )
  }

  yardBlocksForTrainBlock(tb: TrainBlock): YardBlock[] {
    return this.yardBlocks.filter(yb => yb.trainBlock === tb)
  }

  get blockTonnage(): number {
    return this.yardBlocks.reduce<number>((acc, yb) => acc + yb.tonnage, 0)
  }

  tonnageForTrainBlock(tb: TrainBlock): number {
    return this.yardBlocksForTrainBlock(tb).reduce<number>(
      (acc, yb) => acc + yb.tonnage,
      0,
    )
  }

  get tonnage(): number {
    return this.extraTonnage + this.blockTonnage
  }

  get blockTonnagePerStart(): number {
    return this.template.starts.length
      ? this.blockTonnage / this.template.starts.length
      : 0
  }

  get extraTonnagePerStart(): number {
    return this.template.starts.length
      ? this.extraTonnage / this.template.starts.length
      : 0
  }

  get tonnagePerStart(): number {
    return this.blockTonnagePerStart + this.extraTonnagePerStart
  }

  get gtmPerStart(): number {
    return this.tonnagePerStart * this.corridor.distance
  }

  get blockLength(): number {
    return this.yardBlocks.reduce<number>((acc, yb) => acc + yb.blockLength, 0)
  }

  lengthForTrainBlock(tb: TrainBlock): number {
    return this.yardBlocksForTrainBlock(tb).reduce<number>(
      (acc, yb) => acc + yb.blockLength,
      0,
    )
  }

  get length(): number {
    return this.blockLength + this.extraLength
  }

  get blockLengthPerStart(): number {
    return this.template.starts.length
      ? this.blockLength / this.template.starts.length
      : 0
  }

  get extraLengthPerStart(): number {
    return this.template.starts.length
      ? this.extraLength / this.template.starts.length
      : 0
  }

  get lengthPerStart(): number {
    return this.blockLengthPerStart + this.extraLengthPerStart
  }

  get numBlockWagons(): number {
    return this.yardBlocks.reduce<number>((acc, yb) => acc + yb.numWagons, 0)
  }

  get numWagons(): number {
    return this.numBlockWagons + this.extraWagons
  }

  numWagonsForTrainBlock(tb: TrainBlock): number {
    return this.yardBlocksForTrainBlock(tb).reduce<number>(
      (acc, yb) => acc + yb.numWagons,
      0,
    )
  }

  get numLegsUntilYard(): number {
    let leg: TemplateLeg = this
    let legs = 1

    while (leg && !leg.dest.yard) {
      legs += 1
      leg = leg.next
    }

    return legs
  }
}

export { TemplateLeg }
