import { ForeignRailroad } from 'src/service-design/shared/models/foreign-railroad'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'

interface Attrs {
  id: string
  lococlassId: string
  type: 'attach' | 'detach'
  working: boolean
  startId: string
  templateLegId: string
  foreignRailroadId: string
}

interface Rels {
  lococlass: Lococlass
  start: TrainStart
  startLeg: StartLeg
  templateLeg: TemplateLeg
  foreignRailroad: ForeignRailroad
}

class LocoEvent extends Mapper {
  _lococlass: Lococlass
  _lococlassId: string

  /**
   * A LocoEvent attaches locos (by specifying a Lococlass) to or detaches them from a TrainStart.
   * See StartLeg and Lococlass.
   *
   * Attach operations occur prior to the departure of the StartLeg. Detach
   * operations occur after StartLeg arrival.
   *
   * HERE BE DRAGONS: LocoEvents do some pretty sneaky things to support
   * ForeignRailroads. In particular, lococlassId and lococlass are stored at
   * _lococlassId and _lococlass, respectively. They then return a Proxy
   * presenting an 'owned loco' when the lococlass is not one of ours. This
   * allowed us to insert foreign railroads into the application in a fairly
   * surgically but at the cost of complexity.
   *
   * Related models:
   * - `ForeignRailroad`;
   * - `Lococlass`;
   * - `StartLeg`;
   * - `TemplateLeg`;
   * - `TrainStart`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} lococlassId - The id of the Lococlass.
   * @param {string} type - Should be 'attach' or 'detach'.
   * @param {boolean} working - Indicates whether a working or hauled loco is
   *  being attached.
   * @param {string} startId - The id of the TrainStart the empty wagons are
   *  being associated with.
   * @param {string} templateLegId - The id of the TemplateLeg where the empties
   *  are being attached / detached.
   * @param {string|null} foreignRailroadId - The id of the ForeignRailroad (if
   *  any) associated with the Lococlass.
   *
   **/
  constructor({
    id,
    lococlassId,
    type,
    working,
    startId,
    templateLegId,
    foreignRailroadId,
  }: Attrs) {
    super()
    this.id = id
    this._lococlassId = lococlassId
    this.type = type
    this.working = working
    this.startId = startId
    this.templateLegId = templateLegId
    this.foreignRailroadId = foreignRailroadId
  }

  setRels({ lococlass, start, startLeg, templateLeg, foreignRailroad }: Rels) {
    this._lococlass = lococlass
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
    this.foreignRailroad = foreignRailroad
  }

  get lococlassId() {
    return this.lococlass ? this.lococlass.id : null
  }

  get unownedLococlassId() {
    return this._lococlassId
  }

  get lococlass() {
    return this._lococlass
      ? this._lococlass.asOwned(this.foreignRailroad)
      : null
  }

  get isForeign() {
    return Boolean(this.foreignRailroadId)
  }

  get location() {
    return this.type === 'attach' ? this.startLeg.origin : this.startLeg.dest
  }
}

interface LocoEvent extends Attrs, Rels {}
export { LocoEvent }
