import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { YardBlockAssignment } from './model'

export interface IYardBlockAssignmentRepo extends IRepo<YardBlockAssignment> {
  byYardBlockId(id: string): YardBlockAssignment
  byTrainBlockId(id: string): YardBlockAssignment[]
  byDepartingLegId(id: string): YardBlockAssignment[]
}

export class YardBlockAssignmentRepo implements IYardBlockAssignmentRepo {
  values: YardBlockAssignment[]
  idMap: { [key: string]: YardBlockAssignment }
  yardBlockIdMap: { [key: string]: YardBlockAssignment }
  trainBlockIdMap: Indexer<YardBlockAssignment>
  departingLegIdMap: Indexer<YardBlockAssignment>

  constructor(values: YardBlockAssignment[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.yardBlockIdMap = Object.fromEntries(
      values.map(v => [v.yardBlockId, v]),
    )
    this.trainBlockIdMap = new Indexer(values, x => x.trainBlockId)
    this.departingLegIdMap = new Indexer(values, x => x.departingLegId)
  }

  byId(id: string): YardBlockAssignment {
    return this.idMap[id]
  }

  byYardBlockId(id: string): YardBlockAssignment {
    return this.yardBlockIdMap[id]
  }

  byTrainBlockId(id: string): YardBlockAssignment[] {
    return this.trainBlockIdMap.lookup(id)
  }

  byDepartingLegId(id: string): YardBlockAssignment[] {
    return this.departingLegIdMap.lookup(id)
  }
}
