import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Service } from './model'

export interface IServiceRepo extends IRepo<Service> {
  byOriginId(id: string): Service[]
  byDestinationId(id: string): Service[]
  byCargoTypeId(id: string): Service[]
}

export class ServiceRepo implements IServiceRepo {
  values: Service[]
  idMap: { [key: string]: Service }
  originIdMap: Indexer<Service>
  destinationIdMap: Indexer<Service>
  cargoTypeIdMap: Indexer<Service>

  constructor(values: Service[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.originIdMap = new Indexer(values, x => x.originId)
    this.destinationIdMap = new Indexer(values, x => x.destinationId)
    this.cargoTypeIdMap = new Indexer(values, x => x.cargoTypeId)
  }

  byId(id: string): Service {
    return this.idMap[id]
  }

  byOriginId(originId: string): Service[] {
    return this.originIdMap.lookup(originId)
  }

  byDestinationId(destinationId: string): Service[] {
    return this.destinationIdMap.lookup(destinationId)
  }

  byCargoTypeId(cargoTypeId: string): Service[] {
    return this.cargoTypeIdMap.lookup(cargoTypeId)
  }
}
