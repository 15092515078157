import { sumBy, sum } from 'lodash'

import {
  SECONDS_PER_HOUR,
  WEEKS_PER_YEAR,
} from 'src/service-design/shared/constants'
import { CompoundShift } from 'src/service-design/shared/models/compound-shift'
import { CrewType } from 'src/service-design/shared/models/crew-type'
import { RosterHead } from 'src/service-design/shared/models/linked-list-head/roster'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { RDO } from 'src/service-design/shared/models/rdo'
import { RemoteRest } from 'src/service-design/shared/models/remote-rest'
import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import { RosterLine } from 'src/service-design/shared/models/roster-line'
import { Shift } from 'src/service-design/shared/models/shift'
import {
  penaltyMultiplier,
  adjustPenaltyForLeave,
  Duration,
} from 'src/service-design/shared/utils/dates'

type IndicatorState = 'good' | 'error' | 'overused' | 'unavailable' | 'unused'

interface IndicatorSegment {
  state: IndicatorState
  quantity: number
  percentage: number
}

interface Attrs {
  id: string
  typeId: string
}

interface Rels {
  type: CrewType
  shifts: Shift[]
  remoteRests: RemoteRest[]
  lines: RosterLine[]
  rosterHead: RosterHead
  compoundShifts: CompoundShift[]
}

class CrewPool extends Mapper implements IResource {
  entityName: string

  /**
   * A CrewPool represents a number of crew of a CrewType.
   *
   * The size of the CrewPool is determined by the number of RosterLines
   * associated with the pool. Every shift associated with a CrewPool should
   * be placed on a RosterLine (via a ShiftLine) to produce a roster.
   *
   * RosterLines are a description of a crew's week, and may have
   * Shifts assigned through ShiftLines, or RDO assignments.
   *
   * The RosterHead is an arbitrary RosterLine which represents the 'first'
   * line or the 'top' of the roster. It is only stored to ensure the roster
   * is visualized consistently.
   *
   * Each (unnamed) member of the pool begins the roster on a different
   * RosterLine, completes it and then moves to the next line. When the member
   * hits the bottom of the roster they move back to the top. We call the
   * movement through the lines from top to bottom the roster cycle.
   *
   * A relief line is a RosterLine which provides slack to cover for annual
   * leave when it is taken.
   *
   * Related models:
   * - `CrewPool`;
   * - `Shift`;
   * - `RDO`;
   * - `RosterHead`;
   * - `RosterLine`;
   * - `ShiftLine`;
   * - `Location`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} typeId - The id of the CrewType this pool represents.
   **/
  constructor({ id, typeId }: Attrs) {
    super()
    this.id = id
    this.typeId = typeId
    this.entityName = 'CrewPool'
  }

  setRels({
    type,
    shifts = [],
    remoteRests = [],
    lines = [],
    rosterHead,
    compoundShifts = [],
  }: Rels) {
    this.type = type
    this.shifts = shifts
    this.remoteRests = remoteRests
    this.lines = lines
    this.rosterHead = rosterHead
    this.compoundShifts = compoundShifts
  }

  get cycle(): RosterLine[] {
    // this.lines are not guaranteed to be in the correct order, so follow the links from rosterHead
    return this.lines.length ? this.rosterHead.line.cycle : this.lines
  }

  get unassignedShifts(): Shift[] {
    return this.shifts.filter(s => !s.isAssigned)
  }

  get unassignedCompoundShifts(): CompoundShift[] {
    // TODO we should probably validate that all shifts of a compound are rostered together
    return this.compoundShifts.filter(c => !c.isAssigned)
  }

  get adjustedHourlyRate(): number {
    return this.type.hourlyRate * this.type.onCostMultiplier
  }

  get adjustedApmHourlyRate(): number {
    return this.type.apmHourlyRate * this.type.onCostMultiplier
  }

  /**
   * @deprecated
   */
  get minimumShiftSecs(): number {
    return this.minimumShiftSecsVO.toSeconds()
  }

  get minimumShiftSecsVO(): Duration {
    return this.type.minimumShiftSecsVO
  }

  /**
   * @deprecated
   */
  get remoteRestMinimumShiftDurationSecs(): number {
    return this.remoteRestMinimumShiftDurationSecsVO.toSeconds()
  }

  get remoteRestMinimumShiftDurationSecsVO(): Duration {
    return this.type.remoteRestMinimumShiftDurationSecsVO
  }

  get name(): string {
    return this.type.name
  }

  get loc(): Location {
    return this.type.loc
  }

  get locId(): string {
    return this.type.locId
  }

  get quantity(): number {
    return this.lines.length
  }

  get dutyHours(): number {
    return (
      Duration.sum(...this.shifts.map(shift => shift.durationVO)).toSeconds() /
      SECONDS_PER_HOUR
    )
  }

  get reliefLines(): RosterLine[] {
    return this.lines.filter(l => l.relief)
  }

  get rosterDutyHours(): number {
    const reliefDutyHours = sumBy(
      this.reliefLines,
      reliefLine => reliefLine.dutyHours,
    )
    return this.dutyHours + reliefDutyHours
  }

  get workingLines(): RosterLine[] {
    return this.lines.filter(l => !l.relief)
  }

  get requiredRDOs(): number {
    return this.targetRDOsPerLine * this.lines.length
  }

  get averageRosterDutyHoursPerLine(): number {
    return this.rosterDutyHours / this.lines.length
  }

  get RDOs(): RDO[] {
    return this.lines.flatMap(l => l.RDOs)
  }

  get totalRDOs(): number {
    return sumBy(this.lines, line => line.numRDOs)
  }

  get requiredQuantity(): number {
    return this.dutyHours / (this.type.dutyPerWeekSecs / SECONDS_PER_HOUR)
  }

  get spare(): number {
    return this.workingLines.length - this.requiredQuantity
  }

  get unavailable(): number {
    return this.reliefLines.length
  }

  get indicator(): IndicatorSegment[] {
    const percent = (quantity: number): number =>
      (100 * quantity) / this.quantity

    let state: IndicatorState = 'good'
    if (this.quantity < this.requiredQuantity) {
      state = 'error'
    } else if (this.spare < 0) {
      state = 'overused'
    }
    const sets: IndicatorSegment[] = []

    if (this.unavailable > 0) {
      sets.push({
        state: 'unavailable',
        quantity: this.unavailable,
        percentage: percent(this.unavailable),
      })
    }

    if (this.requiredQuantity > 0) {
      sets.push({
        state,
        quantity: this.requiredQuantity,
        percentage: percent(this.requiredQuantity),
      })
    }
    if (this.spare > 0) {
      sets.push({
        state: 'unused',
        quantity: this.spare,
        percentage: percent(this.spare),
      })
    }
    return sets
  }

  get weightedAveragePenaltyMultiplier(): number {
    const weightedShiftPenalties = this.shifts.map(s =>
      s.durationWithBufferVO
        .multiply(
          penaltyMultiplier(
            s.signOnLocalVO.toSeconds(),
            s.signOffLocalWithBufferVO.toSeconds(),
          ),
        )
        .toSeconds(),
    )
    const totalWeightedShiftPenalties = sum(weightedShiftPenalties)
    const totalShiftDuration = Duration.sum(
      ...this.shifts.map(shift => shift.durationWithBufferVO),
    ).toSeconds()
    return totalShiftDuration > 0
      ? totalWeightedShiftPenalties / totalShiftDuration
      : 1
  }

  get annualLeaveLoading(): number {
    return this.type.annualLeaveLoading
  }

  get annualLeavePercentage(): number {
    return (
      this.type.annualLeaveSecs / (this.type.dutyPerWeekSecs * WEEKS_PER_YEAR)
    )
  }

  get adjustedAveragePenaltyMultiplier(): number {
    return adjustPenaltyForLeave(
      this.weightedAveragePenaltyMultiplier,
      this.annualLeaveLoading,
      this.annualLeavePercentage,
    )
  }

  get targetRDOsPerLine(): number {
    return this.type.targetRDOsPerLine
  }

  get nominalDutyCycleHours(): number {
    return this.type.nominalDutyCycleHours
  }
}

interface CrewPool extends Attrs, Rels {}
export { CrewPool }
