import * as constants from 'src/service-design/shared/constants'
import { BuildLeg } from 'src/service-design/shared/models/build-leg'
import { Changeover } from 'src/service-design/shared/models/changeover/base'
import { DriverTask } from 'src/service-design/shared/models/driver-task'
import { DwellResourceProfile } from 'src/service-design/shared/models/dwell-resource-profile'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { IResourceProfiler } from 'src/service-design/shared/models/resource-summaries/types'
import { Service } from 'src/service-design/shared/models/service'
import { DriverAssignment } from 'src/service-design/shared/models/shift-assignment'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { LegTask } from 'src/service-design/shared/models/task'
import { TrainTemplate } from 'src/service-design/shared/models/train-template'
import {
  Delta,
  TimeOfDay,
  EpochTime,
  DAY_DELTA,
  DAY_DURATION,
} from 'src/service-design/shared/utils/dates'

type Attrs = {
  id: string
  departsDayOfWeek: number
  templateId: string
  customName: string
}

type Rels = {
  template: TrainTemplate
  driverAssignments: DriverAssignment[]
  legs: StartLeg[]
  singletons: any // TODO DJH: use document type
}

class TrainStart extends Mapper implements IResourceProfiler {
  public entityName = 'TrainStart'

  unsortedLegs: StartLeg[]
  _legs: StartLeg[]
  _driverTasks: DriverTask[]
  _driverTaskMap: Map<string, DriverTask>
  _buildLegs: BuildLeg[]
  _localTasks: LegTask<StartLeg>[]
  _loadingTasks: LoadingWorkTask[]

  /**
   * A TrainStart is an instance of a TrainTemplate that runs on a specific
   * day of the week. See TrainTemplate for more details.
   *
   * Related models:
   * - TrainTemplate;
   * - StartLeg.
   *
   * @param {string} id: The entity id
   * @param {number} departsDayOfWeek: The day (0-6) which the train start should
   *   run.
   * @param {string} templateId: The template the start is modelled off.
   * @param {string} customName: Optionally override the name of the TrainStart.
   */
  constructor({ id, departsDayOfWeek, templateId, customName }: Attrs) {
    super()
    this.id = id
    this.departsDayOfWeek = departsDayOfWeek
    this.templateId = templateId
    this.customName = customName
  }

  setRels({ template, driverAssignments = [], legs = [], singletons }: Rels) {
    this.template = template
    this.driverAssignments = driverAssignments
    this.unsortedLegs = legs
    this.singletons = singletons
  }

  get legs() {
    if (!this._legs) {
      this._legs = this.unsortedLegs.sort((l1, l2) => l1.legNum - l2.legNum)
    }
    return this._legs
  }

  // TODO: duped between TrainStart and TrainTemplate
  legOffsetLocal(leg: StartLeg): Delta {
    const legIndex = this.legs.indexOf(leg)
    if (legIndex === -1) {
      throw new Error('why would you do that')
    }

    return this.legs
      .slice(0, legIndex)
      .reduce<Delta>(
        (acc, l) => acc.add(l.departsDelta).add(l.arrivesDelta),
        DAY_DELTA.multiply(this.departsDayOfWeek),
      )
  }

  get name() {
    const day = constants.DAYS[this.departsDayOfWeek]
    return this.customName || `${this.template.name} (${day})`
  }

  get daysOfWeek() {
    return [this.departsDayOfWeek]
  }

  get businessGroupId() {
    return this.template.businessGroupId
  }

  get businessGroup() {
    return this.template.businessGroup
  }

  get typeId() {
    return this.template.typeId
  }

  get type() {
    return this.template.type
  }

  get ecpBraking() {
    return this.template.ecpBraking
  }

  get minLeadLocos() {
    return this.template.minLeadLocos
  }

  get maxHauledLocos() {
    return this.template.maxHauledLocos
  }

  get departsTimeOfDay(): TimeOfDay {
    return this.template.departsTimeOfDay
  }

  get departsLocal(): EpochTime {
    return EpochTime.epoch
      .makeLater(DAY_DURATION.multiply(this.departsDayOfWeek))
      .makeLater(Delta.fromSeconds(this.departsTimeOfDay.toSeconds()))
  }

  get origin() {
    return this.template.origin
  }

  get destination() {
    return this.template.destination
  }

  get lightestLeg() {
    return this.legs.length
      ? this.legs.reduce<StartLeg>(
          (lightest, item) =>
            item.tonnage < lightest.tonnage ? item : lightest,
          this.legs[0],
        )
      : null
  }

  get heaviestLeg() {
    return this.legs.length
      ? this.legs.reduce<StartLeg>(
          (heaviest, item) =>
            item.tonnage > heaviest.tonnage ? item : heaviest,
          this.legs[0],
        )
      : null
  }

  get lastLeg() {
    return this.legs.length ? this.legs.slice(-1)[0] : null
  }

  get services(): Service[] {
    return [
      ...new Set(
        this.legs.reduce<Service[]>(
          (acc, { services }) => [...acc, ...services],
          [],
        ),
      ),
    ]
  }

  get driverTasks() {
    if (!this._driverTasks) {
      let prevChangeover: Changeover = null

      this._driverTasks = this.changeovers.map(changeover => {
        const task = new DriverTask({
          start: this,
          startChangeover: prevChangeover,
          endChangeover: changeover,

          assignment: this.getDriverAssignmentForChangeovers(
            prevChangeover,
            changeover,
          ),
        })
        prevChangeover = changeover
        return task
      })

      this._driverTasks.push(
        new DriverTask({
          start: this,
          startChangeover: prevChangeover,
          endChangeover: null,

          assignment: this.getDriverAssignmentForChangeovers(
            prevChangeover,
            null,
          ),
        }),
      )
    }
    return this._driverTasks
  }

  get driverTaskMap() {
    if (!this._driverTaskMap) {
      this._driverTaskMap = new Map(
        this.driverTasks.map(task => [task.id, task]),
      )
    }
    return this._driverTaskMap
  }

  getDriverTask(startChangeoverId: string, endChangeoverId: string) {
    return this.driverTaskMap.get(
      DriverTask.generateId(this.id, startChangeoverId, endChangeoverId),
    )
  }

  getDriverAssignmentForChangeovers(
    startChangeover: Changeover,
    endChangeover: Changeover,
  ) {
    const getChangeoverIdOrNull = (changeover: Changeover | null) =>
      changeover ? changeover.id : null

    return (
      this.driverAssignments.find(
        x =>
          x.startChangeoverId === getChangeoverIdOrNull(startChangeover) &&
          x.endChangeoverId === getChangeoverIdOrNull(endChangeover),
      ) || null
    )
  }

  get changeovers(): Changeover[] {
    return this.legs.reduce<Changeover[]>(
      (coll, leg) => coll.concat(leg.changeovers),
      [],
    )
  }

  get lock() {
    return this.template.lock
  }

  get lockTypes() {
    const result = new Set<string>()
    if (this.lock) {
      result.add(constants.LOCK_TRAIN)
    }

    for (const leg of this.legs) {
      leg.consist.lockTypes.forEach(element => {
        result.add(element)
      })
    }
    return result
  }

  get arrivesLocal(): EpochTime | null {
    return this.legs.length
      ? this.legs[this.legs.length - 1].arrivesLocal
      : null
  }

  get buildLegs() {
    if (!this._buildLegs) {
      this._buildLegs = []
      let accumLegs = []
      for (const leg of this.legs) {
        accumLegs.push(leg)
        if (leg.terminating || leg.dest.yard) {
          this._buildLegs.push(new BuildLeg(accumLegs))
          accumLegs = []
        }
      }
    }
    return this._buildLegs
  }

  getResourceProfiles(location: Location) {
    return [
      ...this.getDwellResourceProfiles(location),
      ...this.getLegResourceProfile(location),
    ]
  }

  getDwellResourceProfiles(location: Location) {
    const dwells = this.legs
      .filter(leg => leg.origin === location)
      .map(
        leg =>
          new DwellResourceProfile({
            arrivingLeg: leg.prev,
            departingLeg: leg,
          }),
      )

    if (this.destination === location) {
      dwells.push(
        new DwellResourceProfile({
          arrivingLeg: this.lastLeg,
        }),
      )
    }

    return dwells
  }

  getLegResourceProfile(location: Location) {
    return this.legs.flatMap(x => x.getResourceProfiles(location))
  }

  get yardTasks() {
    return this.localTasks.filter(
      task =>
        (task.constructor as any).requiresRailOperator && task.duration > 0,
    )
  }

  get localTasks() {
    if (!this._localTasks) {
      this._localTasks = this.legs.flatMap(x => x.localTasks)
    }

    return this._localTasks
  }

  get loadingWorkTasks() {
    if (!this._loadingTasks) {
      this._loadingTasks = this.legs.flatMap(x => x.loadingWorkTasks)
    }

    return this._loadingTasks
  }
}

interface TrainStart extends Attrs, Rels {}
export { TrainStart }
