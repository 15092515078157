import { createSelector } from 'reselect'

import { IContext } from 'src/service-design/sd-plan/reducers/engine'

import * as Shift from 'src/service-design/shared/models/shift'

export const getEngine = (state: any): IContext => state.engine.context
export const getEngineId = createSelector(getEngine, engine => engine.id)
export const getEngineName = createSelector(getEngine, engine => engine.name)

export const getEngineStatus = createSelector(
  getEngine,
  engine => engine.status,
)
export const getEngineLog = (state: any) => state.engine.log

export const getEngineSolution = (state: any) => state.engine.solution

export const getLVTasks = createSelector(Shift.values, shifts =>
  shifts.flatMap(shift => shift.lvTasks),
)
