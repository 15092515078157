import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LocationCargoCompatibility } from './model'

export interface ILocationCargoCompatibilityRepo
  extends IRepo<LocationCargoCompatibility> {
  byCargoTypeId(id: string): LocationCargoCompatibility[]
  byLocationId(id: string): LocationCargoCompatibility[]
}

export class LocationCargoCompatibilityRepo
  implements ILocationCargoCompatibilityRepo {
  values: LocationCargoCompatibility[]
  idMap: { [key: string]: LocationCargoCompatibility }
  cargoTypeIdMap: Indexer<LocationCargoCompatibility>
  locationIdMap: Indexer<LocationCargoCompatibility>

  constructor(values: LocationCargoCompatibility[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.cargoTypeIdMap = new Indexer(values, x => x.cargoTypeId)
    this.locationIdMap = new Indexer(values, x => x.locationId)
  }

  byId(id: string): LocationCargoCompatibility {
    return this.idMap[id]
  }

  byCargoTypeId(cargoTypeId: string): LocationCargoCompatibility[] {
    return this.cargoTypeIdMap.lookup(cargoTypeId)
  }

  byLocationId(locationId: string): LocationCargoCompatibility[] {
    return this.locationIdMap.lookup(locationId)
  }
}
