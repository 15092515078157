import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LococlassAccessRight } from './model'

export const lococlassAccessRightFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.lococlassaccessrights.map(
      data => new LococlassAccessRight(data),
    ),
  setRelsFromSpec((lococlassAccessRight: LococlassAccessRight, state: any) => ({
    accessGroup: registry
      .provide('accessGroupRepo')(state)
      .byId(lococlassAccessRight.accessGroupId),
    lococlass: registry
      .provide('lococlassRepo')(state)
      .byId(lococlassAccessRight.lococlassId),
  })),
)
