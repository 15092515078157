import React from 'react'
import { NavLink } from 'react-router-dom'

import { locationTo } from 'src/service-design/shared/routing'

interface ConnectedLinkProps {
  currentLocation: {
    pathname: string
    search: string
  }
  to: {
    pathname: string
    search: string
  }
  mergeParams?: boolean
}

export const ConnectedLink: React.FC<ConnectedLinkProps> = ({
  currentLocation,
  to,
  mergeParams = true,
  ...props
}) => <NavLink to={locationTo(currentLocation, to, mergeParams)} {...props} />
