import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { uncrewedLegTaskFactory } from './factory'
import { IUncrewedLegTaskRepo, UncrewedLegTaskRepo } from './repo'

const uncrewedLegTaskRepo = defaultMemoize(
  (input: FactoryInput) =>
    new UncrewedLegTaskRepo(uncrewedLegTaskFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  uncrewedLegTaskRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type UncrewedLegTaskRepoReg = {
  uncrewedLegTaskRepo: FromDocumentFactory<FactoryInput, IUncrewedLegTaskRepo>
}

export function setup<R extends UncrewedLegTaskRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('uncrewedLegTaskRepo', uncrewedLegTaskRepo)

  relationships.addConstraints('uncrewedlegtasks', {
    unique: [['templateLegId', 'startId', 'kind']],
    rels: [
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
    ],
  })
}

export { UncrewedLegTask } from './model'
