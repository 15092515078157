import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { InvalidCycle, RosterLine } from './model'

export class InvalidRosterLine extends InstanceRepair {
  static collectionName = 'rosterlines'

  static type = 'service-design::Invalid Roster Line'

  static message =
    'service-design::Roster line with id {{entity.id}} is not fully connected to the {{entity.crewPool.name}} roster'

  static check(line: RosterLine) {
    try {
      const { cycle } = line
      return line.crewPool.lines.length !== cycle.length
    } catch (e) {
      if (e instanceof InvalidCycle) {
        return true
      }
      throw e
    }
  }
}

export const getRosterLineRepairs = getRepairFactory([InvalidRosterLine])
