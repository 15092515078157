import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LocalAssignment } from './model'

export const localAssignmentFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.localassignments.map(data => new LocalAssignment(data)),
  setRelsFromSpec((localAssignment: LocalAssignment, state: any) => ({
    shift: registry.provide('shiftRepo')(state).byId(localAssignment.shiftId),
    start: registry
      .provide('trainStartRepo')(state)
      .byId(localAssignment.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(localAssignment.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${localAssignment.startId}:${localAssignment.templateLegId}`),
  })),
)
