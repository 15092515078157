import * as constants from 'src/service-design/shared/constants'
import { LocationSummary } from 'src/service-design/shared/models/location-summaries'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { ResourceAvailability } from 'src/service-design/shared/models/resource-availability'
import {
  IResource,
  IResourceConsumer,
} from 'src/service-design/shared/models/resource-summaries/types'
import { ResourceUnavailability } from 'src/service-design/shared/models/resource-unavailability'
import { sumCounts } from 'src/service-design/shared/utils/math'

export class ResourceSummary {
  public resource: IResource
  public locationSummaries: LocationSummary[]
  public unutilizedSecs: number
  public workingSecs: number
  public bgWorkingSecs: Map<string, number>
  public hauledSecs: number
  public workingKms: number
  public bgWorkingKms: Map<string, number>
  public hauledKms: number
  public entityName: string
  public preferences: any

  /**
   * A ResourceSummary summarizes information about a specific resource,
   * including:
   * - fixed resource costs
   * - kms usage
   * - time usage
   * - utilization
   *
   * Importantly, this class is also responsible for calculating how many
   * resources are required to operator the plan. At a high level the
   * calculation is:
   *
   * Resources Required = (Idle Seconds + Utilized Seconds) / Seconds In A Week
   *
   * where:
   *
   * Idle Seconds     = The area under a the yard chart for a given resource
   * Utilized Seconds = Effectively the opposite of idle seconds.
   *
   * Utilized seconds is driven off what we call resource consumers. Examples
   * for resource consumers include a train start which consumes loco and wagons
   * or a shift which consumes LVs or crew. Resource consumers are responsible
   * for tracking how many seconds of any resources time it takes.
   *
   * These calculations are all conducted within `buildCollection` which is
   * responsiblie for instantiating the ResourceSummaries.
   *
   * @param resource: The resource this summary is associated with.
   * @param locationSummaries {LocationSummary[]}: The LocationSummaries where
   *  the resource sits idle. Used to determine the unutilized seconds and then
   *  stored here for convienced.
   * @param unutilizedSecs: The number of seconds the resource sits idle at
   *  locations.
   * @param workingSecs: Calculated by summing over all resource consumers.
   * @param bgWorkingSecs: A map from BusinessGroup to working secs. Used for
   *  reporting.
   * @param hauledSecs: Only applied to Lococlasses. The number of seconds a
   *  spent hauled on trains.
   * @param workingKms: The number of KMs traveled while working.
   * @param bgWorkingKms: workingKms broken down by BusinessGroup. Used for
   *  reporting.
   * @param hauledKms: The number of KMs traveled while being hauled.
   * @param preferences: Users preferences passed for warning generation.
   */

  constructor({
    resource,
    locationSummaries,
    unutilizedSecs,
    workingSecs,
    bgWorkingSecs,
    hauledSecs,
    workingKms,
    bgWorkingKms,
    hauledKms,
  }: {
    resource: IResource
    locationSummaries: LocationSummary[]
    unutilizedSecs: number
    workingSecs: number
    bgWorkingSecs: Map<string, number>
    hauledSecs: number
    workingKms: number
    bgWorkingKms: Map<string, number>
    hauledKms: number
  }) {
    this.resource = resource
    this.locationSummaries = locationSummaries
    this.unutilizedSecs = unutilizedSecs
    this.workingSecs = workingSecs
    this.bgWorkingSecs = bgWorkingSecs
    this.hauledSecs = hauledSecs
    this.workingKms = workingKms
    this.bgWorkingKms = bgWorkingKms
    this.hauledKms = hauledKms
    this.entityName = 'ResourceSummary'
  }

  get name(): string {
    return this.resource.name
  }

  get id(): string {
    return this.resource.id
  }

  get horsepowerHours(): number | null {
    return this.resource instanceof Lococlass
      ? this.resource.horsepower * this.rosteredTotal * constants.HOURS_PER_WEEK
      : null
  }

  get unavailabilities(): ResourceUnavailability[] {
    return this.availability ? this.availability.unavailabilities : []
  }

  get unavailable(): number {
    return this.unavailabilities.reduce<number>((acc, u) => acc + u.quantity, 0)
  }

  get rosteredIdle(): number {
    return (1.0 * this.unutilizedSecs) / constants.SECONDS_PER_WEEK
  }

  get rosteredHauled(): number {
    return (1.0 * this.hauledSecs) / constants.SECONDS_PER_WEEK
  }

  get rosteredWorking(): number {
    return (1.0 * this.workingSecs) / constants.SECONDS_PER_WEEK
  }

  get rosterTotalSecs(): number {
    return this.unutilizedSecs + this.hauledSecs + this.workingSecs
  }

  get rosteredTotal(): number {
    return this.rosterTotalSecs / constants.SECONDS_PER_WEEK
  }

  get bgRostered(): Map<string, number> {
    return new Map(
      [...this.bgWorkingSecs.entries()].map(([k, v]) => [
        k,
        (1.0 * v) / constants.SECONDS_PER_WEEK,
      ]),
    )
  }

  get spare(): number {
    return this.fleetTotal - (this.unavailable + this.rosteredTotal)
  }

  get spareMagnitude(): number {
    return Math.abs(this.spare)
  }

  get availability(): ResourceAvailability | null {
    return 'availability' in this.resource ? this.resource.availability : null
  }

  get resourceType(): string {
    return this.resource.entityName
  }

  get fleetTotal(): number {
    if (!this.availability) {
      return this.rosteredTotal
    }
    return this.availability.quantity
  }

  static buildCollection(
    resources: IResource[],
    locationSummaries: LocationSummary[],
    resourceConsumers: IResourceConsumer[],
  ) {
    const unutilizedSecs = locationSummaries.reduce<Map<IResource, number>>(
      (secs, summ) =>
        summ.outOfNetwork ? secs : sumCounts(secs, summ.resourceSecs),
      new Map(),
    )

    let workingKms = new Map<IResource, number>()
    let workingSecs = new Map<IResource, number>()
    let hauledKms = new Map<IResource, number>()
    let hauledSecs = new Map<IResource, number>()
    const bgWorkingKms = new Map<IResource, Map<string, number>>()
    const bgWorkingSecs = new Map<IResource, Map<string, number>>()

    const resourceProfiles = locationSummaries.flatMap(s => s.resourceProfiles)

    for (const demand of [...resourceProfiles, ...resourceConsumers]) {
      const {
        totalWorkingSecs = new Map<IResource, number>(),
        totalWorkingKms = new Map<IResource, number>(),
        totalHauledSecs = new Map<IResource, number>(),
        totalHauledKms = new Map<IResource, number>(),
        businessGroupId,
      } = demand

      workingSecs = sumCounts(workingSecs, totalWorkingSecs)
      workingKms = sumCounts(workingKms, totalWorkingKms)
      hauledSecs = sumCounts(hauledSecs, totalHauledSecs)
      hauledKms = sumCounts(hauledKms, totalHauledKms)

      if (businessGroupId) {
        for (const resource of totalWorkingSecs.keys()) {
          bgWorkingSecs.set(
            resource,
            sumCounts(
              bgWorkingSecs.get(resource) || new Map([]),
              new Map([
                [demand.businessGroupId, totalWorkingSecs.get(resource)],
              ]),
            ),
          )

          bgWorkingKms.set(
            resource,
            sumCounts(
              bgWorkingKms.get(resource) || new Map([]),
              new Map([
                [demand.businessGroupId, totalWorkingKms.get(resource)],
              ]),
            ),
          )
        }
      }
    }

    return resources.map(
      resource =>
        new ResourceSummary({
          resource,
          unutilizedSecs: unutilizedSecs.get(resource) || 0,
          workingSecs: workingSecs.get(resource) || 0,
          bgWorkingSecs: bgWorkingSecs.get(resource) || new Map(),
          hauledSecs: hauledSecs.get(resource) || 0,
          workingKms: workingKms.get(resource) || 0,
          bgWorkingKms: bgWorkingKms.get(resource) || new Map(),
          hauledKms: hauledKms.get(resource) || 0,
          locationSummaries,
        }),
    )
  }
}
