import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainBlock } from './model'

export interface ITrainBlockRepo extends IRepo<TrainBlock> {
  byTemplateLegId(id: string): TrainBlock[]
}

export class TrainBlockRepo implements ITrainBlockRepo {
  values: TrainBlock[]
  idMap: { [key: string]: TrainBlock }
  templateLegIdMap: Indexer<TrainBlock>

  constructor(values: TrainBlock[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
  }

  byId(id: string): TrainBlock {
    return this.idMap[id]
  }

  byTemplateLegId(templateId: string): TrainBlock[] {
    return this.templateLegIdMap.lookup(templateId)
  }
}
