import { Mapper } from 'src/service-design/shared/models/mapper'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Duration } from 'src/service-design/shared/utils/dates'

type StartLegTaskOffsetKind = 'loading' | 'unloading' | 'attach' | 'detach'

class StartLegTaskOffset extends Mapper {
  id: string
  startId: string
  templateLegId: string
  kind: StartLegTaskOffsetKind
  _offset: Duration
  start: TrainStart
  templateLeg: TemplateLeg
  startLeg: StartLeg

  /**
   * A StartLegTaskOffset describes how much a LegTask should be offset in time.
   * When no StartLegTaskOffset is present for a particular task it is assumed
   * that it will take place immediately after the preceeding task. For example,
   * a Detach task will commence immediately after PostArrival when no offset is
   * present.
   *
   * Related models:
   * - Train
   * - Loading;
   * - Unloading;
   * - Detach;
   * - Attach;
   * - LegTask.
   *
   * @param id {string}: The entity id
   * @param startId {string}: The id of the TrainStart which owns the StartLeg
   * @param templateLegId {string}: The id of the TemplateLeg associated with
   *  the StartLeg.
   * @param kind {string}: The type of leg task which is being offset.
   */
  constructor({
    id,
    startId,
    templateLegId,
    kind,
    offset,
  }: {
    id: string
    startId: string
    templateLegId: string
    kind: StartLegTaskOffsetKind
    offset: Duration
  }) {
    super()
    this.id = id
    this.startId = startId
    this.templateLegId = templateLegId
    this.kind = kind
    this._offset = offset
  }

  setRels({
    start,
    templateLeg,
    startLeg,
  }: {
    start: TrainStart
    templateLeg: TemplateLeg
    startLeg: StartLeg
  }) {
    this.start = start
    this.templateLeg = templateLeg
    this.startLeg = startLeg
  }

  // DateTime valueObject Adapters VVV

  get offset(): number {
    return this._offset.toSeconds()
  }

  // DateTime valueObject Adapters ^^^
}

export { StartLegTaskOffset }
