import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { TrainGraph } from 'src/service-design/shared/models/train-graph'

interface Attrs {
  id: string
  trainGraphId: string
  locationId: string
  yPosition: number
}

interface Rels {
  traingraph: TrainGraph
  location: Location
}

class TrainGraphLocation extends Mapper {
  /**
   * A TrainGraphLocation positions a Location within a TrainGraph.
   *
   * Related models:
   * - `Location`;
   * - `TrainGraph`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} trainGraphId - The id of the TrainGraph.
   * @param {string} locationId - The id of the Location.
   * @param {number} yPosition - The y position location (whatever that means)
   */
  constructor({ id, trainGraphId, locationId, yPosition }: Attrs) {
    super()
    this.id = id
    this.trainGraphId = trainGraphId
    this.locationId = locationId
    this.yPosition = yPosition
  }

  setRels({ traingraph, location }: Rels) {
    this.traingraph = traingraph
    this.location = location
  }
}

interface TrainGraphLocation extends Attrs, Rels {}
export { TrainGraphLocation }
