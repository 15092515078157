import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { startLegTaskOffsetFactory } from './factory'
import { getStartLegTaskOffsetRepairs } from './repairs'
import { IStartLegTaskOffsetRepo, StartLegTaskOffsetRepo } from './repo'

const startLegTaskOffsetRepo = defaultMemoize(
  (input: FactoryInput) =>
    new StartLegTaskOffsetRepo(startLegTaskOffsetFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  startLegTaskOffsetRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type StartLegTaskOffsetRepoReg = {
  startLegTaskOffsetRepo: FromDocumentFactory<
    FactoryInput,
    IStartLegTaskOffsetRepo
  >
}

export function setup<R extends StartLegTaskOffsetRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('startLegTaskOffsetRepo', startLegTaskOffsetRepo)

  relationships.addConstraints('startlegtaskoffsets', {
    unique: [['templateLegId', 'startId', 'kind']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
    ],
  })

  repairs.register(createSelector(values, getStartLegTaskOffsetRepairs))
}

export { StartLegTaskOffset } from './model'
