import { LogMessage } from 'src/service-design/shared/models/log-message'

export abstract class InstanceRepair extends LogMessage {
  // Don't stress about collectionName not being typed to the document collections,
  // we hope this is a temporary measure.
  // We can't type this because it's a name supplied to a generic CRUD delete, but
  // this repair action should be taken by something much closer to the model.
  // Ideally all the generic CRUD actions should go away.
  constructor(public affectedObj: any, context: any = affectedObj) {
    super(context)
  }

  get collectionName(): string {
    return (this.constructor as any).collectionName
  }

  static check(entity: any): boolean {
    return false
  }
}

type InstanceRepairConstructor<T> = {
  new (arg: T): InstanceRepair
  check(arg: T): boolean
}
type InstanceRepairFactory<T> = (entities: T[]) => InstanceRepair[]
type RepairSelector = (state: any) => InstanceRepair[]

export class RepairRegistry {
  selectors: RepairSelector[]

  constructor() {
    this.selectors = []
  }

  register(selector: (state: any) => InstanceRepair[]) {
    this.selectors.push(selector)
  }

  build(state: any): InstanceRepair[] {
    return this.selectors.flatMap(selector => selector(state))
  }
}

export const getRepairFactory = <T>(
  repairClasses: InstanceRepairConstructor<T>[],
): InstanceRepairFactory<T> => (entities: T[]) =>
  repairClasses.flatMap(
    (RepairClass: InstanceRepairConstructor<T>): InstanceRepair[] =>
      entities.filter(RepairClass.check).map(entity => new RepairClass(entity)),
  )
