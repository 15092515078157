import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { StartLegTaskOffset } from './model'

export interface IStartLegTaskOffsetRepo extends IRepo<StartLegTaskOffset> {
  byStartId(id: string): StartLegTaskOffset[]
  byTemplateLegId(id: string): StartLegTaskOffset[]
  byStartLegId(id: string): StartLegTaskOffset[]
}

export class StartLegTaskOffsetRepo implements IStartLegTaskOffsetRepo {
  values: StartLegTaskOffset[]
  idMap: { [key: string]: StartLegTaskOffset }
  startIdMap: Indexer<StartLegTaskOffset>
  templateLegIdMap: Indexer<StartLegTaskOffset>
  startLegIdMap: Indexer<StartLegTaskOffset>

  constructor(values: StartLegTaskOffset[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
  }

  byId(id: string): StartLegTaskOffset {
    return this.idMap[id]
  }

  byStartId(id: string): StartLegTaskOffset[] {
    return this.startIdMap.lookup(id)
  }

  byTemplateLegId(id: string): StartLegTaskOffset[] {
    return this.templateLegIdMap.lookup(id)
  }

  byStartLegId(id: string): StartLegTaskOffset[] {
    return this.startLegIdMap.lookup(id)
  }
}
