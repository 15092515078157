import { Mapper } from 'src/service-design/shared/models/mapper'
import { Service } from 'src/service-design/shared/models/service'
import { Wagon } from 'src/service-design/shared/models/wagon'
import { WagonAllocation } from 'src/service-design/shared/models/wagon-allocation'

interface Attrs {
  id?: string
  serviceId: string
  lock: boolean
}

interface Rels {
  service: Service
  allocations: WagonAllocation[]
}

class WagonSet extends Mapper {
  _wagonDict: Map<Wagon, number>

  static defaults = {
    serviceId: null as string,
    lock: false,
  }

  /**
   * A WagonSet associates wagons to a Service via a WagonAllocation.
   * See Wagon.
   *
   * HISTORICAL: engine locks are now implemented as separate models.
   *
   * Related models:
   * - Wagon
   * - WagonAllocation
   * - Service
   *
   * @param {string} id
   * @param {string} serviceId - id of the Service wagons are being assigned to
   * @param {boolean} lock - true if the wagons are locked for engine purposes
   */
  constructor({ id, serviceId, lock }: Attrs) {
    super()
    this.id = id
    this.serviceId = serviceId
    this.lock = lock
    this.applyDefaults()
  }

  setRels({ service, allocations = [] }: Rels) {
    this.service = service
    this.allocations = allocations
  }

  get wagons() {
    return [...new Set(this.allocations.map(item => item.wagon))]
  }

  get capacity() {
    const compatibilities = this.service.wagoncompatibilities
    return this.allocations.reduce<number>((total, allocation) => {
      const compatibility = compatibilities.find(
        c => c.wagonId === allocation.wagonId,
      )
      const volume = compatibility ? compatibility.volume : 0
      return total + volume * allocation.quantity
    }, 0)
  }

  get volumeUnit() {
    return this.service.volumeUnit
  }

  get tonnage() {
    return (
      this.allocations.reduce<number>(
        (total, allocation) => total + allocation.tonnage,
        0,
      ) + this.service.tonnage
    )
  }

  get length() {
    return this.allocations.reduce<number>(
      (total, allocation) => total + allocation.length,
      0,
    )
  }

  get invalidAllocations() {
    return this.allocations.filter(
      a =>
        !this.service.wagoncompatibilities.find(c => c.wagonId === a.wagonId),
    )
  }

  get wagonDict() {
    if (!this._wagonDict) {
      this._wagonDict = new Map(
        this.allocations.map(a => [a.wagon, a.quantity]),
      )
    }
    return this._wagonDict
  }
}

interface WagonSet extends Attrs, Rels {}
export { WagonSet }
