import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LVTrip } from './model'

export interface ILVTripRepo extends IRepo<LVTrip> {
  byLoc1Id(id: string): LVTrip[]
  byLoc2Id(id: string): LVTrip[]
}

export class LVTripRepo implements ILVTripRepo {
  values: LVTrip[]
  idMap: { [key: string]: LVTrip }
  loc1IdMap: Indexer<LVTrip>
  loc2IdMap: Indexer<LVTrip>

  constructor(values: LVTrip[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.loc1IdMap = new Indexer(values, x => x.loc1Id)
    this.loc2IdMap = new Indexer(values, x => x.loc2Id)
  }

  byId(id: string): LVTrip {
    return this.idMap[id]
  }

  byLoc1Id(loc1Id: string): LVTrip[] {
    return this.loc1IdMap.lookup(loc1Id)
  }

  byLoc2Id(loc2Id: string): LVTrip[] {
    return this.loc2IdMap.lookup(loc2Id)
  }
}
