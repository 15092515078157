import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainGraph } from './model'

export interface ITrainGraphRepo extends IRepo<TrainGraph> {}

export class TrainGraphRepo implements ITrainGraphRepo {
  values: TrainGraph[]
  idMap: { [key: string]: TrainGraph }

  constructor(values: TrainGraph[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
  }

  byId(id: string): TrainGraph {
    return this.idMap[id]
  }
}
