import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { ShiftLine } from './model'

export class RosterLineAndShiftMismatchedCrewPool extends InstanceRepair {
  static collectionName = 'shiftlines'

  static type = 'service-design::RosterLine and Shift have mismatched CrewPools'

  static message =
    'service-design::RosterLine and Shift have mismatched CrewPools'

  static check(shiftLine: ShiftLine) {
    return shiftLine.rosterLine.parentId !== shiftLine.shift.poolId
  }
}

export const getShiftLineRepairs = getRepairFactory([
  RosterLineAndShiftMismatchedCrewPool,
])
