import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Wagon } from 'src/service-design/shared/models/wagon'

interface Attrs {
  id: string
  accessGroupId: string
  wagonId: string
}

interface Rels {
  accessGroup: AccessGroup
  wagon: Wagon
}

class WagonAccessRight extends Mapper {
  /**
   * A WagonAccessRight describes wagon classes ability to operate with a
   * subset of a rail network.
   *
   * Related models:
   * - `AccessGroup`;
   * - `Wagon`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} accessGroupId - The id of the AccessGroup
   * @param {string} wagonId - The id of the Wagon
   */
  constructor({ id, accessGroupId, wagonId }: Attrs) {
    super()
    this.id = id
    this.accessGroupId = accessGroupId
    this.wagonId = wagonId
  }

  setRels({ accessGroup, wagon }: Rels) {
    this.accessGroup = accessGroup
    this.wagon = wagon
  }
}

interface WagonAccessRight extends Attrs, Rels {}
export { WagonAccessRight }
