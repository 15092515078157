import { Cost } from 'src/service-design/shared/costs'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Shift } from 'src/service-design/shared/models/shift'
import {
  adjustPenaltyForLeave,
  snapTo,
  Duration,
  EpochTime,
  Interval,
} from 'src/service-design/shared/utils/dates'

export interface Attrs {
  id: string
  shiftId: string
}

export interface Rels {
  shift: Shift
}

abstract class ShiftAssignment extends Mapper {
  abstract get task(): any // TODO DJH
  abstract get name(): string
  static collection: string

  _costs: Cost[]

  /**
   * Base class for assigning/attaching a Task to a Shift.
   * See CrewPool.
   *
   * Note that startTime depends on both Task and Shift timings.
   *
   * Related models:
   * - `Shift`;
   * - `Task`;
   * - `CrewPool`;
   * - `Cost`.
   *
   * @constructor
   * @param {string} id - The ShiftAssignment Id.
   * @param {string} shiftId - The Shift Id.
   */
  constructor({ id, shiftId }: Attrs) {
    super()
    this.id = id
    this.shiftId = shiftId
  }

  setRels({ shift }: Rels) {
    this.shift = shift
  }

  /**
   * @deprecated
   */
  get duration(): number {
    return this.durationVO.toSeconds()
  }

  get durationVO(): Duration {
    return Duration.fromSeconds(this.task.duration)
  }

  get taskId() {
    return this.task.id
  }

  /**
   * @deprecated
   */
  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return EpochTime.fromSeconds(
      snapTo(
        this.task.startTimeLocalNormalized,
        this.shift.signOnLocalVO.toSeconds(),
      ),
    )
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return this.startTimeLocalVO.makeLater(this.durationVO)
  }

  /**
   * @deprecated
   */
  get startTimeLocalNormalized(): number {
    return this.startTimeLocalNormalizedVO.toSeconds()
  }

  get startTimeLocalNormalizedVO(): EpochTime {
    return EpochTime.fromSeconds(this.task.startTimeLocalNormalized)
  }

  /**
   * @deprecated
   */
  get endTimeLocalNormalized(): number {
    return this.endTimeLocalNormalizedVO.toSeconds()
  }

  get endTimeLocalNormalizedVO(): EpochTime {
    return EpochTime.fromSeconds(this.task.endTimeLocalNormalized)
  }

  get adjustedPenaltyMultiplier(): number {
    return adjustPenaltyForLeave(
      this.task.penaltyMultiplier,
      this.shift.pool.annualLeaveLoading,
      this.shift.pool.annualLeavePercentage,
    )
  }

  get adjustedHourlyRate(): number {
    return (
      this.shift.adjustedHourlyRate +
      this.shift.adjustedApmHourlyRate * this.adjustedPenaltyMultiplier
    )
  }

  get sign(): 1 | -1 {
    return this.startTimeLocalVO.isStrictlyEarlier(this.endTimeLocalVO)
      ? +1
      : -1
  }

  /**
   * @deprecated
   */
  get boundsLocal(): [number, number] {
    return [
      this.boundsLocalVO.start.toSeconds(),
      this.boundsLocalVO.end.toSeconds(),
    ]
  }

  get boundsLocalVO(): Interval {
    return this.sign === +1
      ? Interval.fromEpochTimes(this.startTimeLocalVO, this.endTimeLocalVO)
      : Interval.fromEpochTimes(this.endTimeLocalVO, this.startTimeLocalVO)
  }

  /**
   * @deprecated
   */
  get weightedTaskDuration(): number {
    return this.weightedTaskDurationVO.toSeconds()
  }

  get weightedTaskDurationVO(): Duration {
    if (this.durationVO.equals(Duration.nil)) {
      return Duration.nil
    }
    const [startTimeLocal, endTimeLocal] = this.boundsLocal
    const startIndex = this.shift.taskWeights.findIndex(
      ([[time]]) => time === startTimeLocal,
    )
    const endIndex = this.shift.taskWeights.findIndex(
      ([[, time]]) => time === endTimeLocal,
    )

    let duration = Duration.nil

    for (let i = startIndex; i <= endIndex; i++) {
      const [[lowerBound, upperBound], count] = this.shift.taskWeights[i]
      const upperBoundVO = EpochTime.fromSeconds(upperBound)
      const lowerBoundVO = EpochTime.fromSeconds(lowerBound)
      if (count) {
        duration = duration.add(
          Interval.fromEpochTimes(lowerBoundVO, upperBoundVO)
            .duration()
            .divide(count),
        )
      }
    }
    return duration
  }

  get actingAsDriver(): boolean {
    return this.task.requiresDriver
  }

  get requiresTravel(): boolean {
    return this.task.requiresTravel
  }

  isQualified(): boolean {
    return this.shift.isQualified(this.task)
  }
}

interface ShiftAssignment extends Attrs, Rels {}
export { ShiftAssignment }
