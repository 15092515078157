import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { ServiceEvent } from './model'

export interface IServiceEventRepo extends IRepo<ServiceEvent> {
  byStartId(id: string): ServiceEvent[]
  byTemplateLegId(id: string): ServiceEvent[]
  byStartLegId(id: string): ServiceEvent[]
  byServiceId(id: string): ServiceEvent[]
}

export class ServiceEventRepo implements IServiceEventRepo {
  values: ServiceEvent[]
  idMap: { [key: string]: ServiceEvent }
  startIdMap: Indexer<ServiceEvent>
  templateLegIdMap: Indexer<ServiceEvent>
  startLegIdMap: Indexer<ServiceEvent>
  serviceIdMap: Indexer<ServiceEvent>

  constructor(values: ServiceEvent[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
    this.serviceIdMap = new Indexer(values, x => x.serviceId)
  }

  byId(id: string): ServiceEvent {
    return this.idMap[id]
  }

  byStartId(startId: string): ServiceEvent[] {
    return this.startIdMap.lookup(startId)
  }

  byTemplateLegId(templateLegId: string): ServiceEvent[] {
    return this.templateLegIdMap.lookup(templateLegId)
  }

  byStartLegId(startLegId: string): ServiceEvent[] {
    return this.startLegIdMap.lookup(startLegId)
  }

  byServiceId(serviceId: string): ServiceEvent[] {
    return this.serviceIdMap.lookup(serviceId)
  }
}
