import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { UncrewedLegTask } from './model'

export interface IUncrewedLegTaskRepo extends IRepo<UncrewedLegTask> {
  byStartId(id: string): UncrewedLegTask[]
  byTemplateLegId(id: string): UncrewedLegTask[]
  byStartLegId(id: string): UncrewedLegTask[]
}

export class UncrewedLegTaskRepo implements IUncrewedLegTaskRepo {
  values: UncrewedLegTask[]
  idMap: { [key: string]: UncrewedLegTask }
  startIdMap: Indexer<UncrewedLegTask>
  templateLegIdMap: Indexer<UncrewedLegTask>
  startLegIdMap: Indexer<UncrewedLegTask>

  constructor(values: UncrewedLegTask[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
    this.startLegIdMap = new Indexer(
      values,
      x => `${x.startId}:${x.templateLegId}`,
    )
  }

  byId(id: string): UncrewedLegTask {
    return this.idMap[id]
  }

  byStartId(id: string): UncrewedLegTask[] {
    return this.startIdMap.lookup(id)
  }

  byTemplateLegId(id: string): UncrewedLegTask[] {
    return this.templateLegIdMap.lookup(id)
  }

  byStartLegId(id: string): UncrewedLegTask[] {
    return this.startLegIdMap.lookup(id)
  }
}
