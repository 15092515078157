import styled from 'styled-components'

const StyledDiv = styled.div<{ bodyScrolls?: boolean }>`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.3em;
  overflow-y: ${({ bodyScrolls = false }) => (bodyScrolls ? 'hidden' : 'auto')};
  height: 100%;

  .header {
    line-height: 35px;
    flex-shrink: 0;
    font-weight: 500;
    display: flex;
    width: 100%;
    padding-right: 10px;

    .cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      outline: none;
    }

    .cell .hidden {
      visibility: hidden;
    }

    .header-text {
      line-height: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .body {
    border-style: solid;
    border-width: 1px 0;
    border-color: #e5e5e5;
    width: 100%;
    overflow-y: ${({ bodyScrolls = false }) =>
      bodyScrolls ? 'auto' : 'visible'};
  }

  .footer .button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .header .button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 35px;
    margin: 0;
    position: relative;
    outline: none;
  }

  .row:nth-child(odd) {
    background: whitesmoke;
  }

  .row.active {
    background: #ceecf9;
  }

  .cell {
    flex-grow: 1;
    padding: 0.25em 0.75em;
  }

  .row .controls {
    position: absolute;
    z-index: 100;
    right: 0;
    overflow: visible;
  }
`

export const StyledConfigTable = styled(StyledDiv)`
  .cell {
    flex-basis: 0;
  }
`

export default StyledDiv
