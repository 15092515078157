import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { CustomTask } from './model'

export interface ICustomTaskRepo extends IRepo<CustomTask> {
  byStartLocationId(id: string): CustomTask[]
  byEndLocationId(id: string): CustomTask[]
}

export class CustomTaskRepo implements ICustomTaskRepo {
  values: CustomTask[]
  idMap: { [key: string]: CustomTask }
  startLocationIdMap: Indexer<CustomTask>
  endLocationIdMap: Indexer<CustomTask>

  constructor(values: CustomTask[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startLocationIdMap = new Indexer(values, x => x.startLocationId)
    this.endLocationIdMap = new Indexer(values, x => x.endLocationId)
  }

  byId(id: string): CustomTask {
    return this.idMap[id]
  }

  byStartLocationId(startLocationId: string): CustomTask[] {
    return this.startLocationIdMap.lookup(startLocationId)
  }

  byEndLocationId(endLocationId: string): CustomTask[] {
    return this.endLocationIdMap.lookup(endLocationId)
  }
}
