import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { TrainStart } from './model'

export const trainStartFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.trainstarts.map(data => new TrainStart(data)),
  setRelsFromSpec((l: TrainStart, state: any) => ({
    template: registry.provide('trainTemplateRepo')(state).byId(l.templateId),
    driverAssignments: registry
      .provide('driverAssignmentRepo')(state)
      .byTrainId(l.id),
    legs: registry.provide('startLegRepo')(state).byStartId(l.id),
    singletons: state.collectionData.singletons,
  })),
)
