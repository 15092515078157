import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Location } from './model'

export interface ILocationRepo extends IRepo<Location> {
  byTimezoneId(id: string): Location[]
}

export class LocationRepo implements ILocationRepo {
  values: Location[]
  idMap: { [key: string]: Location }
  timezoneIdMap: Indexer<Location>

  constructor(values: Location[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.timezoneIdMap = new Indexer(values, x => x.timezoneId)
  }

  byId(id: string): Location {
    return this.idMap[id]
  }

  byTimezoneId(timezoneId: string): Location[] {
    return this.timezoneIdMap.lookup(timezoneId)
  }
}
