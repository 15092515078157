import { createSelector } from 'reselect'

import { getBatchIds } from 'src/service-design/sd-plan/selectors/trains'
import { RootState } from 'src/service-design/shared/document-factory/factory-input'
import { CustomTask } from 'src/service-design/shared/models/custom-task'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Service } from 'src/service-design/shared/models/service'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Wagon } from 'src/service-design/shared/models/wagon'

export const getIsSelected = createSelector(
  getBatchIds,
  (_: RootState, selected: Record<string, string>) => selected,
  (batchIds, selected) => (target: { id?: string }) => {
    if (target instanceof CustomTask) {
      // handle the task or its assignment being focused
      if (target.id === selected.customTaskId) {
        return true
      }
      if (
        target.assignment &&
        target.assignment.id === selected.customTaskAssignmentId
      ) {
        return true
      }
    } else if (target instanceof TrainStart) {
      // Note: Template is always selected whenever start is selected so if start is
      // selected then *just* check that
      if (selected.trainStartId) {
        return target.id === selected.trainStartId
      }
      return (
        target.templateId === selected.trainTemplateId ||
        batchIds.includes(target.templateId)
      )
    } else if (target instanceof Wagon) {
      if (target.id === selected.wagonId) {
        return true
      }
    } else if (target instanceof Lococlass) {
      if (target.id === selected.lococlassId) {
        return true
      }
    } else if (target instanceof Service) {
      if (target.id === selected.serviceId) {
        return true
      }
    }

    return batchIds.includes(target.id)
  },
)
