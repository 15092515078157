import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  name: string
  bgColor: string
}

class BusinessGroup extends Mapper {
  /**
   * A BusinessGroup is a tag that can be applied to a `TrainTemplate`
   * for accounting purposes.
   *
   * For a large rail operator examples might
   * include: Intermodal, Coal, Cement, Automotive.
   *
   * Related models:
   * - `TrainTemplate`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the business group.
   * @param {string} bgColor - A color string.
   */
  constructor({ id, name, bgColor }: Attrs) {
    super()
    this.id = id
    this.name = name
    this.bgColor = bgColor
  }
}

interface BusinessGroup extends Attrs {}
export { BusinessGroup }
