import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  name: string
}

class TrainGraph extends Mapper {
  /**
   * A TrainGraph describes a way of visualising a 'line' through network.
   *
   * A note on terminology. Within the tool, the train graph visualise
   * represents multiple TrainGraph classes. Each 'piece' of the train graph
   * maps to one of these TrainGraph classes. Arguably, a more suitable name
   * for this class would be a TrainGraphLine.
   *
   * Related models:
   * - `TrainGraphLocation`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the line the TrainGraph represents.
   */
  constructor({ id, name }: Attrs) {
    super()
    this.id = id
    this.name = name
  }
}

interface TrainGraph extends Attrs {}
export { TrainGraph }
