import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { LVTrip } from './model'

export const lvTripFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.lvtrips.map(
      ({ durationSecs, ...data }) =>
        new LVTrip({
          durationSecs: Duration.fromSeconds(durationSecs),
          ...data,
        }),
    ),
  setRelsFromSpec((lvTrip: LVTrip, state: any) => ({
    loc1: registry.provide('locationRepo')(state).byId(lvTrip.loc1Id),
    loc2: registry.provide('locationRepo')(state).byId(lvTrip.loc2Id),
  })),
)
