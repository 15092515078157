import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { CrewPool } from './model'

export interface ICrewPoolRepo extends IRepo<CrewPool> {
  byTypeId(id: string): CrewPool[]
}

export class CrewPoolRepo implements ICrewPoolRepo {
  values: CrewPool[]
  idMap: { [key: string]: CrewPool }
  typeIdMap: Indexer<CrewPool>

  constructor(values: CrewPool[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.typeIdMap = new Indexer(values, x => x.typeId)
  }

  byId(id: string): CrewPool {
    return this.idMap[id]
  }

  byTypeId(typeId: string): CrewPool[] {
    return this.typeIdMap.lookup(typeId)
  }
}
