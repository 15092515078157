import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainTemplate } from './model'

export interface ITrainTemplateRepo extends IRepo<TrainTemplate> {
  byBusinessGroupId(id: string): TrainTemplate[]
  byTypeId(id: string): TrainTemplate[]
}

export class TrainTemplateRepo implements ITrainTemplateRepo {
  values: TrainTemplate[]
  idMap: { [key: string]: TrainTemplate }
  businessGroupIdMap: Indexer<TrainTemplate>
  typeIdMap: Indexer<TrainTemplate>

  constructor(values: TrainTemplate[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.businessGroupIdMap = new Indexer(values, x => x.businessGroupId)
    this.typeIdMap = new Indexer(values, x => x.typeId)
  }

  byId(id: string): TrainTemplate {
    return this.idMap[id]
  }

  byBusinessGroupId(id: string): TrainTemplate[] {
    return this.businessGroupIdMap.lookup(id)
  }

  byTypeId(id: string): TrainTemplate[] {
    return this.typeIdMap.lookup(id)
  }
}
