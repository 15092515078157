import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { RosterHead } from './model'

export interface IRosterHeadRepo extends IRepo<RosterHead> {
  byParentId(id: string): RosterHead
  byHeadId(id: string): RosterHead
}

export class RosterHeadRepo implements IRosterHeadRepo {
  values: RosterHead[]
  idMap: { [key: string]: RosterHead }
  parentIdMap: { [key: string]: RosterHead }
  headIdMap: { [key: string]: RosterHead }

  constructor(values: RosterHead[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.headIdMap = Object.fromEntries(values.map(v => [v.headId, v]))
    this.parentIdMap = Object.fromEntries(values.map(v => [v.parentId, v]))
  }

  byId(id: string): RosterHead {
    return this.idMap[id]
  }

  byHeadId(headId: string): RosterHead {
    return this.headIdMap[headId]
  }

  byParentId(parentId: string): RosterHead {
    return this.parentIdMap[parentId]
  }
}
