import { Selector } from 'react-redux'
import { createSelector } from 'reselect'

import { RootState } from 'src/service-design/shared/document-factory/factory-input'

export type Cost = {
  costType: string
  entity: string
  quantity: number
  rate: number
}

export interface CostSummary extends Cost {
  cost: number
}

// The async worker uses the cost selector from the correct client up in the client folder
// But the export worksheet is still in core so requires some form of dependency injection
// We hope to rectify this in future by moving worksheet into client and removing this
let clientCosts: Selector<RootState, CostSummary[]>
let clientCostBreakdown: readonly string[]
export const setClientCosts = (
  costSelector: Selector<RootState, Record<string, Cost[]>>,
  costBreakdown: readonly string[],
) => {
  clientCosts = createSelector(costSelector, (costs): CostSummary[] =>
    Object.values(costs)
      .flat()
      .map(cost => ({ ...cost, cost: cost.quantity * cost.rate }))
      .filter(cost => cost.cost > 0),
  )
  clientCostBreakdown = costBreakdown
}

// This exists for test purposes only
export const resetClientCosts = () => {
  clientCosts = undefined
  clientCostBreakdown = undefined
}

export const getCosts = (state: RootState) => {
  if (!clientCosts) {
    throw new Error(
      "clientCosts has not been set. Did you forget to call 'setClientCosts'?",
    )
  }
  return clientCosts(state)
}
export const getClientCostBreakdown = () => {
  if (!clientCostBreakdown) {
    throw new Error(
      "clientCostBreakdown has not been set. Did you forget to call 'setClientCosts'?",
    )
  }
  return clientCostBreakdown
}
