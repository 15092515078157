import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainGraphLocation } from './model'

export interface ITrainGraphLocationRepo extends IRepo<TrainGraphLocation> {
  byTrainGraphId(id: string): TrainGraphLocation[]
  byLocationId(id: string): TrainGraphLocation[]
}

export class TrainGraphLocationRepo implements ITrainGraphLocationRepo {
  values: TrainGraphLocation[]
  idMap: { [key: string]: TrainGraphLocation }
  trainGraphIdMap: Indexer<TrainGraphLocation>
  locationIdMap: Indexer<TrainGraphLocation>

  constructor(values: TrainGraphLocation[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.trainGraphIdMap = new Indexer(values, x => x.trainGraphId)
    this.locationIdMap = new Indexer(values, x => x.locationId)
  }

  byId(id: string): TrainGraphLocation {
    return this.idMap[id]
  }

  byTrainGraphId(id: string): TrainGraphLocation[] {
    return this.trainGraphIdMap.lookup(id)
  }

  byLocationId(id: string): TrainGraphLocation[] {
    return this.locationIdMap.lookup(id)
  }
}
