import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainType } from './model'

export interface ITrainTypeRepo extends IRepo<TrainType> {}

export class TrainTypeRepo implements ITrainTypeRepo {
  values: TrainType[]
  idMap: { [key: string]: TrainType }

  constructor(values: TrainType[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
  }

  byId(id: string): TrainType {
    return this.idMap[id]
  }
}
