export class Registry<I> {
  registered: I

  constructor() {
    this.registered = {} as I
  }

  bind<K extends keyof I>(token: K, factory: I[K]): void {
    if (token in this.registered) {
      throw new Error(
        `Container unable to bind token "${token}", token already bound.`,
      )
    }

    this.registered[token] = factory
  }

  provide<K extends keyof I>(token: K): I[K] {
    if (!(token in this.registered)) {
      throw new Error(`Container unable to find token "${token}".`)
    }
    return this.registered[token]
  }
}
