import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/scenario/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { locationFactory } from './factory'
import { getLocationRepairs } from './repairs'
import { ILocationRepo, LocationRepo } from './repo'

const locationRepo = defaultMemoize(
  (input: FactoryInput) => new LocationRepo(locationFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  locationRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type LocationRepoReg = {
  locationRepo: FromDocumentFactory<FactoryInput, ILocationRepo>
}

export function setup<R extends LocationRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('locationRepo', locationRepo)

  relationships.addConstraints('locations', {
    rels: [
      {
        collection: 'timezones',
        foreign: 'timezoneId',
        name: 'timezone',
      },
    ],
  })

  repairs.register(createSelector(values, getLocationRepairs))
}

export { Location } from './model'
