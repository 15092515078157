import { Mapper } from 'src/service-design/shared/models/mapper'
import { Service } from 'src/service-design/shared/models/service'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'

interface Attrs {
  id: string
  startId: string
  templateLegId: string
  serviceId: string
  type: 'attach' | 'detach'
}

interface Rels {
  start: TrainStart
  startLeg: StartLeg
  templateLeg: TemplateLeg
  service: Service
}

class ServiceEvent extends Mapper {
  /**
   * ServiceEvents describe how Services are attach and detached from
   * TrainStarts. By definition these events should come in pairs.
   *
   * TODO: The modelling here is probably more convoluted than it should be. The
   * code in Service.buildAssignments() could probably be dramatically
   * simplified if rather than having a pair of event we one class that
   * describes the Service attach and detach.
   *
   * @param {string} id: The entity id
   * @param {string} startId: The id of the TrainStart
   * @param {string} templateLegId: The id of the templateLeg
   * @param {string} serviceId: The id of the Service
   * @param {'attach' | 'detach'} type: Whether the instance represents an
   *   'attach' or a 'detach'.
   */
  constructor({ id, startId, templateLegId, serviceId, type }: Attrs) {
    super()
    this.id = id
    this.startId = startId
    this.templateLegId = templateLegId
    this.serviceId = serviceId
    this.type = type
  }

  setRels({ start, startLeg, templateLeg, service }: Rels) {
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
    this.service = service
  }

  get location() {
    return this.type === 'attach' ? this.startLeg.origin : this.startLeg.dest
  }
}

interface ServiceEvent extends Attrs, Rels {}
export { ServiceEvent }
