import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { templateLegFactory } from './factory'
import { ITemplateLegRepo, TemplateLegRepo } from './repo'

const templateLegRepo = defaultMemoize(
  (input: FactoryInput) => new TemplateLegRepo(templateLegFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  templateLegRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type TemplateLegRepoReg = {
  templateLegRepo: FromDocumentFactory<FactoryInput, ITemplateLegRepo>
}

export function setup<R extends TemplateLegRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('templateLegRepo', templateLegRepo)

  relationships.addConstraints('templatelegs', {
    unique: [['templateId', 'legNum']],
    rels: [
      {
        collection: 'traintemplates',
        foreign: 'templateId',
        name: 'template',
      },
      {
        collection: 'corridors',
        foreign: 'corridorId',
        name: 'corridor',
      },
    ],
  })
}

export { TemplateLeg } from './model'
