import { DEFAULT_CATEGORY } from 'src/service-design/sd-plan/constants'
import { HOURS_PER_WEEK } from 'src/service-design/shared/constants'
import {
  Schema,
  toDate,
  fromDate,
  fromDuration,
  toDuration,
  fromJSON,
  toJSON,
  TimeOfWeekSchema,
  deltatoExcel,
  cyclicTimeToExcel,
} from 'src/service-design/shared/exporter/schema'

export const CrewPool = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'typeId', dumpFrom: 'typeId' },
  { header: 'Total Duty Hours', dumpFrom: 'rosterDutyHours', load: false },
  {
    header: 'Average Roster Duty Hours Per Line',
    dumpFrom: 'averageRosterDutyHoursPerLine',
    load: false,
  },
  { header: 'APM', dumpFrom: 'adjustedAveragePenaltyMultiplier', load: false },
  { header: 'Number of Lines', dumpFrom: 'lines.length', load: false },
  {
    header: 'Number of Relief Lines',
    dumpFrom: 'reliefLines.length',
    load: false,
  },
  { header: 'Number of RDOs', dumpFrom: 'totalRDOs', load: false },
])

export const DriverAssignment = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'shiftId', dumpFrom: 'shiftId' },
  { header: 'trainId', dumpFrom: 'trainId' },
  {
    header: 'startLocationChangeoverId',
    dumpFrom: 'startLocationChangeoverId',
    allowNull: true,
  },
  {
    header: 'startTrainChangeoverId',
    dumpFrom: 'startTrainChangeoverId',
    allowNull: true,
  },
  {
    header: 'endLocationChangeoverId',
    dumpFrom: 'endLocationChangeoverId',
    allowNull: true,
  },
  {
    header: 'endTrainChangeoverId',
    dumpFrom: 'endTrainChangeoverId',
    allowNull: true,
  },
])

export const LocalAssignment = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'shiftId', dumpFrom: 'shiftId' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Kind', dumpFrom: 'kind' },
])

export const UncrewedLegTask = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Kind', dumpFrom: 'kind' },
])

export const LoadingWorkSplit = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Kind', dumpFrom: 'kind' },
  { header: 'Primary Task Duration', dumpFrom: 'primaryTaskDuration' },
])

export const StartLegTaskOffset = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Kind', dumpFrom: 'kind' },
  { header: 'Time Offset', dumpFrom: 'offset' },
])

export const LoadingAssignment = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'shiftId', dumpFrom: 'shiftId' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Kind', dumpFrom: 'kind' },
  { header: 'cargoTypeId', dumpFrom: 'cargoTypeId' },
  { header: 'Acting as Driver', dumpFrom: 'actingAsDriver' },
  { header: 'Index', dumpFrom: 'index' },
])

export const LocationChangeover = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'At Departure', dumpFrom: 'atDeparture' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'timeOffset', dumpFrom: 'timeOffset' },
])

export const LocationChangeoverLock = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'changeoverId', dumpFrom: 'changeoverId' },
])

export const TrainChangeover = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'At Departure', dumpFrom: 'atDeparture' },
  { header: 'startAId', dumpFrom: 'startAId' },
  { header: 'templateLegAId', dumpFrom: 'templateLegAId' },
  { header: 'startBId', dumpFrom: 'startBId' },
  { header: 'templateLegBId', dumpFrom: 'templateLegBId' },
  { header: 'timeOffset', dumpFrom: 'timeOffset' },
])

export const TrainChangeoverLock = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'changeoverId', dumpFrom: 'changeoverId' },
])

export const EmptyWagonEvent = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId', allowNull: true },
  { header: 'Train', dumpFrom: 'leg.train.name', load: false },
  { header: 'Leg', dumpFrom: 'leg.legNum', load: false },
  { header: 'Quantity', dumpFrom: 'quantity' },
  { header: 'wagonId', dumpFrom: 'wagonId' },
  { header: 'Wagon', dumpFrom: 'wagon.code', load: false },
  { header: 'type', dumpFrom: 'type' },
])

export const Service = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
    validate: { type: 'string' },
    required: true,
  },
  {
    header: 'Name',
    dumpFrom: 'name',
    validate: { type: 'string' },
    required: true,
  },
  {
    header: 'Customer',
    dumpFrom: 'customer',
    validate: { type: 'string' },
    required: true,
  },
  {
    header: 'originId',
    dumpFrom: 'originId',
    validate: { type: 'string' },
    required: true,
  },
  { header: 'Origin Code', dumpFrom: 'origin.code', load: false },
  {
    header: 'destinationId',
    dumpFrom: 'destinationId',
    validate: { type: 'string' },
    required: true,
  },
  { header: 'Destination Code', dumpFrom: 'destination.code', load: false },
  {
    header: 'Delivery Cut-off',
    dumpFrom: 'deliveryCutOffLocal',
    dump: toDate,
    load: fromDate,
    validate: TimeOfWeekSchema,
    required: true,
  },
  {
    header: 'Due',
    dumpFrom: 'dueLocalNormalized',
    loadTo: 'dueLocal',
    dump: toDate,
    load: fromDate,
    validate: TimeOfWeekSchema,
    required: true,
  },
  {
    header: 'cargoTypeId',
    dumpFrom: 'cargoTypeId',
    validate: { type: 'string' },
    required: true,
  },
  {
    header: 'Cargo Type',
    dumpFrom: 'cargoType.name',
    load: false,
  },
  {
    header: 'Volume Unit',
    dumpFrom: 'volumeUnit',
    load: false,
  },
  {
    header: 'Volume',
    dumpFrom: 'volume',
    validate: { type: 'number', minimum: 0 },
    required: true,
  },
  {
    header: 'Tonnage per Unit',
    dumpFrom: 'tonnagePerUnit',
    allowNull: true,
    validate: { type: 'number', minimum: 0 },
    required: true,
  },
  {
    header: 'Requires Loading Driver',
    dumpFrom: 'requiresLoadingDriver',
    default: false,
    validate: { type: 'boolean' },
    required: true,
  },
  {
    header: 'Requires Loading RO',
    dumpFrom: 'requiresLoadingRO',
    default: false,
    validate: { type: 'boolean' },
    required: true,
  },
  {
    header: 'Requires Unloading Driver',
    dumpFrom: 'requiresUnloadingDriver',
    default: false,
    validate: { type: 'boolean' },
    required: true,
  },
  {
    header: 'Requires Unloading RO',
    dumpFrom: 'requiresUnloadingRO',
    default: false,
    validate: { type: 'boolean' },
    required: true,
  },
  {
    header: 'Comment',
    dumpFrom: 'comment',
    default: '',
    validate: { type: 'string' },
  },
])

export const ServiceEvent = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'serviceId', dumpFrom: 'serviceId' },
  { header: 'Demand', dumpFrom: 'service.name', load: false },
  { header: 'Type', dumpFrom: 'type' },
])

export const ServiceLock = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'serviceId', dumpFrom: 'serviceId' },
  { header: 'Demand', dumpFrom: 'service.name', load: false },
])

export const ResourceAvailability = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'lococlassId', dumpFrom: 'lococlassId', allowNull: true },
  { header: 'wagonId', dumpFrom: 'wagonId', allowNull: true },
  { header: 'Resource', dumpFrom: 'resource.name', load: false },
  { header: 'Quantity', dumpFrom: 'quantity' },
])

export const ResourceUnavailability = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'availabilityId', dumpFrom: 'availabilityId' },
  { header: 'Resource', dumpFrom: 'resource.name', load: false },
  { header: 'Quantity', dumpFrom: 'quantity' },
  { header: 'Reason', dumpFrom: 'reason', default: '' },
])

export const TrainStart = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'Reference', dumpFrom: 'name', load: false },
  { header: 'templateId', dumpFrom: 'templateId' },
  { header: 'Template Train ID', dumpFrom: 'template.name', load: false },
  { header: 'Custom Name', dumpFrom: 'customName', default: '' },
  {
    header: 'Departs Day',
    dumpFrom: 'departsDayOfWeek',
  },
])

export const TrainTemplate = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'Template Train ID', dumpFrom: 'name' },
  { header: 'businessGroupId', dumpFrom: 'businessGroupId' },
  { header: 'Business Group', dumpFrom: 'businessGroup.name', load: false },
  { header: 'typeId', dumpFrom: 'typeId' },
  { header: 'Type', dumpFrom: 'type.name', load: false },
  {
    header: 'ECP Braking',
    dumpFrom: 'ecpBraking',
    default: false,
  },
  { header: 'Min Lead Locos', dumpFrom: 'minLeadLocos' },
  { header: 'Max Hauled Locos', dumpFrom: 'maxHauledLocos' },
  { header: 'Lock', dumpFrom: 'lock' },
])

export const TemplateLeg = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'templateId', dumpFrom: 'templateId' },
  {
    header: 'Template Train ID',
    dumpFrom: 'template.name',
    load: false,
  },
  {
    header: 'Number of Starts',
    dumpFrom: 'template.starts.length',
    load: false,
  },
  {
    header: 'Business Group',
    dumpFrom: 'template.businessGroup.name',
    load: false,
  },
  { header: 'Leg', dumpFrom: 'legNum' },
  { header: 'corridorId', dumpFrom: 'corridorId' },
  { header: 'Origin Code', dumpFrom: 'origin.code', load: false },
  { header: 'Destination Code', dumpFrom: 'dest.code', load: false },
  { header: 'Corridor', dumpFrom: 'corridor.name', load: false },
  {
    header: 'Corridor Length',
    dumpFrom: 'corridor.distance',
    load: false,
  },
  {
    header: 'Dep',
    dumpFrom: 'departsLocalNormalized',
    dump: toDate,
    load: false,
  },
  {
    header: 'Arr',
    dumpFrom: 'arrivesLocalNormalized',
    dump: toDate,
    load: false,
  },
  {
    header: 'Departs Delta',
    dumpFrom: 'departsDelta',
    dump: deltatoExcel,
    load: fromDuration,
  },
  {
    header: 'Arrives Delta',
    dumpFrom: 'arrivesDelta',
    dump: deltatoExcel,
    load: fromDuration,
  },
  {
    header: 'Dep UTC',
    dumpFrom: 'departsUtc',
    dump: toDate,
    load: false,
  },
  {
    header: 'Arr UTC',
    dumpFrom: 'arrivesUtc',
    dump: toDate,
    load: false,
  },
  {
    header: 'Direction',
    dumpFrom: 'forward',
    dump: forward => (forward ? 'Fwd' : 'Rev'),
    load: forward => forward === 'Fwd',
  },
  { header: 'Extra Tonnage', dumpFrom: 'extraTonnage' },
  { header: 'Extra Length', dumpFrom: 'extraLength' },
  { header: 'Extra Wagons', dumpFrom: 'extraWagons' },
  {
    header: 'Total Length per Start',
    dumpFrom: 'lengthPerStart',
    load: false,
  },
  {
    header: 'Total Tonnage per Start',
    dumpFrom: 'tonnagePerStart',
    load: false,
  },
  {
    header: 'Maximum Length per Start',
    dumpFrom: 'corridor.maxLength',
    load: false,
  },
  {
    header: 'Maximum Tonnage per Start',
    dumpFrom: 'trainType.maxTonnage',
    load: false,
  },
  { header: 'GTM per Start', dumpFrom: 'gtmPerStart', load: false },
])

export const Shift = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'poolId', dumpFrom: 'poolId' },
  { header: 'Name', dumpFrom: 'name', default: '' },
  { header: 'Comment', dumpFrom: 'comment', default: '' },
  {
    header: 'Sign On',
    dumpFrom: 'signOnLocal',
    dump: toDate,
    load: fromDate,
  },
  {
    header: 'Sign Off',
    dumpFrom: 'signOffLocalNormalized',
    loadTo: 'signOffLocal',
    dump: toDate,
    load: fromDate,
  },
])

export const ShiftLine = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'shiftId', dumpFrom: 'shiftId' },
  { header: 'rosterLineId', dumpFrom: 'rosterLineId' },
])

export const WagonSet = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'serviceId', dumpFrom: 'serviceId' },
  { header: 'Demand', dumpFrom: 'service.name', load: false },
  { header: 'Lock', dumpFrom: 'lock' },
])

export const WagonAllocation = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'Quantity', dumpFrom: 'quantity' },
  { header: 'wagonId', dumpFrom: 'wagonId' },
  { header: 'Wagon', dumpFrom: 'wagon.code', load: false },
  { header: 'wagonsetId', dumpFrom: 'wagonsetId', allowNull: true },
])

export const LocoEvent = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Train', dumpFrom: 'leg.train.name', load: false },
  { header: 'Leg', dumpFrom: 'leg.legNum', load: false },
  { header: 'Type', dumpFrom: 'type' },
  { header: 'Working', dumpFrom: 'working' },
  { header: 'lococlassId', dumpFrom: '_lococlassId', loadTo: 'lococlassId' },
  { header: 'Loco Class', dumpFrom: 'lococlass.name', load: false },
  {
    header: 'foreignRailroadId',
    dumpFrom: 'foreignRailroadId',
    allowNull: true,
  },
  { header: 'Foreign Railroad', dumpFrom: 'foreignRailroad.name', load: false },
])

export const WorkingLocoLock = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'startId', dumpFrom: 'startId' },
  { header: 'templateLegId', dumpFrom: 'templateLegId' },
  { header: 'Train', dumpFrom: 'leg.train.name', load: false },
  { header: 'Leg', dumpFrom: 'leg.legNum', load: false },
])

export const LocationSummary = new Schema([
  { header: 'Location', dumpFrom: 'locationSummary.location.name' },
  { header: 'Train', dumpFrom: 'event.target.name' },
  { header: 'Resource', dumpFrom: 'resource.name' },
  { header: 'Event', dumpFrom: 'event.name' },
  {
    header: 'Time',
    dumpFrom: 'localOffset',
    dump: toDate,
  },
  { header: 'Change in Resources', dumpFrom: 'delta' },
  { header: 'Available Resources', dumpFrom: 'balance' },
])

export const FleetPlan = new Schema([
  { header: 'Category', dumpFrom: 'category' },
  { header: 'Utilization', dumpFrom: 'utilizationType' },
  { header: 'Resource', dumpFrom: 'resource.name' },
  { header: 'Owner', dumpFrom: 'resource.foreignRailroad.name' },
  { header: 'Quantity (loco weeks)', dumpFrom: 'quantity' },
  {
    header: 'Horsepower Hour Debt',
    dumpFrom: 'quantity',
    dump: (quantity, obj) =>
      obj.resource.foreignRailroad
        ? obj.resource.horsepower * quantity * HOURS_PER_WEEK
        : null,
  },
])

export const CustomTask = new Schema([
  { header: 'ID', dumpFrom: 'id' },
  { header: 'Name', dumpFrom: 'name' },
  {
    header: 'Category',
    dumpFrom: 'category',
    default: DEFAULT_CATEGORY,
  },
  { header: 'Start Location ID', dumpFrom: 'startLocationId' },
  { header: 'End Location ID', dumpFrom: 'endLocationId' },
  {
    header: 'Start Time',
    dumpFrom: 'startTimeLocal',
    dump: toDate,
    load: fromDate,
  },
  {
    header: 'End Time',
    dumpFrom: '_endTimeLocal',
    loadTo: 'endTimeLocal',
    dump: cyclicTimeToExcel,
    load: fromDate,
  },
  { header: 'Required Driver?', dumpFrom: 'requiresDriver' },
  { header: 'Comment', dumpFrom: 'comment', allowNull: true },
])

export const CustomTaskAssignment = new Schema([
  { header: 'ID', dumpFrom: 'id' },
  { header: 'Misc Task ID', dumpFrom: 'customTaskId' },
  { header: 'Shift ID', dumpFrom: 'shiftId' },
])

export const RosterLine = new Schema([
  { header: 'ID', dumpFrom: 'id' },
  { header: 'Crew Pool ID', dumpFrom: 'parentId' },
  { header: 'Next Roster Line ID', dumpFrom: 'nextId' },
  { header: 'Relief', dumpFrom: 'relief' },
  { header: 'Line Number', dumpFrom: 'num', load: false },
  { header: 'Duty Hours', dumpFrom: 'dutyHours', load: false },
  { header: 'Duty Cycle Hours', dumpFrom: 'dutyCycleHours', load: false },
  { header: 'RDOs', dumpFrom: 'numRDOs', load: false },
])

export const RosterHead = new Schema([
  { header: 'ID', dumpFrom: 'id' },
  { header: 'Crew Pool ID', dumpFrom: 'parentId' },
  { header: 'Roster Line ID', dumpFrom: 'headId' },
])

export const SwissParameters = new Schema([
  {
    header: 'Cost of leaving leg uncovered',
    dumpFrom: 'underPowerPenalty',
  },
  {
    header: 'Penalty for breaking a lock',
    dumpFrom: 'lockBreakPenalty',
  },
  {
    header: 'Max Consist Configurations',
    dumpFrom: 'maxConsistTypes',
  },
  {
    header: 'Optimality Gap (%)',
    dumpFrom: 'mipgapPercent',
  },
  {
    header: 'Solve Time Limit',
    dumpFrom: 'timeLimitSecs',
    load: fromDuration,
    dump: toDuration,
  },
  {
    header: 'Penalty for changing a loco from current',
    dumpFrom: 'locoDiffCost',
  },
])

export const CetoParameters = new Schema([
  {
    header: 'Optimality Gap (%)',
    dumpFrom: 'mipgapPercent',
  },
  {
    header: 'Solve Time Limit',
    dumpFrom: 'timeLimitSecs',
    load: fromDuration,
    dump: toDuration,
  },
  {
    header: 'Penalty per shift',
    dumpFrom: 'shiftPenalty',
  },
  {
    header: 'Penalty per LV km',
    dumpFrom: 'lvPenalty',
  },
  {
    header: 'Penalty per missing meal break',
    dumpFrom: 'mealBreakPenalty',
  },
  {
    header: 'Penalty per changeover',
    dumpFrom: 'changePenalty',
  },
  {
    header: 'Penalty per short changeover minute',
    dumpFrom: 'shortChangePenalty',
  },
  {
    header: 'Penalty per uncovered task',
    dumpFrom: 'uncoveredTaskCost',
  },
  {
    header: 'Penalty per LV Imbalance',
    dumpFrom: 'lvImbalanceCost',
  },
  {
    header: 'Minimum changeover duration minutes (can be negative)',
    dumpFrom: 'minChangeoverSecs',
  },
  {
    header: 'Separated Driver Shifts',
    dumpFrom: 'unitDefinitions',
    load: fromJSON,
    dump: toJSON,
  },
])

export const PallasParameters = new Schema([
  {
    header: 'Optimality Gap (%)',
    dumpFrom: 'mipgapPercent',
  },
  {
    header: 'Solve Time Limit',
    dumpFrom: 'timeLimitSecs',
    load: fromDuration,
    dump: toDuration,
  },
  {
    header: 'Leg Timing Adjustment Penalty Per Minute',
    dumpFrom: 'adjustmentPenaltyPerMin',
  },
  {
    header: 'Customer Penalty Per Minute',
    dumpFrom: 'customerPenaltyPerMin',
  },
  {
    header: 'Train Validity Penalty Per Minute',
    dumpFrom: 'trainValidityPenaltyPerMin',
  },
  {
    header: 'Train Conflict Avoidance Penalty',
    dumpFrom: 'trainConflictAvoidancePenalty',
  },
  {
    header: 'Shift Validity Penalty Per Minute',
    dumpFrom: 'shiftValidityPenaltyPerMin',
  },
  {
    header: 'Preserve loco / wagon plans',
    dumpFrom: 'constrainResourceFlow',
  },
  {
    header: 'Preserve shift plan',
    dumpFrom: 'preserveShifts',
  },
])

export const SednaParameters = new Schema([
  {
    header: 'Crew Pool Id',
    dumpFrom: 'poolId',
    allowNull: true,
  },
  {
    header: 'Optimality Gap (%)',
    dumpFrom: 'mipgapPercent',
  },
  {
    header: 'Solve Time Limit',
    dumpFrom: 'timeLimitSecs',
  },
  {
    header: 'Single RDO Penalty',
    dumpFrom: 'singleRDOPenalty',
  },
  {
    header: 'Penalty Per Uncovered Shift',
    dumpFrom: 'penaltyPerUncoveredShift',
  },
  {
    header: 'Duty Variation Penalty Per Hour',
    dumpFrom: 'dutyVariationPenaltyPerHr',
  },
  {
    header: 'Min RDOs On Line',
    dumpFrom: 'minRDOsOnLine',
  },
])

export const PlutoParameters = new Schema([
  {
    header: 'Max Demands Per Train',
    dumpFrom: 'maxServicesPerTrain',
  },
  {
    header: 'Max Wait Time',
    dumpFrom: 'maxWaitTime',
    load: fromDuration,
    dump: toDuration,
  },
  {
    header: 'Optimality Gap (%)',
    dumpFrom: 'mipgapPercent',
  },
  {
    header: 'Max Engine Runtime',
    dumpFrom: 'maxEngineRuntime',
    load: fromDuration,
    dump: toDuration,
  },
  {
    header: 'Default Train Type ID',
    dumpFrom: 'defaultTrainTypeId',
    allowNull: true,
  },
  {
    header: 'Cost Per Train Hour',
    dumpFrom: 'costPerTrainHour',
  },
  {
    header: 'Fixed Cost Per Train',
    dumpFrom: 'fixedCostPerTrain',
  },
  {
    header: 'Penalty Per Wagon Over Capacity',
    dumpFrom: 'penaltyPerExtraWagon',
  },
  {
    header: 'Uncovered Demand Penalty',
    dumpFrom: 'uncoveredServicePenalty',
  },
  {
    header: 'Wagon Repositioning Cost',
    dumpFrom: 'wagonRepositioningCost',
  },
])

export const RDO = new Schema([
  { header: 'ID', dumpFrom: 'id' },
  { header: 'Roster Line ID', dumpFrom: 'lineId' },
  { header: 'Day', dumpFrom: 'day' },
  { header: 'Delta', dumpFrom: 'delta' },
])

export const LVAssignment = new Schema([
  {
    header: 'Shift ID',
    dumpFrom: 'shiftId',
  },
  {
    header: 'Shift',
    dumpFrom: 'shift.name',
  },
  {
    header: 'Start Location',
    dumpFrom: 'startLocation.code',
  },
  {
    header: 'Start Location ID',
    dumpFrom: 'startLocation.id',
  },
  {
    header: 'Start Time',
    dumpFrom: 'startTimeLocal',
    dump: toDate,
  },
  {
    header: 'End Location',
    dumpFrom: 'endLocation.code',
  },
  {
    header: 'End Location ID',
    dumpFrom: 'endLocation.id',
  },
  {
    header: 'End Time',
    dumpFrom: 'endTimeLocal',
    dump: toDate,
  },
  {
    header: 'Distance KMs',
    dumpFrom: 'lvTrip.distanceKMs',
  },
  {
    header: 'Available Duration',
    dumpFrom: 'duration',
    dump: toDuration,
  },
  {
    header: 'Travel Duration',
    dumpFrom: 'lvTrip.durationSecs',
    dump: toDuration,
  },
])

export const Cost = new Schema([
  { header: 'Cost Type', dumpFrom: 'costType' },
  { header: 'Entity', dumpFrom: 'entity' },
  { header: 'Rate', dumpFrom: 'rate' },
  { header: 'Quantity', dumpFrom: 'quantity' },
  { header: 'Cost', dumpFrom: 'cost' },
])

export const YardBlock = new Schema([
  {
    header: 'ID',
    dumpFrom: 'id',
    validate: { type: 'string' },
    required: true,
  },
  {
    header: 'Name',
    dumpFrom: 'name',
    validate: { type: 'string' },
    required: true,
  },
  { header: 'Origin Name', dumpFrom: 'origin.name', load: false },
  {
    header: 'Origin ID ',
    dumpFrom: 'originId',
    validate: { type: 'string' },
    required: true,
  },
  { header: 'Destination Name', dumpFrom: 'destination.name', load: false },
  {
    header: 'Destination ID ',
    dumpFrom: 'destinationId',
    validate: { type: 'string' },
    required: true,
  },
  {
    header: 'Length',
    dumpFrom: 'blockLength',
    validate: { type: 'number', minimum: 0 },
    required: true,
  },
  {
    header: 'Number of Rail Cars',
    dumpFrom: 'numWagons',
    validate: { type: 'number', minimum: 0 },
    required: true,
  },
  {
    header: 'Tonnage',
    dumpFrom: 'tonnage',
    validate: { type: 'number', minimum: 0 },
    required: true,
  },
  {
    header: 'Comment',
    dumpFrom: 'comment',
    default: '',
    validate: { type: 'string' },
  },
  {
    header: 'Average Tare Weight',
    dumpFrom: 'avgTareWeight',
    validate: { type: 'number', minimum: 0 },
    required: true,
  },
])

export const YardBlockAssignment = new Schema([
  {
    header: 'ID',
    dumpFrom: 'id',
  },
  {
    header: 'trainBlockId',
    dumpFrom: 'trainBlockId',
  },
  { header: 'yardBlockId', dumpFrom: 'yardBlockId' },
  { header: 'departingLegId', dumpFrom: 'departingLegId' },
])

export const TrainBlock = new Schema([
  {
    header: 'ID',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  { header: 'Template Leg ID', dumpFrom: 'templateLegId' },
  { header: 'Set Out Location', dumpFrom: 'dest.code', load: false },
])

export const RemoteRest = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'Crew Pool ID', dumpFrom: 'poolId' },
  { header: 'Crew Pool Name', dumpFrom: 'pool.name', load: false },
  { header: 'Start Shift ID', dumpFrom: 'startShiftId' },
  { header: 'Start Shift', dumpFrom: 'startShift.name', load: false },
  { header: 'Next Shift ID', dumpFrom: 'nextShiftId' },
  { header: 'Next Shift', dumpFrom: 'nextShift.name', load: false },
  { header: 'Location ID', dumpFrom: 'locationId' },
  { header: 'Location Name', dumpFrom: 'location.name', load: false },
])
