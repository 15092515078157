import { get } from 'lodash'

export const getCurrentRevision = (state: any) =>
  get(state, 'save.currentRevision')
export const documentSaving = (state: any) => get(state, 'save.saving', false)
export const documentSaveStopped = (state: any) =>
  get(state, 'save.saveStopped', false)

export const documentModified = (state: any, documentRoot: string) => {
  const currentRevision = getCurrentRevision(state)
  const document = get(state, documentRoot)
  return Boolean(document && document.data !== currentRevision)
}
