import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  cargoTypeId: string
  locationId: string
  loadingRatePerHour: number
  unloadingRatePerHour: number
}

interface Rels {
  location: Location
  cargoType: CargoType
}

class LocationCargoCompatibility extends Mapper {
  /**
   * A LocationCargoCompatibility describes a Location's ability to load and
   * unload a CargoType.
   *
   * Related models:
   * - `CargoType`;
   * - `Location`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} cargoTypeId - The id of the CargoType
   * @param {string} locationId - The id of the Location
   * @param {number} loadingRatePerHour - The number of cargoTyoe.volumeUnit
   *  that can be loaded per hour.
   * @param {number} unloadingRatePerHour - The number of
   *  cargoType.volumeUnit that can be unloaded per hour.
   */
  constructor({
    id,
    cargoTypeId,
    locationId,
    loadingRatePerHour,
    unloadingRatePerHour,
  }: Attrs) {
    super()
    this.id = id
    this.cargoTypeId = cargoTypeId
    this.locationId = locationId
    this.loadingRatePerHour = loadingRatePerHour
    this.unloadingRatePerHour = unloadingRatePerHour
  }

  setRels({ location, cargoType }: Rels) {
    this.location = location
    this.cargoType = cargoType
  }
}

interface LocationCargoCompatibility extends Attrs, Rels {}
export { LocationCargoCompatibility }
