export function zip<A, B>(rows: [A[], B[]]): [A, B][]
export function zip<A, B, C>(rows: [A[], B[], C[]]): [A, B, C][]
export function zip<A, B, C, D>(rows: [A[], B[], C[], D[]]): [A, B, C, D][]
export function zip<A, B, C, D, E>(
  rows: [A[], B[], C[], D[], E[]],
): [A, B, C, D, E][]
export function zip(rows: any[][]) {
  return rows[0].map((_, c) => rows.map(row => row[c]))
}

type ComparatorFn<T extends string | number> = (a: T, b: T) => number
const defaultComparator = <Val>(a: Val, b: Val) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  }

  throw new Error(
    `Programmer Error: Not able to compare ${typeof a} and ${typeof b}`,
  )
}

/**
 * Takes an array and a compartor function and sorts an array
 * @param   {array} arr Array to be sorted
 * @param   {getter} getter Comparison value getter function
 * @param   {function} comparator Comparison function
 *
 * @returns {array} a new sorted array
 */
export const sortBy = <T, Val extends string | number>(
  arr: T[],
  getter: (arg0: T) => Val,
  comparator: ComparatorFn<Val> = defaultComparator,
): T[] => [...arr].sort((a, b) => comparator(getter(a), getter(b)))

export const sortByName = <T extends { name: string }>(arr: T[]) =>
  sortBy(arr, ({ name }) => name)
