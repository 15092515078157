import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import { Service } from 'src/service-design/shared/models/service'
import { ServiceEvent } from 'src/service-design/shared/models/service-event'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import * as tasks from 'src/service-design/shared/models/task'
import { EpochTime } from 'src/service-design/shared/utils/dates'

export class TrainAssignment {
  service: Service
  attach: ServiceEvent
  detach: ServiceEvent
  assignmentNum: number
  _offsetLegs: OffsetLeg[]
  _legs: StartLeg[]
  _activities: tasks.Task[]

  /**
   * A TrainAssignment describes portion of a Services journey to it's
   * destination on a single TrainStart. eg A Service that is routed to its
   * desination using two TrainStarts will have to TrainAssignments.
   *
   * TODO: Probably a better name here would be ServiceAssignment (or
   * DemandAssignment).
   *
   * @param {Service} service: The service the assignment refers to
   * @param {ServiceEvent} attach: The attach event
   * @param {ServiceEvent} detach: The detach event
   * @param {number} assignmentNum: The sequence number of the TrainAssignment
   *
   */
  constructor({
    service,
    attach,
    detach,
    assignmentNum,
  }: {
    service: Service
    attach: ServiceEvent
    detach: ServiceEvent
    assignmentNum: number
  }) {
    this.service = service
    this.attach = attach
    this.detach = detach
    this.assignmentNum = assignmentNum
    this._offsetLegs = null
  }

  get id() {
    return this.attach.id
  }

  get legs() {
    if (!this._legs) {
      let lastLeg = this.attach.startLeg
      this._legs = [lastLeg]
      while (lastLeg !== this.detach.startLeg) {
        lastLeg = lastLeg.next
        this._legs.push(lastLeg)
      }
    }
    return this._legs
  }

  get name() {
    const { origin, destination, assignmentNum } = this
    return `assignment ${assignmentNum} (${origin.code} - ${destination.code})`
  }

  get origin() {
    return this.originLeg.origin
  }

  get destination() {
    return this.destLeg.dest
  }

  get departsLocal(): EpochTime {
    return this.originLeg.departsLocal
  }

  get arrivesLocal(): EpochTime {
    return this.destLeg.arrivesLocal
  }

  get start() {
    return this.originLeg.start
  }

  get originLeg() {
    return this.attach.startLeg
  }

  get destLeg() {
    return this.detach.startLeg
  }

  get offsetLegs() {
    if (!this._offsetLegs) {
      this.service.assignOffsetLegs()
    }
    return this._offsetLegs
  }

  set offsetLegs(legs) {
    this._offsetLegs = legs
  }

  get next() {
    return this.service.assignments[this.assignmentNum]
  }

  get prev() {
    return this.service.assignments[this.assignmentNum - 2]
  }

  get activities() {
    if (!this._activities) {
      const firstLeg = this.offsetLegs[0]
      const lastLeg = this.offsetLegs.slice(-1)[0]
      this._activities = [
        tasks.Attach.build(firstLeg),
        tasks.AttachAdvance.build(firstLeg),
        tasks.PreDeparture.build(firstLeg),
        new tasks.Transport({
          origin: this.origin,
          destination: this.destination,
          startTimeLocal: firstLeg.departsLocal.toSeconds(),
          endTimeLocal: lastLeg.arrivesLocal.toSeconds(),
          offsetLegs: this.offsetLegs,
        }),
        tasks.PostArrival.build(lastLeg as any), // TODO DJH
        tasks.DetachDelay.build(lastLeg),
        tasks.Detach.build(lastLeg as any), // TODO DJH
      ]
        .filter(Boolean)
        .filter(
          t =>
            t.duration ||
            Object.getPrototypeOf(t).constructor === tasks.Transport,
        )
    }
    return this._activities
  }

  get activityStartLocal() {
    return this.activities[0].startTimeLocal
  }

  get activityEndLocal() {
    return this.activities[this.activities.length - 1].endTimeLocal
  }
}
