import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  name: string
}

class AccessGroup extends Mapper {
  /**
   * An AccessGroup defines a set of Corrdiors in the rail network which
   * Lococlasses and Wagons can be granted access to.
   *
   * Each Corridor is tagged with a single AccessGroup resulting
   * in a network partition. Lococlasses and Wagons need to
   * granted access to an AccessGroup via access rights.
   *
   * NOTE: The term AccessGroup (and AccessRight for that matter) are unlikely
   * to mean much to rail operators in the wild. This terminology has been
   * invented by us as a mechanism for restricting the flow of resources around
   * the network. While this is legitimately something a rail operator will
   * want to do they are unlikely to use this terminology.
   *
   * Related models:
   * - `Corridor`;
   * - `LococlassAccessRight`;
   * - `WagonAccessRight`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the access group.
   */
  constructor({ id, name }: Attrs) {
    super()
    this.id = id
    this.name = name
  }
}

interface AccessGroup extends Attrs {}
export { AccessGroup }
