import { Mapper } from 'src/service-design/shared/models/mapper'
import { ResourceAvailability } from 'src/service-design/shared/models/resource-availability'

interface Attrs {
  id: string
  availabilityId: string
  quantity: number
  reason: string
}

interface Rels {
  availability: ResourceAvailability
}

class ResourceUnavailability extends Mapper {
  /**
   * See ResourceAvailability.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} availabilityId - The id of the ResourceAvailability.
   * @param {number} quantity - The number of resource which are unavailable.
   * @param {string} reason - The reason the resource is unavailable.
   **/
  constructor({ id, availabilityId, quantity, reason }: Attrs) {
    super()
    this.id = id
    this.availabilityId = availabilityId
    this.quantity = quantity
    this.reason = reason
  }

  setRels({ availability }: Rels) {
    this.availability = availability
  }

  get resource() {
    return this.availability.resource
  }
}

interface ResourceUnavailability extends Attrs, Rels {}
export { ResourceUnavailability }
