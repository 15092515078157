import { createSelector } from 'reselect'

import { FocusParamSchema } from 'src/service-design/sd-plan/components/focus/FocusProvider'
import * as Location from 'src/service-design/shared/models/location'

// yardId is potentially a comma-separated list of ids
const focusYardId = createSelector(
  Location.selector,
  (_: any, yardId: string) => yardId,
  (yards, yardId) =>
    yardId && {
      yardId: yardId
        .split(',')
        .filter(y => yards.byId(y))
        .join(','),
    },
)

export const graphFocusSchema = new FocusParamSchema('graph')
graphFocusSchema.register('yardId', focusYardId)
