import { createAction, ActionType } from 'typesafe-actions'

import * as constants from 'src/service-design/shared/constants'
import { BlockingError } from 'src/service-design/shared/document/selectors/index'

export const modalShow = createAction(
  constants.MODAL_SHOW,
  (modalName: string, props: any = {}) => ({
    modalName,
    props,
  }),
)()

export const modalDismiss = createAction(constants.MODAL_DISMISS)()

export const modalActions = { modalShow, modalDismiss } as const
export type ModalActionTypes = ActionType<typeof modalActions>

export const blockingErrorModalShow = (errors: BlockingError[]) =>
  modalShow(constants.MODAL_BLOCKING_ERROR, {
    header: 'Document is invalid.',
    messages: errors.map(({ id, message }) => ({ id, message })),
  })

export const ackableErrorModalShow = (message: any) =>
  modalShow(constants.MODAL_ACKABLE_ERROR, {
    header: 'Errors occurred which were automatically fixed',
    message,
  })

export const errorModalShow = ({
  header,
  message,
  messages,
  entity,
}: {
  header: string
  message?: string
  messages?: { id: string; message: string }[]
  entity?: any
}) => modalShow(constants.MODAL_ERROR, { header, message, messages, entity })
