export const BASE_DATE = '2017-01-01T00:00Z'
export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const
export const DAY_LETTERS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'] as const

export const LONG_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const

export const PENALTY_NORMAL_WEEKDAY = 1
export const PENALTY_OUTSIDE_WEEKDAY = 1.18
export const PENALTY_SATURDAY = 1.5
export const PENALTY_SUNDAY = 2

export const DAYS_IN_WEEK = DAYS.length
export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const SECONDS_PER_HOUR = MINUTES_PER_HOUR * SECONDS_PER_MINUTE
export const HOURS_PER_DAY = 24
export const WEEKS_PER_YEAR = 52
export const HOURS_PER_WEEK = HOURS_PER_DAY * DAYS_IN_WEEK
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY
export const SECONDS_PER_DAY = HOURS_PER_DAY * SECONDS_PER_HOUR
export const SECONDS_PER_WEEK = DAYS_IN_WEEK * SECONDS_PER_DAY
export const SECONDS_PER_ROSTER = SECONDS_PER_WEEK

export const ACCORDION_PANES_SAVE = 'ACCORDION_PANES_SAVE'

export const CONFIG_RECEIVE = 'CONFIG_RECEIVE'

export const DOCUMENT_ERRORS_UPDATE = 'DOCUMENT_ERRORS_UPDATE'

export const INSTANCE_ADD = 'INSTANCE_ADD'
export const INSTANCE_EDIT = 'INSTANCE_EDIT'
export const INSTANCE_PATCH = 'INSTANCE_PATCH'
export const BATCH_PATCH = 'BATCH_PATCH'
export const SINGLETON_EDIT = 'SINGLETON_EDIT'
export const INSTANCE_DELETE = 'INSTANCE_DELETE'

export const LINKED_INSTANCE_INSERT = 'LINKED_INSTANCE_INSERT'
export const LINKED_INSTANCE_DELETE = 'LINKED_INSTANCE_DELETE'

export const LOCK_TRAIN = 'Train Lock'
export const LOCK_WORKING_LOCO = 'Working Loco Lock'

export const NOTIFICATION_POST = 'NOTIFICATION_POST'
export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS'

export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_DISMISS = 'MODAL_DISMISS'
// modals
export const MODAL_BLOCKING_ERROR = 'MODAL_BLOCKING_ERROR'
export const MODAL_ACKABLE_ERROR = 'MODAL_ACKABLE_ERROR'
export const MODAL_DOCUMENT_ARCHIVE = 'MODAL_DOCUMENT_ARCHIVE'
export const MODAL_DOCUMENT_COPY = 'MODAL_DOCUMENT_COPY'
export const MODAL_DOCUMENT_RENAME = 'MODAL_DOCUMENT_RENAME'
export const MODAL_DOCUMENT_UNARCHIVE = 'MODAL_DOCUMENT_UNARCHIVE'
export const MODAL_ERROR = 'MODAL_ERROR'

export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED'

export const REVISIONS_RECEIVE = 'REVISIONS_RECEIVE'

export const SAVE_INTERVAL = 5000
export const SAVE_RETRY_ATTEMPTS = 5
export const SAVE_DEFAULT_ERROR = '[ERR:DEFAULT_SAVE]'
export const SAVE_CONNECTION_ERROR = '[ERR:CONNECTION_ERROR]'
export const SAVE_CONNECTION_ERROR_STATUS = 0

export const FAID_CONNECTION_ERROR = '[ERR:CONNECTION_ERROR_FAID]'

export const KEYCODE_ENTER = 13
