import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { StartLeg } from './model'

export interface IStartLegRepo extends IRepo<StartLeg> {
  byStartId(id: string): StartLeg[]
  byTemplateId(id: string): StartLeg[]
  byTemplateLegId(id: string): StartLeg[]
}

export class StartLegRepo implements IStartLegRepo {
  values: StartLeg[]
  idMap: { [key: string]: StartLeg }
  startIdMap: Indexer<StartLeg>
  templateIdMap: Indexer<StartLeg>
  startLegIdMap: Indexer<StartLeg>
  templateLegIdMap: Indexer<StartLeg>

  constructor(values: StartLeg[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.startIdMap = new Indexer(values, x => x.startId)
    this.templateIdMap = new Indexer(values, x => x.templateId)
    this.templateLegIdMap = new Indexer(values, x => x.templateLegId)
  }

  byId(id: string): StartLeg {
    return this.idMap[id]
  }

  byStartId(startId: string): StartLeg[] {
    return this.startIdMap.lookup(startId)
  }

  byTemplateId(templateId: string): StartLeg[] {
    return this.templateIdMap.lookup(templateId)
  }

  byTemplateLegId(templateLegId: string): StartLeg[] {
    return this.templateLegIdMap.lookup(templateLegId)
  }
}
