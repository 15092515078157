import { LinkedListHead } from 'src/service-design/shared/models/linked-list-head/base'

export class RosterHead extends LinkedListHead {
  get crewPool() {
    return this.parent
  }

  get line() {
    return this.head
  }
}
