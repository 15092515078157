import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { ServiceEvent } from './model'

export class ServiceHasInvalidRoute extends InstanceRepair {
  static collectionName = 'serviceevents'

  static type = 'service-design::Demand has invalid route'

  static message = 'service-design::Demand ({{entity.id}}) has invalid route.'

  static check(event: ServiceEvent) {
    return !event.service.validRoute
  }
}

export class OccursAtNonYardLocation extends InstanceRepair {
  static collectionName = 'serviceevents'

  static type = 'service-design::Occurs at a non-yard location'

  static message =
    'service-design::Demand {{entity.service.name}} attach/detach occurs at {{entity.location.code}} is not a yard'

  static check(event: ServiceEvent) {
    return !event.location.yard
  }
}

export const getServiceEventRepairs = getRepairFactory([
  ServiceHasInvalidRoute,
  OccursAtNonYardLocation,
])
