import { createSelector } from 'reselect'

import * as constants from 'src/service-design/shared/constants'
import { Lococlass } from 'src/service-design/shared/models/lococlass/model'
import { ResourceSummary } from 'src/service-design/shared/models/resource-summaries'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import {
  getLocationSummaries,
  getLococlassSummaries,
} from 'src/service-design/shared/resource-summaries'

const toDay = (seconds: number) =>
  constants.DAYS[
    Math.trunc(seconds / constants.SECONDS_PER_DAY) % constants.DAYS.length
  ]

const toTime = (seconds: number, restrictToDay = true) => {
  const component = restrictToDay
    ? seconds % constants.SECONDS_PER_DAY
    : seconds
  const hours = Math.trunc(component / constants.SECONDS_PER_HOUR)
  const minutes = Math.trunc(
    (component % constants.SECONDS_PER_HOUR) / constants.SECONDS_PER_MINUTE,
  )

  const ph = `00${hours}`.slice(-2)
  const pm = `00${minutes}`.slice(-2)
  return `${ph}:${pm}`
}

const toDuration = (departs: number, arrives: number): string => {
  const time =
    (arrives - departs + constants.SECONDS_PER_WEEK) %
    constants.SECONDS_PER_WEEK
  return toTime(time, false)
}

export const toDayTime = (seconds: number) =>
  `${toDay(seconds)} ${toTime(seconds)}`

const getLocoColumns = (
  consistLococlasses: Map<Lococlass, number>,
  hauled = false,
) => {
  const lococlassList: { [key: string]: number } = {}
  for (const [key, value] of consistLococlasses.entries()) {
    const { name } = key
    lococlassList[hauled ? `${name} Bal` : name] = value
  }
  return lococlassList
}

export const getRosterCSVData = createSelector(
  StartLeg.values,
  getLococlassSummaries,
  (startLegs: StartLeg.StartLeg[], summaries: ResourceSummary[]) => {
    const roster = []

    const defaultFields: { [key: string]: number | string } = {
      id: '',
      type: '',
      businessgroup: '',
      'train type': '',
      'min lead locos': '',
      leg: '',
      origin: '',
      destination: '',
      tonnage: '',
      'departs day': '',
      'departs time': '',
      'arrives day': '',
      'arrives time': '',
      duration: '',
    }

    for (const { resource } of summaries) {
      defaultFields[resource.name] = 0
      defaultFields[`${resource.name} Bal`] = 0
    }

    for (const leg of startLegs) {
      const { start } = leg
      const working = getLocoColumns(leg.consist.workingDict)
      const hauled = getLocoColumns(leg.consist.hauledDict, true)
      const out = {
        id: start.name,
        type: 'scheduled',
        businessgroup: start.businessGroup.name,
        'train type': start.type.name,
        'min lead locos': start.minLeadLocos,
        leg: leg.legNum,
        origin: leg.origin.code,
        destination: leg.dest.code,
        tonnage: leg.tonnage,
        'departs day': toDay(leg.departsLocal.toSeconds()),
        'departs time': toTime(leg.departsLocal.toSeconds()),
        'arrives day': toDay(leg.arrivesLocal.toSeconds()),
        'arrives time': toTime(leg.arrivesLocal.toSeconds()),
        duration: toDuration(
          leg.departsLocal.toSeconds(),
          leg.arrivesLocal.toSeconds(),
        ),
        power: leg.haulageCapacity,
        'can depower a loco': leg.canDepowerALoco,
      }
      roster.push({
        ...defaultFields,
        ...out,
        ...working,
        ...hauled,
      })
    }

    return roster
  },
)

export const getRosterCSVColumns = createSelector(
  getLococlassSummaries,
  summaries => {
    const header = [
      'id',
      'type',
      'businessgroup',
      'train type',
      'min lead locos',
      'leg',
      'origin',
      'destination',
      'tonnage',
      'departs day',
      'departs time',
      'arrives day',
      'arrives time',
      'duration',
      'power',
      'can depower a loco',
    ]

    for (const { resource } of summaries) {
      header.push(resource.name)
      header.push(`${resource.name} Bal`)
    }
    return header
  },
)

export const getHaulageCSVData = createSelector(TrainStart.values, demands => {
  const data = []

  for (const demand of demands) {
    for (const leg of demand.legs) {
      for (const [loco, number] of leg.consist.hauledDict) {
        data.push({
          '# Locos': number,
          Class: loco.name,
          Train: demand.name,
          Origin: leg.origin.name,
          Destination: leg.dest.name,
          'Business Group': demand.businessGroup.name,
          'TT Depart': toDayTime(leg.departsLocal.toSeconds()),
          'TT Arrive': toDayTime(leg.arrivesLocal.toSeconds()),
        })
      }
    }
  }
  return data
})

export const getIdleReportCSVData = createSelector(
  getLocationSummaries,
  summaries => {
    const data = []
    for (const summary of summaries) {
      if (!summary.location.outOfNetwork) {
        for (const [loco, idleSecs] of summary.resourceSecs) {
          data.push({
            Location: summary.location.name,
            'Loco Class': loco.name,
            'Idle Hours': idleSecs / constants.SECONDS_PER_HOUR,
          })
        }
      }
    }
    return data
  },
)
