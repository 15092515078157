import { Changeover } from 'src/service-design/shared/models/changeover/base'
import { DriverAssignment } from 'src/service-design/shared/models/shift-assignment/driver'
import { snapTo, EpochTime } from 'src/service-design/shared/utils/dates'

class ChangeoverAssignment {
  requiresTravel = false
  actingAsDriver = false

  /**
   * A ChangeoverAssignment assigns a Changeover to a
   * DriverAssignment.
   *
   * Related models:
   * - `ChangeoverAssignment`;
   * - `Changeover`;
   * - `DriverAssignment`;
   * - `ShiftAssignment`;
   * - `Shift`.
   *
   * @constructor
   * @param {Changeover} changeover - The Changeover event.
   * @param {number} near - The time at which the Changeover is expected to occur.
   * @param {DriverAssignment} driverAssignment - The DriverAssignment.
   */

  constructor(
    public changeover: Changeover,
    public near: number,
    public driverAssignment: DriverAssignment,
  ) {}

  get id() {
    return this.changeover.id
  }

  get name() {
    return this.changeover.name
  }

  get startLocation() {
    return this.changeover.location
  }

  get endLocation() {
    return this.changeover.location
  }

  /**
   * @deprecated
   */
  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return EpochTime.fromSeconds(
      snapTo(this.changeover.startTimeLocal, this.near),
    )
  }

  /**
   * @deprecated
   */
  get startTimeLocalNormalized(): number {
    return this.startTimeLocalNormalizedVO.toSeconds()
  }

  get startTimeLocalNormalizedVO(): EpochTime {
    return EpochTime.fromSeconds(this.changeover.startTimeLocalNormalized)
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return EpochTime.fromSeconds(
      snapTo(this.changeover.endTimeLocal, this.near),
    )
  }
}
export { ChangeoverAssignment }
