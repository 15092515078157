import { Mapper } from 'src/service-design/shared/models/mapper'
import {
  normalize,
  EpochTime,
  Interval,
  Duration,
} from 'src/service-design/shared/utils/dates'

abstract class TemporalMapper extends Mapper {
  /**
   * A mixin that understand how to normalize times.
   */
  abstract get startTimeLocal(): number
  abstract get endTimeLocal(): number

  get startTimeLocalNormalized() {
    return normalize(this.startTimeLocal)
  }

  get endTimeLocalNormalized() {
    return (
      this.endTimeLocal - this.startTimeLocal + this.startTimeLocalNormalized
    )
  }

  /**
   * @deprecated
   */
  get duration() {
    return this.endTimeLocal - this.startTimeLocal
  }

  get durationVO(): Duration {
    return Interval.fromEpochTimes(
      EpochTime.fromSeconds(this.startTimeLocal), // TODO: Add startTimeLocalVO to the required abstract fields
      EpochTime.fromSeconds(this.endTimeLocal),
    ).duration()
  }
}

export { TemporalMapper }
