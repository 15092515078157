import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'

import i18n from 'src/i18n'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { documentSpec as scenarioSpec } from 'src/service-design/scenario/document'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { documentSpec as planSpec } from 'src/service-design/sd-plan/document'
import * as constants from 'src/service-design/shared/constants'
import { Document } from 'src/service-design/shared/document/types'
import { exportDocumentAction } from 'src/service-design/shared/exporter'
import { modalShow } from 'src/service-design/shared/forms/actions/modals'

import { DocumentActions as DocumentActionsComp } from './component'

interface Item {
  name: string
  currentRevisionId: number
  id: number
  parentId: number
  archived: boolean
  type: string
  modified: boolean
}

interface OwnProps extends RouteComponentProps {
  item?: Item
  document: Document
  modified?: boolean
  trigger: React.ReactNode
  showClose?: boolean
  editDocument?: (data: Item) => void
  renameDocument?: (updated: { name: string }) => void
  copyDocument?: (data: Item) => void
  copyDocumentAndOpen: (
    data: {
      name: string
      copyRevision: number
      documentId: number
      parentId: number
    },
    location: RouteComponentProps['location'],
  ) => void
  copyDocumentTo?: (data: Item) => void
  archiveDocument?: (data: Item) => void
  unarchiveDocument?: (data: Item) => void
  openAuditLog?: (data: Item) => void
  exportDocumentAction?: (data: Item) => void
}

const documentSpecs = {
  scenario: scenarioSpec,
  'service-design': planSpec,
}

const getNormData = (
  item: Item,
  document: Document,
  modified?: boolean,
): Item =>
  item || {
    name: document.meta.name,
    currentRevisionId: document.meta.currentRevisionId,
    id: document.meta.id,
    parentId: document.meta.parentId,
    archived: document.meta.archived,
    type: document.meta.type,
    modified,
  }

const mapStateToProps = (
  state: any,
  { item, document, modified }: OwnProps,
) => {
  const data = getNormData(item, document, modified)
  return {
    data,
  }
}

const mapDispatchToProps = (
  dispatch: any,
  {
    item,
    document,
    modified,
    location,
    renameDocument,
    copyDocument,
    copyDocumentTo,
    copyDocumentAndOpen,
    editDocument,
    archiveDocument,
    unarchiveDocument,
    openAuditLog,
  }: OwnProps,
) => {
  const data = getNormData(item, document, modified)
  return {
    editDocument: () => editDocument(data),
    renameDocument: () =>
      dispatch(
        modalShow(constants.MODAL_DOCUMENT_RENAME, {
          item: data,
          updateFn: renameDocument,
        }),
      ),
    copyDocument: () => copyDocument(data),
    copyDocumentAndOpen: () =>
      copyDocumentAndOpen(
        {
          name: `${data.name} [${i18n.t('Copy')}]`,
          copyRevision: data.currentRevisionId,
          documentId: data.id,
          parentId: data.parentId,
        },
        location,
      ),
    copyDocumentTo: () => copyDocumentTo(data),
    archiveDocument: () => archiveDocument(data),
    unarchiveDocument: () => unarchiveDocument(data),
    openAuditLog: () => openAuditLog(data),
    exportDocumentAction: documentSpecs.hasOwnProperty(data.type)
      ? () => dispatch(exportDocumentAction(data.id))
      : null,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: OwnProps,
) => {
  const { trigger, showClose } = ownProps
  return {
    ...stateProps,
    trigger,
    showClose,
    exportDocumentAction: dispatchProps.exportDocumentAction,
    renameDocument: ownProps.renameDocument
      ? dispatchProps.renameDocument
      : null,
    copyDocument: ownProps.copyDocument ? dispatchProps.copyDocument : null,
    copyDocumentAndOpen: ownProps.copyDocumentAndOpen
      ? dispatchProps.copyDocumentAndOpen
      : null,
    copyDocumentTo: ownProps.copyDocumentTo
      ? dispatchProps.copyDocumentTo
      : null,
    editDocument: ownProps.editDocument ? dispatchProps.editDocument : null,
    archiveDocument: ownProps.archiveDocument
      ? dispatchProps.archiveDocument
      : null,
    unarchiveDocument: ownProps.unarchiveDocument
      ? dispatchProps.unarchiveDocument
      : null,
    openAuditLog: ownProps.openAuditLog ? dispatchProps.openAuditLog : null,
  }
}

export const DocumentActions = withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(DocumentActionsComp),
)
