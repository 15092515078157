import { Mapper } from 'src/service-design/shared/models/mapper'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { LoadTask } from 'src/service-design/shared/models/task'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Duration } from 'src/service-design/shared/utils/dates'

const kindToTasks = {
  loading: 'loadTasks',
  unloading: 'unloadTasks',
} as const

class LoadingWorkSplit extends Mapper {
  id: string
  startId: string
  templateLegId: string
  kind: 'loading' | 'unloading'
  _primaryTaskDuration: Duration
  start: TrainStart
  templateLeg: TemplateLeg
  startLeg: StartLeg

  /**
   * A LoadingWorkSplit represents a break in a LoadWorkTask so that the task
   * can be handed over to another crew member. Without this mechanism it would
   * not be possible to put longing LoadWorkTasks into legal shifts. See
   * LoadWorkTask.
   *
   * Related models:
   * - `LoadWorkTask`;
   * - `LoadTask`;
   * - `TrainStart`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} startId - The id of the TrainStart which owns the LoadTask.
   * @param {string} templateLegId - The id of the TemplateLeg associated with
   *  the LoadTask.
   * @param {string} kind - Should be 'loading' or 'unloading'. Loading occurs
   *  prior to the legs departure, unloading occurs on arrival.
   * @param {number} primaryTaskDuration - The length of the primary work task.
   *
   **/
  constructor({
    id,
    startId,
    templateLegId,
    kind,
    primaryTaskDuration,
  }: {
    id: string
    startId: string
    templateLegId: string
    kind: 'loading' | 'unloading'
    primaryTaskDuration: Duration
  }) {
    super()
    this.id = id
    this.startId = startId
    this.templateLegId = templateLegId
    this.kind = kind
    this._primaryTaskDuration = primaryTaskDuration
  }

  setRels({
    start,
    templateLeg,
    startLeg,
  }: {
    start: TrainStart
    templateLeg: TemplateLeg
    startLeg: StartLeg
  }) {
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
  }

  // DateTime valueObject Adapters VVV

  get primaryTaskDuration(): number {
    return this._primaryTaskDuration.toSeconds()
  }
  // DateTime valueObject Adapters ^^^

  get timeLocal(): number {
    let time: number
    if (this.kind === 'loading') {
      time =
        this.startLeg.attachStartLocalVO.toSeconds() - this.primaryTaskDuration
    } else {
      time =
        this.startLeg.detachEndLocalVO.toSeconds() + this.primaryTaskDuration
    }
    return time + this.startLeg.getTaskTimeOffset(this.kind)
  }

  get tasks(): LoadTask[] {
    return this.startLeg[kindToTasks[this.kind]]
  }
}

export { LoadingWorkSplit }
