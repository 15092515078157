import { push } from 'connected-react-router'
import {
  create as jsonDiffCreate,
  formatters as jsonFormatters,
} from 'jsondiffpatch'
import { get } from 'lodash'
import pako from 'pako'
import { createAction, ActionType } from 'typesafe-actions'

import { Overwrite } from 'utility-types'
import * as uuid from 'uuid'

import { batchActions } from 'src/core/batchMiddleware'

import { IBackendDocument } from 'src/core/types'
import i18n from 'src/i18n'
import api from 'src/service-design/shared/api'
import * as constants from 'src/service-design/shared/constants'
import { documentsReceiveByQuery } from 'src/service-design/shared/document/actions/documents-by-query'
import { getCurrentRevision } from 'src/service-design/shared/document/selectors/save'
import {
  Document,
  BaseDocumentData,
} from 'src/service-design/shared/document/types'
import { modalShow } from 'src/service-design/shared/forms/actions/modals'
import { notificationPost } from 'src/service-design/shared/notifications/actions'
import { retryPoller } from 'src/service-design/shared/utils/poller'

// Diffing on 2 long strings uses a separate text diffing algorithm that outputs a unidiff format.
// Unidiff isn't supported in jsonpatch spec. Need to set min character limit higher, or else the formatter will fail to parse the format.
// (see BOSS-2021)
const jsonDiffPatch = jsonDiffCreate({
  textDiff: {
    minLength: Number.MAX_SAFE_INTEGER,
  },
})

export {
  saveImportedDocument,
  importDocumentFromFile,
  parseWorkbook,
  ImportError,
  FixableError,
} from './import'
export { setupSave } from './save'
export {
  acknowledgeDocumentErrors,
  postDocumentLoadDisplayModalError,
} from './post-document-load'

export const revisionsReceive = (
  revs: { data: any; meta: { type: string } }[],
) => ({
  type: constants.REVISIONS_RECEIVE,
  payload: revs,
})

export const fetchLatest = (id: string | number): Promise<Document> =>
  api
    .get({
      url: `/documents/${id}/latest-revision`,
    })
    .then(response => response.data)

export const documentErrorsUpdate = createAction(
  constants.DOCUMENT_ERRORS_UPDATE,
)<{ id: string; message: string }[]>()
export type DocumentErrorsUpdateActionType = ActionType<
  typeof documentErrorsUpdate
>

export const fetchDocuments = async (
  documentIds: number[],
): Promise<{
  documents: Document[]
  parentDocuments: Record<string, Document>
}> => {
  const firstDocumentId = documentIds[0] // All the documents should have the same parent, so just get the first

  const parentDocumentsInfo: IBackendDocument[] = (
    await api
      .get({ url: `/documents?ancestor_of=${firstDocumentId}` })
      .then(resp => resp.data)
  ).filter((document: IBackendDocument) => document.id !== firstDocumentId)

  const parentDocuments = (
    await Promise.all(parentDocumentsInfo.map(d => fetchLatest(d.id)))
  ).reduce<Record<string, Document>>(
    (acc, document: Document) => ({
      ...acc,
      [document.meta.type]: document,
    }),
    {},
  )

  const documents = await Promise.all(
    documentIds.map(documentId => fetchLatest(documentId)),
  )
  return { documents, parentDocuments }
}

export const documentsLoad = (id: string | number) => (dispatch: any) =>
  api
    .get({
      url: `/documents?ancestor_of=${id}`,
    })
    .then(response =>
      Promise.all([
        ...response.data.map((dep: IBackendDocument) => fetchLatest(dep.id)),
      ]),
    )
    .then(revs => {
      dispatch(revisionsReceive(revs))
    })
    .catch(response =>
      dispatch(
        modalShow(constants.MODAL_ERROR, {
          header: 'There was an error loading the document.',
          message: `${i18n.t('Status Code')} ${response.status}: ${
            response.data
          }`,
        }),
      ),
    )

export interface InstanceAdd<D extends BaseDocumentData> {
  <U extends keyof D>(
    collection: U,
    instance: Overwrite<D[U][0], { id?: string }>,
  ): {
    type: typeof constants.INSTANCE_ADD
    payload: { collection: U; instance: D[U][0] }
  }
}

export const instanceAdd: unknown = (
  collection: any,
  { id, ...instance }: any,
) => ({
  type: constants.INSTANCE_ADD,
  payload: {
    collection,
    instance: { id: id || uuid.v4(), ...instance },
  },
})

export interface InstanceEdit<D extends BaseDocumentData> {
  <U extends keyof D>(collection: U, instance: D[U][0]): {
    type: typeof constants.INSTANCE_EDIT
    payload: { collection: U; instance: D[U][0] }
  }
}

export const instanceEdit: unknown = (collection: any, instance: any) => ({
  type: constants.INSTANCE_EDIT,
  payload: {
    collection,
    instance,
  },
})

export interface SingletonEdit<S extends Record<string, {}>> {
  <U extends keyof S>(singleton: U, instance: S[U]): {
    type: typeof constants.SINGLETON_EDIT
    payload: { singleton: U; instance: S[U] }
  }
}

export const singletonEdit: unknown = (singleton: any, instance: any) => ({
  type: constants.SINGLETON_EDIT,
  payload: {
    singleton,
    instance,
  },
})

export interface InstanceSubmit<D extends BaseDocumentData> {
  <U extends keyof D>(
    collection: U,
    instance: Overwrite<D[U][0], { id?: string }>,
  ): {
    type: typeof constants.INSTANCE_ADD | typeof constants.INSTANCE_EDIT
    payload: {
      collection: U
      instance: D[U][0]
    }
  }
}

/**
 * TODO: the internal implementation here isn't safely typed, it is just cast
 * to make the compiler happy. Probably need to come back to this and fix it at
 * a later stage
 **/
export const instanceSubmit: unknown = (collection: any, instance: any) => {
  const submit = (instance.id ? instanceEdit : instanceAdd) as (
    collection: any,
    instance: any,
  ) => any
  return submit(collection, instance)
}

export type UnsafeInstanceDelete = (collection: any, filterObj: any) => any

export interface InstanceDelete<D extends BaseDocumentData> {
  <U extends keyof D>(collection: U, filterObj: Partial<D[U][0]> | string): {
    type: typeof constants.INSTANCE_DELETE
    payload: {
      collection: U
      filter: Partial<D[U][0]>
    }
  }
}

export const instanceDelete: unknown = (collection: any, filterObj: any) => {
  const filter = filterObj instanceof Object ? filterObj : { id: filterObj }
  return {
    type: constants.INSTANCE_DELETE,
    payload: {
      collection,
      filter,
    },
  }
}

export const documentPost = (
  name: string,
  type: string,
  data: any,
  parentId: number | null,
  version: string,
) =>
  api.post({
    url: '/documents',
    json: {
      name,
      type,
      data: pako.deflate(JSON.stringify(data), { to: 'string' }),
      parent_id: parentId,
      version,
    },
  })

export const revisionSaveStarted = createAction('REVISION_SAVE_STARTED')()
export const revisionSaving = createAction('REVISION_SAVING')()
export const revisionSaveFailed = createAction('REVISION_SAVE_FAILED')()
export const revisionSaveStopped = createAction('REVISION_SAVE_STOPPED')()
export const documentUpdated = createAction('DOCUMENT_UPDATED')<
  IBackendDocument
>()
export const revisionSaved = createAction('REVISION_SAVED')<{}>()

export const saveActions = {
  revisionSaveStarted,
  revisionSaving,
  revisionSaveFailed,
  revisionSaveStopped,
  documentUpdated,
  revisionSaved,
} as const
export type SaveActionType = ActionType<typeof saveActions>

export const documentSave = (documentRoot: string) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState()
  const document = get(state, documentRoot)
  const currentRevision = getCurrentRevision(state)
  if (!currentRevision) {
    throw Error("Must call 'revisionSaved' before calling 'documentSave")
  }

  if (currentRevision === document.data) {
    return Promise.resolve()
  }

  dispatch(revisionSaving())

  const delta = jsonDiffPatch.diff(currentRevision, document.data)
  // hax because jsondiffpatch has awful type support
  const patch = (jsonFormatters as any).jsonpatch.format(delta)
  let response
  try {
    response = await api.post({
      url: '/revisions',
      json: {
        prev_revision_id: document.id,
        version: document.version,
        patch: pako.deflate(JSON.stringify(patch), { to: 'string' }),
      },
    })
  } catch (err) {
    dispatch(revisionSaveFailed())
    throw err
  }
  dispatch(revisionSaved(document.data))
  return dispatch(documentUpdated(response.data.meta))
}

export const retryFailed = (message: string) =>
  batchActions([
    revisionSaveStopped(),
    notificationPost(
      'There was an error saving the document.',
      message,
      'error',
    ),
  ])

interface PollContext {
  dispatch: any
  documentRoot: string
  resetCount: () => void
  incrementCount: () => number
}

export const savePoll = async ({
  dispatch,
  documentRoot,
  resetCount,
}: PollContext) => {
  await dispatch(documentSave(documentRoot))
  resetCount()
  return false
}

export const saveFailureCheckerFactory = ({
  dispatch,
  incrementCount,
}: PollContext) => ({
  status,
  data: { message },
}: {
  status: number
  data: { message: string }
}) => {
  const quit =
    incrementCount() >= constants.SAVE_RETRY_ATTEMPTS || status === 409

  if (quit) {
    let errorCode
    if (status === 409 || status === constants.SAVE_CONNECTION_ERROR_STATUS) {
      const matches = message.match(/\[ERR:\w+\]/)
      errorCode = matches && matches[0]
    }

    if (!errorCode) {
      errorCode = constants.SAVE_DEFAULT_ERROR
    }
    dispatch(retryFailed(errorCode))
  }
  return quit
}

export const saveDocument = (documentRoot: string) => (dispatch: any) => {
  dispatch(revisionSaveStarted())

  let failureCount = 0

  const context: PollContext = {
    dispatch,
    documentRoot,
    resetCount: () => {
      failureCount = 0
    },
    incrementCount: () => {
      failureCount += 1
      return failureCount
    },
  }

  retryPoller(
    savePoll,
    saveFailureCheckerFactory,
    constants.SAVE_INTERVAL,
    context,
  )
}

export const documentUpdate = (
  { name = null, archived = null }: { name?: string; archived?: boolean },
  documentRoot: string,
) => (dispatch: any, getState: any) => {
  const document = get(getState(), documentRoot)
  const updated = {
    name: name === null ? document.meta.name : name,
    archived: archived === null ? document.meta.archived : archived,
  }

  return api
    .put({
      url: `/documents/${document.meta.id}`,
      json: updated,
    })
    .then(() => dispatch(documentUpdated({ ...document.meta, ...updated })))
}

export const documentCopy = (
  copyDetails: { name: string; parentId: number | null; copyRevision: number },
  currentLocation: { search: string; pathname: string },
) => (dispatch: any) =>
  api
    .post({
      url: '/documents/copy',
      json: {
        name: copyDetails.name,
        parent_id: copyDetails.parentId,
        copy_revision: copyDetails.copyRevision,
      },
    })
    .then(response => {
      // TODO: this is terrible. how can we do better?
      // ie. Don't use window directly and build the new URL in a
      // more sensible way.
      const split = currentLocation.pathname.split('/')
      for (let i = 0; i < split.length; i += 1) {
        if (split[i].match(/^\d+$/)) {
          split[i] = response.data.id.toString()
          break
        }
      }
      dispatch(
        push({
          pathname: split.join('/'),
          search: currentLocation.search,
        }),
      )
      dispatch(documentUpdated(response.data))
    })

type DocumentPayload = {
  id: number
  type: string
}

export const documentReceive = createAction('DOCUMENT_RECEIVE')<
  IBackendDocument
>()

export const documentRemove = createAction('DOCUMENT_REMOVE')<DocumentPayload>()

export const documentRename = createAction(
  'DOCUMENT_RENAME',
  (item: DocumentPayload, name: string) => ({ ...item, name }),
)()

export const documentUnarchive = createAction('DOCUMENT_UNARCHIVE')<
  DocumentPayload
>()

export const documentActions = {
  documentsReceiveByQuery,
  documentReceive,
  documentRemove,
  documentRename,
  documentUnarchive,
} as const

export type DocumentActionType = ActionType<typeof documentActions>
