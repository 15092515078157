import { Relationships } from 'src/service-design/shared/document/relationships'
import {
  DocumentTypeToTypes,
  Document,
} from 'src/service-design/shared/document/types'
import { INotification } from 'src/service-design/shared/notifications/reducers'

export type BlockingError = {
  id: string
  message: string
}

export const getDocuments = (state: any) => state.documents
export const getDocumentErrors = (state: any): BlockingError[] => state.errors
export const getNotifications = (state: any): INotification[] =>
  state.notifications

export const getDocument = (state: any, type: string) =>
  getDocuments(state)[type]
export const getCollection = <
  T extends keyof DocumentTypeToTypes,
  K extends keyof DocumentTypeToTypes[T]
>(
  state: any,
  documentType: T,
  collectionName: K,
): DocumentTypeToTypes[T][K][] =>
  getDocument(state, documentType).data[collectionName]

export const getDependenciesFactory = <T extends keyof DocumentTypeToTypes>(
  documentType: T,
  relationships: Relationships<DocumentTypeToTypes[T]>,
) => (state: any, collection: string, row: { id: string }) => {
  const arr: {
    allowCascadeDelete: boolean
    collection: keyof DocumentTypeToTypes[T]
    foreignKey: string | string[]
    dependencyIds: { id: string }[]
  }[] = []
  const { collections } = relationships
  collections.forEach(targetCollection => {
    relationships.getRels(targetCollection).forEach(resource => {
      if (resource.collection === collection) {
        const ids = getCollection(state, documentType, targetCollection)
          // @ts-ignore this code is a world away from being type safe
          // refactoring will be required.
          .filter(o => o[resource.foreign] === row.id)
          // @ts-ignore this code is a world away from being type safe
          // refactoring will be required.
          .map(o => ({ id: o.id }))

        arr.push({
          allowCascadeDelete: resource.allowCascadeDelete,
          collection: targetCollection,
          // @ts-ignore this code is a world away from being type safe
          // refactoring will be required.
          foreignKey: resource.foreign,
          dependencyIds: ids,
        })
      }
    })
  })
  return {
    allowDelete:
      arr.filter(d => !d.allowCascadeDelete && d.dependencyIds.length)
        .length === 0,
    dependencies: arr,
  }
}

export const getFinderUrl = (
  document: Document,
  location: { pathname: string; search: Record<string, string> },
) => {
  if (document) {
    const { meta } = document
    if (meta.type === 'service-design') {
      return `/scenario/${meta.parentId}/service-design/${meta.id}`
    }
    return `/scenario/${meta.id}`
  }
  if (location) {
    if (
      ['/service-design/import', '/kcs-importer'].includes(location.pathname)
    ) {
      const search = new URLSearchParams(location.search)
      return `/scenario/${search.get('parentId')}`
    }
  }
  return ''
}
