import {
  SECONDS_PER_HOUR,
  WEEKS_PER_YEAR,
} from 'src/service-design/shared/constants'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { RouteKnowledge } from 'src/service-design/shared/models/route-knowledge'
import { Duration } from 'src/service-design/shared/utils/dates'

class CrewType extends Mapper {
  id: string
  name: string
  locId: string
  _signOnSecs: Duration
  _signOffSecs: Duration
  _minimumShiftSecs: Duration
  _maximumShiftSecs: Duration
  _mealBreakSecs: Duration
  _mealBreakWindowSecs: Duration
  _mealBreakShiftSecs: Duration
  isDriver: boolean
  _dutyPerWeekSecs: Duration
  baseSalary: number
  allowance: number
  onCostPercentage: number
  weeksInDutyCycle: number
  _reliefBufferSecs: Duration
  _minHomeRestSecs: Duration
  _annualLeaveSecs: Duration
  annualLeaveLoading: number
  targetRDOsPerLine: number
  maxDaysBetweenRDOs: number
  maxWeeksBetweenWeekendRDOs: number
  maxFAIDScore: number
  apmBaseSalary: number
  canRemotelyRest: boolean
  _remoteRestMinDurationSecs: Duration
  _remoteRestMealAllowancePeriodSecs: Duration
  remoteRestMealAllowanceAmount: number
  _remoteRestMinimumShiftDurationSecs: Duration
  _remoteRestCostingMinDurationSecs: Duration
  loc: Location
  routeKnowledges: RouteKnowledge[]

  /**
   * A CrewType represents a type of crew who operate the railway. Crew are
   * either Rail Operators or Drivers.
   *
   * Drivers are qualified to operate locomotives. For safety reasons, loco
   * movements within yards supervised by a Rail Operator, a crew member, who
   * stays outside the locomotive.
   *
   * Beyond driver qualifications, drivers also need to earn qualifications
   * to operate over Corridor. This is configured via RouteKnowledge.
   *
   * It is worth knowing much of the data encoded within a CrewType is
   * driven by TasRail's Enterprise bargaining agreement. The EBA dictates
   * what valid shifts look like and how much crew should be paid for their
   * labour.
   *
   * There will be a strong similarity between TasRail's EBA and Pacific
   * National's but they're unlikely to be completely aligned. Any changes to
   * the configuration of crew types should take this into consideration.
   *
   * Related models:
   * - `CrewPool`;
   * - `Shift`;
   * - `RDO`;
   * - `RosterLine`;
   * - `RouteKnowledge`;
   * - `Location`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name for the crew type.
   * @param {string} isDriver - Whether this crew type represents Drivers or
   *  Rail Operators.
   * @param {string} locId - The id for the crew types depot location.
   * @param {number} signOnSecs - The time (s) to allow for sign on in a shift
   *  for this crew type.
   * @param {number} signOffSecs - The time (s) to allow for sign off in a shift
   *  for this crew type.
   * @param {number} minimumShiftSecs - The shortest shift (s) allowable for
   *  for this crew type.
   * @param {number} maximumShiftSecs - The longest shift (s) allowable for
   *  for this crew type.
   * @param {number} mealBreakSecs - The amount of time (s) required for a
   *  mealbreak (where required see `mealBreakShiftSecs`) for this crew type.
   * @param {number} mealBreakShiftSecs - The length a shift must be duration
   * (s) before a Meal break is required within this crew types shifts.
   * @param {number} dutyPerWeekSecs - The amount of duty (s) required for this
   *  crew type per week.
   * @param {number} baseSalary - The base annual salary ($) for this crew type.
   * @param {number} onCostPercentage - The on costs (%) for this crew type.
   * @param {number} weeksInDutyCycle - The number of weeks within this crew
   *  types duty cycle.
   * @param {number} reliefBufferSecs - The minimum amount of rest (s) required
   *  after a relief line (see RosterLine).
   * @param {number} minHomeRestSecs - In mimimum amount of rest (s) required
   *  between shifts.
   * @param {number} annualLeaveSecs - The amount of annual leave (s) required
   *  TODO: is this the amount of annual leave accured weekly or annually?
   * @param {number} targetRDOsPerLine - The targeted number of RDOs per roster
   *  line.
   * @param {number} maxDaysBetweenRDOs - The maximum number of days between
   *  RDOs.
   * @param {number} maxWeeksBetweenWeekendRDOs - The maximum number of weeks
   *  between weekend RDOs.
   * @param {number} maxFAIDScore - Maximum allowable fatigue score a crew of
   *  this type can obtain (as computed by Interdynamics FAID lib)
   * @param {number} apmBaseSalary - The Aggregiate Penalty Multiplier (APM)
   *  base salary. See EBA, used to infer the APM.
   * @param {boolean} canRemotelyRest - Allowed to be used in a rest location
   *  without incurring a warning.
   * @param {number} remoteRestMinDurationSecs - The minimum duration for a remote
   * rest on this crew type.
   * @param {number} remoteRestMealAllowancePeriodSecs - The period of time during a
   *  remote rest after which a meal allowance will be paid.
   * @param {number} remoteRestMealAllowanceAmount - The amount to pay for a remote
   *  rest meal allowance.
   * @param {number} remoteRestMinimumShiftDurationSecs - The minimum duration of a
   *  shift which has a remote rest.
   * @param {number} remoteRestCostingMinDurationSecs - The minimum duration a remote
   *  rest must be before crew is paid.
   **/
  constructor({
    id,
    name,
    locId,
    signOnSecs,
    signOffSecs,
    minimumShiftSecs,
    maximumShiftSecs,
    mealBreakSecs,
    mealBreakWindowSecs,
    mealBreakShiftSecs,
    isDriver,
    dutyPerWeekSecs,
    baseSalary,
    allowance,
    onCostPercentage,
    weeksInDutyCycle,
    reliefBufferSecs,
    minHomeRestSecs,
    annualLeaveSecs,
    annualLeaveLoading,
    targetRDOsPerLine,
    maxDaysBetweenRDOs,
    maxWeeksBetweenWeekendRDOs,
    maxFAIDScore,
    apmBaseSalary,
    canRemotelyRest,
    remoteRestMinDurationSecs,
    remoteRestMealAllowancePeriodSecs,
    remoteRestMealAllowanceAmount,
    remoteRestMinimumShiftDurationSecs,
    remoteRestCostingMinDurationSecs,
  }: {
    id: string
    name: string
    locId: string
    signOnSecs: Duration
    signOffSecs: Duration
    minimumShiftSecs: Duration
    maximumShiftSecs: Duration
    mealBreakSecs: Duration
    mealBreakWindowSecs: Duration
    mealBreakShiftSecs: Duration
    isDriver: boolean
    dutyPerWeekSecs: Duration
    baseSalary: number
    allowance: number
    onCostPercentage: number
    weeksInDutyCycle: number
    reliefBufferSecs: Duration
    minHomeRestSecs: Duration
    annualLeaveSecs: Duration
    annualLeaveLoading: number
    targetRDOsPerLine: number
    maxDaysBetweenRDOs: number
    maxWeeksBetweenWeekendRDOs: number
    maxFAIDScore: number
    apmBaseSalary: number
    canRemotelyRest: boolean
    remoteRestMinDurationSecs: Duration
    remoteRestMealAllowancePeriodSecs: Duration
    remoteRestMealAllowanceAmount: number
    remoteRestMinimumShiftDurationSecs: Duration
    remoteRestCostingMinDurationSecs: Duration
  }) {
    super()
    this.id = id
    this.name = name
    this.locId = locId
    this._signOnSecs = signOnSecs
    this._signOffSecs = signOffSecs
    this._minimumShiftSecs = minimumShiftSecs
    this._maximumShiftSecs = maximumShiftSecs
    this._mealBreakSecs = mealBreakSecs
    this._mealBreakWindowSecs = mealBreakWindowSecs
    this._mealBreakShiftSecs = mealBreakShiftSecs
    this.isDriver = isDriver
    this._dutyPerWeekSecs = dutyPerWeekSecs
    this.baseSalary = baseSalary
    this.apmBaseSalary = apmBaseSalary
    this.allowance = allowance
    this.onCostPercentage = onCostPercentage
    this.weeksInDutyCycle = weeksInDutyCycle
    this._reliefBufferSecs = reliefBufferSecs
    this._minHomeRestSecs = minHomeRestSecs
    this._annualLeaveSecs = annualLeaveSecs
    this.annualLeaveLoading = annualLeaveLoading
    this.targetRDOsPerLine = targetRDOsPerLine
    this.maxDaysBetweenRDOs = maxDaysBetweenRDOs
    this.maxWeeksBetweenWeekendRDOs = maxWeeksBetweenWeekendRDOs
    this.maxFAIDScore = maxFAIDScore
    this.canRemotelyRest = canRemotelyRest
    this._remoteRestMinDurationSecs = remoteRestMinDurationSecs
    this._remoteRestMealAllowancePeriodSecs = remoteRestMealAllowancePeriodSecs
    this.remoteRestMealAllowanceAmount = remoteRestMealAllowanceAmount
    this._remoteRestMinimumShiftDurationSecs = remoteRestMinimumShiftDurationSecs
    this._remoteRestCostingMinDurationSecs = remoteRestCostingMinDurationSecs
  }

  setRels({
    loc,
    routeKnowledges = [],
  }: {
    loc: Location
    routeKnowledges: RouteKnowledge[]
  }) {
    this.loc = loc
    this.routeKnowledges = routeKnowledges
  }

  /**
   * @deprecated
   */
  get signOnSecs(): number {
    return this.signOnSecsVO.toSeconds()
  }

  get signOnSecsVO(): Duration {
    return this._signOnSecs
  }

  /**
   * @deprecated
   */
  get signOffSecs(): number {
    return this.signOffSecsVO.toSeconds()
  }

  get signOffSecsVO(): Duration {
    return this._signOffSecs
  }

  /**
   * @deprecated
   */
  get minimumShiftSecs(): number {
    return this.minimumShiftSecsVO.toSeconds()
  }

  get minimumShiftSecsVO(): Duration {
    return this._minimumShiftSecs
  }

  /**
   * @deprecated
   */
  get maximumShiftSecs(): number {
    return this.maximumShiftSecsVO.toSeconds()
  }

  get maximumShiftSecsVO(): Duration {
    return this._maximumShiftSecs
  }

  /**
   * @deprecated
   */
  get mealBreakSecs(): number {
    return this.mealBreakSecsVO.toSeconds()
  }

  get mealBreakSecsVO(): Duration {
    return this._mealBreakSecs
  }

  /**
   * @deprecated
   */
  get mealBreakWindowSecs(): number {
    return this.mealBreakWindowSecsVO.toSeconds()
  }

  get mealBreakWindowSecsVO(): Duration {
    return this._mealBreakWindowSecs
  }

  /**
   * @deprecated
   */
  get mealBreakShiftSecs(): number {
    return this.mealBreakShiftSecsVO.toSeconds()
  }

  get mealBreakShiftSecsVO(): Duration {
    return this._mealBreakShiftSecs
  }

  /**
   * @deprecated
   */
  get dutyPerWeekSecs(): number {
    return this.dutyPerWeekSecsVO.toSeconds()
  }

  get dutyPerWeekSecsVO(): Duration {
    return this._dutyPerWeekSecs
  }

  /**
   * @deprecated
   */
  get reliefBufferSecs(): number {
    return this.reliefBufferSecsVO.toSeconds()
  }

  get reliefBufferSecsVO(): Duration {
    return this._reliefBufferSecs
  }

  /**
   * @deprecated
   */
  get minHomeRestSecs(): number {
    return this.minHomeRestSecsVO.toSeconds()
  }

  get minHomeRestSecsVO(): Duration {
    return this._minHomeRestSecs
  }

  /**
   * @deprecated
   */
  get annualLeaveSecs(): number {
    return this.annualLeaveSecsVO.toSeconds()
  }

  get annualLeaveSecsVO(): Duration {
    return this._annualLeaveSecs
  }

  /**
   * @deprecated
   */
  get remoteRestMinDurationSecs(): number {
    return this.remoteRestMinDurationSecsVO.toSeconds()
  }

  get remoteRestMinDurationSecsVO(): Duration {
    return this._remoteRestMinDurationSecs
  }

  /**
   * @deprecated
   */
  get remoteRestMealAllowancePeriodSecs(): number {
    return this.remoteRestMealAllowancePeriodSecsVO.toSeconds()
  }

  get remoteRestMealAllowancePeriodSecsVO(): Duration {
    return this._remoteRestMealAllowancePeriodSecs
  }

  /**
   * @deprecated
   */
  get remoteRestMinimumShiftDurationSecs(): number {
    return this.remoteRestMinimumShiftDurationSecsVO.toSeconds()
  }

  get remoteRestMinimumShiftDurationSecsVO(): Duration {
    return this._remoteRestMinimumShiftDurationSecs
  }

  /**
   * @deprecated
   */
  get remoteRestCostingMinDurationSecs(): number {
    return this.remoteRestCostingMinDurationSecsVO.toSeconds()
  }

  get remoteRestCostingMinDurationSecsVO(): Duration {
    return this._remoteRestCostingMinDurationSecs
  }

  get dutyPerWeekHours(): number {
    return this.dutyPerWeekSecsVO.toSeconds() / SECONDS_PER_HOUR
  }

  get hourlyRate(): number {
    return (
      (this.baseSalary + this.allowance) /
      ((this.dutyPerWeekSecsVO.toSeconds() / SECONDS_PER_HOUR) * WEEKS_PER_YEAR)
    )
  }

  get apmHourlyRate(): number {
    return (
      this.apmBaseSalary /
      ((this.dutyPerWeekSecsVO.toSeconds() / SECONDS_PER_HOUR) * WEEKS_PER_YEAR)
    )
  }

  get onCostMultiplier() {
    return 1 + this.onCostPercentage / 100
  }

  get nominalDutyCycleHours() {
    return this.dutyPerWeekHours * this.weeksInDutyCycle
  }
}

export { CrewType }
