import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { WagonAllocation } from './model'

export interface IWagonAllocationRepo extends IRepo<WagonAllocation> {
  byWagonsetId(id: string): WagonAllocation[]
  byWagonId(id: string): WagonAllocation[]
}

export class WagonAllocationRepo implements IWagonAllocationRepo {
  values: WagonAllocation[]
  idMap: { [key: string]: WagonAllocation }
  wagonIdMap: Indexer<WagonAllocation>
  wagonsetIdMap: Indexer<WagonAllocation>

  constructor(values: WagonAllocation[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.wagonIdMap = new Indexer(values, x => x.wagonId)
    this.wagonsetIdMap = new Indexer(values, x => x.wagonsetId)
  }

  byId(id: string): WagonAllocation {
    return this.idMap[id]
  }

  byWagonId(id: string): WagonAllocation[] {
    return this.wagonIdMap.lookup(id)
  }

  byWagonsetId(id: string): WagonAllocation[] {
    return this.wagonsetIdMap.lookup(id)
  }
}
