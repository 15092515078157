import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { TrainBlock } from './model'

export const trainBlockFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.trainblocks.map(data => new TrainBlock(data)),
  setRelsFromSpec((l: TrainBlock, state: any) => ({
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(l.templateLegId),
    assignments: registry
      .provide('yardBlockAssignmentRepo')(state)
      .byTrainBlockId(l.id),
  })),
)
