import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { wagonSetFactory } from './factory'
import { IWagonSetRepo, WagonSetRepo } from './repo'

const wagonSetRepo = defaultMemoize(
  (input: FactoryInput) => new WagonSetRepo(wagonSetFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  wagonSetRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type WagonSetRepoReg = {
  wagonSetRepo: FromDocumentFactory<FactoryInput, IWagonSetRepo>
}

export function setup<R extends WagonSetRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('wagonSetRepo', wagonSetRepo)

  relationships.addConstraints('wagonsets', {
    unique: [['serviceId']],
    rels: [
      {
        collection: 'services',
        foreign: 'serviceId',
        name: 'service',
      },
    ],
  })
}

export { WagonSet } from './model'
