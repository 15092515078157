import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { Service } from './model'

export class OccursAtNonYardLocation extends InstanceRepair {
  static collectionName = 'services'

  static type = 'service-design::Occurs at a non-yard location'

  static message =
    'service-design::Demand {{entity.name}} originates/terminates at a location which is not a yard'

  static check(service: Service) {
    return !service.origin.yard || !service.destination.yard
  }
}

export const getServiceRepairs = getRepairFactory([OccursAtNonYardLocation])
