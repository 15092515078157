import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { Shift } from './model'

export interface IShiftRepo extends IRepo<Shift> {
  byPoolId(id: string): Shift[]
}

export class ShiftRepo implements IShiftRepo {
  values: Shift[]
  idMap: { [key: string]: Shift }
  poolIdMap: Indexer<Shift>

  constructor(values: Shift[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.poolIdMap = new Indexer(values, x => x.poolId)
  }

  byId(id: string): Shift {
    return this.idMap[id]
  }

  byPoolId(shiftId: string): Shift[] {
    return this.poolIdMap.lookup(shiftId)
  }
}
