import { Mapper } from 'src/service-design/shared/models/mapper'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainBlock } from 'src/service-design/shared/models/train-block'
import { YardBlock } from 'src/service-design/shared/models/yard-block'

type Attrs = {
  id: string
  yardBlockId: string
  trainBlockId: string
  departingLegId: string
}

type Rels = {
  yardBlock: YardBlock
  trainBlock: TrainBlock
  departingLeg: TemplateLeg
}

class YardBlockAssignment extends Mapper {
  /**
   * A YardBlockAssignment assigns a YardBlock to a TrainBlock
   * on a DepartingLeg. See TrainBlock, YardBlock.
   *
   * Related models:
   * - YardBlock
   * - TrainBlock
   * - TemplateLeg
   *
   * @param {string} yardBlockId - id of the YardBlock
   * @param {string} trainBlockId - id of the TrainBlock
   * @param {string} departingLegId - id of the departing TemplateLeg
   */
  constructor({ id, yardBlockId, trainBlockId, departingLegId }: Attrs) {
    super()
    this.id = id
    this.yardBlockId = yardBlockId
    this.trainBlockId = trainBlockId
    this.departingLegId = departingLegId
  }

  setRels({ yardBlock, trainBlock, departingLeg }: Rels) {
    this.yardBlock = yardBlock
    this.trainBlock = trainBlock
    this.departingLeg = departingLeg
  }
}

interface YardBlockAssignment extends Attrs, Rels {}

export { YardBlockAssignment }
