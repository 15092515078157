import { FactoryInput } from 'src/service-design/shared/document-factory'
import { createFactoryFromDocument } from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { TrainType } from './model'

export const trainTypeFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.traintypes.map(
      ({ headwaySecs, clearanceSecs, ...data }) =>
        new TrainType({
          headwaySecs: Duration.fromSeconds(headwaySecs),
          clearanceSecs: Duration.fromSeconds(clearanceSecs),
          ...data,
        }),
    ),
)
