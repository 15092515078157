import * as constants from 'src/service-design/shared/constants'
import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import { ServiceEvent } from 'src/service-design/shared/models/service-event'
import { ServiceLock } from 'src/service-design/shared/models/service-lock'
import { Task } from 'src/service-design/shared/models/task'
import { TrainAssignment } from 'src/service-design/shared/models/train-assignment'
import { Wagon } from 'src/service-design/shared/models/wagon'
import { WagonCargoCompatibility } from 'src/service-design/shared/models/wagon-cargo-compatibility'
import { WagonSet } from 'src/service-design/shared/models/wagon-set'
import {
  toUtc,
  roundToMinute,
  snapTo,
  CyclicTime,
} from 'src/service-design/shared/utils/dates'

class InvalidRoute extends Error {}

class Service extends Mapper {
  entityName: string
  id: string
  name: string
  customer: string
  originId: string
  destinationId: string
  _deliveryCutOffLocal: CyclicTime
  _dueLocal: CyclicTime
  cargoTypeId: string
  volume: number
  tonnagePerUnit: number
  requiresLoadingDriver: boolean
  requiresLoadingRO: boolean
  requiresUnloadingDriver: boolean
  requiresUnloadingRO: boolean
  comment: string

  _assignments: TrainAssignment[]
  _activities: Task[]

  origin: Location
  destination: Location
  cargoType: CargoType
  events: ServiceEvent[]
  _wagonset: WagonSet | null
  serviceLock: ServiceLock | null

  static defaults = {
    comment: '',
    tonnagePerUnit: null as number,
    requiresLoadingDriver: false,
    requiresLoadingRO: false,
    requiresUnloadingDriver: false,
    requiresUnloadingRO: false,
  }

  /**
   * A Service represent is a demand for a customer to have freight moved
   * between two places within the rail operators network within a specified
   * time window.
   *
   * TERMINOLOGY: For historical reason what is called a Service in code is
   * called a Demand in the UI. Ideally we would come through a clean this up.
   *
   * @param {string} id: The entity id
   * @param {string} name: The name of the Service
   * @param {string} customer: The name of the customer who ordered the Service
   * @param {string} originId: The Location id of the Service's origin
   * @param {string} destinationId: The Location id of the Service's destination
   * @param {number} deliveryCutOffLocal: The earliest time at which loading of
   *   a demand can end at the origin. ie latest time of 'last box on'.
   * @param {number} dueLocal: The latest time at which unloading can begin at
   *   the destination. ie earlier time of 'first box off'.
   * @param {string} cargoTypeId: The id of the CargoType.
   * @param {number} volume: The unit here will depend on the CargoType.
   * @param {number|null} [tonnagePerUnit]: null iff CargoType measures volume
   *   in tonnes
   * @param {boolean} requiresLoadingDriver: Whether a Driver is required for
   *   loading
   * @param {boolean} requiresLoadingRO: Whether a Rail Operator is required for
   *   loading
   * @param {boolean} requiresUnloadingDriver: Whether a Driver is required for
   *   unloading
   * @param {boolean} requiresUnloadingRO: Whether a Rail Operator is required
   *   for unloading
   * @param {string} comment:
   */

  constructor({
    id,
    name,
    customer,
    originId,
    destinationId,
    deliveryCutOffLocal,
    dueLocal,
    cargoTypeId,
    volume,
    tonnagePerUnit,
    requiresLoadingDriver,
    requiresLoadingRO,
    requiresUnloadingDriver,
    requiresUnloadingRO,
    comment,
  }: {
    id: string
    name: string
    customer: string
    originId: string
    destinationId: string
    deliveryCutOffLocal: CyclicTime
    dueLocal: CyclicTime
    cargoTypeId: string
    volume: number
    tonnagePerUnit: number
    requiresLoadingDriver: boolean
    requiresLoadingRO: boolean
    requiresUnloadingDriver: boolean
    requiresUnloadingRO: boolean
    comment: string
  }) {
    super()
    this.entityName = 'Service'
    this.id = id
    this.name = name
    this.customer = customer
    this.originId = originId
    this.destinationId = destinationId
    this._deliveryCutOffLocal = deliveryCutOffLocal
    this._dueLocal = dueLocal
    this.cargoTypeId = cargoTypeId
    this.volume = volume
    this.tonnagePerUnit = tonnagePerUnit
    this.requiresLoadingDriver = requiresLoadingDriver
    this.requiresLoadingRO = requiresLoadingRO
    this.requiresUnloadingDriver = requiresUnloadingDriver
    this.requiresUnloadingRO = requiresUnloadingRO
    this.comment = comment
  }

  setRels({
    origin,
    destination,
    cargoType,
    events = [],
    wagonset = null,
    serviceLock,
  }: {
    origin: Location
    destination: Location
    cargoType: CargoType
    events: ServiceEvent[]
    wagonset: WagonSet | null
    serviceLock: ServiceLock | null
  }) {
    this.origin = origin
    this.cargoType = cargoType
    this.destination = destination
    this.events = events
    this.serviceLock = serviceLock
    this._wagonset = wagonset
  }

  get wagonset(): WagonSet {
    if (!this._wagonset) {
      this._wagonset = WagonSet.construct({
        serviceId: this.id,
        service: this,
        lock: false,
        allocations: [],
      })
    }
    return this._wagonset
  }

  get wagoncompatibilities(): WagonCargoCompatibility[] {
    return this.cargoType.wagoncompatibilities
  }

  // DateTime valueObject Adapters VVV
  get deliveryCutOffLocal(): number {
    return this._deliveryCutOffLocal.toSeconds()
  }

  // DateTime valueObject Adapters ^^^

  get deliveryCutOffUtc(): number {
    return toUtc(this.deliveryCutOffLocal, this.origin.timezone.offset)
  }

  get dueLocal(): number {
    const dueUtc = toUtc(
      this.dueLocalNormalized,
      this.destination.timezone.offset,
    )
    return this.deliveryCutOffUtc < dueUtc
      ? this.dueLocalNormalized
      : this.dueLocalNormalized + constants.SECONDS_PER_WEEK
  }

  get dueLocalNormalized(): number {
    return this._dueLocal.toSeconds()
  }

  get terminatingLocation(): Location {
    return this.activities.length
      ? this.activities.slice(-1)[0].destination
      : this.origin
  }

  get terminatingLocal(): number {
    return this.activities.length
      ? this.activities.slice(-1)[0].endTimeLocal
      : this.deliveryCutOffLocal
  }

  get departsLocal(): number | null {
    const firstActivity = this.activities[0]
    return firstActivity ? firstActivity.startTimeLocal : null
  }

  get arrivesLocal(): number | null {
    const lastActivity = this.activities.slice(-1)[0]
    return lastActivity && lastActivity.destination === this.destination
      ? lastActivity.endTimeLocal
      : null
  }

  get isRouted(): boolean {
    return this.arrivesLocal !== null
  }

  get volumeUnit(): string {
    return this.cargoType.volumeUnit
  }

  get usesTonnage(): boolean {
    return this.cargoType.usesTonnage
  }

  get tonnage(): number {
    return this.usesTonnage ? this.volume : this.tonnagePerUnit * this.volume
  }

  get grossTonnage(): number {
    return this.wagonset.tonnage
  }

  get grossLength(): number {
    return this.wagonset.length
  }

  get loadingSecs(): number {
    return this.loadingRatePerHour !== 0
      ? roundToMinute(
          constants.SECONDS_PER_HOUR * (this.volume / this.loadingRatePerHour),
        )
      : 0
  }

  get unloadingSecs(): number {
    return this.unloadingRatePerHour !== 0
      ? roundToMinute(
          constants.SECONDS_PER_HOUR *
            (this.volume / this.unloadingRatePerHour),
        )
      : 0
  }

  get loadingRatePerHour(): number {
    const originCargoCompatibility = this.cargoType.compatibilities.find(
      c => c.locationId === this.origin.id,
    )
    return originCargoCompatibility
      ? originCargoCompatibility.loadingRatePerHour
      : 0
  }

  get unloadingRatePerHour(): number {
    const destinationCargoCompatibility = this.cargoType.compatibilities.find(
      c => c.locationId === this.destination.id,
    )
    return destinationCargoCompatibility
      ? destinationCargoCompatibility.unloadingRatePerHour
      : 0
  }

  get assignments(): TrainAssignment[] {
    if (!this._assignments) {
      try {
        this._assignments = this.buildAssignments()
      } catch (e) {
        if (e instanceof InvalidRoute) {
          // Note: we catch this because postDocumentLoad relies on this thing
          // not exploding as it is used indirectly through numerous checks.
          //
          // Note the there is an explicit postDocumentLoad check
          // ServiceHasInvalidRoute which calls validRoute which ensures that
          // InvalidRoutes aren't completely ignored.
          this._assignments = []
        }
      }
    }
    return this._assignments
  }

  get validRoute(): boolean {
    try {
      this.buildAssignments()
    } catch (e) {
      if (e instanceof InvalidRoute) {
        return false
      }
      throw e
    }
    return true
  }

  get activities(): Task[] {
    if (!this._activities) {
      this._activities = this.assignments
        .map(a => a.activities)
        .reduce<Task[]>((coll, item) => coll.concat(item), [])
    }
    return this._activities
  }

  get shipmentTime(): number {
    return this.isRouted ? this.arrivesLocal - this.departsLocal : 0
  }

  get totalWorkingSecs(): Map<Wagon, number> {
    return new Map(
      this.wagonset.allocations.map(a => [
        a.wagon,
        a.quantity * this.shipmentTime,
      ]),
    )
  }

  buildAssignments(): TrainAssignment[] {
    // Ensure the early legs on trains come first to
    // account for the fact that we might use the same
    // train multiple time (this is a inefficient but valid
    // thing to do)
    let events = [...this.events].sort(
      (a, b) => a.startLeg.legNum - b.startLeg.legNum,
    )

    const assignments = []
    while (events.length) {
      const detachLocations = events
        .filter(e => e.type === 'detach')
        .map(e => e.startLeg.dest)

      const attach = events.find(
        e =>
          e.type === 'attach' && detachLocations.indexOf(e.startLeg.origin) < 0,
      )

      if (!attach) {
        throw new InvalidRoute('Invalid Route')
      }

      const detach = events.find(
        e =>
          e.type === 'detach' && e.startLeg.startId === attach.startLeg.startId,
      )

      if (!detach) {
        throw new InvalidRoute('Invalid Route')
      }

      events = events.filter(e => e !== attach && e !== detach)

      assignments.push(
        new TrainAssignment({
          service: this,
          attach,
          detach,
          assignmentNum: assignments.length + 1,
        }),
      )
    }
    return assignments
  }

  assignOffsetLegs(): void {
    let prev = {
      offset: 0,
      arrivesLocal: this.deliveryCutOffLocal,
    }
    this.assignments.forEach(assignment => {
      const offsetLegs = assignment.legs.map(startLeg => {
        const offset =
          snapTo(startLeg.departsLocal.toSeconds(), prev.arrivesLocal) -
          startLeg.departsLocal.toSeconds()
        const offsetLeg = new OffsetLeg({
          offset,
          startLeg,
        })
        prev = {
          offset: offsetLeg.offset,
          arrivesLocal: offsetLeg.arrivesLocal.toSeconds(),
        }
        return offsetLeg
      })
      // eslint-disable-next-line no-param-reassign
      assignment.offsetLegs = offsetLegs
    })
  }

  get crewRequiredLoading(): boolean {
    return this.requiresLoadingDriver || this.requiresLoadingRO
  }

  get crewRequiredUnloading(): boolean {
    return this.requiresUnloadingDriver || this.requiresUnloadingRO
  }
}

export { Service }
