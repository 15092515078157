import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { TrainChangeover } from './model'

export const trainChangeoverFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.trainchangeovers.map(
      ({ timeOffset, ...data }) =>
        new TrainChangeover({
          timeOffset: Duration.fromSeconds(timeOffset),
          ...data,
        }),
    ),
  setRelsFromSpec((l: TrainChangeover, state: any) => ({
    singletons: state.collectionData.singletons,
    startA: registry.provide('trainStartRepo')(state).byId(l.startAId),
    startLegA: registry
      .provide('startLegRepo')(state)
      .byId(`${l.startAId}:${l.templateLegAId}`),
    templateLegA: registry
      .provide('templateLegRepo')(state)
      .byId(l.templateLegAId),
    startB: registry.provide('trainStartRepo')(state).byId(l.startBId),
    startLegB: registry
      .provide('startLegRepo')(state)
      .byId(`${l.startBId}:${l.templateLegBId}`),
    templateLegB: registry
      .provide('templateLegRepo')(state)
      .byId(l.templateLegBId),
    changeoverLock: registry
      .provide('trainChangeoverLockRepo')(state)
      .byChangeoverId(l.id),
  })),
)
