import { Changeover } from 'src/service-design/shared/models/changeover/base'
import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  changeoverId: string
}

interface Rels<C extends Changeover> {
  changeover: C
}

class ChangeoverLock<C extends Changeover> extends Mapper {
  constructor({ id, changeoverId }: Attrs) {
    super()
    this.id = id
    this.changeoverId = changeoverId
  }

  setRels({ changeover }: Rels<C>) {
    this.changeover = changeover
  }
}

interface ChangeoverLock<C extends Changeover> extends Attrs, Rels<C> {}
export { ChangeoverLock }
