import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainChangeoverLock } from '.'

export interface ITrainChangeoverLockRepo extends IRepo<TrainChangeoverLock> {
  byChangeoverId(changeoverId: string): TrainChangeoverLock
}

export class TrainChangeoverLockRepo implements ITrainChangeoverLockRepo {
  values: TrainChangeoverLock[]
  idMap: { [key: string]: TrainChangeoverLock }
  changeoverIdMap: { [key: string]: TrainChangeoverLock }

  constructor(values: TrainChangeoverLock[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.changeoverIdMap = Object.fromEntries(
      values.map(v => [v.changeoverId, v]),
    )
  }

  byId(id: string): TrainChangeoverLock {
    return this.idMap[id]
  }

  byChangeoverId(changeoverId: string): TrainChangeoverLock {
    return this.changeoverIdMap[changeoverId] || null
  }
}
