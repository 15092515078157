import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LocationChangeoverLock } from '.'

export const locationChangeoverLockFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.locationchangeoverlocks.map(
      data => new LocationChangeoverLock(data),
    ),
  setRelsFromSpec((l: LocationChangeoverLock, state: any) => ({
    changeover: registry
      .provide('locationChangeoverRepo')(state)
      .byId(l.changeoverId),
  })),
)
