import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { serviceEventFactory } from './factory'
import { getServiceEventRepairs } from './repairs'
import { IServiceEventRepo, ServiceEventRepo } from './repo'

const serviceEventRepo = defaultMemoize(
  (input: FactoryInput) => new ServiceEventRepo(serviceEventFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  serviceEventRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type ServiceEventRepoReg = {
  serviceEventRepo: FromDocumentFactory<FactoryInput, IServiceEventRepo>
}

export function setup<R extends ServiceEventRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('serviceEventRepo', serviceEventRepo)

  relationships.addConstraints('serviceevents', {
    unique: [['templateLegId', 'startId', 'serviceId', 'type']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
      {
        collection: 'services',
        foreign: 'serviceId',
        name: 'service',
      },
    ],
  })

  repairs.register(createSelector(values, getServiceEventRepairs))
}

export { ServiceEvent } from './model'
