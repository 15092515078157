export class BrokenRelationship {
  public collectionName: string
  public affectedObj: { id: string }
  public relationDefinition: any

  constructor({
    collectionName,
    affectedObj,
    relationDefinition,
  }: {
    collectionName: string
    affectedObj: { id: string }
    relationDefinition: any
  }) {
    this.collectionName = collectionName
    this.affectedObj = affectedObj
    this.relationDefinition = relationDefinition
  }
}
