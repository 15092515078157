import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { loadingAssignmentFactory } from './factory'
import { getLoadingAssignmentRepairs } from './repairs'
import { ILoadingAssignmentRepo, LoadingAssignmentRepo } from './repo'

const loadingAssignmentRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LoadingAssignmentRepo(loadingAssignmentFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  loadingAssignmentRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type LoadingAssignmentRepoReg = {
  loadingAssignmentRepo: FromDocumentFactory<
    FactoryInput,
    ILoadingAssignmentRepo
  >
}

export function setup<R extends LoadingAssignmentRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('loadingAssignmentRepo', loadingAssignmentRepo)

  relationships.addConstraints('loadingassignments', {
    unique: [
      [
        'shiftId',
        'templateLegId',
        'startId',
        'kind',
        'cargoTypeId',
        'actingAsDriver',
        'index',
      ],
    ],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
      {
        collection: 'shifts',
        foreign: 'shiftId',
        name: 'shift',
      },
      {
        collection: 'cargotypes',
        foreign: 'cargoTypeId',
        name: 'cargoType',
      },
    ],
  })

  repairs.register(createSelector(values, getLoadingAssignmentRepairs))
}

export { LoadingAssignment } from './model'
