import { Mapper } from 'src/service-design/shared/models/mapper'
import { Duration } from 'src/service-design/shared/utils/dates'

class TrainType extends Mapper {
  id: string
  name: string
  _headwaySecs: Duration
  _clearanceSecs: Duration
  maxTonnage: number

  /**
   * A TrainType represents the type of a train. Typical examples might be:
   * - express, a train that moves quickly through the network with a limited
   *   number of stops.
   * - plodder, a train that moves slowly through the network with low priority.
   *
   * Different types of train have different speed profiles resulting in
   * different TransitTimes and LoadTables.
   *
   * Related models:
   * - `LoadTable`;
   * - `TransitTime`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the train type.
   * @param {number} headwaySecs -  Used to trains of this type leave sufficient
   *  headway (s) between themselves and any train they follow. Used for conflict
   *  detection.
   * @param {number} clearanceSecs -  Used to trains of this type have
   *  sufficient clearance (s) between themselves and any train following them.
   *  Used for conflict detection.
   * @param {number} maxTonnage - The maximum allowable tonnage for a train of
   *  this type.
   */
  constructor({
    id,
    name,
    headwaySecs,
    clearanceSecs,
    maxTonnage,
  }: {
    id: string
    name: string
    headwaySecs: Duration
    clearanceSecs: Duration
    maxTonnage: number
  }) {
    super()
    this.id = id
    this.name = name
    this.maxTonnage = maxTonnage
    this._headwaySecs = headwaySecs
    this._clearanceSecs = clearanceSecs
  }

  // DateTime valueObject Adapters VVV

  get headwaySecs(): number {
    return this._headwaySecs.toSeconds()
  }

  get clearanceSecs(): number {
    return this._clearanceSecs.toSeconds()
  }

  // DateTime valueObject Adapters ^^^
}

export { TrainType }
