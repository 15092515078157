/*
Example usage:

 // Generate Foo related warnings
const fooWarning1Selector = (state: any): SimpleWarning => []
const fooWarning2Selector = (state: any): SimpleWarning => []

 // Generate Bar related warnings
const barWarningSelector = (state: any): SimpleWarning => []

// Token which can be used to integrate the WarningRepo
const fooWarning1Token = 'Foo Warning 1!' as WarningTypeToken<'Foo Warning 1!'>
const fooWarning2Token = 'Foo Warning 2!' as WarningTypeToken<'Foo Warning 2!'>
const barWarningToken = 'Bar Warning!' as WarningTypeToken<'Bar Warning!'>

const warningDefinitions = [
  new WarningDefinition(Foo, fooWarning1Token, fooWarning1Selector),
  new WarningDefinition(Foo, fooWarning2Token, fooWarning2Selector),
  new WarningDefinition(Bar, barWarningToken, barWaringSelector),
]

// Generate a selector that will evaluate all the warning definitions
const getWarnings = buildWarningSelector(warningDefinitions)

// Geneate a WarningRepo that can be used to find a filter the Warnings
const warningRepo = new WarningRepo(
  getWarnings(state),
  warningDefinitions,
  [],
)

const foo1Warnings = warningRepo.byType(fooWarning1Token)

 */
import { groupBy, uniq } from 'lodash'
import { createSelector, createStructuredSelector, Selector } from 'reselect'
import { UnionToIntersection } from 'utility-types'
import uuid from 'uuid'

/**
 * A SimpleWarnings are generated by WarningDefinition selectors
 * and later decorated with warning type information to create a Warning.
 *
 * TExtra is used to capture additional information along with the warning.
 */
export type SimpleWarning<TExtra = {}> = {
  entityId: string
  message: string
} & TExtra

export type Warning<TType extends string = string, TExtra = {}> = {
  id: string
  type: TType
} & SimpleWarning<TExtra>

/**
 * A token the can be used to describe and filter warning by type.
 */
export type WarningTypeToken<TType extends string, TExtra = {}> = TType & {
  warning: Warning<TType, TExtra>
}

/**
 * WarningDefintions are used to generate Warnings for
 */
interface Identifiable {
  id: string
}

type Constructable<T = any> = Function & { prototype: T }
type ConstructedInstance<C> = C extends Constructable<infer T> ? T : never

type WarningSelector<TState, TExtra = {}> = Selector<
  TState,
  SimpleWarning<TExtra>[]
>
export class WarningDefinition<
  Cls extends Constructable,
  TState = any,
  TType extends string = string,
  TExtra = {}
> {
  constructor(
    public cls: Cls,
    public type: WarningTypeToken<TType, TExtra>,
    public selector: WarningSelector<TState, TExtra>,
  ) {}
}

export class WarningHierarchy<
  PCls extends Constructable,
  CCls extends Constructable
> {
  constructor(
    public parentCls: PCls,
    public childClass: CCls,
    public map: (
      instance: ConstructedInstance<PCls>,
    ) => ConstructedInstance<CCls>[],
  ) {}
}

type BuiltWarningSelector<
  TWarningDefinitions extends readonly WarningDefinition<any>[]
> = Selector<
  UnionToIntersection<
    {
      [K in keyof TWarningDefinitions]: TWarningDefinitions[K] extends {
        selector: Selector<infer TState, SimpleWarning[]>
      }
        ? TState
        : never
    }[number]
  >,
  Warning<string>[]
>

export function buildWarningSelector<
  TWarningDefinitions extends readonly WarningDefinition<any>[]
>(
  warningDefinitions: TWarningDefinitions,
): BuiltWarningSelector<TWarningDefinitions> {
  const all: Selector<
    any,
    Record<string, Warning<string>[]>
  > = createStructuredSelector(
    Object.fromEntries(
      warningDefinitions.map((w, idx) => [
        // Not multiple warning selectors may have the same 'type'
        // so we use the index here instead of w.type
        idx,
        createSelector(w.selector, results =>
          results.map(result => ({
            id: uuid.v4(),
            type: w.type,
            ...result,
          })),
        ),
      ]),
    ),
  )
  return createSelector(all, w => Object.values(w).flat())
}

export class WarningRepo<
  TWarningDefinitions extends readonly WarningDefinition<any>[],
  TWarningHierarchy extends readonly WarningHierarchy<any, Constructable>[]
> {
  public entityMap: Record<string, Warning<string>[]>

  constructor(
    public values: Warning<string>[],
    public warningDefinitions: TWarningDefinitions,
    public warningHierarchy: TWarningHierarchy,
  ) {
    // TODO: If the warningDefinitions included yup schemas we could
    // validate the values (maybe only in dev mode)

    // TODO: Can we validate definitions against the hierarchy?

    this.entityMap = groupBy(values, v => v.entityId)
  }

  // Get all warnings of a specific type
  byType<TType extends string, TExtra>(
    warningType: WarningTypeToken<TType, TExtra>,
  ): Warning<TType, TExtra>[] {
    return this.values.filter(
      (w): w is Warning<TType, TExtra> => w.type === warningType,
    )
  }

  // Get all the warning associated with a specific entity optionally
  // filter to a specific list of warning types
  byEntity<
    TCls extends Identifiable,
    TTokens extends readonly WarningTypeToken<string, any>[]
  >(
    cls: Constructable<TCls>,
    entity: TCls,
    tokens: TTokens,
  ): TTokens[number]['warning'][]

  byEntity<TCls extends Identifiable>(
    cls: Constructable<TCls>,
    entity: TCls,
  ): Warning<string>[]

  byEntity<
    TCls extends Identifiable,
    TTokens extends readonly WarningTypeToken<string, any>[]
  >(
    cls: Constructable<TCls>,
    entity: TCls,
    tokens?: TTokens,
  ): TTokens[number]['warning'][] {
    const clsDefinitions = this.warningDefinitions.filter(x => x.cls === cls)
    const types = new Set<string>(clsDefinitions.map(x => x.type))

    const entityWarnings = (this.entityMap[entity.id] || []).filter(
      (w): w is TTokens[number]['warning'] =>
        (!tokens || tokens.includes(w.type as any)) && types.has(w.type),
    )

    const children = this.warningHierarchy.filter(x => x.parentCls === cls)
    return [
      ...entityWarnings,
      ...children.flatMap(c =>
        c
          .map(entity)
          .flatMap(child => this.byEntity(c.childClass, child, tokens)),
      ),
    ]
  }

  hasWarnings<TCls extends Identifiable>(
    cls: Constructable<TCls>,
    entity: TCls,
  ): boolean {
    return Boolean(this.byEntity(cls, entity).length)
  }

  typesByEntity<T extends Identifiable>(cls: Constructable<T>): string[] {
    return uniq(this._typesByEntity(cls)).sort()
  }

  _typesByEntity<T extends Identifiable>(cls: Constructable<T>): string[] {
    const children = this.warningHierarchy.filter(x => x.parentCls === cls)
    return [
      ...this.warningDefinitions.filter(w => w.cls === cls).map(w => w.type),
      ...children.flatMap(c => this._typesByEntity(c.childClass)),
    ]
  }

  allTypes(): string[] {
    return uniq(this.warningDefinitions.map(w => w.type)).sort()
  }
}

// HACK: use dependency injection instead
let _factory: (
  warnings: Warning[],
) => WarningRepo<
  readonly WarningDefinition<any>[],
  readonly WarningHierarchy<any, Constructable>[]
>
export function setWarningRepoFactory(
  factory: (
    warnings: Warning[],
  ) => WarningRepo<
    readonly WarningDefinition<any>[],
    readonly WarningHierarchy<any, Constructable>[]
  >,
) {
  _factory = factory
}

export function buildWarningRepo(warnings: Warning[] = []) {
  if (!_factory) {
    throw new Error('setWarningRepoFactory needs to be called first!')
  }
  return _factory(warnings)
}
