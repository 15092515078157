import { AccessGroup } from 'src/service-design/shared/models/access-group'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { ResourceAvailability } from 'src/service-design/shared/models/resource-availability'
import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import { WagonAccessRight } from 'src/service-design/shared/models/wagon-access-right'
import { WagonCargoCompatibility } from 'src/service-design/shared/models/wagon-cargo-compatibility'

interface Attrs {
  id: string
  code: string
  length: number
  tareWeight: number
  bgColor: string
  fixedCost: number
  workingKmCost: number
  coupledSetSize: number
  comment: string
}

interface Rels {
  compatibilities: WagonCargoCompatibility[]
  accessRights: WagonAccessRight[]
  // TODO: Wagons shouldn't have a reference to the resource availability.
  // If anything, this relationship should go that other way.
  availability: ResourceAvailability
}

class Wagon extends Mapper implements IResource {
  entityName: string
  name: string
  _availability: ResourceAvailability

  /**
   * A Wagon represents a class of wagon that can be attached to trains empty
   * or loaded to haul freight.
   *
   * TERMINOLOGY ISSUE / FUN FACT: This should be called WagonClass not Wagon.
   *
   * Related models:
   * - `CargoType`;
   * - `EmptyWagonEvent`;
   * - `WagonSet`;
   * - `WagonAllocation`;
   * - `WagonCargoCompatibility`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} code - The code which we use to refer to this wagon class.
   * @param {number} length - The length of a this
   * @param {number} tareWeight - The empty weight (t) of a wagon of this class.
   * @param {number} fixedCost - The weekly maintance cost ($) associated with
   *  wagons of this class.
   * @param {number} workingKmCost - The cost ($) of "wear and tear" on a wagon
   *  of this class when it moves a km.
   * @param {number} coupledSetSize - Some classes of wagon are required to move
   *  in sets. For example, coupledSetSize=2 implies a wagon class needs to stay
   *  in pairs.
   * @param {string} comment -
   */
  constructor({
    id,
    code,
    bgColor,
    length,
    tareWeight,
    fixedCost,
    workingKmCost,
    coupledSetSize,
    comment,
  }: Attrs) {
    super()
    this.entityName = 'Wagon'
    this.id = id
    this.name = code
    this.bgColor = bgColor
    this.code = code
    this.length = length
    this.tareWeight = tareWeight
    this.fixedCost = fixedCost
    this.workingKmCost = workingKmCost
    this.coupledSetSize = coupledSetSize
    this.comment = comment
  }

  setRels({ compatibilities = [], availability, accessRights = [] }: Rels) {
    this.compatibilities = compatibilities
    this._availability = availability
    this.accessRights = accessRights
  }

  getAccessRight(accessGroup: AccessGroup) {
    return (
      this.accessRights.find(right => right.accessGroup === accessGroup) || null
    )
  }

  hasAccess(accessGroup: AccessGroup) {
    return this.getAccessRight(accessGroup) !== null
  }

  get availability() {
    if (!this._availability) {
      this._availability = new ResourceAvailability({
        id: null,
        lococlassId: null,
        wagonId: this.id,
        wagon: this,
        quantity: 0,
      })
    }
    return this._availability
  }
}

interface Wagon extends Attrs, Rels {}
export { Wagon }
