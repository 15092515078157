import { sumBy } from 'lodash'

import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { ResourceUnavailability } from 'src/service-design/shared/models/resource-unavailability'
import { Wagon } from 'src/service-design/shared/models/wagon'

interface Attrs {
  id: string | null
  lococlassId: string | null
  wagonId: string | null
  quantity: number
}

interface Rels {
  lococlass?: Lococlass
  wagon?: Wagon
  unavailabilities?: ResourceUnavailability[]
}

class ResourceAvailability extends Mapper {
  static defaults = {
    quantity: 0,
    wagonId: null as string,
    lococlassId: null as string,
  }

  /**
   * A ResourceAvailability indicates the total number of Lococlasses or Wagons
   * that are available in the plan.
   *
   * ResourceUnavailabilities are substracted from this total. So if
   * resourceAvailability.quantity = 12 and there are 2 resource unavailable
   * then 10 resources could be used without incurring a warning within the
   * plan.
   *
   * NOTE: Within the application any Lococlasses or Wagons defined in the scenario
   * is available for assignment. This is largely for historical reasons which
   * are longer relevant. A better solution would be that Lococlasses / Wagons
   * only become available for assignment once an availability is added to the
   * plan. This would also reduce the overall complexity of the code.
   *
   * NOTE: Ideally properties lococlassId and wagonId would be consolidated
   * into a single generic foreign key or this class would be broken into two.
   * Having optional foreign keys is far from ideal.
   *
   * Related models:
   * - `Lococlass`;
   * - `ResourceUnavailability`;
   * - `Wagon`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string|null} lococlassId - The id of the Lococlass.
   * @param {string|null} wagonId - The id of the Wagon.
   * @param {number} quantity - The number of the resource available.
   **/
  constructor({ id, lococlassId, wagonId, quantity, ...rels }: Attrs & Rels) {
    super()
    this.id = id
    this.lococlassId = lococlassId
    this.wagonId = wagonId
    this.quantity = quantity
    this.applyDefaults()
    this.setRels(rels)
  }

  setRels({ lococlass, wagon, unavailabilities = [] }: Rels) {
    this.lococlass = lococlass
    this.wagon = wagon
    this.unavailabilities = unavailabilities
  }

  get resource() {
    return this.lococlass ? this.lococlass : this.wagon
  }

  get unavailable(): number {
    return sumBy(this.unavailabilities, x => x.quantity)
  }

  get idType() {
    return this.lococlass ? 'lococlassId' : 'wagonId'
  }

  get resourceType() {
    return this.resource.entityName
  }
}

interface ResourceAvailability extends Attrs, Rels {}
export { ResourceAvailability }
