import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Mapper } from 'src/service-design/shared/models/mapper'

interface Attrs {
  id: string
  name: string
}

interface Rels {
  lococlasses: Lococlass[]
}

class LoadCategory extends Mapper {
  /**
   * LoadCategories classify the pulling power characteristics of Lococlasses.
   *
   * Related models:
   * - LoadTable;
   * - Lococlass.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the LoadCategory.
   **/
  constructor({ id, name }: Attrs) {
    super()
    this.id = id
    this.name = name
  }

  setRels({ lococlasses = [] }: Rels) {
    this.lococlasses = lococlasses
  }
}

interface LoadCategory extends Attrs, Rels {}
export { LoadCategory }
