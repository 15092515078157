import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TrainStart } from './model'

export interface ITrainStartRepo extends IRepo<TrainStart> {
  byTemplateId(id: string): TrainStart[]
}

export class TrainStartRepo implements ITrainStartRepo {
  values: TrainStart[]
  idMap: { [key: string]: TrainStart }
  templateIdMap: Indexer<TrainStart>

  constructor(values: TrainStart[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.templateIdMap = new Indexer(values, x => x.templateId)
  }

  byId(id: string): TrainStart {
    return this.idMap[id]
  }

  byTemplateId(id: string): TrainStart[] {
    return this.templateIdMap.lookup(id)
  }
}
