import { getCollection } from 'src/service-design/sd-plan/selectors/base'

export const getRawWagonSets = (state: any) =>
  getCollection(state, 'service-design', 'wagonsets')

export const getRawEmptyWagonEvents = (state: any) =>
  getCollection(state, 'service-design', 'emptywagonevents')

export const getRawWagonAllocations = (state: any) =>
  getCollection(state, 'service-design', 'wagonallocations')
