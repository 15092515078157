import { intersection } from 'lodash'

import { Cost } from 'src/service-design/shared/costs'
import {
  Changeover,
  LocationChangeover,
  TrainChangeover,
} from 'src/service-design/shared/models/changeover'
import { CompoundShift } from 'src/service-design/shared/models/compound-shift'
import { CrewPool } from 'src/service-design/shared/models/crew-pool'
import { CustomTask } from 'src/service-design/shared/models/custom-task'
import { DriverTask } from 'src/service-design/shared/models/driver-task'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { RemoteRest } from 'src/service-design/shared/models/remote-rest'
import { restDuration } from 'src/service-design/shared/models/remote-rest/model'
import {
  IResource,
  IResourceProfiler,
} from 'src/service-design/shared/models/resource-summaries/types'
import { RosterLine } from 'src/service-design/shared/models/roster-line'
import {
  ChangeoverAssignment,
  DriverAssignment,
  LocalAssignment,
  LoadingAssignment,
  CustomTaskAssignment,
} from 'src/service-design/shared/models/shift-assignment'
import { ShiftLine } from 'src/service-design/shared/models/shift-line'
import { ShiftResourceProfile } from 'src/service-design/shared/models/shift-resource-profile'
import { LVTask, LegTask } from 'src/service-design/shared/models/task'
import { sortBy } from 'src/service-design/shared/utils/arrays'
import {
  offsetString,
  penaltyMultiplier,
  adjustPenaltyForLeave,
  inBoundsUnWrapped,
  CyclicTime,
  EpochTime,
  Duration,
  Interval,
  WEEK_DURATION,
} from 'src/service-design/shared/utils/dates'
import { sumCounts } from 'src/service-design/shared/utils/math'

type ProductiveAssignment =
  | DriverAssignment
  | LocalAssignment
  | CustomTaskAssignment
  | LoadingAssignment

export type AllAssignmentTypes =
  | ProductiveAssignment
  | ChangeoverAssignment
  | LVTask

interface IAssignmentEvent {
  startLocation: Location
  startTimeLocal: number
  endLocation: Location
  endTimeLocal: number
}

class Shift extends Mapper implements IResourceProfiler {
  id: string
  poolId: string
  name: string
  comment: string
  _signOnLocal: CyclicTime
  _signOffLocal: CyclicTime

  pool: CrewPool
  driverAssignments: DriverAssignment[]
  localAssignments: LocalAssignment[]
  customTaskAssignments: CustomTaskAssignment[]
  loadingAssignments: LoadingAssignment[]
  startAtRemoteRest: RemoteRest
  endAtRemoteRest: RemoteRest
  singletons: any // TODO: DJH should use document type here
  shiftLine: ShiftLine
  compoundShift: CompoundShift

  _productiveAssignments: ProductiveAssignment[]
  _assignments: AllAssignmentTypes[]
  _changeovers: any[]
  _taskWeights: any[]
  _nonProductiveCosts: Cost[]
  _productiveCosts: Cost[]

  static defaults = {
    comment: '',
  }

  static sortShiftAssignments<
    T extends { id: string } & { [k in S]: number } & { [k in E]: number },
    S extends string = 'startTimeLocal',
    E extends string = 'endTimeLocal'
  >(
    tasks: T[],
    startTimeAttr: S = 'startTimeLocal' as S,
    endTimeAttr: E = 'endTimeLocal' as E,
  ): T[] {
    return tasks.sort((a, b) => {
      if (a[startTimeAttr] === b[startTimeAttr]) {
        if (a[endTimeAttr] === b[endTimeAttr]) {
          return a.id.localeCompare(b.id)
        }
        return a[endTimeAttr] - b[endTimeAttr]
      }
      return a[startTimeAttr] - b[startTimeAttr]
    })
  }

  static construct(
    data: Partial<
      ConstructorParameters<typeof Shift>[0] & Parameters<Shift['setRels']>[0]
    >,
  ) {
    const shift = new Shift(data as any)
    shift.setRels(data as any)
    return shift
  }

  /**
   * A Shift represents an interval of time, typically of several hours,
   * during which a CrewPool can perform Tasks/Assignments.
   *
   * A Shift is placed in a RosterLine via a ShiftLine. See CrewPool.
   *
   * The signOnLocal and signOffLocal times are number of seconds from
   * the start of the week. The signOffLocal provided to the model is normalized
   * and can be accessed with signOffLocalNormalized. The getter signOffLocal
   * returns the denormalized sign off time.
   *
   * A Shift's parent CrewPool determines whether an Assignment is legal
   * and is checked with isQualified().
   *
   * A Shift computes the costs associated with it including:
   * - Productive costs (from Productive Assignments)
   * - Non-productive costs
   * - LV usage costs
   * - Duty costs
   *
   * Related models:
   * - `Shift`;
   * - `CrewPool`;
   * - `DriverAssignment`;
   * - `LocalAssignment`;
   * - `CustomTaskAssignment`;
   * - `LoadingAssignment`;
   * - `ChangeoverAssignment`;
   * - `ShiftLine`;
   * - `LVTask`;
   * - `ShiftResourceProfile`;
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} poolId - The CrewPool id.
   * @param {string} name - The name of the Shift.
   * @param {string} comment - Any attached comments.
   * @param {number} signOnLocal - The sign on time of the Shift.
   * @param {number} signOffLocal - The sign off time of the Shift.
   **/

  constructor({
    id,
    poolId,
    name,
    comment,
    signOnLocal,
    signOffLocal,
  }: {
    id: string
    poolId: string
    name: string
    comment: string
    signOnLocal: CyclicTime
    signOffLocal: CyclicTime
  }) {
    super()
    this.id = id
    this.poolId = poolId
    this.name = name
    this.comment = comment
    this._signOnLocal = signOnLocal
    this._signOffLocal = signOffLocal
  }

  setRels({
    pool,
    driverAssignments = [],
    localAssignments = [],
    customTaskAssignments = [],
    loadingAssignments = [],
    startAtRemoteRest = null,
    endAtRemoteRest = null,
    singletons,
    shiftLine,
    compoundShift,
  }: {
    pool: CrewPool
    driverAssignments: DriverAssignment[]
    localAssignments: LocalAssignment[]
    customTaskAssignments: CustomTaskAssignment[]
    loadingAssignments: LoadingAssignment[]
    startAtRemoteRest: RemoteRest
    endAtRemoteRest: RemoteRest
    singletons: any // TODO: DJH should use document type here
    shiftLine: ShiftLine
    compoundShift: CompoundShift
  }) {
    this.pool = pool
    this.driverAssignments = driverAssignments
    this.localAssignments = localAssignments
    this.customTaskAssignments = customTaskAssignments
    this.loadingAssignments = loadingAssignments
    this.startAtRemoteRest = startAtRemoteRest
    this.endAtRemoteRest = endAtRemoteRest
    this.singletons = singletons
    this.shiftLine = shiftLine
    this.compoundShift = compoundShift
  }

  /**
   * @deprecated
   */
  get signOnLocal(): number {
    return this._signOnLocal.toSeconds()
  }

  get signOnLocalVO(): EpochTime {
    return EpochTime.fromSeconds(this._signOnLocal.toSeconds())
  }

  get line(): RosterLine {
    return this.shiftLine && this.shiftLine.rosterLine
  }

  get displayName(): string {
    return `${this.pool.name}, ${this.name}`
  }

  get desc() {
    return `${offsetString(this.signOnLocalVO.toSeconds())} – ${offsetString(
      this.signOffLocalVO.toSeconds(),
    )}`
  }

  /**
   * @deprecated
   */
  get maximumShiftDuration(): number {
    return this.maximumShiftDurationVO.toSeconds()
  }

  get maximumShiftDurationVO(): Duration {
    return Duration.fromSeconds(this.pool.type.maximumShiftSecs)
  }

  /**
   * @deprecated
   */
  get signOnStartLocal(): number {
    return this.signOnStartLocalVO.toSeconds()
  }

  get signOnStartLocalVO(): EpochTime {
    return EpochTime.fromSeconds(this._signOnLocal.toSeconds())
  }

  /**
   * @deprecated
   */
  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return this.signOnLocalVO
  }

  /**
   * @deprecated
   */
  get signOnSecs(): number {
    return this.signOnSecsVO.toSeconds()
  }

  get signOnSecsVO(): Duration {
    return this.pool.type.signOnSecsVO
  }

  /**
   * @deprecated
   */
  get signOnEndLocal(): number {
    return this.signOnEndLocalVO.toSeconds()
  }

  get signOnEndLocalVO(): EpochTime {
    return EpochTime.fromSeconds(this._signOnLocal.toSeconds()).makeLater(
      this.signOnSecsVO,
    )
  }

  get latestSignOnVO(): EpochTime {
    let slack: Duration = Duration.nil
    const firstTask = this.assignments?.[0]
    if (firstTask instanceof LVTask) {
      slack = firstTask.slackDurationVO
    } else if (firstTask) {
      slack = slack.add(
        Interval.fromEpochTimes(
          this.signOnEndLocalVO,
          firstTask.startTimeLocalVO,
        ).duration(),
      )
    }

    return this.startTimeLocalVO.makeLater(slack)
  }

  get earliestSignOffVO(): EpochTime {
    // NOTE: It is not enough to simply take
    // the last assignment in the list. Since
    // 'time travelling' is allowed it's possible
    // that one of the assignment in the middle
    // may actually extend further into the future
    // than the last assignment.

    // TODO: endTimeLocal to use VO
    const reversedAssignments = sortBy(this.assignments, x => -x.endTimeLocal)

    let slack: Duration = Duration.nil
    const lastTask = reversedAssignments?.[0]
    if (lastTask instanceof LVTask) {
      slack = lastTask.slackDurationVO
    } else if (lastTask) {
      slack = slack.add(
        Interval.fromEpochTimes(
          lastTask.endTimeLocalVO,
          this.signOffStartLocalVO,
        ).duration(),
      )
    } else {
      // In the case where there is no work in the shift
      // we have elected shrink the shift down to the sign
      // on
      slack = slack.add(
        Interval.fromEpochTimes(
          this.signOnEndLocalVO,
          this.signOffStartLocalVO,
        ).duration(),
      )
    }

    return this.endTimeLocalVO.makeEarlier(slack)
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return this.signOffLocalVO
  }

  /**
   * @deprecated
   */
  get signOffSecs(): number {
    return this.signOffSecsVO.toSeconds()
  }

  get signOffSecsVO(): Duration {
    return this.pool.type.signOffSecsVO
  }

  /**
   * @deprecated
   */
  get signOffStartLocal(): number {
    return this.signOffStartLocalVO.toSeconds()
  }

  get signOffStartLocalVO(): EpochTime {
    return this.signOffLocalVO.makeEarlier(this.signOffSecsVO)
  }

  /**
   * @deprecated
   */
  get signOffEndLocal(): number {
    return this.signOffEndLocalVO.toSeconds()
  }

  get signOffEndLocalVO(): EpochTime {
    return this.signOffLocalVO
  }

  /**
   * @deprecated
   */
  get minHomeRestSecs(): number {
    return this.minHomeRestSecsVO.toSeconds()
  }

  get minHomeRestSecsVO(): Duration {
    return this.pool.type.minHomeRestSecsVO
  }

  /**
   * @deprecated
   */
  get minShiftDuration(): number {
    return this.minShiftDurationVO.toSeconds()
  }

  get minShiftDurationVO(): Duration {
    if (this.startAtRemoteRest || this.endAtRemoteRest) {
      return Duration.longest(
        this.pool.minimumShiftSecsVO,
        this.pool.remoteRestMinimumShiftDurationSecsVO,
      )
    }
    return this.pool.minimumShiftSecsVO
  }

  /**
   * @deprecated
   */
  get signOffLocalWithBuffer(): number {
    return this.signOffLocalWithBufferVO.toSeconds()
  }

  get signOffLocalWithBufferVO(): EpochTime {
    const bufferDuration = Duration.longest(
      this.minShiftDurationVO.subtract(this.durationVO),
      Duration.fromSeconds(0),
    )
    return this.signOffLocalVO.makeLater(bufferDuration)
  }

  /**
   * @deprecated
   */
  get signOffLocal(): number {
    return this.signOffLocalVO.toSeconds()
  }

  get signOffLocalVO(): EpochTime {
    const normalizedSignOffLocal = EpochTime.fromSeconds(
      this.signOffLocalNormalizedVO.toSeconds(),
    )
    if (normalizedSignOffLocal.isEarlier(this.signOnLocalVO)) {
      return normalizedSignOffLocal.makeLater(WEEK_DURATION)
    }
    return EpochTime.fromSeconds(this._signOffLocal.toSeconds())
  }

  /**
   * @deprecated
   */
  get signOffLocalNormalized(): number {
    return this.signOffLocalNormalizedVO.toSeconds()
  }

  get signOffLocalNormalizedVO(): CyclicTime {
    return this._signOffLocal
  }

  /**
   * @deprecated
   */
  get duration(): number {
    return this.durationVO.toSeconds()
  }

  get durationVO(): Duration {
    return Interval.fromEpochTimes(
      this.signOnLocalVO,
      this.signOffEndLocalVO,
    ).duration()
  }

  /**
   * @deprecated
   */
  get durationWithBuffer(): number {
    return this.durationWithBufferVO.toSeconds()
  }

  get durationWithBufferVO(): Duration {
    return Interval.fromEpochTimes(
      this.signOnLocalVO,
      this.signOffLocalWithBufferVO,
    ).duration()
  }

  get productiveAssignments(): ProductiveAssignment[] {
    if (!this._productiveAssignments) {
      this._productiveAssignments = Shift.sortShiftAssignments([
        ...this.driverAssignments,
        ...this.localAssignments,
        ...this.customTaskAssignments,
        ...this.loadingAssignments,
      ] as ProductiveAssignment[])
    }
    return this._productiveAssignments
  }

  get assignments(): AllAssignmentTypes[] {
    if (!this._assignments) {
      const signOn: IAssignmentEvent = {
        endLocation: this.startAtRemoteRest
          ? this.startAtRemoteRest.location
          : this.pool.loc,
        endTimeLocal: this.signOnEndLocalVO.toSeconds(),
        // the start values are not used, but help typing
        startLocation: this.pool.loc,
        startTimeLocal: 0,
      }

      // Interleave changeover with the productive assignments
      // Note: The only reason we can't just sort the changeovers
      // into the productive assignments is because changeover
      // can have a negative duration.
      const seen = new Set()
      const sorted = this.productiveAssignments.reduce<
        (ProductiveAssignment | ChangeoverAssignment)[]
      >((acc, ass) => {
        if (
          ass instanceof DriverAssignment &&
          ass.startChangeover &&
          !seen.has(ass.startChangeover)
        ) {
          const startAss = new ChangeoverAssignment(
            ass.startChangeover,
            ass.startTimeLocal,
            ass,
          )
          acc.push(startAss)
          seen.add(ass.startChangeover)
        }
        acc.push(ass)
        if (
          ass instanceof DriverAssignment &&
          ass.endChangeover &&
          !seen.has(ass.endChangeover)
        ) {
          const endAss = new ChangeoverAssignment(
            ass.endChangeover,
            ass.endTimeLocal,
            ass,
          )
          acc.push(endAss)
          seen.add(ass.endChangeover)
        }
        return acc
      }, [])

      const signOff = {
        startLocation: this.endAtRemoteRest
          ? this.endAtRemoteRest.location
          : this.pool.loc,
        startTimeLocal: this.signOffStartLocalVO.toSeconds(),
        // the end values are not used, but help typing
        endLocation: this.pool.loc,
        endTimeLocal: 0,
      }

      this._assignments = [...sorted, signOff]
        .reduce<IAssignmentEvent[]>(
          (acc, curr) => {
            const prev = acc[acc.length - 1]
            if (prev.endLocation !== curr.startLocation) {
              const lvTask = new LVTask({
                LV: this.singletons.lv,
                shift: this,
                origin: prev.endLocation,
                destination: curr.startLocation,
                startTimeLocal: prev.endTimeLocal,
                endTimeLocal: curr.startTimeLocal,
              })
              acc.push(lvTask)
            }
            acc.push(curr as any)
            return acc
          },
          [signOn],
        )
        .slice(1, -1) as (
        | ProductiveAssignment
        | ChangeoverAssignment
        | LVTask
      )[]
    }
    return this._assignments
  }

  get lvTasks(): LVTask[] {
    return this.assignments.filter(
      (assign): assign is LVTask => assign instanceof LVTask,
    )
  }

  get changeovers(): Changeover[] {
    if (!this._changeovers) {
      const changeovers = new Set(
        this.driverAssignments.reduce<(LocationChangeover | TrainChangeover)[]>(
          (acc, da) => acc.concat([da.startChangeover, da.endChangeover]),
          [],
        ),
      )
      // Handle start and end assignments that have undefined changeovers
      changeovers.delete(undefined)
      this._changeovers = [...changeovers]
    }
    return this._changeovers
  }

  getResourceProfiles(location: Location) {
    return [
      new ShiftResourceProfile({
        id: this.id,
        lvTasks: this.lvTasks,
        location,
        LV: this.singletons.lv,
      }),
    ]
  }

  get totalWorkingSecs() {
    return this.lvTasks.reduce<Map<IResource, number>>(
      (acc, curr) => sumCounts(curr.totalWorkingSecs, acc),
      new Map(),
    )
  }

  get isAssigned(): boolean {
    return Boolean(this.shiftLine)
  }

  isQualified(
    task: DriverTask | CustomTask | LoadingWorkTask | LegTask<any>,
  ): boolean {
    const needsDriver = task instanceof DriverTask || task.requiresDriver

    if (!needsDriver) {
      return true
    }

    const crewType = this.pool.type
    if (!crewType.isDriver) {
      return false
    }

    if (
      task instanceof CustomTask ||
      task instanceof LoadingWorkTask ||
      task instanceof LegTask
    ) {
      return true
    }

    const routeKnowledges = crewType.routeKnowledges.map(r => r.corridor)
    const { corridors } = task
    const hasRouteKnowledge =
      intersection(corridors, routeKnowledges).length === corridors.length
    return hasRouteKnowledge
  }

  get adjustedPenaltyMultiplier(): number {
    return adjustPenaltyForLeave(
      penaltyMultiplier(
        this.signOnLocalVO.toSeconds(),
        this.signOffLocalWithBufferVO.toSeconds(),
      ),
      this.pool.annualLeaveLoading,
      this.pool.annualLeavePercentage,
    )
  }

  get adjustedHourlyRate(): number {
    return this.pool.adjustedHourlyRate
  }

  get adjustedApmHourlyRate(): number {
    return this.pool.adjustedApmHourlyRate
  }

  /**
   * @deprecated
   */
  get shiftMidpoint(): number {
    return this.shiftMidpointVO.toSeconds()
  }

  get shiftMidpointVO(): EpochTime {
    return Interval.fromEpochTimes(
      this.signOnLocalVO,
      this.signOffEndLocalVO,
    ).midPoint()
  }

  /**
   * @deprecated
   */
  get mealBreakWindowStartLocal(): number {
    return this.mealBreakWindowStartLocalVO.toSeconds()
  }

  get mealBreakWindowStartLocalVO(): EpochTime {
    return this.shiftMidpointVO.makeEarlier(
      this.pool.type.mealBreakWindowSecsVO.divide(2),
    )
  }

  /**
   * @deprecated
   */
  get mealBreakWindowEndLocal(): number {
    return this.mealBreakWindowEndLocalVO.toSeconds()
  }

  get mealBreakWindowEndLocalVO(): EpochTime {
    return this.shiftMidpointVO.makeLater(
      this.pool.type.mealBreakWindowSecsVO.divide(2),
    )
  }

  /**
   * @deprecated
   */
  get mealBreakSecs(): number {
    return this.mealBreakSecsVO.toSeconds()
  }

  get mealBreakSecsVO(): Duration {
    return this.pool.type.mealBreakSecsVO
  }

  get requiresMealBreak(): boolean {
    return this.pool.type.mealBreakShiftSecs < this.durationVO.toSeconds()
  }

  get taskWeights(): [[number, number], number][] {
    if (!this._taskWeights) {
      const timestamps = [
        ...new Set(
          this.productiveAssignments.reduce<number[]>(
            (acc, item) => acc.concat(item.boundsLocal),
            [],
          ),
        ),
      ].sort((a, b) => a - b)

      const taskWeights = []

      for (let i = 0; i < timestamps.length - 1; i++) {
        const curr = timestamps[i]
        const next = timestamps[i + 1]

        const count = this.productiveAssignments.reduce<number>((acc, item) => {
          const { boundsLocal: bounds, sign } = item
          if (inBoundsUnWrapped(bounds as [number, number], [curr, next])) {
            return acc + sign
          }
          return acc
        }, 0)

        taskWeights.push([[curr, next], count])
      }
      this._taskWeights = taskWeights
    }
    return this._taskWeights
  }

  timingChangeWarnings(newSignOn: number, newSignOff: number) {
    const remoteRestAtEnd = this.endAtRemoteRest
    const remoteRestAtStart = this.startAtRemoteRest
    const warnRemoteRestAtEnd =
      remoteRestAtEnd &&
      restDuration(newSignOff, remoteRestAtEnd.endTimeLocal) < 0
    const warnRemoteRestAtStart =
      remoteRestAtStart &&
      restDuration(remoteRestAtStart.startTimeLocal, newSignOn) < 0
    const warnRosteringDeleteImminent =
      this.isAssigned &&
      (newSignOn !== this.signOnLocal || newSignOff !== this.signOffLocal)
    return {
      warnRemoteRestAtEnd,
      warnRemoteRestAtStart,
      warnRosteringDeleteImminent,
    }
  }
}

export { Shift }
