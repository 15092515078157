import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { loadingWorkSplitFactory } from './factory'
import { ILoadingWorkSplitRepo, LoadingWorkSplitRepo } from './repo'

const loadingWorkSplitRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LoadingWorkSplitRepo(loadingWorkSplitFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  loadingWorkSplitRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type LoadingWorkSplitRepoReg = {
  loadingWorkSplitRepo: FromDocumentFactory<FactoryInput, ILoadingWorkSplitRepo>
}

export function setup<R extends LoadingWorkSplitRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('loadingWorkSplitRepo', loadingWorkSplitRepo)

  relationships.addConstraints('loadingworksplits', {
    unique: [['templateLegId', 'startId', 'kind']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
    ],
  })
}

export { LoadingWorkSplit } from './model'
