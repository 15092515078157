import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { TransitTimes } from './model'

export interface ITransitTimesRepo extends IRepo<TransitTimes> {
  byCorridorId(id: string): TransitTimes[]
  byTrainTypeId(id: string): TransitTimes[]
}

export class TransitTimesRepo implements ITransitTimesRepo {
  values: TransitTimes[]
  idMap: { [key: string]: TransitTimes }
  corridorIdMap: Indexer<TransitTimes>
  trainTypeIdMap: Indexer<TransitTimes>

  constructor(values: TransitTimes[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.corridorIdMap = new Indexer(values, x => x.corridorId)
    this.trainTypeIdMap = new Indexer(values, x => x.trainTypeId)
  }

  byId(id: string): TransitTimes {
    return this.idMap[id]
  }

  byCorridorId(id: string): TransitTimes[] {
    return this.corridorIdMap.lookup(id)
  }

  byTrainTypeId(id: string): TransitTimes[] {
    return this.trainTypeIdMap.lookup(id)
  }
}
