import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { routeKnowledgeFactory } from './factory'
import { IRouteKnowledgeRepo, RouteKnowledgeRepo } from './repo'

const routeKnowledgeRepo = defaultMemoize(
  (input: FactoryInput) => new RouteKnowledgeRepo(routeKnowledgeFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  routeKnowledgeRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type RouteKnowledgeRepoReg = {
  routeKnowledgeRepo: FromDocumentFactory<FactoryInput, IRouteKnowledgeRepo>
}

export function setup<R extends RouteKnowledgeRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('routeKnowledgeRepo', routeKnowledgeRepo)

  relationships.addConstraints('routeknowledge', {
    unique: [['corridorId', 'crewTypeId']],
    rels: [
      {
        collection: 'corridors',
        foreign: 'corridorId',
        name: 'corridor',
      },
      {
        collection: 'crewtypes',
        foreign: 'crewTypeId',
        name: 'crewType',
      },
    ],
  })
}

export { RouteKnowledge } from './model'
