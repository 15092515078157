import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { LocationChangeoverLock } from '.'

export interface ILocationChangeoverLockRepo
  extends IRepo<LocationChangeoverLock> {
  byChangeoverId(changeoverId: string): LocationChangeoverLock
}

export class LocationChangeoverLockRepo implements ILocationChangeoverLockRepo {
  values: LocationChangeoverLock[]
  idMap: { [key: string]: LocationChangeoverLock }
  changeoverIdMap: { [key: string]: LocationChangeoverLock }

  constructor(values: LocationChangeoverLock[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.changeoverIdMap = Object.fromEntries(
      values.map(v => [v.changeoverId, v]),
    )
  }

  byId(id: string): LocationChangeoverLock {
    return this.idMap[id]
  }

  byChangeoverId(changeoverId: string): LocationChangeoverLock {
    return this.changeoverIdMap[changeoverId] || null
  }
}
