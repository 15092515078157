import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  RootState,
} from 'src/service-design/shared/document-factory/factory-input'

import { yardBlockAssignmentFactory } from './factory'
import { getYardBlockAssignmentRepairs } from './repairs'
import { IYardBlockAssignmentRepo, YardBlockAssignmentRepo } from './repo'

const yardBlockAssignmentRepo = defaultMemoize(
  (input: FactoryInput) =>
    new YardBlockAssignmentRepo(yardBlockAssignmentFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  yardBlockAssignmentRepo(input),
)
export const values = (state: RootState) => selector(state).values

export type YardBlockAssignmentRepoReg = {
  yardBlockAssignmentRepo: FromDocumentFactory<
    FactoryInput,
    IYardBlockAssignmentRepo
  >
}

export function setup<R extends YardBlockAssignmentRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('yardBlockAssignmentRepo', yardBlockAssignmentRepo)

  relationships.addConstraints('yardblockassignments', {
    unique: [['yardBlockId']],
    rels: [
      {
        collection: 'trainblocks',
        foreign: 'trainBlockId',
        name: 'trainBlock',
      },
      {
        collection: 'yardblocks',
        foreign: 'yardBlockId',
        name: 'yardBlock',
      },
      {
        collection: 'templatelegs',
        foreign: 'departingLegId',
        name: 'departingLeg',
      },
    ],
  })

  repairs.register(createSelector(values, getYardBlockAssignmentRepairs))
}

export { YardBlockAssignment } from './model'
