// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { ScenarioTypes } from 'src/service-design/scenario/document/types'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { ServiceDesignTypes } from 'src/service-design/sd-plan/document/types'
import { CollectionData } from 'src/service-design/shared/document-factory/factory-input'
import { Relationships } from 'src/service-design/shared/document/relationships'

import { BrokenRelationship } from './objects'

export const findBrokenRelations = (
  relationships: Relationships<
    ServiceDesignTypes,
    ScenarioTypes & ServiceDesignTypes
  >,
  collectionData: CollectionData,
) => {
  const problems = []
  for (const collectionName of relationships.collections) {
    for (const relation of relationships.getRels(collectionName)) {
      const targetIds = new Set(
        (collectionData[relation.collection] as any[]).map(x => x.id),
      )

      for (const obj of collectionData[collectionName] || []) {
        // @ts-ignore type-safe is a nice idea
        const foreignValue = obj[relation.foreign]
        const isInvalid = foreignValue !== null && !targetIds.has(foreignValue)
        if (isInvalid) {
          problems.push(
            new BrokenRelationship({
              collectionName,
              affectedObj: obj,
              relationDefinition: relation,
            }),
          )
        }
      }
    }
  }
  return problems
}
