import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class PreDeparture<T extends IStartLeg> extends LegTask<T> {
  /**
   * PreDeparture represents the time between a TrainStarts initial movement
   * out of a location and it hitting a Locations 'yard limit'. Generally a
   * Rail Operator will be required to perform a 'roll-by' inspection to ensure
   * all cargo, wagons and locos is safely attached.
   */
  static arrivingTask = false

  static kind = 'pre-departure'

  static requiresRailOperator = true

  static timeOffsetAllowed = false

  static build<S extends IStartLeg>(startLeg: S) {
    return new this({
      origin: startLeg.origin,
      destination: startLeg.origin,
      startTimeLocal: startLeg.preDepartStartLocal,
      endTimeLocal: startLeg.preDepartEndLocal,
      startLeg,
    })
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(
      this.duration,
      this.startLeg.consist.workingDict,
      this.startLeg.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.startLeg.consist.hauledDict)
  }
}
