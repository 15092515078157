import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { StartLegTaskOffset } from './model'

const POSITIVE_KINDS = new Set(['detach', 'unloading'])
const NEGATIVE_KINDS = new Set(['attach', 'loading'])

export class OffsetMustBePositive extends InstanceRepair {
  static collectionName = 'startlegtaskoffsets'

  static type = 'service-design::Start Leg Task Offset has invalid offset'

  static message =
    'service-design::Start Leg Task Offset ({{entity.id}}) for {{entity.kind}} must have a positive offset.'

  static check(offset: StartLegTaskOffset) {
    return POSITIVE_KINDS.has(offset.kind) && offset.offset < 0
  }
}

export class OffsetMustBeNegative extends InstanceRepair {
  static collectionName = 'startlegtaskoffsets'

  static type = 'service-design::Start Leg Task Offset has invalid offset'

  static message =
    'service-design::Start Leg Task Offset ({{entity.id}}) for {{entity.kind}} must have a negative offset.'

  static check(offset: StartLegTaskOffset) {
    return NEGATIVE_KINDS.has(offset.kind) && offset.offset > 0
  }
}

export const getStartLegTaskOffsetRepairs = getRepairFactory([
  OffsetMustBePositive,
  OffsetMustBeNegative,
])
