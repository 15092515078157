import { createSelector } from 'reselect'

import { IFilters } from 'src/service-design/sd-plan/reducers/filters'

export const getQueryString = (state: any) => state.router.location.search

export const getFilters = (state: any): IFilters => state.ui.filters

export const getTrainFilter = createSelector(
  getFilters,
  filters => filters.trains,
)

export const getServiceFilter = createSelector(
  getFilters,
  filters => filters.services,
)

export const getCrewFilter = createSelector(
  getFilters,
  filters => filters.crews,
)

export const getCustomTasksFilter = createSelector(
  getFilters,
  filters => filters.customTasks,
)

export const getYardBlocksFilter = createSelector(
  getFilters,
  filters => filters.yardBlocks,
)

export const isAdvancedFilter = (filter: {}) => {
  for (const [key, value] of Object.entries(filter)) {
    if (value && key !== 'name') {
      return true
    }
  }
  return false
}

export type FilterFn<T extends { name: string }, A extends {}> = (
  value: T,
  filter: A,
) => boolean

export const filterItems = <T extends { name: string }, A extends {}>(
  {
    simple,
    advanced,
    advancedActivated,
  }: { simple: string; advanced: A; advancedActivated: boolean },
  items: T[],
  behaviours: FilterFn<T, A>[],
) => {
  if (
    !simple &&
    (!Object.keys(advanced).length || !advancedActivated || !behaviours)
  ) {
    return items
  }

  return items.filter(
    item =>
      (!simple || item.name.toLowerCase().includes(simple.toLowerCase())) &&
      (!advancedActivated ||
        !behaviours ||
        behaviours.every(behaviour => behaviour(item, advanced))),
  )
}

export const oneOf = (valueSet: Set<string>, value: string) => {
  if (!value) {
    return true
  }
  if (value === '*') {
    if (!valueSet.size) {
      return false
    }
  } else if (!valueSet.has(value)) {
    return false
  }
  return true
}
