import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { ShiftLine } from './model'

export const shiftLineFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    collectionData.shiftlines.map(data => new ShiftLine(data)),
  setRelsFromSpec((shiftLine: ShiftLine, state: any) => ({
    shift: registry.provide('shiftRepo')(state).byId(shiftLine.shiftId),
    rosterLine: registry
      .provide('rosterLineRepo')(state)
      .byId(shiftLine.rosterLineId),
  })),
)
