import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { Wagon } from 'src/service-design/shared/models/wagon'

interface Attrs {
  id: string
  cargoTypeId: string
  wagonId: string
  volume: number
}

interface Rels {
  wagon: Wagon
  cargoType: CargoType
}

class WagonCargoCompatibility extends Mapper {
  /**
   * A WagonCargoCompatibility describes a Wagon ability to be loaded with a
   * CargoType.
   *
   * Related models:
   * - `CargoType`;
   * - `Wagon`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} cargoTypeId - The id of the CargoType
   * @param {string} wagonId - The id of the Wagon
   * @param {number} volume - The number of cargoTyoe.volumeUnit
   *  that can be loaded on to a Wagon.
   */
  constructor({ id, cargoTypeId, wagonId, volume }: Attrs) {
    super()
    this.id = id
    this.cargoTypeId = cargoTypeId
    this.wagonId = wagonId
    this.volume = volume
  }

  setRels({ wagon, cargoType }: Rels) {
    this.wagon = wagon
    this.cargoType = cargoType
  }
}

interface WagonCargoCompatibility extends Attrs, Rels {}
export { WagonCargoCompatibility }
